import { takeEvery } from "redux-saga/effects";

import {
  userLogin,
  userSignUp,
  getOTP,
  verifyOTP,
  forgotSentEmail,
  updatePassword,
  confirmEmailWithOtp,
  socailLogin,
} from "./userSaga";
import {
  getEszCommitteeFilterListData,
  getEszCommitteeTracker,
  getEszNotificationFilterListData,
  getEszNotificationTracker,
  getFilterListData,
  getFilterUIDataSaga,
  getNearByPAs,
  getProtectedAreas,
} from "./ProtectedAreaSaga";
import {
  getGlobalSearchInfo,
  getHomePageData,
  getHomePageMapData,
  getMeetingDateInfo,
  getNbwlFilterListData,
  getNbwlTableData,
} from "./nbwlSaga";
import {
  getAllBills,
  getAllReports,
  getBillsFilterListData,
  getOverViewData,
  getQuestionFilterListData,
  getQuestionMapData,
  getQuestionMapFilterListData,
  getQuestionTableData,
  getReportsFilterListData,
  getDebatesTableData,
  getDebatesFilterListData,
  postFeedBackForm,
} from "./parliamentUpdates";
import {
  getAllPublications,
  getProfileInformation,
  getPublicationFilterListData,
} from "./publicationSaga.js";
import { postNewsLetter } from "./homeSaga";

const watchUser = function* watchUser() {
  yield takeEvery("USER_LOGIN_SAGA", userLogin);
  yield takeEvery("SOCIAL_LOGIN", socailLogin);
  yield takeEvery("USER_CREATE_SAGA", userSignUp);
  yield takeEvery("USER_GET_OTP", getOTP);
  yield takeEvery("USER_VERIFY_OTP", verifyOTP);
  yield takeEvery("USER_SEND_RESET", forgotSentEmail);
  yield takeEvery("CONFIRM_EMAIL_OTP", confirmEmailWithOtp);
  yield takeEvery("UPDATE_PASSWORD", updatePassword);
  yield takeEvery("GET_ALL_PA", getProtectedAreas);
  yield takeEvery("PA_FILTER_LIST_DATA", getFilterListData);
  yield takeEvery("GET_FILTER_UI_DATA", getFilterUIDataSaga);

  yield takeEvery("NEAR_BY_PAS", getNearByPAs);
  yield takeEvery("ESZ_NOTIF_DATA", getEszNotificationTracker);
  yield takeEvery("ESZ_COMMITTEE_DATA", getEszCommitteeTracker);
  yield takeEvery("ESZ_NOTIF_FILTER_DATA", getEszNotificationFilterListData);
  yield takeEvery("ESZ_COMMITTEE_FILTER_DATA", getEszCommitteeFilterListData);
  yield takeEvery("NBWL_FILTER_DATA", getNbwlFilterListData);
  yield takeEvery("GET_NBWL_DATA", getNbwlTableData);
  yield takeEvery("QUESTION_OVERVIEW_DATA", getOverViewData);
  yield takeEvery("QUESTION_FILTER_DATA", getQuestionFilterListData);
  yield takeEvery("GET_QUESTION_DATA", getQuestionTableData);
  yield takeEvery("GET_QUESTION_MAP_STATES", getQuestionMapData);
  yield takeEvery("QUESTION_MAP_FILTER_DATA", getQuestionMapFilterListData);
  yield takeEvery("GET_ALL_BILLS", getAllBills);
  yield takeEvery("BILLS_FILTER_DATA", getBillsFilterListData);
  yield takeEvery("REPORTS_FILTER_DATA", getReportsFilterListData);
  yield takeEvery("GET_ALL_REPORTS", getAllReports);
  yield takeEvery("GET_ALL_PUBLICATIONS", getAllPublications);
  yield takeEvery("PUBLICATIONS_FILTER_DATA", getPublicationFilterListData);
  yield takeEvery("DEBATES_FILTER_DATA", getDebatesFilterListData);
  yield takeEvery("GET_ALL_DEBATES", getDebatesTableData);
  yield takeEvery("POST_FEEDBACK", postFeedBackForm);
  yield takeEvery("PROFILE_INFO", getProfileInformation);
  yield takeEvery("POST_NEWS_LETTER", postNewsLetter);
  yield takeEvery("GET_HOMEPAGE_DATA", getHomePageData);
  yield takeEvery("GET_HOMEPAGE_MAP_DATA", getHomePageMapData);
  yield takeEvery("GET_MEETING_INFO", getMeetingDateInfo);
  yield takeEvery("GLOBAL_SEARCH", getGlobalSearchInfo);

  // yield takeEvery("GET_DEBAT_INFO", );
};

const rootSaga = function* mySagas() {
  yield watchUser();
};

export default rootSaga;
