import { Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const Toaster = () => {
  const message = useSelector( (state) => state.commonReducer.snackbarMessage)
  const open = useSelector( (state) => state.commonReducer.showSnakbar)
  const dispatch = useDispatch();

  console.log("messge in Toster", message)
  console.log("open in Toster", open)

  const [state, setState] = React.useState({
    // open: false,
    vertical: 'bottom',
    horizontal: 'right',
  });
  
  const handleClose = () => {
    dispatch({type : "HIDE_SNACKBAR"})
  };
  const { vertical, horizontal } = state;
  return (
    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        autoHideDuration={6000}
        key={vertical + horizontal}
      />
  )
}

export default Toaster