import { Button, Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { ButtonPrimary, ButtonText } from '../StyledComponents/StyledComponents'
import { Circle, CircleInner } from '../StyledComponents/ToggleThemeIcon'

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';


import { useContext } from 'react'
import { ColorModeContext } from '../../ColorMode/ColorMode'
import {ReactComponent as ToggleIcon} from '../../assets/SvgIcons/toggleModeIcon.svg'
import {ReactComponent as ToggleIconDark} from '../../assets/SvgIconsDarkMode/icon=icon28.svg'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getItem, setItem } from '../../utils/validators';



const TopBar = () => {
  const {fontSize, DecreaseFontSize, defaultFontSize, IncreaseFontSize, colorMode }= useContext(ColorModeContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const user = useSelector( (state) => state.userReducer.loginData)
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  
  console.log("user...",user)
  const isUserLoggedIn = getItem('isUserLoggedIn')

  return (
   <Grid
    item
    container 
    justifyContent="flex-end"
    alignItems="center"
    sx={{ height : 52, pr : "2%", width :"100%", backgroundColor : (theme) => theme.palette.mode === "light" ? theme.palette.secondary.dark : theme.palette.background.default, gap : "20px"}}>

     {/* {theme.palette.mode == "dark" ? <p style={{color : "white"}}>Dark</p> : <p style={{color : theme.vidhi.red}}>Light</p>} */}
     
       {/* Buttons Container  and responsive not of matches to hide when screen reaches to md size*/}
       {
          !matches && (

       
      <Grid item xs container justifyContent="flex-end" alignItems={"center"}>
      <Grid item>
        <ButtonText 
        disabled={fontSize == -4} 
        sx={{fontSize : 14}}
         onClick={DecreaseFontSize}>A-</ButtonText>
      </Grid>

      <Grid item onClick={defaultFontSize}>
       <ButtonText
        disabled ={fontSize == 0} 
        sx={{fontSize : 16}}
         onClick={defaultFontSize}>A</ButtonText>
      </Grid>

      <Grid item >
        <ButtonText 
        disabled={fontSize == 4} 
        sx={{fontSize : 18}}
        onClick={IncreaseFontSize}>A+</ButtonText>
      </Grid>

      </Grid>
         )
        }
 
     {/* <Grid item>
      {
        theme.palette.mode == "light" ? ( <ToggleIcon onClick={()=>colorMode.toggleColorMode()} style={{paddingTop : 10}} /> ) :
        ( 
        // <ToggleIconDark onClick={colorMode.toggleColorMode}/>
          <Circle blackIcon onClick={colorMode.toggleColorMode} >
    <CircleInner blackIcon></CircleInner> 
    </Circle> 
        ) 
      }
      </Grid> */}


    
      {
        theme.palette.mode == "light" ? ( <LightModeIcon onClick={()=>colorMode.toggleColorMode()} /> ) :
        ( 
        <DarkModeIcon onClick={colorMode.toggleColorMode}/>
   
        ) 
      }
      
{/* 
     <Circle onClick={()=>colorMode.toggleColorMode()}>
       <CircleInner></CircleInner> 
    </Circle>    */}

    {/* <Circle blackIcon>
    <CircleInner blackIcon></CircleInner> 
    </Circle> */}

    {/* Username if user LoggedIN */}
    
    
    
    
   {!isUserLoggedIn ? <ButtonPrimary
    onClick={()=>navigate("/login")}
    sx={{ height : 36}}>Login/Registration</ButtonPrimary> : <ButtonPrimary
    onClick={()=>{
      navigate("/login")
      setItem("isUserLoggedIn","")
    }}
    sx={{ height : 36}}>Logout</ButtonPrimary>}
   

   </Grid>
  )
}

export default TopBar;