import React, {useState} from 'react'
import { Dialog, Grid, Typography } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { ButtonPrimary, FieldStyled } from '../../components/StyledComponents/StyledComponents';
import * as VALIDATOR from "../../utils/validators"
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader'



const ReportSpeciesSchedule = ({open, closeReportForm, paName, paId}) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);
  const [name , setName] = useState("")
  const [email, setEmail] = useState("")
  const [feedback, setFeedback] = useState("")
  

  const [emptyNameErr, setEmptyNameErr] = useState(false)
  const [emptyEmailErr, setEmptyEmailErr] = useState(false)
  const [invalidEmailErr, setInvalidEmailErr] = useState(false)
  const [emptyFeedbackErr, setEmptyFeedbackErr] = useState(false)




  const handleSendfeedback = () => {
    let isValid = true;
  
    if (name.trim().length === 0) {
      setEmptyNameErr(true);
      isValid = false;
    } else {
      setEmptyNameErr(false);
    }
  
    if (email.trim().length === 0) {
      setEmptyEmailErr(true);
      isValid = false;
    } else if (!VALIDATOR.isEmail(email)) {
      setEmptyEmailErr(false);
      setInvalidEmailErr(true);
      isValid = false;
    } else {
      setInvalidEmailErr(false);
    }
  
    if (feedback.trim().length === 0) {
      setEmptyFeedbackErr(true);
      isValid = false;
    } else {
      setEmptyFeedbackErr(false);
    }

    if (isValid) {
      dispatch({
        type : "POST_FEEDBACK",
        payload : {
          name : name,
          email : email,
          feedBackText : feedback,
          feedBackTypeNumber : 1, //scheduled 1
          paId: paId,
          successCallBack : successCallBack
        }
      })
    }
     
     }
  

     const successCallBack = () => {
       closeReportForm()
     }

  return (
    <Dialog
    open={open}
    onClose={closeReportForm}
    sx={{
      "& .MuiPaper-root": {
        minWidth: "40vw",
        // height : "80vw",
        // margin : 0,
        // backgroundColor : 'khaki'
      },
    }}
  >
    <Grid 
    container 
    sx={{ p : '30px', gap :'12px'}}
    alignContent={"flex-start"}
    direction="column"
    >
     

     <Grid
        item
        sx={{ width: "100%", position: "relative" }}
      >
         <Typography variant='h2'>Report Missing Species</Typography>
        <CloseIcon
          onClick={closeReportForm}
          sx={{ position: "absolute", right: 0, top: 0 }}
        />
      </Grid>

   
    <Typography variant='body1' color="text.secondary">Our friendly team would love to hear from you</Typography>

 
    <Grid container>
    <Typography variant='body1' color="vidhiRed.main" >Protected Area : </Typography>
    <Typography variant='body1' sx={{ml : '4px'}} >{paName}</Typography>
    </Grid>
     
    <Grid item>
          <Typography>Name</Typography>
          <FieldStyled
          variant='outlined' 
          fullWidth
          placeholder='Enter your name'
          value={name}
          onChange={(e)=>setName(e.target.value)}
           onFocus={()=>{
            setEmptyNameErr(false)
           }}
          />
          {
            emptyNameErr && 
            <Typography variant='body2' color="vidhiRed.main">Name Required</Typography>
          }
     </Grid>

     <Grid item>
          <Typography>Email</Typography>
          <FieldStyled
          variant='outlined' 
          fullWidth
          placeholder='Enter your email'
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
          onFocus={()=>{
            setEmptyEmailErr(false)
            setInvalidEmailErr(false)
           }}
          />
          {
            emptyEmailErr && 
            <Typography variant='body2' color="vidhiRed.main">Email Required</Typography>
          }
          {
            invalidEmailErr && 
            <Typography variant='body2' color="vidhiRed.main">Invalid Email</Typography>
          }
     </Grid>

     <Grid item>
          <Typography >Feedback (Scheduled 1 species)</Typography>
          <FieldStyled
          fullWidth
           multiline
           minRows={2}
           placeholder='Leave us a message...'
          value={feedback}
          onChange={(e)=>setFeedback(e.target.value)}
           onFocus={()=>{
            setEmptyFeedbackErr(false)
           }}
          />
          {
            emptyFeedbackErr && 
            <Typography variant='body2' color="vidhiRed.main">Feedback Required</Typography>
          }
     </Grid>
    
     {
        loader ? <Loader /> : (
          <ButtonPrimary onClick={handleSendfeedback}>Send Feedback</ButtonPrimary>

        )
      }
 
      
   </Grid>


  </Dialog>
  )
}

export default ReportSpeciesSchedule