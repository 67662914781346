import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  InputBase,
  Typography,
  useTheme,
  Dialog,
  Divider,
  DialogActions,
  FormControl,
  useMediaQuery
} from "@mui/material";
import LinkBar from "../../components/LinkBar/LinkBar";
import SearchIcon from "@mui/icons-material/Search";

import FilterListIcon from "@mui/icons-material/FilterList";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {
  SearchBarStyled,
  FilterStyled,
  ButtonOutlined,
  ButtonPrimary,
  FieldStyled,
  MyBox,
} from "../../components/StyledComponents/StyledComponents";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import * as CONST from '../../utils/Contants'

import MySelect from "../../components/Select/MySelect";
import MySelectMultiple from "../../components/Select/MySelectMultiple";
import NoDataFoundComponent from "../../components/NoDataFoundUI/NoDataFound";
import MyDateRange from "../../components/DateRange/MyDateRange";
import { handleDateFormatforFilter } from "../../utils/StatusStyle";
import axios from "axios";
import { showSnackbar } from "../../utils/PdfUtils";

const AllBills = () => {
  const navigate = useNavigate();
  const theme = useTheme()
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));

  // const [data, setData] = useState(Array.from({length : 20}))
  const [openFilter, setOpenFilter] = React.useState(false);
  const [pageReset, setPageReset] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");


  const [allBills, setAllBills] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [scrollLoader, setScrollLoader] = useState(false)



  //states for holding filter's item's data Listing
  const [houseList, setHouseList] = useState("");
  const [sessionNumberList, setSessionNumberList] = useState("");
  const [billTypeList, setBillTypeList] = useState("");
  // const [billNumberList, setQuestionNumberList] = useState("");
  const [memberOfPaList, setMemberOfPaList] = useState("");
  const [ministryList, setMinistryList] = useState("");
  const [billThemeList, setBillThemeList] = useState([]);
  const [statusList, setStatusList] = useState("");

  //states for holding filter's item's value
  const [house, setHouse] = useState("");
  const [session, setSession] = useState("");
  const [billType, setBillType] = useState("");
  const [memberOfPa, setMemberOfPa] = useState("");
  const [ministry, setMinistry] = useState("");
  const [status, setStatus] = useState("");
  const [billThemes, setBillThemes] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);



  
  useEffect(() => {
    // getBillsList()
    getFilterListData()
  }, []);

  
  useEffect(() => {
    console.log("useEffect single",page)
    fetchMoreData(page)
  }, []);

  useEffect(() => {
    console.log("useEffect",page)
    if(isFilterApplied){
    console.log("useEffect isFilter APplied true and page is also changing useEffect and page",page)
       fetchMoreData(page)
    }
    if(pageReset){
      fetchMoreData(page)
     }
  }, [page, isFilterApplied, pageReset]);
  


  const getFilterListData = () => {
    dispatch({
      type: "BILLS_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
      })
  }
  


  const successCallBackFilterListData = (data) => {
    setHouseList(data?.house)
    setSessionNumberList(data?.sessions)
    setBillTypeList(data?.type)
    setMemberOfPaList(data.member_of_parliament)
    setMinistryList(data?.ministry)
    setStatusList(data?.status)
    setBillThemeList(data?.theme_subject)
  };

  
  
  const successCallBackScroll = (res) => {
    setScrollLoader(false)
    if(res?.data?.data.length < 1){
      setHasMore(false)
      return
    }
    setPageReset(false)
    let data = res?.data?.data;
    setAllBills((bills) => [...bills, ...data]);
    setPage((p) => p + 1);
  }

  const fetchMoreData = async () => {
    setScrollLoader(true)
    let path = `${CONST.BASE_URL}/contents/bills?less_details=true&page=${page}&per_page=10`;
    if(isFilterApplied){
     let isSearching = false;
    if(house){
      isSearching = true
      path = path + `&house_id[]=${house}`
    }
    if(session){
      isSearching = true
      path = path + `&session_number_id[]=${session}`
    }
    if(billType){
      isSearching = true
      path = path + `&type_of_bill=${billType}`
    }
    if(billThemes){
      isSearching = true
      path = path + handleThemePath()
    }
    if(memberOfPa){
      isSearching = true
      path = path + `&member_of_parliament_id[]=${memberOfPa}` 
    }
    if(status){
      isSearching = true
      path = path + `&status=${status}`
    }
    if(ministry){
      isSearching = true
      path = path + `&ministry_id[]=${ministry}` 
    }
    if(dateRange[0].startDate && dateRange[0].endDate ){
      isSearching = true
      path = path + `&date_of_intro_start=${handleDateFormatforFilter(dateRange[0].startDate)}&date_of_intro_end=${handleDateFormatforFilter(dateRange[0].endDate)}`
    }
    if(isSearching){
      path = path + "&search=true"
    }
    }
    
    try {
      await axios.get(path)
      .then(res => {
          successCallBackScroll(res)
        });
    } catch (error) {
      scrollLoader(false)
      showSnackbar("Server Error!",dispatch)
    }
       
    }
    
  
  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };
   
  const handleThemePath = () => {
    let str = ""
    billThemes.forEach((item)=> {
      str = str + `&theme_subjects_id[]=${item.id}`
    })
    return str;
  };
  
  const handleFilterApply = () => {
    setAllBills([])
    setPage(1)
    setIsFilterApplied(true)
    setOpenFilter(false)
   };

 const handleFilterReset = () => {
    setHouse("")
    setSession("")
    setBillType("");
    setMemberOfPa("");
    setMinistry("");
    setBillThemes([]);
    setStatus("")
    setDateRange((date) =>  {
      return [{...date[0], startDate : new Date(), endDate : null}]
      })
     setIsFilterApplied(false) 
     setOpenFilter(false)
     setAllBills([])
     setPage(1)
     setHasMore(true)
     setPageReset(true)
    
  };


  const handleMemberDisable = () => {
   if (billType === 0) {
      return true;
    } 
  };

  const handleMinistryDisable = () => {
    if(billType === 1) {
      return true;
    } 
  };

  const handleChangeBillThemes = (event) => {
    const {
      target: { value },
    } = event;
    setBillThemes(typeof value === "string" ? value.split(",") : value);
  };

 
  const renderNoDatafound = () => {
    return <NoDataFoundComponent data={allBills} title={"No Bills Found"} loader={loader} searchValue={searchValue} />
    
  };

  return (
    <>
      <Grid
        item
        container
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === "dark"
              ? t.palette.background.default
              : t.palette.grey[100],
        }}
      >
        <LinkBar title="Bills/Act" />

        {/* first line */}
        <Grid item xs={12} container 
         sx={{
          px: isXs ? "16px" : '32px',
          my: "1.5rem",
          justifyContent: "space-between",
          alignItems: "center",
          rowGap : "16px"
        }}
        >
         <Typography 
            sx={{width : isXs ? "100%" : "auto"}}
            variant="h2">All Bills/Act</Typography>
            <Box
            >
              <SearchBarStyled>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search here"
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </SearchBarStyled>
              {/* {page} {house} */}
            </Box>
            {
              isXs ? (
                isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}}/>)
              )  : (
                <FilterStyled isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
              )
            }

           </Grid>

       
        {/* card section */}
        {
          loader ? <MyBox sx={{width : 1, height : "50vh"}}><Loader /></MyBox> : (
            
        <Grid item xs={12}>
        <InfiniteScroll
          dataLength={allBills?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4 style={{ textAlign: "center", display : scrollLoader ? "block" : "none" }}>{}Loading...</h4>}
         >
      
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(3,1fr)",
                  lg: "repeat(4,1fr)",
                  xl: "repeat(4,1fr)",
                },
                gap: "50px 50px",
                gridGap: "50px 50px",
                px: isXs ? "16px" : "32px",
                pb: "32px",
                boxSizing: "border-box",
              }}
            >
              {allBills?.filter((item) =>
                          item.title
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase())
                        ).map((item) => (
                <Card
                  key={item?.id}
                  item={item}
                  title={item?.title}
                  subtitle={item?.small_detail}
                  // cardImg={debatesImg}
                  cardImg={item?.thumbnail_detail?.link}
                  date={item?.date}
                  to="/bill"
                />
              ))}
            </Box>

         
         
        </InfiniteScroll>
          {renderNoDatafound()}
          </Grid>
         
         )
        }
      </Grid>

        {/* card section end    */}

      {/* Filter Dialog start here */}
      <Dialog
        fullScreen={isXs}
        open={openFilter}
        onClose={handleFilterClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "90vw",
           },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(3,1fr)",
                  lg: "repeat(3,1fr)",
                  xl: "repeat(3,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              {/* PA Type Select     */}
             <FormControl fullWidth>
                <Typography>House</Typography>
                <MySelect
                 value={house}
                 onChange={e=>setHouse(e.target.value)}
                 menuArray={houseList}
                />
              </FormControl>

              {/* Session Number */}
              <FormControl fullWidth>
                <Typography>Session Number</Typography>
                <MySelect
                 value={session}
                 onChange={e=>setSession(e.target.value)}
                 menuArray={sessionNumberList}
                />
              </FormControl>

              {/* Date */}
              <FormControl fullWidth>
                <Typography>Date</Typography>
                <MyDateRange
                onChange={(item) => setDateRange([item.selection])}
                dateRange={dateRange}
                />
               
              </FormControl>

              {/* Type */}
              <FormControl fullWidth>
                <Typography>Type</Typography>
                <MySelect
                  value={billType}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setBillType(e.target.value)}

                  }
                  menuArray={billTypeList}
                />
                </FormControl>


               {/*Member of Parliament*/}
              <FormControl fullWidth>
                <Typography>Member of Parliament</Typography>
                <MySelect
                  value={memberOfPa}
                  onChange={(e) => {
                    if(billType === ""){
                      setBillType(1)
                    }
                    setMemberOfPa(e.target.value)
                  }}
                  mydisabled
                  handleDisable={handleMemberDisable()}
                  menuArray={memberOfPaList}
                 />
              </FormControl>

              {/* Ministry*/}
              <FormControl fullWidth>
                <Typography>Ministry</Typography>
                <MySelect
                  value={ministry}
                  onChange={(e) => {
                    if(billType === ""){
                      setBillType(0)
                    }
                    setMinistry(e.target.value)
                  }}
                  mydisabled
                  handleDisable={handleMinistryDisable()}
                  menuArray={ministryList}
                  />
              </FormControl>

              {/* Theme/Subject*/}
              <FormControl fullWidth>
                <Typography>Theme/Subject</Typography>
                <MySelectMultiple
                 value={billThemes}
                 onChange={handleChangeBillThemes}
                 menuArray={billThemeList}
                />
              </FormControl>

              {/* Status*/}
              <FormControl fullWidth>
                <Typography>Status</Typography>
                <MySelect
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  menuArray={statusList}
                />
              </FormControl>

             </Box>

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined
            onClick={handleFilterReset}
            sx={{ textTransform: "none" }}
          >
            Reset
          </ButtonOutlined>
          <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllBills;


// const AllBills = () => {
//   const navigate = useNavigate();
//   const theme = useTheme()
//   const dispatch = useDispatch();
//   const loader = useSelector((state) => state.commonReducer.showLoader);

//   let isXs = false;
//   isXs = useMediaQuery(theme.breakpoints.down('sm'));

//   // const [data, setData] = useState(Array.from({length : 20}))
//   const [openFilter, setOpenFilter] = React.useState(false);
//   const [isFilterApplied, setIsFilterApplied] = React.useState(false);
//   const [searchValue, setSearchValue] = useState("");


//   const [allBills, setAllBills] = useState([]);
//   const [totalRecords, setTotalRecords] = useState(0);
//   const [page, setPage] = useState(1);
//   const [hasMore, setHasMore] = useState(true);



//   //states for holding filter's item's data Listing
//   const [houseList, setHouseList] = useState("");
//   const [sessionNumberList, setSessionNumberList] = useState("");
//   const [billTypeList, setBillTypeList] = useState("");
//   // const [billNumberList, setQuestionNumberList] = useState("");
//   const [memberOfPaList, setMemberOfPaList] = useState("");
//   const [ministryList, setMinistryList] = useState("");
//   const [billThemeList, setBillThemeList] = useState([]);
//   const [statusList, setStatusList] = useState("");

//   //states for holding filter's item's value
//   const [house, setHouse] = useState("");
//   const [session, setSession] = useState("");
//   const [billType, setBillType] = useState("");
//   const [memberOfPa, setMemberOfPa] = useState("");
//   const [ministry, setMinistry] = useState("");
//   const [status, setStatus] = useState("");
//   const [billThemes, setBillThemes] = useState([]);
//   const [dateRange, setDateRange] = useState([
//     {
//       startDate: new Date(),
//       endDate: null,
//       key: "selection",
//     },
//   ]);



  
//   useEffect(() => {
//     // getBillsList()
//     getFilterListData()
//   }, []);

  
//   useEffect(() => {
//     fetchMoreData(page)
//   }, []);

//   useEffect(() => {
//     if(isFilterApplied){
//       fetchMoreData(page)
//       }
//   }, [page, isFilterApplied]);
  

//   // const getBillsList = () => {
//   //   dispatch({
//   //     type: "GET_ALL_BILLS",
//   //     successCallBack: successCallBack,
//   //     path: `/contents/bills?less_details=true&page=${page}&per_page=10`,
//   //   })
//   // }

//   const getFilterListData = () => {
//     dispatch({
//       type: "BILLS_FILTER_DATA",
//       successCallBack: successCallBackFilterListData,
//       })
//   }
  
//   // const getBillsFilteredData = (path) => {
//   //   dispatch({
//   //     type: "GET_ALL_BILLS",
//   //     successCallBack: successCallBackForFilteredData,
//   //     path: path,
//   //   });
//   // };

   
// //    const successCallBackForFilteredData = (res) => {
// //     setAllBills(res?.data?.data)
// //     setIsFilterApplied(true)
// //     setOpenFilter(false)
// //  };

//   const successCallBackFilterListData = (data) => {
//     setHouseList(data?.house)
//     setSessionNumberList(data?.sessions)
//     setBillTypeList(data?.type)
//     setMemberOfPaList(data.member_of_parliament)
//     setMinistryList(data?.ministry)
//     setStatusList(data?.status)
//     setBillThemeList(data?.theme_subject)
//   };

//   // const successCallBack = (res) => {
//   //   let data = res?.data?.data;
//   //   setTotalRecords(res?.data?.metadata?.total_count);
//   //   setAllBills((bills) => [...bills, ...data]);
//   //   setPage((p) => p + 1);
//   // };
  
//   const successCallBackScroll = (res) => {
//     if(res?.data?.data.length < 1){
//       setHasMore(false)
//     }
//     let data = res?.data?.data;
//     setAllBills((bills) => [...bills, ...data]);
//     setPage((p) => p + 1);
//   }

//   const fetchMoreData = async () => {
//     let path = isFilterApplied ?  `${CONST.BASE_URL}/contents/bills?less_details=true&page=${page}&per_page=10` : 
//     `${CONST.BASE_URL}/contents/bills?less_details=true&page=${page}&per_page=10` ;
//     try {
//       await axios.get(`https://api.ecosystems.vidhilegalpolicy.in/api/v1/contents/bills?less_details=true&page=${page}&per_page=10`)
//       .then(res => {
//           successCallBackScroll(res)
//         });
//     } catch (error) {
//       showSnackbar("Server Error!",dispatch)
//     }
       
//     }
    
  
//   const handleFilterOpen = () => {
//     setOpenFilter(true);
//   };

//   const handleFilterClose = () => {
//     setOpenFilter(false);
//   };
   
//   const handleThemePath = () => {
//     let str = ""
//     billThemes.forEach((item)=> {
//       str = str + `&theme_subjects_id[]=${item.id}`
//     })
//     return str;
//   };
  
//   const handleFilterApply = () => {
//     let path = `/contents/bills?less_details=true&page=${1}&per_page=10`;
//     let isSearching = false;
//     if(house){
//       isSearching = true
//       path = path + `&house_id[]=${house}`
//     }
//     if(session){
//       isSearching = true
//       path = path + `&session_number_id[]=${session}`
//     }
//     if(billType){
//       isSearching = true
//       path = path + `&type_of_bill=${billType}`
//     }
//     if(billThemes){
//       isSearching = true
//       path = path + handleThemePath()
//     }
//     if(memberOfPa){
//       isSearching = true
//       path = path + `&member_of_parliament_id[]=${memberOfPa}` 
//     }
//     if(status){
//       isSearching = true
//       path = path + `&status=${status}`
//     }
//     if(ministry){
//       isSearching = true
//       path = path + `&ministry_id[]=${ministry}` 
//     }
//     if(dateRange[0].startDate && dateRange[0].endDate ){
//       isSearching = true
//       path = path + `&date_of_intro_start=${handleDateFormatforFilter(dateRange[0].startDate)}&date_of_intro_end=${handleDateFormatforFilter(dateRange[0].endDate)}`
//     }
//     if(isSearching){
//       path = path + "&search=true"
//     }
//     // getBillsFilteredData(path)
//   };

//   const handleSetPage = (handleFilterReset) => {
//      setPage(0)
//      setHasMore(true)
//      console.log("handleFilterReset",handleFilterReset)
//      handleFilterReset();
//   }

//   const handleFilterReset = () => {
//     setHouse("")
//     setSession("")
//     setBillType("");
//     setMemberOfPa("");
//     setMinistry("");
//     setBillThemes([]);
//     setStatus("")
//     setDateRange((date) =>  {
//       return [{...date[0], startDate : new Date(), endDate : null}]
//       })
//      setIsFilterApplied(false) 
//      setOpenFilter(false)
//      getBillsList(); // all bills api callling
//   };


//   const handleMemberDisable = () => {
//    if (billType === 0) {
//       return true;
//     } 
//   };

//   const handleMinistryDisable = () => {
//     if(billType === 1) {
//       return true;
//     } 
//   };

//   const handleChangeBillThemes = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setBillThemes(typeof value === "string" ? value.split(",") : value);
//   };

//   // const handleMemberDisable = () => {
//   //   if (billType === "") {
//   //     return true;
//   //   } else if (billType === 1) {
//   //     return false;
//   //   } else {
//   //     return true;
//   //   }
//   // };

//   // const handleMinistryDisable = () => {
//   //   if (billType === "") {
//   //     return true;
//   //   } else if (billType === 0) {
//   //     return false;
//   //   } else {
//   //     return true;
//   //   }
//   // };
//   const renderNoDatafound = () => {
//     return <NoDataFoundComponent data={allBills} title={"No Bills Found"} loader={loader} searchValue={searchValue} />
    
//   };

//   return (
//     <>
//       <Grid
//         item
//         container
//         sx={{
//           backgroundColor: (t) =>
//             t.palette.mode === "dark"
//               ? t.palette.background.default
//               : t.palette.grey[100],
//         }}
//       >
//         <LinkBar title="Bills" />

//         {/* first line */}
//         <Grid item xs={12} container 
//          sx={{
//           px: isXs ? "16px" : '32px',
//           my: "1.5rem",
//           justifyContent: "space-between",
//           alignItems: "center",
//           rowGap : "16px"
//         }}
//         >
//          <Typography 
//             sx={{width : isXs ? "100%" : "auto"}}
//             variant="h2">All Bills</Typography>
//             <Box
//             >
//               <SearchBarStyled>
//                 <SearchIcon />
//                 <InputBase
//                   sx={{ ml: 1, flex: 1 }}
//                   placeholder="Search here"
//                   inputProps={{ "aria-label": "search google maps" }}
//                   onChange={(e) => setSearchValue(e.target.value)}
//                 />
//               </SearchBarStyled>
//               {page}
//             </Box>
//             {
//               isXs ? (
//                 isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}}/>)
//               )  : (
//                 <FilterStyled isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
//                 <FilterListIcon />
//                 <Typography>Filter</Typography>
//               </FilterStyled>
//               )
//             }

//            </Grid>

       
//         {/* card section */}
//         {
//           loader ? <MyBox sx={{width : 1, height : "50vh"}}><Loader /></MyBox> : (
            
//         <InfiniteScroll
//           dataLength={allBills?.length}
//           next={fetchMoreData}
//           hasMore={hasMore}
//           //  loader={<div sx={{display : 'flex', padding : "20px", alignItems :'center', width :'100%', backgroundColor :'khaki'}}> <Loader /></div>}
//           loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
//           endMessage={
//             <p style={{ textAlign: "center" }}>
//               <b>Total Records : {allBills?.length}</b>
//             </p>
//           }
//         >
      
//             <Grid item xs={12}>
//             <Box
//               sx={{
//                 display: "grid",
//                 gridTemplateColumns: {
//                   xs: "repeat(1,1fr)",
//                   sm: "repeat(2,1fr)",
//                   md: "repeat(3,1fr)",
//                   lg: "repeat(4,1fr)",
//                   xl: "repeat(4,1fr)",
//                 },
//                 gap: "50px 50px",
//                 gridGap: "50px 50px",
//                 px: isXs ? "16px" : "32px",
//                 pb: "32px",
//                 boxSizing: "border-box",
//               }}
//             >
//               {allBills?.filter((item) =>
//                           item.title
//                             ?.toLowerCase()
//                             .includes(searchValue?.toLowerCase())
//                         ).map((item) => (
//                 <Card
//                   key={item?.id}
//                   item={item}
//                   title={item?.title}
//                   subtitle={item?.small_detail}
//                   // cardImg={debatesImg}
//                   cardImg={item?.thumbnail_detail?.link}
//                   date={item?.date}
//                   to="/bill"
//                 />
//               ))}
//             </Box>
//           </Grid>

         
         
//         </InfiniteScroll>
         
//          )
//         }

//         {/* card section end    */}
//       </Grid>

//         {renderNoDatafound()}
//       {/* Filter Dialog start here */}
//       <Dialog
//         // fullWidth={fullWidth}
//         //  maxWidth={maxWidth}
//         // maxWidth={['xs','sm','md','xl','lg']}
//         // maxWidth={"80vw"}
//         fullScreen={isXs}
//         open={openFilter}
//         onClose={handleFilterClose}
//         sx={{
//           "& .MuiPaper-root": {
//             minWidth: "90vw",
//             // backgroundColor : 'khaki'
//           },
//         }}
//       >
//         <Grid container>
//           <Grid
//             item
//             sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
//           >
//             <Typography variant="h2">Filters</Typography>
//             <CloseIcon
//               onClick={handleFilterClose}
//               sx={{ position: "absolute", right: 0, top: 0 }}
//             />
//           </Grid>

//           {/* top divider */}
//           <Grid item xs={12}>
//             <Divider />
//           </Grid>

//           {/* Dialog filed container */}
//           <Grid item xs={12}>
//             <Box
//               sx={{
//                 display: "grid",
//                 gridTemplateColumns: {
//                   xs: "repeat(1,1fr)",
//                   sm: "repeat(2,1fr)",
//                   md: "repeat(3,1fr)",
//                   lg: "repeat(3,1fr)",
//                   xl: "repeat(3,1fr)",
//                 },
//                 gap: "20px 50px",
//                 // gridGap : '50px 50px',
//                 px: "32px",
//                 py: "25px",
//                 boxSizing: "border-box",
//               }}
//             >
//               {/* PA Type Select     */}
//               {/* {JSON.stringify(billThemes)} */}
//               <FormControl fullWidth>
//                 <Typography>House</Typography>
//                 <MySelect
//                  value={house}
//                  onChange={e=>setHouse(e.target.value)}
//                  menuArray={houseList}
//                 />
//               </FormControl>

//               {/* Session Number */}
//               <FormControl fullWidth>
//                 <Typography>Session Number</Typography>
//                 <MySelect
//                  value={session}
//                  onChange={e=>setSession(e.target.value)}
//                  menuArray={sessionNumberList}
//                 />
//               </FormControl>

//               {/* Date */}
//               <FormControl fullWidth>
//                 <Typography>Date</Typography>
//                 <MyDateRange
//                 onChange={(item) => setDateRange([item.selection])}
//                 dateRange={dateRange}
//                 />
               
//               </FormControl>

//               {/* Type */}
//               <FormControl fullWidth>
//                 <Typography>Type</Typography>
//                 <MySelect
//                   value={billType}
//                   onChange={(e) => {
//                     console.log(e.target.value)
//                     setBillType(e.target.value)}

//                   }
//                   menuArray={billTypeList}
//                   // menuArray={[
//                   //   { id: 0, name: "Government Member Bill" },
//                   //   { id: 1, name: "Private Member Bill" },
//                   // ]}
//                 />
//                 {/* {console.log("billtype",billType,typeof(billType))} */}
//               </FormControl>


//                {/*Member of Parliament*/}
//                {/* <FormControl fullWidth>
//                 <Typography>Member of Parliament</Typography>
//                 <MySelect
//                   value={memberOfPa}
//                   onChange={(e) => setMemberOfPa(e.target.value)}
//                   mydisabled
//                   handleDisable={handleMemberDisable()}
//                   menuArray={[
//                     { id: 0, name: "member 1" },
//                     { id: 1, name: "member 2" },
//                   ]}
//                 />
//               </FormControl> */}

//               {/* Ministry*/}
//               {/* <FormControl fullWidth>
//                 <Typography>Ministry</Typography>
//                 <MySelect
//                   value={ministry}
//                   onChange={(e) => setMinistry(e.target.value)}
//                   mydisabled
//                   handleDisable={handleMinistryDisable()}
//                   menuArray={[
//                     { id: 0, name: "ministry 1" },
//                     { id: 1, name: "ministry 2" },
//                   ]}
//                 />
//               </FormControl> */}

//               {/*Member of Parliament*/}
//               <FormControl fullWidth>
//                 <Typography>Member of Parliament</Typography>
//                 <MySelect
//                   value={memberOfPa}
//                   onChange={(e) => {
//                     if(billType === ""){
//                       setBillType(1)
//                     }
//                     setMemberOfPa(e.target.value)
//                   }}
//                   mydisabled
//                   handleDisable={handleMemberDisable()}
//                   menuArray={memberOfPaList}
//                   // menuArray={[
//                   //   { id: 0, name: "member 1" },
//                   //   { id: 1, name: "member 2" },
//                   // ]}
//                 />
//               </FormControl>

//               {/* Ministry*/}
//               <FormControl fullWidth>
//                 <Typography>Ministry</Typography>
//                 <MySelect
//                   value={ministry}
//                   onChange={(e) => {
//                     if(billType === ""){
//                       setBillType(0)
//                     }
//                     setMinistry(e.target.value)
//                   }}
//                   mydisabled
//                   handleDisable={handleMinistryDisable()}
//                   menuArray={ministryList}
//                   // menuArray={[
//                   //   { id: 0, name: "ministry 1" },
//                   //   { id: 1, name: "ministry 2" },
//                   // ]}
//                 />
//               </FormControl>

//               {/* Theme/Subject*/}
//               <FormControl fullWidth>
//                 <Typography>Theme/Subject</Typography>
//                 <MySelectMultiple
//                  value={billThemes}
//                  onChange={handleChangeBillThemes}
//                  menuArray={billThemeList}
//                 />
//               </FormControl>

//               {/* Status*/}
//               <FormControl fullWidth>
//                 <Typography>Status</Typography>
//                 <MySelect
//                   value={status}
//                   onChange={(e) => setStatus(e.target.value)}
//                   menuArray={statusList}
//                 />
//               </FormControl>

//               {/* Title*/}
//               {/* <FormControl fullWidth>
//                 <Typography>Title</Typography>
//                 <FieldStyled
//                   variant="outlined"
//                   fullWidth
//                   placeholder="Enter title"
//                   value={billTitle}
//                   onChange={(e) => setBillTitle(e.target.value)}
//                 />
//               </FormControl> */}
//             </Box>

//             {/* Dialog field container end */}
//           </Grid>

//           {/* bottom Divider */}
//           <Grid item xs={12}>
//             <Divider />
//           </Grid>
//         </Grid>

//         <DialogActions sx={{ my: "10px" }}>
//           <ButtonOutlined
//             onClick={()=>handleSetPage(handleFilterReset)}
//             sx={{ textTransform: "none" }}
//           >
//             Reset
//           </ButtonOutlined>
//           <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default AllBills;
