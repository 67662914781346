
// import { createTheme } from '@mui/material/styles';



export const getTheme = (mode, fontSize) => ({
    // "@global": {
    //     // MUI typography elements use REMs, so you can scale the global
    //     // font size by setting the font-size on the <html> element.
    //     html: {
    //       fontSize: 16,
    //       "@media (max-width:600px)": {
    //                     fontSize : 12
    //                    },
    //     },
    //   },
    // overrides: {
    //     MuiCssBaseline: {
    //       '@global': {
    //         html: {
    //           WebkitFontSmoothing: 'auto',
    //           fontSize : "16px",
    //           "@media (max-width:600px)": {
    //             fontSize : "12px"
    //            },

    //         },
    //       },
    //     },
    //   },
    "breakpoints": {
        "keys": [
            "xs",
            "sm",
            "md",
            "lg",
            "xl"
        ],
        "values": {
            xs: 0,
            sm: 600,
            md: 1080,
            lg: 1600,
            xl: 1920
        },
        "unit": "px"
    },
    // "direction": "ltr",
    "components": {
        MuiCheckbox:{
            styleOverrides:{
                root:{
                    "&.Mui-checked":{
                        color : "#FF8278"
                    }
                }
               
            }
        },
        // MuiIconButton:{
        //     styleOverrides:{
        //         root :{
        //             backgroundColor : "#DC002D",
        //             borderRadius : "4px",
        //             color : "#ffffff"
        //         }
        //     }
        // },
        MuiTypography: {
            defaultProps: {
              variantMapping: {
                // currently, you need to provide all of the default mapping because it will replace, not merge.
                h1: "h1",
                h2: "h2",
                h3: "h3",
                h4: "h4",
                h5: "h5",
                h6: "h6",
                subtitle1: "h6",
                subtitle2: "h6",
                body1: "p",
                body2: "p",
                inherit: "p",
                // You have to add this line
                // otherwise the `span` (display `inline` by default)
                // cannot have margin.
                h7 : "h3",
                body3: "p",
                subtitle3 : "p",
                bodybold1 : "p",
                bodybold2 : "p",
                bodybold3 : "p",
                subheading1 : "p",
                subheading2 : "p",
                subheading3 : "p",
                regular1 : "p",
                regular2 : "p",
                regular3 : "p",
                regularbold1 : "p",
                regularbold2 : "p",
                regularbold3 : "p",
                vidhiFont : "p"
              }
            }
          }
        
    },
   "palette": {
        mode,
        "common": {
            ...(mode === "light" ? {
            "black": "#000000",
            "white": "#ffffff"
            } : {
            "black": "#ffffff",
            "white": "#000000"
            })
            
        },
        "primary": {
            ...(mode === "light" ? {
            "main": "#1D1D1D",
            "light": "#ffffff",
             "dark": "#DC002D",
            "contrastText": "#fff",
            } : {
            "main": "#1D1D1D",
            "light": "#1C1C1E",
            "dark": "#FF8278",
            "contrastText": "#fff",
            })
            
        },
        "secondary": {
            ...(mode == "light" ? {
                "main": "#555555",
                "light": "#999999",
                "dark": "#EEEEEE",
                "searchbar" : "#ECECEC",
                "contrastText": "#fff"
            } : {
                "main": "#555555",
                "light": "#999999",
                "dark": "#000000",
                "searchbar" : "#1C1C1C",
              "contrastText": "#fff"
            })
            
        },
        "error": {
            "main": "#d32f2f",
            "light": "#ef5350",
            "dark": "#c62828",
            "contrastText": "#fff"
        },
        "grey": {
            "50": "#F9FAFB",
            "100": "#f5f5f5",
            "200": "#EAECF0",
            "300": "#ffffff99",
            "400" : "#D0D5DD",
            "500" : "#AAAAAA"
        },
        "vidhiRed":{
            "main"  : "#DC002D",
            "light" : "#FF8278",
            "lightest" : "#DC002D1A",
        },
        "contrastThreshold": 3,
        "tonalOffset": 0.2,
        "text": {
            ...(mode === "light" ? {
            "primary": "#1D1D1D",
            "secondary": "#555555",
            "alwayswhite" : "#ffffff",
            "alwaysblack" : "#000000",
            "disabled": "rgba(0, 0, 0, 0.38)"
            } : {
            "primary": "#ffffff",
            "secondary": "#EEEEEE",
            "alwayswhite" : "#ffffff",
            "alwaysblack" : "#000000",
            "disabled": "rgba(0, 0, 0, 0.38)"
            })
            
        },
        ...(mode === "light" ? { "divider" : "rgba(0, 0, 0, 0.12)" } : {"divider" : "#555555"}),
        "background": {
            ...(mode === "light" ? {
            "paper": "#ffffff",
            "default": "#ffffff"
            } : {
            "paper": "#000000",
            "default": "#000000"
            })
            
        },
     },
    "shape": {
        "borderRadius": 4
    },
    "typography": {
        "fontFamily": ['Lato', '"GT Super Text"'].join(','),
        "htmlFontSize": 16,
        "h1": {
            "fontFamily": "GT Super Text",
            "fontWeight": 700,
            "fontSize":  `${36 + fontSize}px`,  //36px
            "lineHeight": 1.14,
            "@media (max-width:1080px)": {
                "fontSize" : '24px',
               },
        },
        "h2": {
            "fontFamily": "GT Super Text",
            "fontWeight": 700,
            "fontSize": `${32 + fontSize}px`,   //32px
            "lineHeight": 1.16,
             "@media (max-width:1024px)": {
                "fontSize" : '24px',
               },
        },
        "h3": {
            "fontFamily": "GT Super Text",
            "fontWeight": 700,
            "fontSize": `${24 + fontSize}px` ,  //24px
            "lineHeight": 1.16,
            
        },
        "h4": {
            "fontFamily": "GT Super Text",
            "fontWeight": 700,
            "fontSize": `${20 + fontSize}px`,  //20px
            "lineHeight": 1.16
        },
        "h5": {
            "fontFamily": "GT Super Text",
            "fontWeight": 300,
            "fontSize": `${36 + fontSize}px`,           //36px
            "lineHeight": 1.14
        },
        "h6": {
            "fontFamily": "GT Super Text",  //32px
            "fontWeight": 300,
            "fontSize": `${32 + fontSize}px`,
            "lineHeight": 1.16
        },
        "h7": {
            "fontFamily": "GT Super Text",  //24px
            "fontWeight": 300,
            "fontSize": `${24 + fontSize}px`,
            "lineHeight": 1.16
        },
        "subheading1" : {
             "fontFamily" : "Lato",
             "fontWeight" : 400,
             "fontSize" : `${24 + fontSize}px`,    //24px
             "lineHeight" : 1.2
            },
        "subheading2" : {
            "fontFamily" : "Lato",
            "fontWeight" : 400,
            "fontSize" : `${20 + fontSize}px`,    //20px
            "lineHeight" : 1.2
         },
       "subheading3" : {
            "fontFamily" : "Lato",
            "fontWeight" : 400,
            "fontSize" : `${18 + fontSize}px`,    //18px
            "lineHeight" : 1.2
       },
           "regular1" : {
            "fontFamily" : "Lato",
            "fontWeight" : 700,
            "fontSize" : `${24 + fontSize}px`,    //24px
            "lineHeight" : 1.2
           },
          "regular2" : {
              "fontFamily" : "Lato",
              "fontWeight" : 700,
              "fontSize" : `${20 + fontSize}px`,    //20px
              "lineHeight" : 1.2
           },
          "regular3" : {
               "fontFamily" : "Lato",
               "fontWeight" : 700,
               "fontSize" : `${18 + fontSize}px`,    //18px
               "lineHeight" : 1.2
           },
           "regularbold1" : {
            "fontFamily" : "Lato",
            "fontWeight" : 900,
            "fontSize" : `${24 + fontSize}px`,    //24px
            "lineHeight" : 1.2
           },
          "regularbold2" : {
              "fontFamily" : "Lato",
              "fontWeight" : 900,
              "fontSize" : `${20 + fontSize}px`,    //20px
              "lineHeight" : 1.2
           },
          "regularbold3" : {
               "fontFamily" : "Lato",
               "fontWeight" : 900,
               "fontSize" : `${18 + fontSize}px`,    //18px
               "lineHeight" : 1.2
           },
        "body1": {
            "fontFamily": "Lato",
            "fontWeight": 400,
            "fontSize": `${16 + fontSize}px`,   //16px
            "lineHeight": 1.5,
        },
        "body2": {
            "fontFamily": "Lato",
            "fontWeight": 400,
            "fontSize": `${14 + fontSize}px`,  //14px
            "lineHeight": 1.43,
            "@media (max-width:1080px)": {
                "fontSize" : '12px',
               },
        },
        "body3": {
            "fontFamily": "Lato",
            "fontWeight": 400,
            "fontSize": `${12 + fontSize}px`,  //12px //0.75rem
            "lineHeight": 1.34
        },
        "subtitle1": {
            "fontFamily": "Lato",
            "fontWeight": 700,
              "fontSize": `${16 + fontSize}px`,  //16px
            "lineHeight": 1.5
        },
        "subtitle2": {
            "fontFamily": "Lato",
            "fontWeight": 700,
            "fontSize": `${14 + fontSize}px`, //14px
            "lineHeight": 1.43
        },
        "subtitle3": {
            "fontFamily": "Lato",
            "fontWeight": 700,
            "fontSize": `${12 + fontSize}px`, //12px
            "lineHeight": 1.34
        },
        "bodybold1":{
            "fontFamily": "Lato",
            "fontWeight": 900,
            "fontSize": `${16 + fontSize}px`,   //16px
            "lineHeight": 1.5
         },
         "bodybold2":{
            "fontFamily": "Lato",
            "fontWeight": 900,
            "fontSize": `${14 + fontSize}px`,  //14px
            "lineHeight": 1.43
         },
         "bodybold3":{
            "fontFamily": "Lato",
            "fontWeight": 900,
            "fontSize": `${12 + fontSize}px`,  //12px
            "lineHeight": 1.34
         },
    //     "button": {
    //         "fontFamily": "Lato",
    //         "fontWeight": 500,
    //         "fontSize": "0.875rem",
    //         "lineHeight": 1.75,
    //         "textTransform": "uppercase"
    //     },
        "caption": {
            "fontFamily": "Lato",
            "fontWeight": 400,
            "fontSize": "0.75rem",
            "lineHeight": 1.66
        },
        vidhiFont : {
            fontSizeCustom : "20px",
            "@media (max-width:960px)": {
                fontSizeCustom : "18px"
                },
                "@media (max-width:600px)": {
                    fontSizeCustom : "16px"
                            }
        },
    },
    // "unstable_sxConfig": {
    //     "border": {
    //         "themeKey": "borders"
    //     },
    //     "borderTop": {
    //         "themeKey": "borders"
    //     },
    //     "borderRight": {
    //         "themeKey": "borders"
    //     },
    //     "borderBottom": {
    //         "themeKey": "borders"
    //     },
    //     "borderLeft": {
    //         "themeKey": "borders"
    //     },
    //     "borderColor": {
    //         "themeKey": "palette"
    //     },
    //     "borderTopColor": {
    //         "themeKey": "palette"
    //     },
    //     "borderRightColor": {
    //         "themeKey": "palette"
    //     },
    //     "borderBottomColor": {
    //         "themeKey": "palette"
    //     },
    //     "borderLeftColor": {
    //         "themeKey": "palette"
    //     },
    //     "borderRadius": {
    //         "themeKey": "shape.borderRadius"
    //     },
    //     "color": {
    //         "themeKey": "palette"
    //     },
    //     "bgcolor": {
    //         "themeKey": "palette",
    //         "cssProperty": "backgroundColor"
    //     },
    //     "backgroundColor": {
    //         "themeKey": "palette"
    //     },
    //     "p": {},
    //     "pt": {},
    //     "pr": {},
    //     "pb": {},
    //     "pl": {},
    //     "px": {},
    //     "py": {},
    //     "padding": {},
    //     "paddingTop": {},
    //     "paddingRight": {},
    //     "paddingBottom": {},
    //     "paddingLeft": {},
    //     "paddingX": {},
    //     "paddingY": {},
    //     "paddingInline": {},
    //     "paddingInlineStart": {},
    //     "paddingInlineEnd": {},
    //     "paddingBlock": {},
    //     "paddingBlockStart": {},
    //     "paddingBlockEnd": {},
    //     "m": {},
    //     "mt": {},
    //     "mr": {},
    //     "mb": {},
    //     "ml": {},
    //     "mx": {},
    //     "my": {},
    //     "margin": {},
    //     "marginTop": {},
    //     "marginRight": {},
    //     "marginBottom": {},
    //     "marginLeft": {},
    //     "marginX": {},
    //     "marginY": {},
    //     "marginInline": {},
    //     "marginInlineStart": {},
    //     "marginInlineEnd": {},
    //     "marginBlock": {},
    //     "marginBlockStart": {},
    //     "marginBlockEnd": {},
    //     "displayPrint": {
    //         "cssProperty": false
    //     },
    //     "display": {},
    //     "overflow": {},
    //     "textOverflow": {},
    //     "visibility": {},
    //     "whiteSpace": {},
    //     "flexBasis": {},
    //     "flexDirection": {},
    //     "flexWrap": {},
    //     "justifyContent": {},
    //     "alignItems": {},
    //     "alignContent": {},
    //     "order": {},
    //     "flex": {},
    //     "flexGrow": {},
    //     "flexShrink": {},
    //     "alignSelf": {},
    //     "justifyItems": {},
    //     "justifySelf": {},
    //     "gap": {},
    //     "rowGap": {},
    //     "columnGap": {},
    //     "gridColumn": {},
    //     "gridRow": {},
    //     "gridAutoFlow": {},
    //     "gridAutoColumns": {},
    //     "gridAutoRows": {},
    //     "gridTemplateColumns": {},
    //     "gridTemplateRows": {},
    //     "gridTemplateAreas": {},
    //     "gridArea": {},
    //     "position": {},
    //     "zIndex": {
    //         "themeKey": "zIndex"
    //     },
    //     "top": {},
    //     "right": {},
    //     "bottom": {},
    //     "left": {},
    //     "boxShadow": {
    //         "themeKey": "shadows"
    //     },
    //     "width": {},
    //     "maxWidth": {},
    //     "minWidth": {},
    //     "height": {},
    //     "maxHeight": {},
    //     "minHeight": {},
    //     "boxSizing": {},
    //     "fontFamily": {
    //         "themeKey": "typography"
    //     },
    //     "fontSize": {
    //         "themeKey": "typography"
    //     },
    //     "fontStyle": {
    //         "themeKey": "typography"
    //     },
    //     "fontWeight": {
    //         "themeKey": "typography"
    //     },
        
        "letterSpacing": { 
            "one" : "1px",
            "two" : '2px'
        },
    //     "textTransform": {},
    //     "lineHeight": {},
    //     "textAlign": {},
    //     "typography": {
    //         "cssProperty": false,
    //         "themeKey": "typography"
    //     }
    // },
    // "mixins": {
    //     "toolbar": {
    //         "minHeight": 56,
    //         "@media (min-width:0px)": {
    //             "@media (orientation: landscape)": {
    //                 "minHeight": 48
    //             }
    //         },
    //         "@media (min-width:600px)": {
    //             "minHeight": 64
    //         }
    //     }
    // },
    // "shadows": [
    //     "none",
    //     "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    //     "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    //     "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    //     "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    //     "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    //     "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    //     "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    //     "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    //     "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    //     "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    //     "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    //     "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    //     "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    //     "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    //     "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    //     "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    //     "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    //     "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    //     "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    //     "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    //     "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    //     "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    //     "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    //     "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
    // ],
    // "transitions": {
    //     "easing": {
    //         "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
    //         "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
    //         "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
    //         "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
    //     },
    //     "duration": {
    //         "shortest": 150,
    //         "shorter": 200,
    //         "short": 250,
    //         "standard": 300,
    //         "complex": 375,
    //         "enteringScreen": 225,
    //         "leavingScreen": 195
    //     }
    // },
    // "zIndex": {
    //     "mobileStepper": 1000,
    //     "fab": 1050,
    //     "speedDial": 1050,
    //     "appBar": 1100,
    //     "drawer": 1200,
    //     "modal": 1300,
    //     "snackbar": 1400,
    //     "tooltip": 1500
    // }
})



// export const theme = createTheme({
//     // "breakpoints": {
//     //     "keys": [
//     //         "xs",
//     //         "sm",
//     //         "md",
//     //         "lg",
//     //         "xl"
//     //     ],
//     //     "values": {
//     //         "xs": 0,
//     //         "sm": 600,
//     //         "md": 900,
//     //         "lg": 1200,
//     //         "xl": 1536
//     //     },
//     //     "unit": "px"
//     // },
//     // "direction": "ltr",
//     "components": {
//         MuiCheckbox:{
//             styleOverrides:{
//                 root:{
//                     "&.Mui-checked":{
//                         color : "#FF8278"
//                     }
//                 }
               
//             }
//         }
//     },
//     "palette": {
//         "mode": "dark",
//         "common": {
//             "black": "#000000",
//             "white": "#ffffff"
//         },
//         "primary": {
//             "main": "#1D1D1D",
//             "light": "#42a5f5",
//             "dark": "#1565c0",
//             "contrastText": "#fff"
//         },
//         "secondary": {
//             "main": "#555555",
//             "light": "#999999",
//             "dark": "#7b1fa2",
//             "contrastText": "#fff"
//         },
//         "error": {
//             "main": "#d32f2f",
//             "light": "#ef5350",
//             "dark": "#c62828",
//             "contrastText": "#fff"
//         },
//         "warning": {
//             "main": "#ed6c02",
//             "light": "#ff9800",
//             "dark": "#e65100",
//             "contrastText": "#fff"
//         },
//         "info": {
//             "main": "#0288d1",
//             "light": "#03a9f4",
//             "dark": "#01579b",
//             "contrastText": "#fff"
//         },
//         "success": {
//             "main": "#2e7d32",
//             "light": "#4caf50",
//             "dark": "#1b5e20",
//             "contrastText": "#fff"
//         },
//         "grey": {
//             "50": "#F9FAFB",
//             "100": "#f5f5f5",
//             "200": "#EAECF0",
//             "300": "#e0e0e0",
//             "400": "#bdbdbd",
//             "500": "#9e9e9e",
//             "600": "#757575",
//             "700": "#616161",
//             "800": "#424242",
//             "900": "#212121",
//             "A100": "#f5f5f5",
//             "A200": "#eeeeee",
//             "A400": "#bdbdbd",
//             "A700": "#616161"
//         },
//         "contrastThreshold": 3,
//         "tonalOffset": 0.2,
//         "text": {
//             "primary": "rgba(0, 0, 0, 0.87)",
//             "secondary": "rgba(0, 0, 0, 0.6)",
//             "disabled": "rgba(0, 0, 0, 0.38)"
//         },
//         "divider": "rgba(0, 0, 0, 0.12)",
//         "background": {
//             "paper": "#fff",
//             "default": "#fff"
//         },
//         "action": {
//             "active": "rgba(0, 0, 0, 0.54)",
//             "hover": "rgba(0, 0, 0, 0.04)",
//             "hoverOpacity": 0.04,
//             "selected": "rgba(0, 0, 0, 0.08)",
//             "selectedOpacity": 0.08,
//             "disabled": "rgba(0, 0, 0, 0.26)",
//             "disabledBackground": "rgba(0, 0, 0, 0.12)",
//             "disabledOpacity": 0.38,
//             "focus": "rgba(0, 0, 0, 0.12)",
//             "focusOpacity": 0.12,
//             "activatedOpacity": 0.12
//         }
//     },
//     "shape": {
//         "borderRadius": 4
//     },
//     "typography": {
//         "fontFamily": "Lato",
//         "htmlFontSize": 16,
//     //     "fontSize": 14,
//     //     "fontWeightLight": 300,
//     //     "fontWeightRegular": 400,
//     //     "fontWeightMedium": 500,
//     //     "fontWeightBold": 700,
//     //     "h1": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 300,
//     //         "fontSize": "6rem",
//     //         "lineHeight": 1.167
//     //     },
//     //     "h2": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 300,
//     //         "fontSize": "3.75rem",
//     //         "lineHeight": 1.2
//     //     },
//         "h3": {
//             "fontFamily": "GT Super Text",
//             "fontWeight": 300,
//             "fontSize": "1.5rem",
//             "lineHeight": 1.167
//         },
//         "h4": {
//             "fontFamily": "GT Super Text",
//             "fontWeight": 700,
//             "fontSize": "2.125rem",
//             "lineHeight": 1.235
//         },
//         "h5": {
//             "fontFamily": "GT Super Text",
//             "fontWeight": 700,
//             "fontSize": "1.25rem",
//             "lineHeight": 1.334
//         },
//         // "h6": {
//         //     "fontFamily": "Lato",
//         //     "fontWeight": 500,
//         //     "fontSize": "1rem",
//         //     "lineHeight": 1.6
//         // },
//     //     "subtitle1": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 400,
//     //         "fontSize": "1rem",
//     //         "lineHeight": 1.75
//     //     },
//     //     "subtitle2": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 500,
//     //         "fontSize": "0.875rem",
//     //         "lineHeight": 1.57
//     //     },
//     //     "body1": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 400,
//     //         "fontSize": "1rem",
//     //         "lineHeight": 1.5
//     //     },
//     //     "body2": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 400,
//     //         "fontSize": "0.875rem",
//     //         "lineHeight": 1.43
//     //     },
//     //     "button": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 500,
//     //         "fontSize": "0.875rem",
//     //         "lineHeight": 1.75,
//     //         "textTransform": "uppercase"
//     //     },
//     //     "caption": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 400,
//     //         "fontSize": "0.75rem",
//     //         "lineHeight": 1.66
//     //     },
//     //     "overline": {
//     //         "fontFamily": "Lato",
//     //         "fontWeight": 400,
//     //         "fontSize": "0.75rem",
//     //         "lineHeight": 2.66,
//     //         "textTransform": "uppercase"
//     //     },
//     //     "inherit": {
//     //         "fontFamily": "inherit",
//     //         "fontWeight": "inherit",
//     //         "fontSize": "inherit",
//     //         "lineHeight": "inherit",
//     //         "letterSpacing": "inherit"
//     //     }
//     },
//     // "unstable_sxConfig": {
//     //     "border": {
//     //         "themeKey": "borders"
//     //     },
//     //     "borderTop": {
//     //         "themeKey": "borders"
//     //     },
//     //     "borderRight": {
//     //         "themeKey": "borders"
//     //     },
//     //     "borderBottom": {
//     //         "themeKey": "borders"
//     //     },
//     //     "borderLeft": {
//     //         "themeKey": "borders"
//     //     },
//     //     "borderColor": {
//     //         "themeKey": "palette"
//     //     },
//     //     "borderTopColor": {
//     //         "themeKey": "palette"
//     //     },
//     //     "borderRightColor": {
//     //         "themeKey": "palette"
//     //     },
//     //     "borderBottomColor": {
//     //         "themeKey": "palette"
//     //     },
//     //     "borderLeftColor": {
//     //         "themeKey": "palette"
//     //     },
//     //     "borderRadius": {
//     //         "themeKey": "shape.borderRadius"
//     //     },
//     //     "color": {
//     //         "themeKey": "palette"
//     //     },
//     //     "bgcolor": {
//     //         "themeKey": "palette",
//     //         "cssProperty": "backgroundColor"
//     //     },
//     //     "backgroundColor": {
//     //         "themeKey": "palette"
//     //     },
//     //     "p": {},
//     //     "pt": {},
//     //     "pr": {},
//     //     "pb": {},
//     //     "pl": {},
//     //     "px": {},
//     //     "py": {},
//     //     "padding": {},
//     //     "paddingTop": {},
//     //     "paddingRight": {},
//     //     "paddingBottom": {},
//     //     "paddingLeft": {},
//     //     "paddingX": {},
//     //     "paddingY": {},
//     //     "paddingInline": {},
//     //     "paddingInlineStart": {},
//     //     "paddingInlineEnd": {},
//     //     "paddingBlock": {},
//     //     "paddingBlockStart": {},
//     //     "paddingBlockEnd": {},
//     //     "m": {},
//     //     "mt": {},
//     //     "mr": {},
//     //     "mb": {},
//     //     "ml": {},
//     //     "mx": {},
//     //     "my": {},
//     //     "margin": {},
//     //     "marginTop": {},
//     //     "marginRight": {},
//     //     "marginBottom": {},
//     //     "marginLeft": {},
//     //     "marginX": {},
//     //     "marginY": {},
//     //     "marginInline": {},
//     //     "marginInlineStart": {},
//     //     "marginInlineEnd": {},
//     //     "marginBlock": {},
//     //     "marginBlockStart": {},
//     //     "marginBlockEnd": {},
//     //     "displayPrint": {
//     //         "cssProperty": false
//     //     },
//     //     "display": {},
//     //     "overflow": {},
//     //     "textOverflow": {},
//     //     "visibility": {},
//     //     "whiteSpace": {},
//     //     "flexBasis": {},
//     //     "flexDirection": {},
//     //     "flexWrap": {},
//     //     "justifyContent": {},
//     //     "alignItems": {},
//     //     "alignContent": {},
//     //     "order": {},
//     //     "flex": {},
//     //     "flexGrow": {},
//     //     "flexShrink": {},
//     //     "alignSelf": {},
//     //     "justifyItems": {},
//     //     "justifySelf": {},
//     //     "gap": {},
//     //     "rowGap": {},
//     //     "columnGap": {},
//     //     "gridColumn": {},
//     //     "gridRow": {},
//     //     "gridAutoFlow": {},
//     //     "gridAutoColumns": {},
//     //     "gridAutoRows": {},
//     //     "gridTemplateColumns": {},
//     //     "gridTemplateRows": {},
//     //     "gridTemplateAreas": {},
//     //     "gridArea": {},
//     //     "position": {},
//     //     "zIndex": {
//     //         "themeKey": "zIndex"
//     //     },
//     //     "top": {},
//     //     "right": {},
//     //     "bottom": {},
//     //     "left": {},
//     //     "boxShadow": {
//     //         "themeKey": "shadows"
//     //     },
//     //     "width": {},
//     //     "maxWidth": {},
//     //     "minWidth": {},
//     //     "height": {},
//     //     "maxHeight": {},
//     //     "minHeight": {},
//     //     "boxSizing": {},
//     //     "fontFamily": {
//     //         "themeKey": "typography"
//     //     },
//     //     "fontSize": {
//     //         "themeKey": "typography"
//     //     },
//     //     "fontStyle": {
//     //         "themeKey": "typography"
//     //     },
//     //     "fontWeight": {
//     //         "themeKey": "typography"
//     //     },
//     //     "letterSpacing": {},
//     //     "textTransform": {},
//     //     "lineHeight": {},
//     //     "textAlign": {},
//     //     "typography": {
//     //         "cssProperty": false,
//     //         "themeKey": "typography"
//     //     }
//     // },
//     // "mixins": {
//     //     "toolbar": {
//     //         "minHeight": 56,
//     //         "@media (min-width:0px)": {
//     //             "@media (orientation: landscape)": {
//     //                 "minHeight": 48
//     //             }
//     //         },
//     //         "@media (min-width:600px)": {
//     //             "minHeight": 64
//     //         }
//     //     }
//     // },
//     // "shadows": [
//     //     "none",
//     //     "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
//     //     "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
//     //     "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
//     //     "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
//     //     "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
//     //     "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
//     //     "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
//     //     "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
//     //     "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
//     //     "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
//     //     "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
//     //     "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
//     //     "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
//     //     "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
//     //     "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
//     //     "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
//     //     "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
//     //     "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
//     //     "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
//     //     "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
//     //     "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
//     //     "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
//     //     "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
//     //     "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
//     // ],
//     // "transitions": {
//     //     "easing": {
//     //         "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
//     //         "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
//     //         "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
//     //         "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
//     //     },
//     //     "duration": {
//     //         "shortest": 150,
//     //         "shorter": 200,
//     //         "short": 250,
//     //         "standard": 300,
//     //         "complex": 375,
//     //         "enteringScreen": 225,
//     //         "leavingScreen": 195
//     //     }
//     // },
//     // "zIndex": {
//     //     "mobileStepper": 1000,
//     //     "fab": 1050,
//     //     "speedDial": 1050,
//     //     "appBar": 1100,
//     //     "drawer": 1200,
//     //     "modal": 1300,
//     //     "snackbar": 1400,
//     //     "tooltip": 1500
//     // }
// })


