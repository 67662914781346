import React from 'react'

import Grid from '@mui/material/Grid';
import { Typography, Box, useTheme } from '@mui/material';

import { Link } from 'react-router-dom';

import defaultBackground from "../../assets/SvgIcons/bg-image-red.png"
import DarkBackground from  "../../assets/SvgIconsDarkMode/bg-image-black.png"
import {ReactComponent as LogoWhite} from '../../assets/SvgIcons/vidhiLogoWhite.svg'
import useMediaQuery from '@mui/material/useMediaQuery';



const LoginSignUpBackground = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  console.log("matches",matches)

  return (
   <Grid
          item
          container
          xs={0}
          sm={0}
          md={8}
          lg={8}
          xl={8}
          sx={{
            backgroundImage: (theme) => theme.palette.mode === "light" ? `url(${defaultBackground})` : `url(${DarkBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position : 'relative',
            height : ["150px", "200px", "100vh", "100vh", "100vh"],
            flexDirection : ["row", "row", "column", "column", "column"],
            justifyContent : "center",
            alignItems : 'center'
          }}
          
        >
            <Link to="/">
            <LogoWhite
              
              style={{
              ...(!matches && {
              position : 'absolute',
              top : "10%",
              left : "10%",
              } ),
             
              }}
           
            />
            </Link>

            {
              !matches && (
                <Grid container direction='column' style={{paddingLeft : "10%"}}>
                <Typography sx={{color : "#FFFF", fontWeight : 600, fontSize : "72px"}} component="span" variant="h1" >
                Unveiling <br /> Boundaries
                </Typography>
                <Typography sx={{ color : '#FFFF', maxWidth : 500,}}>
                Discover India's Richness: Unveiling Boundaries and Exploring Depths through Interactive Maps
                </Typography>
                </Grid>
              )
            }
           
            
           </Grid>
      
  )
}

export default LoginSignUpBackground