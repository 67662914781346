import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import Navbar from '../../components/Navbars/Navbar'
import TopBar from '../../components/Navbars/TopBar'
import Footer from '../../components/Footer/Footer'
import LandingScreen from '../../screens/Landing/LandingScreen'

const HomePage = () => {
  return (
    <>   
    <Grid
     container
     flexDirection="column"
     alignContent={'flex-start'}
     justifyContent="flex-start"
     style={{width : "100%", minHeight : '100dvh'}}
    //  style={{backgroundColor : "#eeee"}} 
     > 
    <TopBar />
    <Navbar />
    <Outlet />
    {/* <LandingScreen /> */}
    <Footer />
    </Grid>
    </>

  )
}

export default HomePage