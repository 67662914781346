import React from 'react'
import {
    createBrowserRouter,
    createRoutesFromElements, 
    RouterProvider,
    Route,
  } from "react-router-dom";
import SignUp from '../pages/SignUp/SignUp'
import HomePage from '../pages/HomePage/HomePage'
import Login from '../pages/Login/Login'
import ErrorPage from './ErrorPage';
import LandingScreen from '../screens/Landing/LandingScreen';
import Questions from '../screens/Questions/Questions';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import AllDebates from '../screens/Debates/AllDebates';
import Bill from '../screens/BIlls/Bill';
import AllReports from '../screens/Reportes/AllReports';
import AllBills from '../screens/BIlls/AllBills';
import Report from '../screens/Reportes/Report';
import AllProtectedAreas from '../screens/ProtectedAreas/AllProtectedAreas';
import EszCommittee from '../screens/ESZ/EszCommittee';
import EszNotificationTracker from '../screens/ESZ/EszNotificationTracker';
import Nbwl from '../screens/NBWL/Nbwl';
import AuthorPage from '../screens/AuthorPage/AuthorPage'
import AllPublications from '../screens/ResearchUpdates/AllPublications';
import OtpPage from '../pages/OneTimePassword/OtpPage';
import PAInfromation from '../screens/ProtectedAreas/PAInfromation';
import Meeting from '../screens/Meetings/Meeting';
import SearchGlobal from '../screens/SearchGlobal/SearchGlobal';
import Publication from '../screens/ResearchUpdates/Publication';

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route errorElement={<ErrorPage />}>
//     <Route path="/" element={<HomePage />} caseSensitive>
//     <Route index element={<LandingScreen />} />
//     <Route path="questions" element={<Questions />} />
//     <Route path="bills" element={<Bills />}  />
//     <Route path="bills/allbills" element={<AllBill />} />
//     <Route path="debates" element={<Debates />} />
//   </Route>
//   <Route path="login" element={<Login />} />
//   <Route path="signup" element={<SignUp />} />
//   </Route>
//   )
// );

const router = createBrowserRouter([
  
    {
      path: "/",
      element: <HomePage />,
      errorElement : <ErrorPage />,
      children: [
        {
          path: "/",
          element: <LandingScreen />,
        },
        {
          path: "/protectedareas",
          element: <AllProtectedAreas />,
        },
        {
          path: "/paInformation",
          element: <PAInfromation />,
        },
        {
          path: "/esznotification",
          element: <EszNotificationTracker />,
        },
        {
          path: "/eszcommittee",
          element: <EszCommittee />,
        },
        {
          path: "/nbwl",
          element: <Nbwl />,
        },
         {
          path: "/questions",
          element: <Questions />,
        },
        {
          path: "/allbills",
          element: <AllBills />,
        },
        {
          path: "/bill",
          element: <Bill />
        },
        {
          path: "/alldebates",
          element: <AllDebates />,
        },
        {
          path: "/allreports",
          element: <AllReports />,
        },
        {
          path: "/report",
          element: <Report />,
        },
        {
          path: "/allpublications",
          element: <AllPublications />,
        },
        {
          path: "/publication",
          element: <Publication />,
        },
        {
          path: "/author",
          element: <AuthorPage />, 
        },
        // {
        //   path: "/meeting",
        //   element: <Meeting />,
        // },
        {
          path: "/meeting/:id/:type",
          element: <Meeting />,
        },
        {
          path: "/search",
          element: <SearchGlobal />,
        },
       
      ],
      
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword />
    },
    {
      path: "/otp",
      element: <OtpPage />
    },
    {
      path: "/resetpassword",
      element: <ResetPassword />
    },
  ]);

const MyRoutes = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default MyRoutes