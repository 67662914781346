import {
  Grid,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  Divider
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { PATypeLabelStyled } from "../../components/StyledComponents/StyledComponents";

const RedListPopUp = ({ open, onClose, speciesList, iucnName }) => {
  let isXs = false;

  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  isXs = useMediaQuery(theme.breakpoints.down("sm"));

  

  const handleSpeciesClick = (id, name) => {
    let path = `id=${id}&name=${name}&filter=sheduledOne`
    setSearchParams(path) 
    onClose();  
  };

  return (
    <Dialog
      open={open}
      fullScreen={isXs}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          minWidth: "80vw",
          // backgroundColor : 'khaki'
        },
      }}
    >
      <Grid container>
        <Grid
          item
          sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
        >
          <Typography variant="h2">{iucnName}</Typography>
          <CloseIcon
            onClick={onClose}
            sx={{ position: "absolute", right: 0, top: 0 }}
          />
        </Grid>

         {/* top divider */}
         <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sx={{ p: "32px" }}>
          <Grid container sx={{gap : '16px'}}>
            {
               speciesList?.length > 0 ? speciesList?.map( (animal, index) => (
                    <PATypeLabelStyled key={index} onClick={()=>handleSpeciesClick(animal.id, animal.name)} clickable>
                    <Typography variant="regular3" color="primary.dark">
                      {animal.name}
                    </Typography>
                  </PATypeLabelStyled> 
                )) : 
                "NA"
            }
           

          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RedListPopUp;
