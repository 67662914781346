const initialState = {
  showSnakbar: false,
  snackbarMessage: "",
  showLoader: false,
  showLoader2: false,
};

export const commonReducer = (state = initialState, action) => {
  console.log("action ", action, "state", state);
  switch (action.type) {
    case "SHOW_SNACKBAR":
      return {
        ...state,
        showSnakbar: true,
        snackbarMessage: action.message,
      };

    case "HIDE_SNACKBAR":
      return {
        ...state,
        showSnakbar: false,
        snackbarMessage: "",
      };

    case "SHOW_LOADER":
      return {
        ...state,
        showLoader: true,
      };

    case "HIDE_LOADER":
      return {
        ...state,
        showLoader: false,
      };
    case "SHOW_LOADER_MAP":
      return {
        ...state,
        showLoader2: true,
      };

    case "HIDE_LOADER_MAP":
      return {
        ...state,
        showLoader2: false,
      };
    default:
      return state;
  }
};
