import React from "react";
import DatamapsIndia from "react-datamaps-india";
import "./mapstyle.css"

const MapChart = ({myRegionData}) => {
  return (
    <DatamapsIndia
    regionData={myRegionData}
    labels={{
      customLabelText: regionData => {
        const stateName = regionData.state;
        const { value } = regionData;
        return `${stateName}: ${value}`;
      },
      fontSize: 20,
      labelColor: "#000000"
    }}
  //  customHover={(geography, regionData) => {
  //   console.log("regionData",regionData)
  //       const stateName = geography.properties.name;
  //       const stateData = regionData[stateName];
  //       const { value } = stateData || { value: 0 };

  //       console.log("stateName:", stateName);
  //       console.log("stateData:", stateData);
      
  //       return (
  //         <div>
  //           <div>{value} question</div>
  //         </div>
  //       );
  //     }}
     hoverComponent={({ value }) => {
        console.log("value",value)

        return (
          <div>
            <div>{value?.value ? value.value : 0 }</div>
          </div>
        );
      }}
      mapLayout={{
        title: "",
        legendTitle: "questions",
        startColor: "#FFDAB9",
        endColor: "#FF6347",
        hoverTitle: "Count",
        noDataColor: "#f5f5f5",
        borderColor: "#8D8D8D",
        hoverBorderColor: "#8D8D8D",
        hoverColor: "#8D8D8D",
        height: 70,
        weight: 30,
        top : 0
      }}
    />
  );
};

export default MapChart;


// regionData={{
//   Maharashtra: { value: 10 },
//   Rajasthan: { value: 1000 },
//   Gujarat: { value: 800 },
//   Karnataka: { value: 700 },
//   TamilNadu: { value: 190 },
//   Kerala: { value: 890 },
//   Odisha: { value: 300 }
// }}