import React, { useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; //
import "./dateRangeStyle.css";
import { DateRange } from "react-date-range";
import { BoxDateStyled, MyBox } from "../StyledComponents/StyledComponents";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Menu, Typography } from "@mui/material";

const MyDateRange = ({ dateRange, onChange, homePage }) => {
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <BoxDateStyled
       homePage={homePage}
        onClick={handleClick}
      >
        <span>
          {dateRange[0]?.startDate && dateRange[0]?.endDate
            ? `${moment(dateRange[0]?.startDate).format(
                "DD/MM/YYYY"
              )} to ${moment(dateRange[0]?.endDate).format("DD/MM/YYYY")}`
            : "Select"}
        </span>
        <EventIcon />
        {/* {open ? <CloseIcon /> : <EventIcon />} */}
      </BoxDateStyled>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root":{
            backgroundColor :'#ffffff'
          }
        }}
      >
        <MyBox sx={{justifyContent : 'space-between', px: '10px', pb : '5px'}}>
          <Typography color="text.alwaysblack">DateRange</Typography>
          <CloseIcon sx={{color : "#000000"}} onClick={handleClose}/>
        </MyBox>
        <DateRange
          editableDateInputs={false}
          onChange={onChange}
          moveRangeOnFirstSelection={true}
          ranges={dateRange}
          className={"dateRangeStyle"}
          dateDisplayFormat="d MMM yyyy"
          />
      </Menu>
    </>
  );
};

// const MyDateRange = ({ dateRange, onChange }) => {
//   const [showDatePicker, setShowDatePicker] = useState(false);

//   return (
//     <>
//       <BoxDateStyled
//         onClick={() => setShowDatePicker(!showDatePicker)}
//         // style={{ position: "realative" }}
//       >
//         <span>
//           {dateRange[0]?.startDate && dateRange[0]?.endDate
//             ? `${moment(dateRange[0]?.startDate).format(
//                 "DD/MM/YYYY"
//               )} to ${moment(dateRange[0]?.endDate).format("DD/MM/YYYY")}`
//             : "Select"}
//         </span>
//         {showDatePicker ? (<CloseIcon />) : ( <EventIcon />)}

//       </BoxDateStyled>
//       {showDatePicker && (
//         <Menu>
//         <DateRange
//           editableDateInputs={false}
//           onChange={onChange}
//           moveRangeOnFirstSelection={false}
//           ranges={dateRange}
//           className={"dateRangeStyle"}
//         />
//         </Menu>
//       )}
//     </>
//   );
// };

export default MyDateRange;
