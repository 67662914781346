const initialState = {
   loginData : {},
   user : {}
};

export const userReducer = (state = initialState, action) => {
  console.log("action ", action);
  switch (action.type) {
    case "CREATE_USER":
      return {
        ...state,
        user : action.payload
      };

    case "LOGIN_USER":
      return {
        ...state,
        loginData: action.payload,
      };
  default:
      return state;
  }
};

