import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  InputBase,
  Typography,
  TablePagination,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Dialog,
  Divider,
  DialogActions,
  FormControl,
  Skeleton,
  useMediaQuery
} from "@mui/material";

import LinkBar from "../../components/LinkBar/LinkBar";
import CloseIcon from "@mui/icons-material/Close";

import {
  ButtonOutlined,
  FilterStyled,
  SearchBarStyled,
  DialogStyled,
  ButtonPrimary,
  PaginationContainerStyled,
  MyBox,
} from "../../components/StyledComponents/StyledComponents";
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
} from "../../components/StyledComponents/StyledTable/styledTable";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";



import MySelect from "../../components/Select/MySelect";
import MySelectMultiple from "../../components/Select/MySelectMultiple";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import NoDataFoundComponent from '../../components/NoDataFoundUI/NoDataFound'
import MyDateRange from "../../components/DateRange/MyDateRange";
import { handleArrayQueryParam, handleDateFormatforFilter } from "../../utils/StatusStyle";


const AllDebates = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));


  const [openFilter, setOpenFilter] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

 
  const [debatesData, setDebatesData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  //states for holding filter's item's data Listing
  const [houseList, setHouseList] = useState("");
  const [sessionNumberList, setSessionNumberList] = useState("");
  const [debateTypeList, setDebateTypeList] = useState("");
  const [memberOfPaList, setMemberOfPaList] = useState("");
  const [ministryList, setMinistryList] = useState("");
  const [debateThemeList, setDebateThemeList] = useState([]);
  const [debateStatusList, setDebateStatusList] = useState("");


 
  //states for holding filter's item's value
  const [house, setHouse] = useState("");
  const [sessionNumber, setSessionNumber] = useState("");
  const [debateType, setDebateType] = useState("");
  const [memberOfPa, setMemberOfPa] = useState("");
  const [ministry, setMinistry] = useState("");
  const [debateTheme, setDebateTheme] = useState([]);
  const [debateStatus, setDebateStatus] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
 

   //useEffect for filter item data listing and overview data
   useEffect(() => {
    getFilterListData();
   
  }, []);

  //Effect for question data
  useEffect(() => {
    if (isFilterApplied) {
      handleFilterApply();
    } else {
      getDebatesData();
    }
  }, [rowsPerPage, page]);
  

  const getFilterListData = () => {
    dispatch({
      type: "DEBATES_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
    });
  };

  
  const getDebatesData = () => {
    dispatch({
      type: "GET_ALL_DEBATES",
      successCallBack: successCallBack,
      path: `/contents/debates?page=${page+1}&per_page=${rowsPerPage}`,
    });
  };

  const getDebatesFilteredData = (path) => {
    dispatch({
      type: "GET_ALL_DEBATES",
      successCallBack: successCallBackForFilteredData,
      path: path,
    });
  };

 

  const successCallBackFilterListData = (data) => {
    setHouseList(data?.house);
    setSessionNumberList(data?.sessions);
    setDebateTypeList(data?.debate_types);
    // setDebateSubTypeList(data?.debate_sub_types);
    setMemberOfPaList(data?.member_of_parliament);
    setDebateThemeList(data?.theme_subject);
    setMinistryList(data?.ministry);
    setDebateStatusList(data?.debate_status)
    
  };

  const successCallBack = (res) => {
    setDebatesData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
   };

  const successCallBackForFilteredData = (res) => {
    setDebatesData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    setIsFilterApplied(true)
    setOpenFilter(false)
  };

 
  

  const showPDF = (pdfUrl) => {
    if(!pdfUrl){
      showSnackbar("No pdf found");
    }
  
     if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank'; // Open in a new tab
      link.rel = 'noopener noreferrer';
      link.download = pdfUrl;
      link.click();
    }
    //  window.open(pdfUrl, "_blank");
  };

  const showSnackbar = (message) => {
    try {
      dispatch({
        type: "SHOW_SNACKBAR",
        message: message,
      });
    } catch (error) {
      console.error("Failed to dispatch Snackbar:", error);
      // Handle the error appropriately, e.g., log it or show a generic error message.
    }
  };

  const handleChangePage = (event, newPage) => {
    console.log("new Page", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 
  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const handleChangeDebatesThemes = (event) => {
    const {
      target: { value },
    } = event;
    setDebateTheme(typeof value === "string" ? value.split(",") : value);
  };

  const handleFilterApply = () => {
    let path = `/contents/debates?page=${page+1}&per_page=${rowsPerPage}`;
    let isSearching = false;
    if(house){
      isSearching = true
      path = path + `&house_id[]=${house}`
    }
    if(sessionNumber){
      isSearching = true
      path = path + `&session_number_id[]=${sessionNumber}`
    }
    if(debateType){
      isSearching = true
      path = path + `&debate_type_id=${debateType}`
    }
    if(debateTheme){
      isSearching = true
      path = path + handleArrayQueryParam(debateTheme, "theme_subjects_id[]")
    }
    if(memberOfPa){
      isSearching = true
      path = path + `&member_of_parliament_id[]=${memberOfPa}` 
    }
    if(debateStatus){
      isSearching = true
      path = path + `&status[]=${debateStatus}`
    }
    if(ministry){
      isSearching = true
      path = path + `&ministry_id[]=${ministry}` 
    }
    if(dateRange[0].startDate && dateRange[0].endDate ){
      isSearching = true
      path = path + `&date_of_debate_start=${handleDateFormatforFilter(dateRange[0].startDate)}&date_of_debate_end=${handleDateFormatforFilter(dateRange[0].endDate)}`
    }
    if(isSearching){
      path = path + "&search=true"
    }
    getDebatesFilteredData(path)
  };

  const handleFilterReset = () => {
    setHouse("");
    setSessionNumber("");
    setDebateType("");
    setMemberOfPa("");
    setMinistry("");
    setDebateTheme([]);
    setDebateStatus("");
    setDateRange((date) =>  {
      return [{...date[0], startDate : new Date(), endDate : null}]
      })
    setOpenFilter(false)
    setIsFilterApplied(false)
    getDebatesData() // all questions api calling 
  };

   
  const renderNoDatafound = () => {
     return <NoDataFoundComponent data={debatesData} title={"No Debates Found"} loader={loader} searchValue={searchValue} />
   };

 
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRecords) : 0;

  return (
    <>
      <Grid
        item
        container
        sx={{ backgroundColor: (t) => t.palette.background.default }}
      >
        <LinkBar title="Debates"/>
    
        <Grid
          container
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "dark"
                ? t.palette.background.default
                : theme.palette.grey[100],
          }}
        >
          

          <Grid
            container
            sx={{
              px: isXs ? "16px" : '32px',
              my: "1.5rem",
              justifyContent: "space-between",
              alignItems: "center",
              rowGap : "16px"
             }}
          >
            <Typography
            sx={{width : isXs ? "100%" : "auto"}}
            variant="h2">Debates</Typography>
            <Box>
              <SearchBarStyled>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search here"
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </SearchBarStyled>
            </Box>
            {
              isXs ? (
                isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}}/>)
              )  : (
                <FilterStyled isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
              )
            }
           </Grid>
          {loader ? (
            <MyBox sx={{ height: "50vh", width: "100%" }}>
              {" "}
              <Loader />
            </MyBox>
          ) : (
            <Grid container sx={{ pl: isXs ? "16px" : '32px', pb: "1rem" }}>
              <Grid item xs={12}>
                <StyledTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>House</StyledTableCell>
                        <StyledTableCell>Session Number</StyledTableCell>
                        <StyledTableCell>Debate Type</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Member of Parliament</StyledTableCell>
                        <StyledTableCell>Ministry</StyledTableCell>
                        <StyledTableCell>View</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {debatesData
                        ?.filter((item) =>
                          item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) || item?.house.name?.toLowerCase().includes(searchValue?.toLowerCase()) 
                        )
                        .map((row) => (
                          <StyledTableRow key={row?.id}>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.house?.name}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.session_number?.name}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "220px !important" }}
                            >
                              {row?.debate_type?.name ?? "NA"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.date_of_debate ?? "NA"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "220px !important" }}
                            >
                              {row?.member_of_parliaments_details[0]?.name ?? "NA"}
                            </StyledTableCell>
                           
                            <StyledTableCell
                              sx={{ minWidth: "250px !important" }}
                            >
                              {/* {row?.ministries_details[0]?.name ?? "NA" } */}
                              {row?.ministries_details && row?.ministries_details.length > 0 ? (
                                <>
                                  {row?.ministries_details?.map(
                                    (m, index) => (
                                      <div key={index}>{m?.name}{ index == row?.ministries_details.length -1 ? "" : ","}</div>
                                    ),
                                  )}
                                  </>
                              ) : (
                                "NA"  
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{
                                // minWidth: "280px !important",
                                color: "#DC002D !important",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                showPDF(
                                  row?.doc_detail?.link
                                )
                              }
                            >
                              {row?.title ?? 'NA' }
                              
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={10} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </Grid>

              {renderNoDatafound()}

              {/* Table Pagination starts here */}
              <Grid item xs={12}>
                <PaginationContainerStyled
                  container
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography sx={{ pl: 1 }} variant="body2">
                    {totalRecords} entries found
                  </Typography>
                  <TablePagination
                    component="div"
                    count={totalRecords}
                    page={page}
                    rowsPerPageOptions={[5, 10, 20]}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </PaginationContainerStyled>
              </Grid>
            </Grid>
          )}
        </Grid>

      
      </Grid>

      {/* Filter Dialog start here */}
      <Dialog
        open={openFilter}
        onClose={handleFilterClose}
        fullScreen={isXs}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "90vw",
            },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(4,1fr)",
                  lg: "repeat(4,1fr)",
                  xl: "repeat(4,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              {/* House    */}
              <FormControl fullWidth>
                <Typography>House</Typography>
                <MySelect
                  value={house}
                  onChange={(e) => setHouse(e.target.value)}
                  menuArray={houseList}
                />
              </FormControl>

              {/* Session Number */}
              <FormControl fullWidth>
                <Typography>Session Number</Typography>
                <MySelect
                  value={sessionNumber}
                  onChange={(e) => setSessionNumber(e.target.value)}
                  menuArray={sessionNumberList}
                />
              </FormControl>

              

              {/* Date */}
              <FormControl fullWidth>
                <Typography>Date</Typography>
                <MyDateRange
                onChange={(item) => setDateRange([item.selection])}
                dateRange={dateRange}
                />
               </FormControl>

              {/* Type */}
              <FormControl fullWidth>
                <Typography>Type</Typography>
                <MySelect
                  value={debateType}
                  onChange={(e) => setDebateType(e.target.value)}
                  menuArray={debateTypeList}
                />
              </FormControl>

              {/* Member of Parliament */}
              <FormControl fullWidth>
                <Typography>Member of Parliament</Typography>
                <MySelect
                  value={memberOfPa}
                  onChange={(e) => setMemberOfPa(e.target.value)}
                  menuArray={memberOfPaList}
                />
              </FormControl>

              {/* Theme/Subject*/}
              {/* <FormControl fullWidth>
                <Typography>Theme/Subject</Typography>
                <MySelectMultiple
                  value={debateTheme}
                  onChange={handleChangeDebatesThemes}
                  menuArray={debateThemeList}
                />
              </FormControl> */}

              {/* Ministry*/}
              <FormControl fullWidth>
                <Typography>Ministry</Typography>
                <MySelect
                  value={ministry}
                  onChange={(e) => setMinistry(e.target.value)}
                  menuArray={ministryList}
                />
              </FormControl>

             {/* State*/}
              {/* <FormControl fullWidth>
                <Typography>Status</Typography>
                <MySelect
                  value={debateStatus}
                  onChange={(e) => setDebateStatus(e.target.value)}
                  menuArray={debateStatusList}
                />
              </FormControl> */}
            </Box>

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined
            onClick={handleFilterReset}
            sx={{ textTransform: "none" }}
          >
            Reset
          </ButtonOutlined>
          <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};


export default AllDebates;

