import moment from "moment"


export const handleStatusColor = (status) => {
    status = status?.toUpperCase()
    switch(status){
        case "PENDING" : 
        return {
          backgroundColor : "#BC06064D" ,
          color : "#BC0606",
          border : '1px solid #BC06064D',
        }
        case "FINAL" : 
        return {
          backgroundColor : "#0E7B204D" ,
          color : "#0E7B20",
          border : '1px solid #0E7B204D',
        }
        case "DRAFT" :
          return {
            backgroundColor : "#EB5C0A4D",
            color : "#EB5C0A",
            border: "1px solid #EB5C0A4D",
         }
        case "WAITING" :
        return {
            backgroundColor : "#CB9F054D",
            color : "#CB9F05",
            border: "1px solid #CB9F054D",
        } 
        case "DECIDED" :
        return {
            backgroundColor : "#113CD64D",
            color : "#113CD6",
            border: "1px solid #113CD64D",
        }
        case "DEFFERRED" :
        return {
            backgroundColor : "#DF76764D",
            color : "#DF7676",
            border: "1px solid #DF76764D",
            padding : '2px 2px',
            width : "80px"
        }
        case "REJECTED" :
        return {
            backgroundColor : "#950C004D",
            color : "#950C00",
            border: "1px solid #950C004D",
            }    
        case "RECOMMENDED" :
        return {
            backgroundColor : "#1390D74D",
            color : "#1390D7",
            border: "1px solid #1390D74D",
            padding : '2px 1px',
            width : "100px"
         }
         
      }
}


export const handleDateFormat = (date) => {
  if(date){
    return moment(date).format('DD MMM YYYY')
 }
 else
 return "NA"
}

export const handleDateFormatforFilter = (date) => {
  if(date){
    return moment(date).format('YYYY-MM-DD')
 }
 else
 return "NA"
}

export const handleArrayQueryParam = (arr, queryString) => {
  let str = ""
  arr.forEach((item)=> {
    str = str + `&${queryString}=${item.id}`
  })
  return str;
}


export const addCommasToNumber = (number) => {
  // Convert the number to a string
  const numberString = number?.toString();

  // Use regular expression to add commas
  const formattedNumber = numberString?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedNumber;
}

export const handleTextTransform = (value) => {
  if(value){
   return {textTransform : "lowercase !important"}
  }else 
  return {textTransform : "uppercase important"}
}

// export const handleStatusColor = (status) => {
//   status = status.toUpperCase()
//   switch(status){
//       case "Pending" : 
//       return {
//         backgroundColor : "#BC06064D" ,
//         color : "#BC0606",
//         border : '1px solid #BC06064D',
//       }
//       case "Final" : 
//       return {
//         backgroundColor : "#0E7B204D" ,
//         color : "#0E7B20",
//         border : '1px solid #0E7B204D',
//       }
//       case "Draft" :
//         return {
//           backgroundColor : "#EB5C0A4D",
//           color : "#EB5C0A",
//           border: "1px solid #EB5C0A4D",
//        }
//       case "waiting" :
//       return {
//           backgroundColor : "#CB9F054D",
//           color : "#CB9F05",
//           border: "1px solid #CB9F054D",
//       } 
//       case "decided" :
//       return {
//           backgroundColor : "#113CD64D",
//           color : "#113CD6",
//           border: "1px solid #113CD64D",
//       }
//       case "defferred" :
//       return {
//           backgroundColor : "#DF76764D",
//           color : "#DF7676",
//           border: "1px solid #DF76764D",
//           padding : '2px 2px',
//           width : "80px"
//       }
//       case "rejected" :
//       return {
//           backgroundColor : "#950C004D",
//           color : "#950C00",
//           border: "1px solid #950C004D",
//           }    
//       case "recommended" :
//       return {
//           backgroundColor : "#1390D74D",
//           color : "#1390D7",
//           border: "1px solid #1390D74D",
//           padding : '2px 1px',
//           width : "100px"
//        }
       
//     }
// }



