import React, {useState, useEffect} from 'react'
import { Box, Grid, Paper, Typography, Tabs, Tab, Table, TableBody, TableRow,TableHead, TableCell, useMediaQuery, useTheme} from '@mui/material'
import LinkBar from '../../components/LinkBar/LinkBar'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AuthorImg from '../../assets/Images/author.png'
import debatesImg from '../../assets/Images/debates.png'
import Card from '../../components/Card/Card'
import { ButtonPrimary, MyBox } from '../../components/StyledComponents/StyledComponents';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { tabValuesArray } from './tabValueArray';
import { StyledTableCell, StyledTableContainer, StyledTableRow } from '../../components/StyledComponents/StyledTable/styledTable';

const AuthorPage = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const {state} = useLocation()
  const id = state ? state.id : null
  const type = state ? state.type : null
  // const {id, type } = state;

  let isXs = false;
  let isSm = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));
  isSm = useMediaQuery(theme.breakpoints.down('md'))

  //states
  const [tabValue, setTabValue] = useState("Latest");
  const [profileInfo, setProfileInfo] = useState({});

  const allowedTabs =  [ "questions","debates","reports","bills"]
  const keys = Object.keys(profileInfo)
  console.log("keys",keys)

  let TabsToRender = keys.filter( key => allowedTabs.includes(key))
  console.log("tabs",TabsToRender)
  TabsToRender.unshift("Latest")
  // TabsToRender = TabsToRender.map( (item, index) => {
  //   return {
  //     value : index + 2, name : item
  //  }
  // }
  
  // )

  // TabsToRender.unshift({value : 1, name : "Latest"})


  //api calling
  useEffect(() => {
    getProfileInfo()
  }, [])
  
  const getProfileInfo = () => {
    let path = getPath();
     dispatch({
      type : "PROFILE_INFO",
      path : path,
      successCallBack : successCallBack
     })
  }

  const getPath = () => {
    switch(type){
      case "author" :
      return `/contents/${id}/author_detail?type=Author`
    
 
     case "memberOfParliament" :
      return `/contents/${id}/author_detail?type=MemberOfParliament`
    
     
     case "ministry" :
      return `/contents/${id}/author_detail?type=Ministry`
     
     case "committee" :
      return `/contents/${id}/author_detail?type=Committee`

     default:
      return ""
  }
}

  const successCallBack = (data) => {
    setProfileInfo(data)
  }
    

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  }
  

  const showPDF = (pdfUrl) => {
    if(!pdfUrl){
      showSnackbar("No link found");
      return
    }
    // for any type of link
    window.open(pdfUrl, "_blank");

    // if (pdfUrl) {
    //   const link = document.createElement('a');
    //   link.href = pdfUrl;
    //   link.target = '_blank'; // Open in a new tab
    //   link.rel = 'noopener noreferrer';
    //   link.download = pdfUrl;
    //   link.click();
    // }
  };

  const showSnackbar = (message) => {
    try {
      dispatch({
        type: "SHOW_SNACKBAR",
        message: message,
      });
    } catch (error) {
      console.error("Failed to dispatch Snackbar:", error);
      // Handle the error appropriately, e.g., log it or show a generic error message.
    }
  };

  const capitalizeFirstLetter = (str) => {
    // Check if the input string is empty or null
    if (!str) {
      return str;
    }
  
    // Capitalize the first letter and concatenate it with the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
 
  const handleType = (type) =>{
    if(type === "Report"){
      return "/report"
    }else if(type === "Bill"){
      return "/bill"
    } 
  }

  return (
    <Grid
    item
    container
    sx={{backgroundColor : (t)=> t.palette.mode === "dark" ? t.palette.background.default : t.palette.grey[100]}}
    >
    <LinkBar title={profileInfo?.name}/>

    <Grid
    container
    sx={{backgroundColor : (t) => t.palette.primary.main}}>
      <Grid item xs={8} sm={6} md={3} lg={3} xl={3} sx={{border : 1, padding : "20px"}}>
        <Box 
        component="img"
        src={profileInfo?.profile_picture_detail?.link }
        style={{width : "100%", maxHeight : "300px"}}
        alt="author"/>
      </Grid>
      <Grid item xs={12} sm={12} md={6} sx={{py : "20px", 
      pl : ["20px", "20px", "10px", "10px", "20px"], 
      pr : '30px'}}>
        {
          type === "author" && <Typography variant='subtitle2' sx={{textTransform : 'uppercase', mb : '15px'}}color="text.alwayswhite">{profileInfo?.title}</Typography>

        }
        <Typography variant='h1' color="text.alwayswhite" sx={{ mb : '15px'}}>{profileInfo?.name}</Typography>
        <Typography variant='subheading2' color="text.alwayswhite">
          {profileInfo?.bio}
        {/* Himanshu is a Research Fellow with the Climate and Ecosystems Team at Vidhi. He has previously worked as an academic researcher with Dr. Sujith Koonan on the issues of Manual Scavenging and Environmental Rule of Law in India. He has also previously worked as a Project Fellow with the Climate and Ecosystems Team at Vidhi. Himanshu completed his LL.B. from Campus Law Centre, University of Delhi in 2020. He is interested in working on Environmental Law & Policy regime from a critical ecocentric legal theory perspective. He enjoys reading social science literature and listening to music. */}
        </Typography>
      </Grid>
      <Grid item container alignContent={"flex-end"} xs={12} sm={12} md={3} lg={3} xl={3}sx={{py : "20px", 
       pl : isSm ? "20px": "10px",
       pr : '30px', gap :'25px'}}>

       {/* <Box sx={{width :1}}>
         <Typography variant="subtitle2" color="text.alwayswhite">TOPICS:</Typography>
         {
          ["Climate and Ecosystem","Nyayaa","samvidhan FellowShip"].map( item => (
            <Typography sx={{wordBreak : 'break-all'}} variant="body1" color="vidhiRed.light">{item}</Typography>
          ))
         }
       </Box> */}

       {
        type === "author" && (
          <>
           
       <Box sx={{width :1, }}>
         <Typography variant="subtitle2" color="text.alwayswhite">MAILS:</Typography>
          <Typography sx={{wordBreak : 'break-all'}} variant="body1" color="vidhiRed.light">{profileInfo?.mail}</Typography>
       </Box>
       
       <Box sx={{width : '100%', display :'flex' }}> 
           <Box sx={{ padding : '5px 10px', border :'1px solid #FF8278'}}>
             <MailOutlineIcon sx={{color : (t) => t.palette.vidhiRed.light }} />
          </Box>
       </Box>
      </>
       
        )
       }

   


       
      </Grid>
 </Grid>

  {
    type !== "author" && (

      <Grid
      container
      sx={{px : '32px', py : '10px', backgroundColor : (t) => t.palette.primary.main}}>
       <Grid container sx={{ borderTop :'2px solid #FF8278', pt : '20px'}}>
        {/* Protected Areas Tabs */}
        <Box sx={{ width: "100%"}}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="secondary tabs example"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: (t) => t.palette.primary.dark,
              },
              "& .MuiButtonBase-root": {
                minWidth: 0,
                minHeight : '40px',
                textTransform: "none",
                fontSize: 16,
              },
            }}
          >
            {TabsToRender?.map((item, index) => (
              <Tab
                disableRipple
                wrapped
                sx={{
                  color : (t) => t.palette.vidhiRed.light,
                  "&.Mui-selected": {
                    backgroundColor : (t) => t.palette.vidhiRed.light,
                    borderRadius :'4px',
                    color : (t) => t.palette.primary.main,
                    fontWeight : 700,
                  },
                }}
                // value={item.value}
                // label={item.name}
                value={item}
                label={capitalizeFirstLetter(item)}
                key={index}
              />
             ))}
          </Tabs>
        
        </Box>
  
       </Grid>
      </Grid>
    )
  }
   

     
     {/* Related Posts of Author */}


    <Grid container>
      {/* Heading */}
      <Grid 
      item
      xs={12}
      sx={{width :'100%'}}>
      <Typography align='center' variant='h4' sx={{my: "30px"}}>Related Posts</Typography>
      </Grid>

       {/* Publication posts*/}
       <Grid item xs={12}>
      <Box
       sx= {{
        display : 'grid',
        gridTemplateColumns : { xs : 'repeat(1,1fr)', sm : 'repeat(2,1fr)',  md : 'repeat(3,1fr)', lg : 'repeat(4,1fr)', xl : 'repeat(4,1fr)'},
        gap : '50px 50px',
        gridGap : '50px 50px',
        px : '32px',
        pb : '32px',
        boxSizing : 'border-box'
    }}
       >

        {
          profileInfo?.publications?.map( item => (
            <Card
            key={item?.id}
            item={item}
            title={item?.title}
            subtitle={item?.small_detail}
            cardImg={item?.thumbnail_detail?.link}
            date={item?.date}
            to={"/publication"}
            />
           
          ))

        }
        

       </Box>
      </Grid> 

       {/* Reports */}
       {
          tabValue === "Latest" && (
            <Grid item xs={12}>
      <Box
       sx= {{
        display : 'grid',
        gridTemplateColumns : { xs : 'repeat(1,1fr)', sm : 'repeat(2,1fr)',  md : 'repeat(3,1fr)', lg : 'repeat(4,1fr)', xl : 'repeat(4,1fr)'},
        gap : '50px 50px',
        gridGap : '50px 50px',
        px : '32px',
        pb : '32px',
        boxSizing : 'border-box'
    }}
       >

        {

          profileInfo?.latest?.length == 0 ? <NoDataFound />:
         profileInfo?.latest?.map( item => (
            <Card
            key={item?.id}
            item={item}
            title={item?.title}
            subtitle={item?.small_detail}
            cardImg={item?.thumbnail_detail?.link}
            date={item?.date}
            to={handleType(item?.type)}
            />
           
          ))

        }
        

       </Box>
      </Grid> 
          )
         }

      {/* Questions */}
      {
        tabValue === "questions" && (
          <Grid container sx={{ pl: "1.5rem", pb: "1rem" }}>
          <Grid item xs={12}>
            <StyledTableContainer>
              <Table
                stickyHeader aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>House</StyledTableCell>
                    <StyledTableCell>Session Number</StyledTableCell>
                    <StyledTableCell>Question Number</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Member of Parliament</StyledTableCell>
                    <StyledTableCell>Theme/Subject</StyledTableCell>
                    <StyledTableCell>Ministry</StyledTableCell>
                    <StyledTableCell>Title</StyledTableCell>
                    {/* <StyledTableCell></StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                {/* ?.filter((item) => item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) || item?.house.name?.toLowerCase().includes(searchValue?.toLowerCase()) ) */}
                  {
                     profileInfo?.questions?.length < 1 ? <NoDataFound /> :
                  profileInfo?.questions?.map((row) => (
                      <StyledTableRow key={row?.id}>
                        <StyledTableCell
                          sx={{ minWidth: "150px !important" }}
                        >
                          {row?.house?.name ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: "150px !important" }}
                        >
                          {row?.session_number?.name  ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: "150px !important" }}
                        >
                          {row?.question_no  ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: "150px !important" }}
                        >
                          {row?.question_date  ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: "150px !important" }}
                        >
                          {row?.type_of_question  ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: "220px !important" }}
                        >
                          {row?.member_of_parliament_details[0]?.name  ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: "220px !important" }}
                        >
                          {row?.theme_subject?.name  ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ minWidth: "150px !important" }}
                        >
                          {row?.ministry_details[0]?.name  ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            minWidth: "280px !important",
                            color: "#DC002D !important",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            showPDF(
                              row?.doc_detail?.link
                            )
                          }
                        >
                          {row?.title  ?? "NA"}
                        </StyledTableCell>
                        {/* <StyledTableCell><MoreVertIcon /></StyledTableCell> */}
                      </StyledTableRow>
                    ))}
                  {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={10} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>

          {/* {renderNoDatafound()} */}

          {/* Table Pagination starts here */}
          {/* <Grid item xs={12}>
            <PaginationContainerStyled
              container
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography sx={{ pl: 1 }} variant="body2">
                {totalRecords} entries found
              </Typography>
              <TablePagination
                component="div"
                count={totalRecords}
                page={page}
                rowsPerPageOptions={[5, 10, 20]}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </PaginationContainerStyled>
          </Grid> */}
        </Grid>
        )
      }

        {/* Debates */}
        {
          tabValue === "debates" && (
            <Grid container sx={{ pl: "1.5rem", pb: "1rem" }}>
            <Grid item xs={12}>
              <StyledTableContainer>
                <Table
                 stickyHeader aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>House</StyledTableCell>
                      <StyledTableCell>Session Number</StyledTableCell>
                      <StyledTableCell>Debate Type</StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      {/* <StyledTableCell>Type</StyledTableCell> */}
                      <StyledTableCell>Member of Parliament</StyledTableCell>
                      {/* <StyledTableCell>Theme/Subject</StyledTableCell> */}
                      <StyledTableCell>Ministry</StyledTableCell>
                      <StyledTableCell>View</StyledTableCell>
                      {/* <StyledTableCell></StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {/* filter((item) =>item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) || item?.house.name?.toLowerCase().includes(searchValue?.toLowerCase()) ). */}
                    {
                     profileInfo?.debates?.length < 1 ? <NoDataFound /> :
                    profileInfo?.debates?.map((row) => (
                        <StyledTableRow key={row?.id}>
                          <StyledTableCell
                            sx={{ minWidth: "150px !important" }}
                          >
                            {row?.house?.name ?? "NA"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "150px !important" }}
                          >
                            {row?.session_number?.name ?? "NA"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "220px !important" }}
                          >
                            {row?.debate_type?.name ?? "NA"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "150px !important" }}
                          >
                            {row?.date_of_debate ?? "NA"}
                          </StyledTableCell>
                          {/* <StyledTableCell
                            sx={{ minWidth: "150px !important" }}
                          >
                            {row?.type_of_question}
                          </StyledTableCell> */}
                          <StyledTableCell
                            sx={{ minWidth: "220px !important" }}
                          >
                            {row?.member_of_parliaments_details[0]?.name ?? "NA"}
                          </StyledTableCell>
                         
                          <StyledTableCell
                            sx={{ minWidth: "220px !important" }}
                          >
                            {row?.ministries_details[0]?.name ?? "NA" }
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              // minWidth: "280px !important",
                              color: "#DC002D !important",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              showPDF(
                                row?.doc_detail?.link
                              )
                            }
                          >
                            {row?.title ?? 'NA' }
                            
                          </StyledTableCell>
                          {/* <StyledTableCell><MoreVertIcon /></StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                   </TableBody>
                </Table>
              </StyledTableContainer>
            </Grid>


          </Grid>
          )
        }

         {/* Reports */}
         {
          tabValue === "reports" && (
            <Grid item xs={12}>
      <Box
       sx= {{
        display : 'grid',
        gridTemplateColumns : { xs : 'repeat(1,1fr)', sm : 'repeat(2,1fr)',  md : 'repeat(3,1fr)', lg : 'repeat(4,1fr)', xl : 'repeat(4,1fr)'},
        gap : '50px 50px',
        gridGap : '50px 50px',
        px : '32px',
        pb : '32px',
        boxSizing : 'border-box'
    }}
       >

        {
            profileInfo?.reports?.length < 1 ? <NoDataFound /> :
         profileInfo?.reports?.map( item => (
            <Card
            key={item?.id}
            item={item}
            title={item?.title}
            subtitle={item?.small_detail}
            cardImg={item?.thumbnail_detail?.link}
            date={item?.date}
            to={"/report"}
            />
           
          ))

        }
        

       </Box>
      </Grid> 
          )
         }

         {/* Bills */}
         {
          tabValue === "bills" && ( 
            <Grid item xs={12}>
      <Box
       sx= {{
        display : 'grid',
        gridTemplateColumns : { xs : 'repeat(1,1fr)', sm : 'repeat(2,1fr)',  md : 'repeat(3,1fr)', lg : 'repeat(4,1fr)', xl : 'repeat(4,1fr)'},
        gap : '50px 50px',
        gridGap : '50px 50px',
        px : '32px',
        pb : '32px',
        boxSizing : 'border-box'
    }}
       >

        {  profileInfo?.bills?.length < 1 ? <NoDataFound /> :
          profileInfo?.bills?.map( item => (
            <Card
            key={item?.id}
            item={item}
            title={item?.title}
            subtitle={item?.small_detail}
            cardImg={item?.thumbnail_detail?.link}
            date={item?.date}
            to={"/bill"}
            />
           
          ))

        }
        

       </Box>
      </Grid> 
          )
         }

    </Grid>

     
    
    
    </Grid>
  )
}

export default AuthorPage


const NoDataFound = () => {
  return <MyBox center sx={{width : "100%", height : '30vh'}}>
    <Typography variant='body1'>No Data Found</Typography>
  </MyBox>
}