import React, { useState } from "react";
import { Popover, Box, Typography } from "@mui/material";

const MyPopover = ({ caseTitle, content }) => {
  //state to open popover

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
    {
      caseTitle ? ( 
      <Box
        sx={{ color: "#0288d1 !important", cursor: "pointer" }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {caseTitle}
      </Box>) : 
      <Typography 
       variant="body2">NA</Typography>
      
    }
     

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            // minWidth : "300px",
            maxWidth: "600px",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={0}
        marginThreshold={1}
      >
        <Typography variant="body1" sx={{ p: 1 }}>
          {content}
        </Typography>
      </Popover>
    </>
  );
};

export default MyPopover;
