import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Slider,
  Switch,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LinkBar from "../../components/LinkBar/LinkBar";
import {
  ButtonOutlined,
  ButtonPrimary,
  FilterStyled,
  SearchBarStyled,
  TextUpperCase,
  UnderlinedTypography,
} from "../../components/StyledComponents/StyledComponents";
import { TabsArray } from "./tabsArray";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PaTable from "./PaTable";
import { useEffect } from "react";


import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import "./style.css";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { getItem, setItem } from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import MySelect from "../../components/Select/MySelect";
import MySelectMultiple from "../../components/Select/MySelectMultiple";

import MySliderForESZArea from "../../components/Slider/MySliderForESZArea";
import MyDateRange from "../../components/DateRange/MyDateRange";
import { handleDateFormatforFilter } from "../../utils/StatusStyle";
import { showPDF } from "../../utils/PdfUtils";
import SpeciesPopUp from "./SpeciesPopUp";
import RedListPopUp from "./RedListPopUp";

const minDistance = 10;
let minValue = 0;
let maxValue = 100;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AllProtectedAreas = () => {
  // hooks Calling
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const loader = useSelector((state) => state.commonReducer.showLoader);

  const id = searchParams.get("id");
  const name = searchParams.get("name");
  const filterName = searchParams.get("filter");
 

  let filterArray = ["bioZone", "sheduledOne", "invasiveSpecies"];
  let DetailedFilter = filterArray.find((item) => item === filterName);

  let isXs = false;
  let isMd = false;
  isXs = useMediaQuery(theme.breakpoints.down("sm"));
  isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const paName = getItem("pa");
  console.log("paName ================  ", paName);

  // Component State starts here
  const [open, setOpen] = React.useState(false);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterUIData, setFilterUIData] = useState({});
  const [isSpeciesDialogOpen, setIsSpeciesDialogOpen] = useState(false);
  const [isRedListDialogOpen, setIsRedListDilaogOpen] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAscending, setIsAscending] = useState("default");
  const [isAscendDN, setIsAscendDN] = useState("default");

  // Filter Fields States
  const [allStatesList, setAllStatesList] = useState([]);
  const [paTypesList, setPaTypesList] = React.useState([]);
  const [specialRecognitionList, setSpecialRecognitionList] = useState([]);
  const [eszZoneList, setEszZoneList] = useState([]);

  const [statesData, setStatesData] = useState([]);
  const [paTypesData, setPaTypesData] = React.useState([]);
  const [specialRecognition, setSpecialRecognition] = useState("");
  const [eszStatus, setEszStatus] = useState("");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  
  const [ESZAreaMinMax, setESZAreaMinMax] = React.useState([0, 100]);

  const [tabValue, setTabValue] = useState(paName);
  const [totalRecords, setTotalRecords] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchQuery.length === 0) {
      getTablesData();
    } else if (searchQuery.length > 2) {
      handleFilterApply();
    }
  }, [searchQuery]);

  // effect for data of Filter's Fields
  useEffect(() => {
    dispatch({
      type: "PA_FILTER_LIST_DATA",
      successCallBack: successCallBackFilterList,
    });
  }, []);

  useEffect(() => {
    setTabValue(paName);
  }, [paName]);

  useEffect(() => {
    if (searchParams.get("name")) {
      setIsFilterApplied(true);
      if (DetailedFilter) {
        // Swith case for calling filter UI
        let path = `/protected_areas/${id}/`;
        switch (DetailedFilter) {
          case "bioZone":
            path = path + "bio_geographic_zone_detail";
            break;
          case "sheduledOne":
            path = path + "schedule_one_specie_detail";
            break;
          case "invasiveSpecies":
            path = path + "invasive_specie_detail";
            break;
          default:
            // Handle the case where DetailedFilter doesn't match any condition
            return null;
        }

        getFilterUIData(path);
      }

      //  filtered data for from Pa Screen
      switch (tabValue) {
        case "National Park":
          let path0 = handleFilterPathfromPAInfromation("1");
          handleFilterDispatch(path0);
          break;
        case "Wildlife Sanctuary":
          let path1 = handleFilterPathfromPAInfromation("0");
          handleFilterDispatch(path1);
          break;
        case "Conservation Reserve":
          let path2 = handleFilterPathfromPAInfromation("2");
          handleFilterDispatch(path2);
          break;
        case "Community Reserve":
          let path3 = handleFilterPathfromPAInfromation("3");
          handleFilterDispatch(path3);
          break;
        default:
          let path = handleFilterPathfromPAInfromation();
          handleFilterDispatch(path);
          break;
      }
    } else if (isFilterApplied) {
      handleFilterApply(); //condition if rowperpage and page changed
    } else {
      getTablesData();
    }
    handleDisablePaValue();
  }, [searchParams, tabValue, page, rowsPerPage, isAscendDN, isAscending]);

  const getFilterUIData = (path) => {
    dispatch({
      type: "GET_FILTER_UI_DATA",
      successCallBack: successCallBackFilterUIData,
      path: path,
    });
  };

  const successCallBackFilterUIData = (res) => {
    setFilterUIData(res?.data?.data);
  };

  const successCallBack = (res) => {
    setTableData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    console.log("table data", tableData, totalRecords);
    setOpen(false);
    setIsFilterApplied(false);
  };

  const handleTableDatadispatch = (type) => {
    let path = `/protected_areas?page=${page + 1}&per_page=${rowsPerPage}`;

    if (type) {
      path = path + `&pa_category[]=${type}&search=true`;
    }

    if (isAscending !== "default") {
      path = path + `&sort_by_area_of_pa=${isAscending}`;
    }
    if (isAscendDN !== "default") {
      path = path + `&sort_by_date_of_notify=${isAscendDN}`;
    }
    dispatch({
      type: "GET_ALL_PA",
      successCallBack: successCallBack,
      path: path,
    });
  };

  const getTablesData = () => {
    switch (tabValue) {
      case "National Park":
        handleTableDatadispatch("1");
        // dispatch({
        //   type: "GET_ALL_PA",
        //   successCallBack: successCallBack,
        //   path: path,
        // });
        break;
      case "Wildlife Sanctuary":
        handleTableDatadispatch("0");
        // dispatch({
        //   type: "GET_ALL_PA",
        //   successCallBack: successCallBack,
        //   path: `/protected_areas/wild_life_sanctuary?page=${
        //     page + 1
        //   }&per_page=${rowsPerPage}`,
        // });
        break;
      case "Conservation Reserve":
        handleTableDatadispatch("2");
        // dispatch({
        //   type: "GET_ALL_PA",
        //   successCallBack: successCallBack,
        //   path: `/protected_areas/conservation_reserve?page=${
        //     page + 1
        //   }&per_page=${rowsPerPage}`,
        // });
        break;
      case "Community Reserve":
        handleTableDatadispatch("3");

        // dispatch({
        //   type: "GET_ALL_PA",
        //   successCallBack: successCallBack,
        //   path: `/protected_areas/community_reserve?page=${
        //     page + 1
        //   }&per_page=${rowsPerPage}`,
        // });
        break;
      default:
        handleTableDatadispatch();
        break;
    }
  };

  const successCallBackFilterList = (data) => {
    minValue = data?.esz_min_area;
    maxValue = data?.esz_max_area;
    setAllStatesList(data?.states_data);
    setPaTypesList(data?.pa_categories_data);
    setSpecialRecognitionList(data?.special_recognition_data);
    console.log(
      ">>>>>> esz_max_area 1",
      data?.esz_min_area,
      data?.esz_max_area,
      data
    );
    setEszZoneList(data?.esz_status_data);
    let eszArea = [data?.esz_min_area, data?.esz_max_area];
    setESZAreaMinMax(eszArea);
    setTimeout(() => {
      console.log(">>>>>> esz_max_area ", ESZAreaMinMax, eszArea);
    }, 2000);
  };

  const handleFilterPathfromPAInfromation = (paType) => {
    let path = `/protected_areas?page=${
      page + 1
    }&per_page=${rowsPerPage}&search=true`;

    // let filterName = getItem("paFilterName");
    // let filterId = getItem("paFilterId");
    // if (subPath === "all") {
    //   path = `/protected_areas?page=${page + 1}&per_page=${rowsPerPage}&search=true`;
    // }
    // else {
    //   path = `/protected_areas/${subPath}?page=${page + 1}&per_page=${rowsPerPage}`;
    // }
    if (paType) {
      path = path + `&pa_category[]=${paType}`;
    }
    if (filterName === "specialRecognition") {
      path = path + `&special_recognition_ids=${id}`;
    }
    if (filterName === "specialRecognitionDate") {
      path = path + `&date_special_recognition=${id}`;
    }
    if (filterName === "bioZone") {
      path = path + `&bio_geographic_zone_ids[]=${id}`;
    }
    if (filterName === "state") {
      path = path + `&state_ids[]=${id}`;
    }
    if (filterName === "sheduledOne") {
      path = path + `&schedule_one_species_ids[]=${id}`;
    }
    // if (filterName === "nearByPa") {
    //   path = path + `&invasive_species_ids[]=${filterId}`;
    // }
    if (filterName === "invasiveSpecies") {
      path = path + `&invasive_species_ids[]=${id}`;
    }
    if (filterName === "borderStates") {
      path = path + `&border_states_ids[]=${id}`;
    }

    return path;
  };

  const handleAscendDescendAreaPA = () => {
    setIsAscending((pre) => {
      if (pre === "default") {
        return "asc";
      } else if (pre === "asc") {
        return "dsc";
      } else if (pre === "dsc") {
        return "default";
      }
    });
    setIsAscendDN("default");
    // setIsAscending((pre) => {
    //   if (pre === "asc") {
    //     return "desc";
    //   } else return "asc";
    // });
  };

  const handleAscendDescendDateofNotify = () => {
    setIsAscendDN((pre) => {
      if (pre === "default") {
        return "asc";
      } else if (pre === "asc") {
        return "dsc";
      } else if (pre === "dsc") {
        return "default";
      }
    });
    setIsAscending("default");
    // setIsAscendDN((pre) => {
    //   if (pre === "asc") {
    //     return "desc";
    //   } else return "asc";
    // });
  };

  const handleDisablePaValue = () => {
    let newValue = getItem("pa");
    switch (newValue) {
      case "National Park": {
        setPaTypesData([{ id: 1, name: "National Park" }]);
        break;
      }
      case "Wildlife Sanctuary": {
        setPaTypesData([{ id: 0, name: "Wild Life Sanctuary" }]);
        break;
      }
      case "Conservation Reserve": {
        setPaTypesData([{ id: 2, name: "Conservation Reserve" }]);
        break;
      }
      case "Community Reserve": {
        setPaTypesData([{ id: 3, name: "Community Reserve" }]);
        break;
      }
      default:
        setPaTypesData([]);
    }
  };

  const handleChange = (event, newValue) => {
    setSearchQuery("")
    setSearchParams("");
    setTabValue(newValue);
    setPage(0);
    setRowsPerPage(10);
    setItem("pa", newValue);
    setIsAscendDN("default");
    setIsAscending("default");
    setEszStatus("");
    setSpecialRecognition("");
    // handleDisablePaValue()
    setStatesData([]);
    setDateRange((date) => {
      return [{ ...date[0], startDate: new Date(), endDate: null }];
    });
    setShowDatePicker(false);
    setIsFilterApplied(false);
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    setESZAreaMinMax(newValue);
  };

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleChangePaTypes = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);

    setPaTypesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const paDataforFilter = paTypesData.map((item) => item.id);
  console.log("paDataforFilter", paDataforFilter);

  const handleChangeStates = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    setStatesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const stateDataForFilter = statesData.map((item) => item.id);
  console.log("stateDataForFilter", stateDataForFilter);

  // handling Pagination Functions
  const handleChangePage = (event, newPage) => {
    console.log("new Page", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //handling filter Actions
  const getSelectedStates = () => {
    let str = "";
    stateDataForFilter.forEach((el) => {
      str = str + "&state_ids[]=" + el;
      // str = str + "state_ids[]=" + el + "&"
    });
    return str;
  };

  const getSlectedPAs = () => {
    let str = "";
    paDataforFilter.forEach((el) => {
      str = str + "&pa_category[]=" + el;
    });
    return str;
  };
  
  const successCallBackFilter = (res) => {
    setTableData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    setOpen(false);
    if (searchQuery.length > 2) {
      setIsFilterApplied(false)
    } else {
      setIsFilterApplied(true);
    }
  };

  const handleFilterDispatch = (path) => {
    dispatch({
      type: "GET_ALL_PA",
      path: path,
      successCallBack: successCallBackFilter,
    });
  };

  const handleFilterPath = () => {
    let path = `/protected_areas?page=${page + 1}&per_page=${rowsPerPage}`;

    if (isAscending !== "default") {
      path = path + `&sort_by_area_of_pa=${isAscending}`;
    }
    if (isAscendDN !== "default") {
      path = path + `&sort_by_date_of_notify=${isAscendDN}`;
    }
    let isSearching = false;
    // for Search
    if (searchQuery.length > 2) {
      isSearching = true;
      path = path + `&name=${searchQuery}`;
    }
    if (paDataforFilter.length > 0) {
      isSearching = true;
      path = path + getSlectedPAs();
    }
    if (stateDataForFilter.length > 0) {
      isSearching = true;
      path = path + getSelectedStates();
    }
    if (specialRecognition) {
      isSearching = true;
      path = path + `&special_recognition_ids[]=${specialRecognition}`;
    }
    if (eszStatus) {
      isSearching = true;
      path = path + `&esz_status=${eszStatus}`;
    }
    if (dateRange[0].startDate && dateRange[0].endDate) {
      isSearching = true;
      path =
        path +
        `&date_of_notify_start=${handleDateFormatforFilter(
          dateRange[0].startDate
        )}&date_of_notify_end=${handleDateFormatforFilter(
          dateRange[0].endDate
        )}`;
    }
    if (ESZAreaMinMax[0] !== minValue || ESZAreaMinMax[1] !== maxValue) {
      isSearching = true;
      path =
        path +
        `&area_of_pa_start=${ESZAreaMinMax[0]}&area_of_pa_end=${ESZAreaMinMax[1]}`;
    }
    if (isSearching) {
      path = path + "&search=true";
    }
    return path;
  };

  const handleFilterApply = () => {
    let path = handleFilterPath();
    handleFilterDispatch(path);
  };

  const handleFilterReset = () => {
    setIsFilterApplied(false);
    setStatesData([]);
    setSpecialRecognition("");
    setEszStatus("");
    let eszArea = [minValue, maxValue];
    setESZAreaMinMax(eszArea);
    setDateRange((date) => {
      return [{ ...date[0], startDate: new Date(), endDate: null }];
    });
    setShowDatePicker(false);
    setOpen(false);
    if (searchParams.get("name")) {
      setSearchParams("");
    } else {
      getTablesData();
    }
  };

  const handleArray = (selected) => {
    let arr = [];
    arr = selected.map((item) => item.name);
    return arr;
  };

  const handleFilterSpeciesLastSectionUI = () => {
    switch (DetailedFilter) {
      case "sheduledOne":
        return (
          <>
            <Grid item>
              <TextUpperCase variant="subtitle2">
                Protection Status :
              </TextUpperCase>
              <UnderlinedTypography
                red
                color="primary.dark"
                variant="body1"
                sx={{ mt: "6px" }}
                onClick={() => setIsSpeciesDialogOpen(true)}
              >
                Schedule 1 Species
              </UnderlinedTypography>
            </Grid>

            <Grid item>
              <TextUpperCase variant="subtitle2">IUCN Red List</TextUpperCase>
              {filterUIData?.iucn_status ? (
                <UnderlinedTypography
                  red
                  color="primary.dark"
                  variant="body1"
                  sx={{ mt: "6px" }}
                  onClick={() => setIsRedListDilaogOpen(true)}
                >
                  {filterUIData?.iucn_status}
                </UnderlinedTypography>
              ) : (
                "NA"
              )}
            </Grid>
          </>
        );
        break;
      case "bioZone":
        return (
          <Grid item>
            <TextUpperCase variant="subtitle2">States :</TextUpperCase>
            <Grid container sx={{ gap: "8px" }}>
              {filterUIData?.states?.length > 0
                ? filterUIData?.states?.map((state, index) => (
                    <Typography
                      color="primary.dark"
                      variant="body1"
                      sx={{ mt: "6px" }}
                      key={index}
                    >
                      {state}
                      {filterUIData?.states.length - 1 === index ? "" : ", "}
                    </Typography>
                  ))
                : "NA"}
            </Grid>
          </Grid>
        );
        break;
      case "invasiveSpecies":
        return (
          <Grid item>
            <TextUpperCase variant="subtitle2">Countries :</TextUpperCase>
            {filterUIData?.country?.name ? (
              <Typography
                color="primary.dark"
                variant="body1"
                sx={{ mt: "6px" }}
              >
                {filterUIData?.country?.name}
              </Typography>
            ) : (
              "NA"
            )}
          </Grid>
        );
        break;
      default:
        return null;
    }
  };

  const FilteredSpeciesUI = () => {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: (t) => t.palette.primary.light,
          px: isXs ? "16px" : "32px",
          pb: "32px",
          pt: "16px",
          mt : "1px"
        }}
      >
        {/* Species Image starts */}
        <Grid item xs={10} sm={6} md={2} lg={2} xl={2}>
          <Box
            style={{
              borderRadius: "8px",
              height: "228px",
              overflow: "hidden",
            }}
          >
            {loader ? (
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={"100%"}
                width={"100%"}
              />
            ) : (
              <Box
                component={"img"}
                src={filterUIData?.image_detail?.link}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </Box>
        </Grid>
        {/* Species Image end */}

        {/* Species short summary start */}
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          xl={7}
          sx={{ borderRight: (t) => `1px solid ${t.palette.divider}` }}
        >
          <Grid container direction={"column"} sx={{ gap: "20px", pl: "8px" }}>
            {loader ? (
              <Skeleton />
            ) : (
              <Typography variant="h2" sx={{ textTransform: "capitalize" }}>
                {filterUIData?.name ?? "NA"}
              </Typography>
            )}

            {loader ? (
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={"150px"}
                width={"100%"}
              />
            ) : (
              <Typography variant="subheading3" sx={{ width: "90%" }}>
                {filterUIData?.detail ?? "NA"}
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* Species short summary start */}

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{}}>
          <Grid
            container
            direction={"column"}
            justifyContent="space-between"
            sx={{
              gap: "20px",
              pl: isMd && "30px",
              // background: "khaki",
              height: "100%",
            }}
          >
            <Grid container direction={"column"} sx={{ gap: "20px" }}>
              {handleFilterSpeciesLastSectionUI()}
            </Grid>

            {/* Read more button */}
            {DetailedFilter === "bioZone" ? null : (
              <Grid container>
                <Grid
                  item
                  onClick={() =>
                    showPDF(filterUIData?.link, dispatch, "No link found")
                  }
                  sx={readMoreStyle}
                >
                  <UnderlinedTypography
                    red
                    color="primary.dark"
                    variant="body1"
                  >
                    Read more about the species
                  </UnderlinedTypography>
                </Grid>
              </Grid>
            )}

            {/* Read more button end */}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleFilteredTitle = () => {
    if (name) return `(${name})`;
    else return null;
  };

  return (
    <Grid
      item
      container
      sx={{
        pb: "30px",
        backgroundColor: (t) =>
          t.palette.mode === "dark"
            ? t.palette.background.default
            : t.palette.grey[100],
      }}
      // key={paName}
    >
      <LinkBar paName={tabValue} />

      {DetailedFilter && FilteredSpeciesUI()}

      {/* heading */}
      <Grid item sx={{ width: "100%", px: isXs ? "16px" : "32px", py: "20px" }}>
        <Typography variant="h2">
          List of Protected Areas{" "}
          <Typography
            variant="h2"
            component={"span"}
            sx={{
              color: (t) => t.palette.primary.dark,
              textTransform: "capitalize",
            }}
          >
            {handleFilteredTitle()}
          </Typography>
        </Typography>
      </Grid>

      {/* Protected Areas Tabs */}
      <Box sx={{ width: "100%", pl: isXs ? "16px" : "32px" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="secondary tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: (t) => t.palette.primary.dark,
            },
            "& .MuiButtonBase-root": {
              minWidth: 0,
              textTransform: "none",
              fontSize: 16,
            },
          }}
        >
          {TabsArray.map((item) => (
            <Tab
              key={item.name}
              disableRipple
              wrapped
              sx={{
                "&.Mui-selected": {
                  color: (t) => t.palette.primary.dark,
                  // fontWeight : 700
                },
              }}
              value={item.value}
              label={item.name}
            />
            // <Tab value={item.value}><Typography>{item.name}</Typography></Tab>
          ))}
        </Tabs>
      </Box>

      {/* search and filter */}
      <Grid
        item
        container
        justifyContent={"space-between"}
        alignItems="center"
        sx={{
          width: "100%",
          px: isXs ? "16px" : "32px",
          py: "20px",
          rowGap: "10px",
        }}
      >
        <Box
        // sx={{
        //   display: ["none", "block", "block", "block", "block"],
        // }}
        >
          <SearchBarStyled>
            <SearchIcon />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here"
              inputProps={{ "aria-label": "search google maps" }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </SearchBarStyled>
        </Box>
        {isXs ? (
          isFilterApplied ? (
            <FilterAltIcon
              onClick={handleFilterOpen}
              sx={{ color: theme.palette.primary.dark }}
            />
          ) : (
            <FilterAltOutlinedIcon
              onClick={handleFilterOpen}
              sx={{ color: theme.palette.primary.dark }}
            />
          )
        ) : (
          <FilterStyled
            isFilterApplied={isFilterApplied}
            onClick={handleFilterOpen}
          >
            <FilterListIcon />
            <Typography>Filter</Typography>
          </FilterStyled>
        )}
      </Grid>

      {/* proteced areas table */}

      <Grid item container sx={{ width: "100%", pl: isXs ? "16px" : "32px" }}>
        <PaTable
          isAscending={isAscending}
          handleAscendDescendAreaPA={handleAscendDescendAreaPA}
          isAscendDN={isAscendDN}
          handleAscendDescendDateofNotify={handleAscendDescendDateofNotify}
          data={tableData}
          totalRecords={totalRecords}
          // searchValue={searchQuery}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>

      {/* Scheduled Species Popups */}
      {isSpeciesDialogOpen && (
        <SpeciesPopUp
          speciesList={filterUIData?.all_schedule_one_species}
          open={isSpeciesDialogOpen}
          onClose={() => setIsSpeciesDialogOpen(false)}
        />
      )}

      {isRedListDialogOpen && (
        <RedListPopUp
          iucnName={filterUIData?.iucn_status}
          speciesList={filterUIData?.list_by_iucn_status}
          open={isRedListDialogOpen}
          onClose={() => setIsRedListDilaogOpen(false)}
        />
      )}

      {/* Filter Dialog start here */}
      <Dialog
        open={open}
        onClose={handleFilterClose}
        fullScreen={isXs}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "80vw",
            },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(3,1fr)",
                  lg: "repeat(4,1fr)",
                  xl: "repeat(4,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              {/* State Select */}
              <FormControl fullWidth>
                <Typography>State</Typography>
                <MySelectMultiple
                  value={statesData}
                  onChange={handleChangeStates}
                  menuArray={allStatesList}
                />
               </FormControl>

              {/* PA Type Select     */}
              <FormControl fullWidth>
                <Typography>PA Type</Typography>
                <Select
                  IconComponent={() => (
                    <ExpandMore
                      sx={{ color: (t) => t.palette.secondary.main }}
                    />
                  )}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "44px",
                    backgroundColor: (t) => t.palette.grey[300],
                  }}
                  // labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={paTypesData}
                  onChange={handleChangePaTypes}
                  // input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) =>
                    selected.length == 0 ? (
                      <span style={{ color: "#555555" }}>Select</span>
                    ) : (
                      handleArray(selected).join(", ")
                    )
                  }
                  MenuProps={MenuProps}
                  disabled={[
                    "National Park",
                    "Wildlife Sanctuary",
                    "Conservation Reserve",
                    "Community Reserve",
                  ].some((value) => value === tabValue)}
                >
                  {paTypesList?.map((pa) => (
                    <MenuItem
                      key={pa.id}
                      value={pa}
                     >
                      <Checkbox
                        disableRipple
                        checked={handleArray(paTypesData).indexOf(pa.name) > -1}
                      />
                      <ListItemText disableRipple primary={pa.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Special Recogintion Select   */}
              <FormControl fullWidth>
                <Typography>Special Recognition</Typography>
                <MySelect
                  value={specialRecognition}
                  onChange={(e) => setSpecialRecognition(e.target.value)}
                  menuArray={specialRecognitionList}
                />
              </FormControl>

              {/* Ecosensitive Zone Status */}
              <FormControl fullWidth>
                <Typography>Ecosensitive Zone Status</Typography>
                <MySelect
                  value={eszStatus}
                  onChange={(e) => setEszStatus(e.target.value)}
                  menuArray={eszZoneList}
                />
              </FormControl>

              {/* Date Range Select */}
              <FormControl fullWidth>
                <Typography>Date Range</Typography>

                <MyDateRange
                  onChange={(item) => setDateRange([item.selection])}
                  dateRange={dateRange}
                />
                {/* <Typography>Date Range</Typography>
                <BoxDateStyled
                  onClick={() => setShowDatePicker(!showDatePicker)}
                  style={{ position: "realative" }}
                >
                  <span>
                  {
                  dateRange[0]?.startDate && dateRange[0]?.endDate? 
                  `${moment(dateRange[0]?.startDate).format('DD/MM/YYYY')} to ${moment(
                  dateRange[0]?.endDate).format('DD/MM/YYYY') 
                }`
                : "Select"
              }
                  </span>
                </BoxDateStyled>
               {showDatePicker && (
                  <DateRange
                    editableDateInputs={false}
                    onChange={(item) => setDateRange([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    className={"dateRangeStyle"}
                  />
                )}
                 */}
              </FormControl>

              {/* Esz Area   */}
              <FormControl fullWidth sx={{ pr: "10px" }}>
                <Typography>ESZ Area (sq km)</Typography>
                <MySliderForESZArea
                  value={ESZAreaMinMax}
                  onChange={handleChange1}
                  maxValue={maxValue}
                />
              </FormControl>
            </Box>

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined
            onClick={handleFilterReset}
            sx={{ textTransform: "none" }}
          >
            Reset
          </ButtonOutlined>
          <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>

      {/* main Grid end */}
    </Grid>
  );
};

export default AllProtectedAreas;

const readMoreStyle = {
  border: "1px solid",
  borderColor: (t) => t.palette.primary.dark,
  borderRadius: "8px",
  px: "10px",
  py: "4px",
};
