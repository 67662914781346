import React from "react";
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import './custom.css'

const MySwiper = ({ children }) => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Autoplay, Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      className="mySwiper"
      pagination={{ clickable: true }}
    >
     {children}
    </Swiper>
  );
};

export default MySwiper;


