import React from 'react'
import { Grid, Typography } from '@mui/material';
import * as CONST from '../../utils/Contants'

const NoDataFoundComponent = ({data, searchValue, title, loader}) => {
    if (data?.length == 0 && !loader) {
        return (
          <Grid
            item
            xs={12}
            container
            justifyContent={"center"}
            alignContent={"center"}
            sx={{ height: "30vh" }}
          >
            <Typography variant="subheading1">{title}</Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ width: "100%", mt: "10px" }}
            >
              {CONST.NoDataFound}
            </Typography>
          </Grid>
        );
      }
      let filterData = data?.filter((item) =>
        item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.house?.name?.toLowerCase().includes(searchValue?.toLowerCase()) 
      );
      if (searchValue.length > 0 && filterData.length < 1) {
        return (
          <Grid
            item
            xs={12}
            container
            justifyContent={"center"}
            alignContent={"center"}
            sx={{ height: "30vh" }}
          >
            <Typography variant="subheading1">{title}</Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ width: "100%", mt: "10px" }}
            >
              {CONST.NoDataFound}
            </Typography>
          </Grid>
        );
      }
}

export default NoDataFoundComponent


    
