import React from "react"
import MyRoutes from "./routes/MyRoutes";
import ColorModeWrapper from "./ColorMode/ColorMode";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Snackbar } from "@mui/material";
import Toaster from "./components/Toaster/Toaster";


function App() {
 
  return (
    <Provider store={store}>
    <GoogleOAuthProvider clientId="461582810016-u0st9pu7mfopk3prgkefmijq1mfomlfo.apps.googleusercontent.com">
    <LocalizationProvider dateAdapter={AdapterMoment}>
     <ColorModeWrapper>
       <MyRoutes />
       <Toaster />
    </ColorModeWrapper>
    </LocalizationProvider>
    </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;





