import { put, call } from "redux-saga/effects";
import { performGetRequest, performPostRequest } from "../../services/apiServices/AxiosUtils";
import {showSnackbarAction} from "../actions/commonActions"


export const getAllPublications = function* getAllPublications(action) {
    yield put({type : "SHOW_LOADER"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getAllPublications  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
      } 
      else{
        yield put({type : "HIDE_LOADER"})
       }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getAllPublications API error ========", error);
      }
  };


  //-----------------------------------------------------------------------------------



export const getPublicationFilterListData = function* getPublicationFilterListData(action) {

  yield put({type : "SHOW_LOADER"})
  const path = '/list/publication_filters_list';  
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ getPublicationFilterListData Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data?.data)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
    yield put(showSnackbarAction("server error!"))
      console.log("@@@ getPublicationFilterListData API error ========", error);
    }
};

  //-----------------------------------------------------------------------------------

  export const getProfileInformation = function* getProfileInformation(action) {
    yield put({type : "SHOW_LOADER"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getProfileInformation  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res?.data?.data)
        yield put({type : "HIDE_LOADER"})
      }
      else{
        yield put({type : "HIDE_LOADER"})
        
      }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getProfileInformation API error ========", error);
      }
  };




  //-----------------------------------------------------------------------------------
  
