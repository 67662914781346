import { put, call } from "redux-saga/effects";
import { performPatchRequest, performPostRequest } from "../../services/apiServices/AxiosUtils";
import { showSnackbarAction } from "../actions/commonActions";



//-----------------------------------------------------------------------------------
export const postNewsLetter = function* postNewsLetter(action) {
  yield put({type : "SHOW_LOADER"})
 
  let body = {
    data: {
      email: action.payload.email,
      status : true  
    },
  };

  try {
    const path = "/news_letters";
    const res = yield call(performPostRequest, path, body);
   if (res !== undefined && res.data.status === "success") {
     yield put({type : "HIDE_LOADER"})
     yield action.payload.successCallBack();
     yield put(showSnackbarAction("Bravo! 👏 You've joined our newsletter club."))
 
     } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
     }
  } catch (error) {
    console.log("@@@ postNewsLetter API error ========", error);
    }
};