import {
  Box,
  Divider,
  Drawer,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Collapse,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { pa, esz, parliametryUpdates, researchUpdates } from "./MenuItems";
import {
  UpArrowIconStyled,
  LinkStyled,
  Dot,
  DrawerListStyled,
  MyBox,
} from "../StyledComponents/StyledComponents";

import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { ReactComponent as SearchIcon } from "../../assets/SvgIcons/icon=Search-new.svg";
import { ReactComponent as NavbarLogo } from "../../assets/SvgIcons/vidhilogoRed.svg";
import { ReactComponent as NavbarLogoDark } from "../../assets/SvgIconsDarkMode/vidhiLogoDark.svg";
import NavbarLogoImg from "../../assets/Images/newLogo.png";
import "./nav.css";
import { setItem } from "../../utils/validators";
import SearchAll from "./SearchAll";

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const darkMode = theme.palette.mode === "dark" ? true : false;

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorElPA, setAnchorElPA] = useState(false);
  const [anchorElEsz, setAnchorElEsz] = useState(false);
  const [anchorElPU, setAnchorElPU] = useState(false);
  const [anchorElRU, setAnchorElRU] = useState(false);

  const [openSearch, setOpenSearch] = useState(false);
  const [drawerState, setDrawerState] = useState(false);

  const handleSearchClose = () => {
    setOpenSearch(!openSearch);
  };

  const handleDrawerOpen = () => {
    setDrawerState(true);
  };
  const handleDrawerClose = () => {
    setDrawerState(false);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    console.log("use Effect chala");
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const handleDynamicWidth = () => {
    if (isSm) {
      return { width: "180px", height: "80px" };
    } else if (matches) {
      return { width: "250px", height: "80px" };
    } else
      return {
        width: "260px",
        height: "80px",
      };
  };

  return (
    <>
      {/* <Grid container style={{position : 'relative'}}> */}
      <Grid
        item
        container
        sx={{
          width: "100%",
          height: 80,
          // backgroundColor :  "rgba(255, 255, 255)",
          // ...(scroll && glassEffect),
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",
          backdropFilter: "blur(2px)",
          position: "sticky",
          top: 0,
          zIndex: 4,
          px: "2%",
        }}
        style={{
          // backgroundColor :  scroll ? "skyblue": "rgba(255, 255, 255)",
          backgroundColor: scroll
            ? "rgba(255, 255, 255,0.7)"
            : theme.palette.primary.light,
          ...(darkMode && {
            backgroundColor: scroll
              ? "rgba(255, 255, 255,0.1)"
              : theme.palette.primary.light,
          }),
        }}
        alignItems="center"
      >
        <Grid item container justifyContent="space-between" alignItems="center">
          <Link to="/">
            {theme.palette.mode === "dark" ? (
              <Box
                component="img"
                src={NavbarLogoImg}
                style={handleDynamicWidth()}
              />
            ) : (
              <Box
                component="img"
                src={NavbarLogoImg}
                style={handleDynamicWidth()}
              />
            )}
          </Link>

          {matches && (
            <Grid
              item
              container
              sx={{ flex: 1, gap: "10px", justifyContent: "flex-end" }}
            >
              <SearchIcon
                onClick={handleSearchClose}
                // onMouseEnter={handleSearchClose}
                // onMouseLeave={handleSearchClose}
                style={{
                  fill: theme.palette.mode === "dark" ? "#ffffff" : "#1C1B1F",
                }}
              />

              <WidgetsOutlinedIcon onClick={handleDrawerOpen} />
            </Grid>
          )}

          <Grid
            item
            container
            alignItems={"center"}
            justifyContent="flex-end"
            sx={{
              gap: "20px",
              flex: 1,
              height: 80,
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                xl: "flex",
                lg: "flex",
              },
            }}
          >
            <ul className="navItems-ul">
              <li className={darkMode ? "navItems-li liDark" : "navItems-li"}>
                <Link to="/" className="navItem-a">
                  <Typography variant="body2">Home</Typography>
                </Link>
              </li>
              <li
                onMouseEnter={() => setAnchorElPA(true)}
                onMouseLeave={() => setAnchorElPA(false)}
                className={darkMode ? "navItems-li liDark" : "navItems-li"}
              >
                <div className="li-box">
                  <Typography className="li-box-text" variant="body2">
                    protected area
                  </Typography>
                  <div className="arrowDownIcon"></div>
                </div>
                {anchorElPA && <DropDownPA setAnchorElPA={setAnchorElPA} />}
              </li>
              <li
                onMouseEnter={() => setAnchorElEsz(true)}
                onMouseLeave={() => setAnchorElEsz(false)}
                className={darkMode ? "navItems-li liDark" : "navItems-li"}
              >
                <div className="li-box">
                  <Typography className="li-box-text" variant="body2">
                    eco sensitive zone
                  </Typography>
                  <div className="arrowDownIcon"></div>
                </div>{" "}
                {anchorElEsz && <DropDownESZ setAnchorElEsz={setAnchorElEsz} />}
              </li>
              <li className={darkMode ? "navItems-li liDark" : "navItems-li"}>
                <Link to="/nbwl" className="navItem-a">
                  <Typography variant="body2">nbwl</Typography>
                </Link>
              </li>
              <li
                onMouseEnter={() => setAnchorElPU(true)}
                onMouseLeave={() => setAnchorElPU(false)}
                className={darkMode ? "navItems-li liDark" : "navItems-li"}
              >
                <div className="li-box">
                  <Typography className="li-box-text" variant="body2">
                    parliamentary updates
                  </Typography>
                  <div className="arrowDownIcon"></div>
                </div>{" "}
                {anchorElPU && <DropDownPU setAnchorElPU={setAnchorElPU} />}
              </li>
              <li
                onMouseEnter={() => setAnchorElRU(true)}
                onMouseLeave={() => setAnchorElRU(false)}
                className={darkMode ? "navItems-li liDark" : "navItems-li"}
              >
                {" "}
                <div className="li-box">
                  <Typography className="li-box-text" variant="body2">
                    research updates
                  </Typography>
                  <div className="arrowDownIcon"></div>
                </div>
                {anchorElRU && <DropDownRU setAnchorElRU={setAnchorElRU} />}
              </li>
            </ul>

            <SearchIcon
              onClick={handleSearchClose}
              // onMouseEnter={handleSearchClose}
              // onMouseLeave={handleSearchClose}
              style={{
                fill: theme.palette.mode === "dark" ? "#ffffff" : "#1C1B1F",
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {openSearch && (
        <SearchAll openSearch={openSearch} closeSearch={handleSearchClose} />
      )}

      <Drawer
        anchor={"right"}
        open={drawerState}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: ["100%", "70%"],
          },
        }}
      >
        <Grid container direction="column">
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            sx={{
              height: "80px",
              px: "25px",
              mb: "8px",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",
            }}
          >
            <Link to="/">
              {theme.palette.mode === "dark" ? (
                <Box
                component="img"
                src={NavbarLogoImg}
                style={handleDynamicWidth()}
              />
              ) : (
                <Box
                component="img"
                src={NavbarLogoImg}
                style={handleDynamicWidth()}
              />
              )}
            </Link>

            <HighlightOffIcon fontSize="large" onClick={handleDrawerClose} />
          </Grid>
          <BasicAccordion
            handleDrawerClose={handleDrawerClose}
            navigate={navigate}
          />
        </Grid>
      </Drawer>

      {/* </Grid> */}
    </>
  );
};

export default Navbar;

const DropDownPA = ({ setAnchorElPA }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark" ? true : false;

  return (
    <div className="menu">
      <ul
        className="menu-ul"
        style={{ backgroundColor: darkMode && "#555555" }}
      >
        {pa.map((item) => (
          <li
            onClick={() => {
              setItem("pa", item.title);
              // setAnchorElPA(false)
              // navigate(`${item.path}`,{state : { paName : item.title}})
            }}
            className="menu-li"
            key={item.id}
          >
            <Link
              to={item.path}
              onClick={() => {
                setAnchorElPA(false);
                navigate(`/protectedareas`, { state: { paName: item.title } });
              }}
            >
              <div className="menuLiItem">
                <Dot />
                <Typography variant="body1">{item.title}</Typography>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const DropDownESZ = ({ setAnchorElEsz }) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark" ? true : false;

  return (
    <div className="menu">
      <ul
        className="menu-ul"
        style={{ backgroundColor: darkMode && "#555555" }}
      >
        {esz.map((item) => (
          <li key={item.id} className="menu-li">
            <Link to={item.path} onClick={() => setAnchorElEsz(false)}>
              <div className="menuLiItem">
                <Dot />
                <Typography variant="body1">{item.title}</Typography>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const DropDownPU = ({ setAnchorElPU }) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark" ? true : false;

  return (
    <div className="menu">
      <ul
        className="menu-ul"
        style={{ backgroundColor: darkMode && "#555555" }}
      >
        {parliametryUpdates.map((item) => (
          <li
            key={item.id}
            className="menu-li"
            onClick={() => setAnchorElPU(false)}
          >
            <Link to={item.path}>
              <div className="menuLiItem">
                <Dot />
                <Typography variant="body1">{item.title}</Typography>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const DropDownRU = ({ setAnchorElRU }) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark" ? true : false;

  return (
    <div className="menu">
      <ul
        className="menu-ul"
        style={{ backgroundColor: darkMode && "#555555" }}
      >
        {researchUpdates.map((item) => (
          <li
            key={item.id}
            className="menu-li"
            onClick={() => setAnchorElRU(false)}
          >
            <Link to={item.path}>
              <div className="menuLiItem">
                <Dot />
                <Typography variant="body1">{item.title}</Typography>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

function BasicAccordion({ handleDrawerClose, navigate }) {
  const [expandedPA, setExpandedPA] = React.useState(false);
  const [expandedESZ, setExpandedESZ] = React.useState(false);
  const [expandedPU, setExpandedPU] = React.useState(false);
  const [expandedPUB, setExpandedPUB] = React.useState(false);

  const handleExpandPAClick = () => {
    setExpandedPA(!expandedPA);
    setExpandedESZ(false);
    setExpandedPU(false);
    setExpandedPUB(false);
  };

  const handleExpandESZClick = () => {
    setExpandedESZ(!expandedESZ);
    setExpandedPA(false);
    setExpandedPU(false);
    setExpandedPUB(false);
  };

  const handleExpandPUClick = () => {
    setExpandedPU(!expandedPU);
    setExpandedPA(false);
    setExpandedESZ(false);
    setExpandedPUB(false);
  };

  const handleExpandPUBClick = () => {
    setExpandedPUB(!expandedPUB);
    setExpandedPA(false);
    setExpandedESZ(false);
    setExpandedPU(false);
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: (t) =>
          t.palette.mode === "dark"
            ? t.palette.background.default
            : t.palette.grey[100],

        // height : "200px"
      }}
    >
      <DrawerListStyled
        sx={{ py: "16px", px: "25px" }}
        onClick={() => {
          handleDrawerClose();
          navigate("/");
        }}
      >
        <Typography variant="body2" color="primary.dark">
          HOME
        </Typography>
      </DrawerListStyled>
      <Divider />

      <DrawerListStyled onClick={handleExpandPAClick}>
        <Typography variant="body2" color="primary.dark">
          PROTECTED AREAS
        </Typography>
        <ArrowDropDownIcon />
      </DrawerListStyled>
      <Collapse
        sx={{ width: "100%" }}
        in={expandedPA}
        timeout="auto"
        unmountOnExit
      >
        <MyBox column sx={{ px: "35px" }}>
          {pa?.map((item) => (
            <MyBox
              key={item.id}
              onClick={() => {
                setItem("pa", item.title);
                navigate(`${item.path}`);
                handleDrawerClose();
              }}
              sx={{ gap: "16px", pb: "10px" }}
            >
              <Dot />
              <Typography varaiant="body2" color="common.black">
                {item.title}
              </Typography>
            </MyBox>
          ))}
        </MyBox>
      </Collapse>
      <Divider />

      <DrawerListStyled
        sx={{ py: "16px", px: "25px" }}
        onClick={handleExpandESZClick}
      >
        <Typography variant="body2" color="primary.dark">
          ESZ
        </Typography>
        <ArrowDropDownIcon />
      </DrawerListStyled>
      <Collapse
        sx={{ width: "100%" }}
        in={expandedESZ}
        timeout="auto"
        unmountOnExit
      >
        <MyBox column sx={{ px: "35px" }}>
          {esz?.map((item) => (
            <MyBox
              key={item.id}
              onClick={() => {
                navigate(`${item.path}`);
                handleDrawerClose();
              }}
              sx={{ gap: "16px", pb: "10px" }}
            >
              <Dot />
              <Typography varaiant="body2" color="common.black">
                {item.title}
              </Typography>
            </MyBox>
          ))}
        </MyBox>
      </Collapse>
      <Divider />

      <DrawerListStyled
        sx={{ py: "16px", px: "25px" }}
        onClick={() => {
          handleDrawerClose();
          navigate("/nbwl");
        }}
      >
        <Typography variant="body2" color="primary.dark">
          NBWL
        </Typography>
      </DrawerListStyled>
      <Divider />

      <DrawerListStyled
        sx={{ py: "16px", px: "25px" }}
        onClick={handleExpandPUClick}
      >
        <Typography variant="body2" color="primary.dark">
          PARLIAMENTARY UPDATES
        </Typography>
        <ArrowDropDownIcon />
      </DrawerListStyled>
      <Collapse
        sx={{ width: "100%" }}
        in={expandedPU}
        timeout="auto"
        unmountOnExit
      >
        <MyBox column sx={{ px: "35px" }}>
          {parliametryUpdates?.map((item) => (
            <MyBox
              key={item.id}
              onClick={() => {
                handleDrawerClose();
                navigate(`${item.path}`);
              }}
              sx={{ gap: "16px", pb: "10px" }}
            >
              <Dot />
              <Typography varaiant="body2" color="common.black">
                {item.title}
              </Typography>
            </MyBox>
          ))}
        </MyBox>
      </Collapse>
      <Divider />

      <DrawerListStyled
        sx={{ py: "16px", px: "25px" }}
        onClick={handleExpandPUBClick}
      >
        <Typography variant="body2" color="primary.dark">
          RECENT PUBLICATIONS
        </Typography>
        <ArrowDropDownIcon />
      </DrawerListStyled>
      <Collapse
        sx={{ width: "100%" }}
        in={expandedPUB}
        timeout="auto"
        unmountOnExit
      >
        <MyBox column sx={{ px: "35px" }}>
          {researchUpdates?.map((item) => (
            <MyBox
              onClick={() => {
                navigate(`${item.path}`);
                handleDrawerClose();
              }}
              sx={{ gap: "16px", pb: "10px" }}
              key={item.id}
            >
              <Dot />
              <Typography varaiant="body2" color="common.black">
                {item.title}
              </Typography>
            </MyBox>
          ))}
        </MyBox>
      </Collapse>
      <Divider />
    </Box>
  );
}
