import * as React from 'react';
import {Box} from "@mui/material"
import { alpha, styled } from '@mui/material/styles';

export const Circle = styled(Box)(({ blackIcon, theme }) => ({
      position: "relative",
      width: "22px",
      height: "22px",
      backgroundColor: "#fff",
      borderRadius: "50%",
      ...(blackIcon && {
          backgroundColor : "#000"
      })
  
  }));
  
  
  export const CircleInner= styled(Box)(({ blackIcon, theme }) => ({
        position: "absolute",
        left : 0,
        width : "11px",
        height : "22px",
        backgroundColor :"#000000",
        borderRadius: "100px 0px 0px 100px",
        ...(blackIcon && {
          backgroundColor : "#ffffff"
      })
    
    }));

  
   




// export const Circle = styled(Box, {
//   shouldForwardProp: (prop) => prop !== 'blackIcon',
  
// })(({ blackIcon, theme }) => ({
//     position: "relative",
//     width: "34px",
//     height: "34px",
//     backgroundColor: "#fff",
//     borderRadius: "50%",
//     ...(blackIcon && {
//         backgroundColor : "#000"
//     })

// }));


// export const CircleInner= styled(Box, {
//     shouldForwardProp: (prop) => prop !== 'blackIcon',
    
//   })(({ blackIcon, theme }) => ({
//       position: "absolute",
//       left : 0,
//       width : "17px",
//       height : "34px",
//       backgroundColor :"#000",
//       borderRadius: "100px 0px 0px 100px",
//       ...(blackIcon && {
//         backgroundColor : "#fff"
//     })
  
//   }));



