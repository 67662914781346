import {
  Grid,
  Box,
  Typography,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LinkBar from "../../components/LinkBar/LinkBar";
import { handleDateFormat } from "../../utils/StatusStyle";

import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import parse from "html-react-parser";

import {
  ButtonPrimary,
  MyBox,
  SocaiIconsRedBorder,
  TextUpperCase,
  BillDateBoxStyled,
} from "../../components/StyledComponents/StyledComponents";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import { ReactComponent as FacebookIcon } from "../../assets/SvgIcons/facebook.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/SvgIcons/linkedIN.svg";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../components/Loader/Loader";
import { showPDF, showSnackbar } from "../../utils/PdfUtils";

const Bill = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const loader = useSelector((state) => state.commonReducer.showLoader);
  const { state } = useLocation();
  const id = state ? state.id : null;

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const [billDetails, setBillDetails] = useState({});

  const getBillDetailedInfo = () => {
    dispatch({
      type: "GET_ALL_BILLS",
      successCallBack: successCallBack,
      path: `/contents/bills?search=true&id=${id}`,
    });
  };

  useEffect(() => {
    getBillDetailedInfo();
  }, []);

  const successCallBack = (res) => {
    setBillDetails(res?.data?.data[0]);
  };

  const parsedHtml =
    billDetails?.full_detail && parse(billDetails?.full_detail);

  const data = [
    {
      name: "Introduced on",
      value: `${handleDateFormat(billDetails?.introduced_on)}`,
      icon: <CalendarTodayOutlinedIcon />,
    },
    {
      name: "Passed in LokSabha on",
      value: `${handleDateFormat(billDetails?.date_passed_in_ls)}`,
      icon: <CalendarMonthOutlinedIcon />,
    },
    {
      name: "Passed in RajyaSabha on",
      value: `${handleDateFormat(billDetails?.date_passed_in_rs)}`,
      icon: <CalendarMonthOutlinedIcon />,
    },
    {
      name: "Status",
      value: `${billDetails?.status ?? "NA"}`,
      icon: <SellOutlinedIcon />,
    },
    {
      name: "Gazette Notification",
      value: `${handleDateFormat(billDetails?.gazette_notification_date)}`,
      icon: <DescriptionIcon />,
    },
    {
      name: "Referred to the Parliamentary Committee",
      value: billDetails?.referred_to_the_parliamentary_committee ?? "NA",
      icon: <CalendarMonthOutlinedIcon />,
    },
    {
      name: "Date of Table of Report",
      value: `${handleDateFormat(billDetails?.date_of_table_of_report)}`,
      icon: <CalendarMonthOutlinedIcon />,
    },
    {
      name: "LokSabha Session",
      value: `${billDetails?.session ?? "NA"} ${billDetails?.session_number?.name}`,
      icon: <SellOutlinedIcon />,
    },
  ];

  const handleType = () => {
    if (billDetails?.creater_type === "MemberOfParliament") {
      return "memberOfParliament";
    } else if (billDetails?.creater_type === "Ministry") {
      return "ministry";
    }
  };

  const handleShowPDF = (link) => {
    if (link !== null && link !== "") {
      showPDF(link, dispatch, "No link found");
    } else {
      showSnackbar("No link is found", dispatch);
    }
  };

  const handleClickBillTable = (dateType) => {
    const clickHandlers = {
      "Gazette Notification": () => {
        const reportLink = billDetails?.gazette_notification_link;
        handleShowPDF(reportLink);
      },
      "Referred to the Parliamentary Committee": () => {
        let linkCommittee =
          billDetails?.referred_to_parliamentary_committee_link;
        if (billDetails?.referred_to_the_parliamentary_committee === "Yes") {
          showPDF(linkCommittee, dispatch, "No link found");
        } else {
          showSnackbar("No link is found", dispatch);
        }
      },
      "Introduced on": () => {
        let introducedLink = billDetails?.introduced_on_link;
        handleShowPDF(introducedLink);
      },
      "Passed in LokSabha on": () => {
        let lokSabhaLink = billDetails?.date_passed_in_ls_link;
        handleShowPDF(lokSabhaLink);
      },
      "Passed in RajyaSabha on": () => {
        let rajyaSabhaLink = billDetails?.date_passed_in_rs_link;
        handleShowPDF(rajyaSabhaLink);
      },
      "Date of Table of Report": () => {
        let tableReportLink = billDetails?.date_of_table_of_report_link;
        handleShowPDF(tableReportLink);
      },
    };
    // Check if a handler exists for the clicked element
    if (clickHandlers[dateType]) {
      clickHandlers[dateType]();
    }
  };

  const handleStyleBillTableDate = (rowName) => {
    return [
      "Introduced on",
      "Gazette Notification",
      "Referred to the Parliamentary Committee",
      "Passed in LokSabha on",
      "Passed in RajyaSabha on",
      "Date of Table of Report",
    ].includes(rowName)
      ? { cursor: "pointer" }
      : {};
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: (t) =>
          t.palette.mode === "dark"
            ? t.palette.background.default
            : theme.palette.grey[100],
      }}
    >
      <LinkBar title="Bills" subtitle={billDetails?.title} />

      {loader ? (
        <MyBox sx={{ height: "50vh", width: 1 }}>
          <Loader />
        </MyBox>
      ) : (
        <>
          <Grid
            container
            sx={{
              alignItems: "center",
              px: ["0px", "0px", "20px", "32px", "32px"],
            }}
          >
            {/* card item */}
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              sx={{ order: [2, 2, 1, 1, 1] }}
            >
              <Grid container sx={{ backgroundColor: "black" }}>
                <Grid xs={false} sm={false} md={1} lg={1} xl={1} />
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={8}
                  lg={8}
                  xl={8}
                  sx={{
                    px: isXs ? "16px" : "30px",
                    py: "30px",
                  }}
                >
                  <Grid
                    container
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                    sx={{ gap: "20px" }}
                  >
                    {/* first line */}
                    <Grid item container>
                      <Grid item>
                        <TextUpperCase
                          sx={{ borderRight: "1px solid white", pr: 1 }}
                          variant="subtile2"
                          color="vidhiRed.light"
                        >
                          Bills/Act
                        </TextUpperCase>
                      </Grid>
                      <Grid item>
                        <TextUpperCase
                          sx={{ borderRight: "1px solid white", px: 1 }}
                          variant="subtile2"
                          color="text.alwayswhite"
                        >
                          {/* 19 May 2023 */}
                          {handleDateFormat(billDetails?.date_of_intro)}
                        </TextUpperCase>
                      </Grid>
                      <Grid item>
                        <TextUpperCase
                          sx={{ pl: 1 }}
                          variant="subtile2"
                          color="text.alwayswhite"
                        >
                          {/* 12345 */}
                          {billDetails?.bill_number}
                        </TextUpperCase>
                      </Grid>
                    </Grid>
                    <Typography variant="h2" color="text.alwayswhite">
                      {/* The INDIAN FOREST (AMENDMENT) BILL, 2012 */}
                      {billDetails?.title}
                    </Typography>
                    <Typography variant="subheading2" color="text.alwayswhite">
                      {billDetails?.small_detail}
                      {/* Submission to The Joint Parliamentary Committee on The Forest
                  (Conservation) Amendment Bill 2023 */}
                    </Typography>

                    <Grid container>
                      <Typography
                        variant="subheading2"
                        color="text.alwayswhite"
                      >
                        Introduced by : {billDetails?.house?.name}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      sx={{ width: 40, height: 4, backgroundColor: "#FF8278" }}
                    ></Grid>
                    <Grid item xs container alignItems="center">
                      {billDetails?.creater_detail?.profile_picture && (
                        <Avatar
                          sx={{ width: "24px", height: "24px" }}
                          src={
                            billDetails?.creater_detail?.profile_picture?.link
                          }
                        />
                      )}

                      <Typography
                        sx={{ ml: 1, cursor: "pointer" }}
                        variant="subtitle2"
                        color="vidhiRed.light"
                        onClick={() =>
                          navigate("/author", {
                            state: {
                              id: billDetails?.creater_detail?.id,
                              type: handleType(),
                            },
                          })
                        }
                      >
                        {billDetails?.creater_detail?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* image item */}
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              sx={{ order: [1, 1, , 2, 2, 2] }}
            >
              <Grid container alignItems={"center"}>
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    //  ml : ["0px", "0px", "0px", "40px", "50px"],
                    //  zIndex : 1,
                    position: "relative",
                    left: ["0px", "0px", "-20px", "-30px", "-30px"],
                    objectFit: "fill",

                    // backgroundColor: "lightGreen",
                  }}
                  component="img"
                  src={billDetails?.thumbnail_detail?.link}
                ></Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Image and card container */}

          {/* Bill table starts here */}
          <Grid container sx={{ px: "20px" }}>
            <Grid
              item
              xs={12}
              sx={{
                py: "20px",
                backgroundColor: (t) =>
                  t.palette.mode === "dark"
                    ? t.palette.background.default
                    : t.palette.grey[50],
              }}
            >
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                  {/* <Grid container  sx={{columnGap :'40px'}}> */}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "auto",
                        sm: "auto auto",
                        md: "1fr 1fr 1fr 1fr",
                        lg: "1fr 1fr 1fr 1fr",
                        xl: "1fr 1fr 1fr 1fr",
                      },
                      columnGap: "40px",
                      "& > :nth-child(-n+4)": {
                        borderBottom: "1px solid #AAAAAA", // Default style for xs
                      },
                      "@media (max-width: 600px)": {
                        // Adjust styles for sm and larger screens
                        "& > :nth-child(-n+6)": {
                          borderBottom: "1px solid #AAAAAA", // Style for sm, md, lg, and xl
                        },
                      },
                      "@media (max-width: 960px)": {
                        "& > :nth-child(-n+6)": {
                          borderBottom: "1px solid #AAAAAA", // Style for xl
                        },
                      },
                    }}
                  >
                    {data?.map((row, index) => (
                      <BillDateBoxStyled key={index}>
                        {row?.icon}
                        <MyBox column>
                          <Typography variant="regular2" sx={{ mb: "10px" }}>
                            {row?.name}
                          </Typography>
                          <Typography
                            style={handleStyleBillTableDate(row?.name)}
                            // style={{cursor  : row?.name === "Gazette Notification" || row?.name === "Referred to the Parliamentary Committee" ? 'pointer' : 'default'}}
                            onClick={() => handleClickBillTable(row?.name)}
                            variant="regular2"
                            color="primary.dark"
                          >
                            {row.value}
                          </Typography>
                        </MyBox>
                      </BillDateBoxStyled>
                    ))}
                  </Box>

                  {/* </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* paragraph and image section starts here */}
          <Grid container justifyContent="center" sx={{ py: "24px" }}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              sx={{
                px: ["15px", "15px", 0, 0, 0],
              }}
            >
              <Grid container sx={{ overflow: "auto" }}>
                {parsedHtml}
                {/* <RenderHTML htmlContent={billDetails?.full_detail}/> */}
              </Grid>

              {/* social icon and download button */}
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    my: 2,
                    "&:last-child>:last-child": {
                      borderRight: "1px solid #DC002D",
                    },
                  }}
                >
                  <a
                    href="https://twitter.com/vidhi_india?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocaiIconsRedBorder>
                      <TwitterIcon />
                    </SocaiIconsRedBorder>
                  </a>
                  <a
                    href="https://www.facebook.com/vidhilegalpolicy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocaiIconsRedBorder>
                      <FacebookIcon />
                    </SocaiIconsRedBorder>
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocaiIconsRedBorder>
                      <WhatsAppIcon />
                    </SocaiIconsRedBorder>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/vidhi-centre-for-legal-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocaiIconsRedBorder>
                      <LinkedInIcon />
                    </SocaiIconsRedBorder>
                  </a>
                </Box>
              </Box>

              {/* About the author */}
              <Grid item xs={12} container sx={{ gap: "20px", my: 3 }}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                >
                  About the {billDetails?.creater_type}
                </Typography>
              </Grid>

              <Grid item xs={12} container alignItems="center">
                <Avatar
                  src={billDetails?.creater_detail?.profile_picture?.link}
                />
                <Typography
                  sx={{ ml: 2 }}
                  variant="subtitle2"
                  color="vidhiRed.main"
                >
                  {billDetails?.creater_detail?.name}
                </Typography>
              </Grid>
              <Typography variant="body1" sx={{ mt: "10px", mb: "20px" }}>
                {billDetails?.creater_detail?.bio ?? "No Bio Available"}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Bill;

const RenderHTML = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
