import { useTheme } from "@emotion/react";
import { Slider } from "@mui/material";
import React from "react";


function valuetext(value) {
    return `${value}`;
  }

const MySlider = ({onChange, value, maxValue}) => {
 const theme = useTheme();
  return (
    <Slider
    value={value}
    onChange={onChange}
    aria-labelledby="range-slider"
    getAriaValueText={valuetext}
    max={maxValue}
    getAriaLabel={() => "Minimum distance"}
    valueLabelFormat={(value) => `${value}`}
    disableSwap
    aria-label="myslider"
    valueLabelDisplay="on"
    sx={{
      "& .MuiSlider-rail": {
        backgroundColor: theme.palette.grey[200],
        height: "8px",
        opacity: 1,
      },
      "& .MuiSlider-track": {
        backgroundColor: theme.palette.vidhiRed.main,
        height: "8px",
        border: "none",
      },
      "& .MuiSlider-thumb": {
        backgroundColor: theme.palette.vidhiRed.main,
      },
      "& .MuiSlider-valueLabelOpen": {
        top: "52px",
        padding: "2px",
        color: theme.palette.common.black,
        backgroundColor: "transparent",
        "&:before": {
          top: "-6px",
        },
      },
      "& .MuiSlider-valueLabelCircle": {
        // width : "max-content",
        // backgroundColor :'red'
      },
      
      }}
    />
  );
};

export default MySlider;
