import {
  Grid,
  Slider,
  Typography,
  Box,
  Divider,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LinkBar from "../../components/LinkBar/LinkBar";

import {
  ButtonPrimary,
  ChipStyled,
  MyBox,
  PaDetailBoxStyled,
  PAStateLabelStyled,
  PATypeLabelStyled,
  StatusBox,
  UnderlinedTypography,
} from "../../components/StyledComponents/StyledComponents";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../components/StyledComponents/StyledTable/styledTable";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  handleStatusColor,
  handleTextTransform,
} from "../../utils/StatusStyle";
import MyMapComponent from "../../components/Map/MyMapComponent";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { setItem } from "../../utils/validators";
import FeedbackForm from "./FeedbackForm";
import ReportSpeciesSchedule from "./ReportSpeciesFormSchedule";
import ReportSpeciesInvasive from "./ReportSpeciesFormInvasive";
import { showPDF } from "../../utils/PdfUtils";

const EszStaticData = {
  ESZInformation: {
    status: "Finalised",
    area: "1268.0367",
    periphery: "794.04",
  },
  partOfAanyRecognizedWildlifeCorridor:
    "Pench-Satpura-Melghat (Central India & eastern Ghats)",
  dateOfDraftNotification: "30-06-2015",
  dateOfFinalNotification: "27-12-2016",
  minutesOfMeetings: "05.07.2016, 27.02.2017",
};

const minDistance = 10;

const PAInfromation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);
  const { state } = useLocation();
  // const {paData} = state;

  let isXs = false;
  let isSm = false;
  isXs = useMediaQuery(theme.breakpoints.down("sm"));
  isSm = useMediaQuery(theme.breakpoints.down("md"));

  const paID = state ? state?.paID : null;
  console.log("paData ", state);

  const [paData, setPaData] = useState({});
  const [paId, setPaId] = useState(paID);
  const [nearByPaList, setNearByPaList] = useState([]);

  const [value1, setValue1] = React.useState([0, 50]);
  const [draftChip, setDraftChip] = useState(false);
  const [finalChip, setFinalChip] = useState(false);
  const [nearByChip, setNearByChip] = useState(false);

  const [openFeedback, setOpenFeedback] = useState(false);
  const [openReportSpeciesForm, setOpenReportSpeciesForm] = useState(false);
  const [openReportSpeciesFormAnother, setOpenReportSpeciesFormAnother] =
    useState(false);

  const getPaInformation = () => {
    dispatch({
      type: "GET_ALL_PA",
      successCallBack: successCallBackPaInfo,
      path: `/protected_areas?page=1&per_page=10&search=true&id[]=${paId}`,
    });
  };

  //  use effect for pa information api calling
  useEffect(() => {
    getPaInformation();
  }, [paId]);

  // calling nearby areas api as soon as we get data/id of PA
  useEffect(() => {
    handleNearByPaRange();
  }, [paData]);

  const successCallBackNearByPa = (data) => {
    setNearByPaList(data);
  };

  const successCallBackPaInfo = (res) => {
    setPaData(res?.data?.data[0]);
  };

  // useEffect( () => {
  //   dispatch({
  //     type : "NEAR_BY_PAS",
  //     payload : {
  //       id : paData?.id,
  //       startKM : value1[0],
  //       endKM : value1[1]
  //     },
  //     successCallBack : successCallBack,
  //     })
  // }, [value1] )

  const handleNearByPaRange = () => {
    if (paData?.id) {
      dispatch({
        type: "NEAR_BY_PAS",
        payload: {
          id: paData?.id,
          startKM: value1[0],
          endKM: value1[1],
        },
        successCallBack: successCallBackNearByPa,
      });
    }
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    console.log("newValue", newValue);
    console.log("active thumb", activeThumb);
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const handleChangeOnCommitted = (event, newValue, activeThumb) => {
    console.log("newValue", newValue);
    console.log("active thumb", activeThumb);
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      // setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
      handleNearByPaRange();
    } else {
      // setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
      handleNearByPaRange();
    }
  };

  const getNearbyPAs = (nearbyPAs) => {
    let nearbypa = "";

    nearbyPAs?.map((nearbyPA, index) => {
      if (nearbyPAs.length == index + 1) {
        return (nearbypa = nearbypa + nearbyPA);
      }
      return (nearbypa = nearbypa + nearbyPA + ", ");
    });

    return nearbypa;
  };

  const handleFeedbackForm = () => {
    setOpenFeedback(false);
  };

  const handleReportSpeciesForm = () => {
    setOpenReportSpeciesForm(false);
  };

  const handleReportSpeciesFormAnother = () => {
    setOpenReportSpeciesFormAnother(false);
  };

  const handleClickFilter = (filterOn, id, name) => {
    setItem("pa", "All");
    // setItem("paFilterName", name);
    // setItem("paFilterId", id);
    // setItem("fromPa", "yes");
    navigate(`/protectedareas?id=${id}&name=${name}&filter=${filterOn}`);
  };

  function valuetext(value) {
    return `${value}`;
  }
  return (
    <Grid
      item
      container
      sx={{
        backgroundColor: (t) =>
          t.palette.mode === "dark"
            ? t.palette.background.default
            : t.palette.grey[50],
      }}
    >
      <LinkBar paName={paData?.name} />

      {/* pa on map and left panel scrollable */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ order: [2, 2, 1, 1, 1] }}
        >
          <Box
            sx={{
              height: 600,
              overflowY: "scroll",
              // border : 1
            }}
          >
            {/* conditon for responsive design */}
            {isSm ? null : (
              <>
                <PaDetailBoxStyled>
                  <Typography
                    variant="regular3"
                    color="secondary.main"
                    gutterBottom
                  >
                    PA Information
                  </Typography>
                  <Typography variant="h2">{paData?.name}</Typography>
                  <Grid container sx={{ gap: "10px", pt: "10px" }}>
                    {paData?.pa_categories?.map((pa, index) => (
                      <PATypeLabelStyled key={index}>
                        <Typography variant="body2" color="primary.dark">
                          {pa?.name}
                        </Typography>
                      </PATypeLabelStyled>
                    ))}
                  </Grid>
                </PaDetailBoxStyled>

                <Divider />
              </>
            )}

            <PaDetailBoxStyled>
              <Grid
                container
                sx={{ py: "5px", rowGap: "10px" }}
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="body1" color="primary.dark">
                    Area :
                  </Typography>
                  <Typography>{paData?.area_of_pa} sq km</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="primary.dark">
                    Periphery :
                  </Typography>
                  <Typography>
                    {paData?.periphery ? paData?.periphery + " sq km" : "NA"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="primary.dark">
                    Date of Notification:
                  </Typography>
                  {paData?.date_of_notify ? (
                    <Typography
                      onClick={() =>
                        showPDF(
                          paData?.detail_pa_notification_file?.link,
                          dispatch,
                          "No link found"
                        )
                      }
                      sx={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      {paData?.date_of_notify}
                    </Typography>
                  ) : (
                    "NA"
                  )}
                </Grid>
              </Grid>
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Special Recognition :
              </Typography>
              <Grid container>
                {paData?.special_recognitions?.length > 0
                  ? paData?.special_recognitions?.map((pa, index) => (
                      <UnderlinedTypography
                        key={pa?.id}
                        style={{
                          paddingLeft: index !== 0 && "6px",
                        }}
                        onClick={() => {
                          handleClickFilter(
                            "specialRecognition",
                            pa?.id,
                            pa?.name
                          );
                        }}
                        variant="subtitle1"
                      >
                        {pa?.name}
                        {index < paData?.special_recognitions.length - 1
                          ? ", "
                          : ""}
                      </UnderlinedTypography>
                    ))
                  : "NA"}
              </Grid>

              {/* <UnderlinedTypography onClick={()=>{
             handleClickFilter("specialRecognition",paData?.special_recognition?.id)
        }} variant='subtitle1'>{paData?.special_recognition?.name ? paData?.special_recognition?.name : ""}
        </UnderlinedTypography> */}
              <Typography variant="body1" color="primary.dark">
                Date of Special Recognition :
              </Typography>
              {paData?.date_special_recognition ? (
                <UnderlinedTypography
                  onClick={() =>
                    showPDF(
                      paData?.detail_date_special_recognition_doc?.link,
                      dispatch,
                      "No link found"
                    )
                  }
                  variant="subtitle1"
                >
                  {paData?.date_special_recognition}
                </UnderlinedTypography>
              ) : (
                <Typography>NA</Typography>
              )}
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Biogeographic zone :
              </Typography>
              {paData?.bio_geographic_zone?.name ? (
                <UnderlinedTypography
                  onClick={() => {
                    handleClickFilter(
                      "bioZone",
                      paData?.bio_geographic_zone?.id,
                      paData?.bio_geographic_zone?.name
                    );
                  }}
                  variant="subtitle1"
                >
                  {paData?.bio_geographic_zone?.name}
                </UnderlinedTypography>
              ) : (
                <Typography>NA</Typography>
              )}
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Schedule 1 Species Reported :
              </Typography>
              {/* <Typography variant='subtitle1'>{paData?.schedule_one_species_reported}</Typography> */}
              <Grid container>
                {paData?.schedule_one_species?.length > 0 ? (
                  paData?.schedule_one_species?.map((pa, index) => (
                    <UnderlinedTypography
                      key={pa.id}
                      style={{ paddingLeft: index !== 0 && "6px" }}
                      onClick={() =>
                        handleClickFilter("sheduledOne", pa?.id, pa?.name)
                      }
                      variant="subtitle1"
                    >
                      {pa.name}
                      {index < paData?.schedule_one_species.length - 1
                        ? ", "
                        : " "}
                    </UnderlinedTypography>
                  ))
                ) : (
                  <Typography>NA</Typography>
                )}
              </Grid>

              <Box sx={{ display: "flex" }}>
                <PAStateLabelStyled
                  lightPink
                  onClick={() => setOpenReportSpeciesForm(true)}
                >
                  <Typography variant="body2" color="vidhiRed.light">
                    Report missing species
                  </Typography>
                </PAStateLabelStyled>
              </Box>
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Invasive species (if reported):
              </Typography>
              {/* {paData?.invasive_species?.map(i=>
        <Typography variant='subtitle1'>{i?.name ? i.name : ""}</Typography>
          )} */}
              <Grid container>
                {paData?.invasive_species?.length > 0 ? (
                  paData?.invasive_species?.map((pa, index) => (
                    <UnderlinedTypography
                      style={{ paddingLeft: index !== 0 && "6px" }}
                      onClick={() =>
                        handleClickFilter("invasiveSpecies", pa.id, pa?.name)
                      }
                      variant="subtitle1"
                    >
                      {pa.name}
                      {index < paData?.invasive_species.length - 1 ? ", " : " "}
                    </UnderlinedTypography>
                  ))
                ) : (
                  <Typography>NA</Typography>
                )}
              </Grid>
              <Box sx={{ display: "flex" }}>
                <PAStateLabelStyled
                  lightPink
                  onClick={() => setOpenReportSpeciesFormAnother(true)}
                >
                  <Typography variant="body2" color="vidhiRed.light">
                    Report missing species
                  </Typography>
                </PAStateLabelStyled>
              </Box>
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                State :
              </Typography>
              <Grid container sx={{ gap: "10px" }}>
                {paData?.states?.length > 0 ? (
                  paData?.states?.map((s) => (
                    <PAStateLabelStyled>
                      <Typography
                        onClick={() =>
                          handleClickFilter("state", s?.id, s?.name)
                        }
                        variant="body2"
                      >
                        {s?.name ? s?.name : ""}
                      </Typography>
                    </PAStateLabelStyled>
                  ))
                ) : (
                  <Typography>NA</Typography>
                )}
              </Grid>
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Adjoining PA :
              </Typography>
              <Grid container sx={{ columnGap: "8px" }}>
                {paData?.adjoining_pas_list?.length > 0 ? (
                  paData?.adjoining_pas_list.map((pa, index) => (
                    <UnderlinedTypography
                      onClick={() => setPaId(pa.id)}
                      key={pa.id}
                      variant="subtitle1"
                    >
                      {pa.name}
                      {index < pa.length - 1 ? ", " : ""}
                    </UnderlinedTypography>
                  ))
                ) : (
                  <Typography>NA</Typography>
                )}
              </Grid>
              {/* <Typography variant='subtitle1'>{paData?.adjoining_pa ? paData.adjoining_pa : "NA"}</Typography> */}
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Nearby PAs (Within 50 Kms)
              </Typography>
              {loader ? (
                <Box
                  sx={{
                    height: "20vh",
                    display: "flex",
                    alignItems: "center",
                    width: 1,
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <>
                  <Box sx={{ my: "20px", pl: "10px", pr: "20px" }}>
                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={value1}
                      onChange={handleChange1}
                      getAriaValueText={valuetext}
                      valueLabelFormat={(value) => `${value}sq km`}
                      disableSwap
                      aria-label="myslider"
                      valueLabelDisplay="on"
                      onChangeCommitted={handleChangeOnCommitted}
                      max={50}
                      sx={{
                        "& .MuiSlider-rail": {
                          backgroundColor: theme.palette.grey[200],
                          height: "8px",
                          opacity: 1,
                        },
                        "& .MuiSlider-track": {
                          backgroundColor: theme.palette.vidhiRed.main,
                          height: "8px",
                          border: "none",
                        },
                        "& .MuiSlider-thumb": {
                          backgroundColor: theme.palette.vidhiRed.main,
                        },
                        "& .MuiSlider-valueLabelOpen": {
                          top: "52px",
                          padding: "2px",
                          color: theme.palette.common.black,
                          backgroundColor: "transparent",
                          "&:before": {
                            top: "-6px",
                          },
                        },
                        "& .MuiSlider-valueLabelCircle": {
                          // width : "max-content",
                          // backgroundColor :'red'
                        },
                      }}
                    />
                  </Box>
                  {/* <Grid container ><ButtonPrimary onClick={handleNearByPaRange} sx={{height : "30px", my : '5px'}}>Apply</ButtonPrimary></Grid> */}
                  <Grid container sx={{ columnGap: "8px" }}>
                    {nearByPaList.length === 0 ? (
                      <Typography variant="subtitle1">
                        No Nearby PA available
                      </Typography>
                    ) : (
                      nearByPaList?.map((pa) => (
                        <UnderlinedTypography
                          onClick={() => setPaId(pa.protected_area_name_id)}
                          key={pa.id}
                          variant="subtitle1"
                        >
                          {pa.protected_area_name},
                        </UnderlinedTypography>
                      ))
                    )}
                  </Grid>
                </>
              )}
              {/* <Typography variant='subtitle1'>{
      getNearbyPAs(paData?.nearby_pa)
      }
        </Typography> */}
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Boundary overlap with State border :
              </Typography>
              <Grid container sx={{ gap: "10px" }}>
                {paData?.border_states?.length > 0 ? (
                  paData?.border_states?.map((state) => (
                    <PAStateLabelStyled>
                      <Typography
                        onClick={() =>
                          handleClickFilter(
                            "borderStates",
                            state?.id,
                            state?.name
                          )
                        }
                        variant="body2"
                      >
                        {state?.name}
                      </Typography>
                    </PAStateLabelStyled>
                  ))
                ) : (
                  <Typography>NA</Typography>
                )}
              </Grid>
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Boundary overlap with international border :
              </Typography>
              <Grid container sx={{ gap: "10px" }}>
                {paData?.countries?.length > 0 ? (
                  paData?.countries?.map((country) => (
                    <PAStateLabelStyled>
                      <Typography variant="body2">{country.name}</Typography>
                    </PAStateLabelStyled>
                  ))
                ) : (
                  <Typography>NA</Typography>
                )}
              </Grid>
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Forest Division Contact :
              </Typography>
              <Grid container sx={{ py: "5px", gap: "5px" }}>
                <Grid item container sx={{ gap: "5px" }}>
                  <Typography variant="body1" color="primary.dark">
                    Address :
                  </Typography>
                  <Typography variant="subtitle1">
                    {paData?.forest_division_contact?.address
                      ? paData.forest_division_contact.address
                      : "NA"}
                  </Typography>
                </Grid>
                <Grid item container sx={{ gap: "5px" }}>
                  <Typography variant="body1" color="primary.dark">
                    Email :
                  </Typography>
                  <Typography variant="subtitle1">
                    {paData?.forest_division_contact?.email
                      ? paData.forest_division_contact.email
                      : "NA"}
                  </Typography>
                </Grid>
                <Grid item container sx={{ gap: "5px" }}>
                  <Typography variant="body1" color="primary.dark">
                    Contact :
                  </Typography>
                  <Typography variant="subtitle1">
                    {paData?.forest_division_contact?.contact
                      ? paData.forest_division_contact.email
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>
              {/* <MyBox sx={{gap : '10px'}}>
        <Typography variant='body1' color="primary.dark">Address :</Typography>
        <Typography variant='subtitle1'>{paData?.forest_division_contact?.address? paData.forest_division_contact.address : "NA"}</Typography>
         </MyBox>

         <MyBox sx={{gap : '10px'}}>
        <Typography variant='body1' color="primary.dark">Email :</Typography>
        <Typography variant='subtitle1'>{paData?.forest_division_contact?.email ? paData.forest_division_contact.email : "NA"}</Typography>
         </MyBox>

         <MyBox sx={{gap : '10px'}}>
        <Typography variant='body1' color="primary.dark">Contact :</Typography>
        <Typography variant='subtitle1'>{paData?.forest_division_contact?.contact ? paData.forest_division_contact.email : "NA"}</Typography>
         </MyBox> */}
            </PaDetailBoxStyled>

            <Divider />

            {/* Esz section of Pa Details */}
            <Box sx={{ px: "20px", pt: "30px", pb: "10px" }}>
              <Typography variant="h3">ESZ Information</Typography>
            </Box>

            <PaDetailBoxStyled>
              <Grid container sx={{ py: "5px" }} justifyContent="space-between">
                <Grid item>
                  <Typography variant="body1" color="primary.dark">
                    Status :
                  </Typography>
                  {paData?.esz_notification_tracker?.status ? (
                    <StatusBox
                      mt={"8px"}
                      style={handleStatusColor(
                        paData?.esz_notification_tracker?.status
                      )}
                    >
                      {paData?.esz_notification_tracker?.status}
                    </StatusBox>
                  ) : (
                    <Typography mt={"8px"}>NA</Typography>
                  )}
                  {/* <StatusBox sx={{mt : 1}} style={handleStatusColor("final")}>final</StatusBox> */}
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="primary.dark">
                    Area :
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    {paData?.esz_notification_tracker?.area
                      ? `${paData?.esz_notification_tracker?.area} sq km`
                      : "NA"}{" "}
                  </Typography>
                </Grid>
                <Grid item sx={{ pr: "8px" }}>
                  <Typography variant="body1" color="primary.dark">
                    Periphery :
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    {paData?.esz_notification_tracker?.periphery
                      ? `${paData?.esz_notification_tracker?.periphery} sq km`
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Part of any recognized wildlife corridor :
              </Typography>
              {/* <Typography variant='subtitle1'>{paData?.partOfAanyRecognizedWildlifeCorridor}</Typography> */}
              {paData?.esz_notification_tracker?.wildlife_corridor?.name ? (
                <Typography variant="subtitle1">
                  {paData?.esz_notification_tracker?.wildlife_corridor?.name}
                </Typography>
              ) : (
                "NA"
              )}
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Date of draft notification(s) :
              </Typography>
              {paData?.esz_notification_tracker?.date_of_draft_notification ? (
                <UnderlinedTypography
                  onClick={() =>
                    showPDF(
                      paData?.esz_notification_tracker
                        ?.detail_draft_notification_file?.link,
                      dispatch,
                      "No link found"
                    )
                  }
                  variant="subtitle1"
                >
                  {paData?.esz_notification_tracker?.date_of_draft_notification}
                </UnderlinedTypography>
              ) : (
                "NA"
              )}

              {/* <Typography variant='subtitle1'>{EszStaticData?.dateOfDraftNotification}</Typography> */}
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Date of final notification :
              </Typography>
              {paData?.esz_notification_tracker?.date_of_final_notification ? (
                <UnderlinedTypography
                  onClick={() =>
                    showPDF(
                      paData?.esz_notification_tracker
                        ?.detail_final_notification_file?.link,
                      dispatch,
                      "No link found"
                    )
                  }
                  variant="subtitle1"
                >
                  {paData?.esz_notification_tracker?.date_of_final_notification}
                </UnderlinedTypography>
              ) : (
                "NA"
              )}
            </PaDetailBoxStyled>

            <Divider />

            <PaDetailBoxStyled>
              <Typography variant="body1" color="primary.dark">
                Minutes of Meetings :
              </Typography>
              {paData?.minutesOfMeetings ? (
                <Typography variant="subtitle1">
                  {paData?.minutesOfMeetings}
                </Typography>
              ) : (
                "NA"
              )}
            </PaDetailBoxStyled>

            <Divider />

            {/* NBWl descison section of Pa Details */}
            <Box sx={{ px: "20px", pt: "30px", pb: "10px" }}>
              <Typography variant="h3">NBWL Decisions</Typography>
              <Grid container sx={{ mt: "15px" }}>
                <TableContainer>
                  <Table sx={{}} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Project Title</StyledTableCell>
                        <StyledTableCell>Project Category </StyledTableCell>
                        <StyledTableCell>Area</StyledTableCell>
                        {/* <StyledTableCell>Date of Approval</StyledTableCell> */}
                        <StyledTableCell>Meeting Date</StyledTableCell>
                        {/* <StyledTableCell>Further Information</StyledTableCell> */}
                        <StyledTableCell>Final Decision</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell style={{ minWidth: "150px" }}>
                          {paData?.nbwl_details?.project_tittle ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell style={{ minWidth: "120px" }}>
                          {paData?.nbwl_details?.project_category?.name ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            minWidth: "100px",
                            ...handleTextTransform(
                              paData?.nbwl_details?.forest_area_affected
                            ),
                          }}
                        >
                          {paData?.nbwl_details?.forest_area_affected
                            ? `${paData?.nbwl_details?.forest_area_affected} sq km`
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell style={{ minWidth: "150px" }}>
                          {paData?.nbwl_details?.meeting_date ?? "NA"}
                        </StyledTableCell>
                        {/* <StyledTableCell style={{minWidth :'150px'}}>Permission Letter Information</StyledTableCell> */}
                        <StyledTableCell style={{ minWidth: "150px" }}>
                          {paData?.nbwl_details?.final_decision ?? "NA"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* {
           [1,2].map( (row) => (
              <StyledTableRow key={row.no}>
             <StyledTableCell style={{minWidth :'150px'}}>Abc Canal</StyledTableCell>
            <StyledTableCell style={{minWidth :'120px'}}>Linear</StyledTableCell>
            <StyledTableCell style={{minWidth :'100px'}}>25 sq km</StyledTableCell>
            <StyledTableCell style={{minWidth :'150px'}}>26/07/2023</StyledTableCell>
            <StyledTableCell style={{minWidth :'150px'}}>Permission Letter Information</StyledTableCell>
            </StyledTableRow>
            ))
          } */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Box>
          </Box>
        </Grid>

        {/* Map item start here */}
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          sx={{ order: [1, 1, 2, 2, 2] }}
        >
          {isSm ? (
            <>
              <PaDetailBoxStyled>
                <Typography
                  variant="regular3"
                  color="secondary.main"
                  gutterBottom
                >
                  PA Information
                </Typography>
                <Typography variant="h2">{paData?.name}</Typography>
                <Grid container sx={{ gap: "10px", pt: "10px" }}>
                  {paData?.pa_categories?.map((pa) => (
                    <PATypeLabelStyled>
                      <Typography variant="body2" color="primary.dark">
                        {pa?.name}
                      </Typography>
                    </PATypeLabelStyled>
                  ))}
                </Grid>
              </PaDetailBoxStyled>
              <Divider />
            </>
          ) : null}

          <Grid
            container
            sx={{
              width: "100%",
              height: isSm ? "500px" : "100%",
              position: "relative",
            }}
          >
            {/* status and FeedBack chip and buttons */}

            <MyMapComponent
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCwv6RWio5eJBZ5T9mEJfAa1ysutFbDpz0&v=3.exp&libraries=geometry,drawing,places&callback=Function.prototype"
              loadingElement={<div style={{ height: `100%` }} />}
              // containerElement= {<div style={{ height: `400px` }} />}
              // mapElement= {<div style={{ height: `100%` }} />}
              draftKML={paData?.detail_attachment_draft?.link} //paData?.detail_attachment_draft?.base_url +
              finalKML={paData?.detail_attachment_final?.link} //paData?.detail_attachment_final?.base_url +
              paKML={paData?.detail_attachment_notified?.link} //paData?.detail_attachment_notified?.base_url +
              draftChip={draftChip}
              finalChip={finalChip}
              nearByChip={nearByChip}
              nearByPaList={nearByPaList}
            />

            <Grid
              item
              container
              sx={{
                position: "absolute",
                top: "10px",
                px: "5px",
                ml: ["120px"],
                //  width : 'max-content',
                // width : "310px",
                // backgroundColor : "white",
                width: isXs ? "60%" : "max-content",
                overflow: "auto",
                whiteSpace: "nowrap",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <MyBox sx={{ gap: "8px" }}>
                <ChipStyled
                  selected={draftChip}
                  onClick={() => setDraftChip(!draftChip)}
                >
                  <Typography>Draft ESZ</Typography>
                </ChipStyled>
                <ChipStyled
                  selected={finalChip}
                  onClick={() => setFinalChip(!finalChip)}
                >
                  <Typography>Final ESZ</Typography>
                </ChipStyled>
                <ChipStyled
                  selected={nearByChip}
                  onClick={() => setNearByChip(!nearByChip)}
                >
                  <Typography>Nearby PA's</Typography>
                </ChipStyled>
                {isXs && (
                  <ButtonPrimary
                    onClick={() => setOpenFeedback(true)}
                    sx={{ height: "30px" }}
                  >
                    Feedback
                  </ButtonPrimary>
                )}
              </MyBox>
            </Grid>
            {isXs ? null : (
              <ButtonPrimary
                onClick={() => setOpenFeedback(true)}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 0,
                  height: "22px",
                  marginRight: "10px",
                }}
              >
                Feedback
              </ButtonPrimary>
            )}
            {/* status and FeedBack chip and ends here */}
          </Grid>
        </Grid>
      </Grid>

      {/* FeedBack Dialog */}
      {openFeedback && (
        <FeedbackForm
          open={openFeedback}
          closeFeedback={handleFeedbackForm}
          paName={paData?.name}
          paId={paId}
        />
      )}
      {/* Report Species  Dilaog */}
      {openReportSpeciesForm && (
        <ReportSpeciesSchedule
          open={openReportSpeciesForm}
          closeReportForm={handleReportSpeciesForm}
          paName={paData?.name}
          paId={paId}
        />
      )}
      {/* Report Species Second Form  Dilaog */}
      {openReportSpeciesFormAnother && (
        <ReportSpeciesInvasive
          open={openReportSpeciesFormAnother}
          closeReportForm={handleReportSpeciesFormAnother}
          paName={paData?.name}
          paId={paId}
        />
      )}
    </Grid>
  );
};

export default PAInfromation;
