import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Slider,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import ExpandMoreIcon from  "@mui/icons-material/ExpandMoreIcon"
import MapImage from "../../assets/Images/thumb.png";
import {
  ButtonOutlined,
  ButtonPrimary,
  ButtonWhiteBg,
  MapfilterBackIcon,
  StyledDrawer,
  MenuBoxStyled,
} from "../../components/StyledComponents/StyledComponents";
import ExpandMore from "@mui/icons-material/ExpandMore";

import MyDateRange from "../../components/DateRange/MyDateRange";

import { useNavigate } from "react-router-dom";
import { setItem } from "../../utils/validators";
import { useDispatch } from "react-redux";
import HomeMapComponent from "./HomeMapComponent";
import Loader from "../../components/Loader/Loader";
import { handleDateFormatforFilter } from "../../utils/StatusStyle";
import MySelectMultiple from "../../components/Select/MySelectMultiple";
import MySliderForESZArea from "../../components/Slider/MySliderForESZArea"

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let minValue = 0;
let maxValue = 100;

const Map = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [mapData, setMapData] = useState([]);

  //State for listing filter items
  const [allStatesList, setAllStatesList] = useState([]);
  const [paTypeList, setPaTypeList] = React.useState([]);
  const [specialRecognitionList, setSpecialRecognitionList] = useState([]);
  const [eszZoneList, setEszZoneList] = useState([]);

  const [ESZAreaMinMax, setESZAreaMinMax] = React.useState([0, 100]);


  //state for holding filter item values
  // const [state, setState] = useState("");
  const [statesData, setStatesData] = useState([{id: 594, name: "Madhya Pradesh"}]);
  const [paTypesData, setPaTypesData] = useState([]);
  const [specialRecognition, setSpecialRecognition] = useState("");
  const [eszStatus, setEszStatus] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [value1, setValue1] = React.useState([20, 37]);

  // effect for data of Filter's Fields
  useEffect(() => {
    getFilterListData();
    // getMapData();
    handleFilterApply();

  }, []);

  const getMapData = () => {
    dispatch({
      type: "GET_HOMEPAGE_MAP_DATA",
      path: '/home/pa_data',
      successCallBack: successCallBackForMapData,
    });
  };

  const getFilterListData = () => {
    dispatch({
      type: "PA_FILTER_LIST_DATA",
      successCallBack: successCallBackFilterList,
    });
  };

  const getFilteredMapData = (path) => {
    dispatch({
      type: "GET_HOMEPAGE_MAP_DATA",
      path: path,
      successCallBack: successCallBackForMapData,
    });
  };

  const successCallBackForMapData = (res) => {
    let data = res?.data?.data;
    setMapData(res?.data?.data);
    console.log("Map data", data);
  };

  const successCallBackFilterList = (data) => {
    minValue=data?.esz_min_area;
    maxValue=data?.esz_max_area;
    setAllStatesList(data?.states_data);
    setPaTypeList(data?.pa_categories_data);
    setSpecialRecognitionList(data?.special_recognition_data);
    setEszZoneList(data?.esz_status_data);
    let eszArea = [data?.esz_min_area, data?.esz_max_area];
    setESZAreaMinMax(eszArea) 
  };

  const handleChangePaTypesdata = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    setPaTypesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeStatesData = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);

    setStatesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

   //handling filter Actions
 const getSelectedStates = () => {
  let str = "";
  statesData?.forEach((item) => {
    str = str + "&state_ids[]=" + item.id;
    
  });
  return str;
};

const getSlectedPAs = () => {
  let str = "";
  paTypesData?.forEach((item) => {
    str = str + "&pa_category[]=" + item.id;
  });
  return str;
};

  const handleFilterApply = () => {
    const queryParams = [];
     if (paTypesData.length  > 0) {
        let str = getSlectedPAs()
      queryParams.push(str);
    }
    if (statesData.length > 0) {
      let str = getSelectedStates()
      queryParams.push(str);
      }
    if (specialRecognition) {
      queryParams.push(`special_recognition_ids[]=${specialRecognition}`);
    }
    if (eszStatus) {
      queryParams.push(`esz_status[]=${eszStatus}`);
    }
    if (dateRange[0].startDate && dateRange[0].endDate) {
      queryParams.push(
        `date_of_notify_start=${handleDateFormatforFilter(
          dateRange[0].startDate
        )}&date_of_notify_end=${handleDateFormatforFilter(
          dateRange[0].endDate
        )}`
      );
    }
    if (minValue !== ESZAreaMinMax[0] || maxValue !== ESZAreaMinMax[1]) {
      queryParams.push(`area_of_pa_start=${ESZAreaMinMax[0]}&area_of_pa_end=${ESZAreaMinMax[1]}`);
    }
     if (queryParams.length > 0) {
      queryParams.push("search=true");
    }

    const path = `/home/pa_data${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`;
    getFilteredMapData(path)
  };

  const handleFilterReset = () => {
    setPaTypesData([]);
    setStatesData([]);
    setSpecialRecognition("");
    setEszStatus("");
    setDateRange((date) => {
      return [{ ...date[0], startDate: new Date(), endDate: null }];
    });
    setESZAreaMinMax([minValue, maxValue])
    getMapData();
  };

  //   "params": {
  //     "id": [
  //         1
  //     ],
  //     "date_of_notify_start": "2023-08-12",
  //     "date_of_notify_end": "2023-08-12",
  //     "state_ids": [
  //         1,
  //         2
  //     ],
  //     "pa_category": [
  //         0,
  //         1,
  //         2
  //     ],
  //     "special_recognition_ids": [
  //         1,
  //         2
  //     ],
  //     "esz_status": [
  //         0,
  //         1
  //     ]
  // }

  const handleChange1 = (event, newValue, activeThumb) => {
     console.log('setValue(newValue);', newValue);
    setESZAreaMinMax(newValue)
 };

  const handleFilterMenuOpen = () => {
    setOpen(true);
  };
  const handleFilterMenuClose = () => {
    setOpen(false);
  };

  function valuetext(value) {
    return `${value}`;
  }

  return (
    <Box
      // style={{  backgroundImage: `url(${MapImage})`,}}
      sx={{
        width: "100%",
        height: "650px",
        position: "relative",
        // background: `url(${MapImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      {props.loader ? (
        <Loader />
      ) : (
        <>
          <HomeMapComponent
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCwv6RWio5eJBZ5T9mEJfAa1ysutFbDpz0&v=3.exp&libraries=geometry,drawing,places&callback=Function.prototype"
            loadingElement={<div style={{ height: `100%` }} />}
            data={mapData}
            // containerElement= {<div style={{ height: `400px` }} />}
            // mapElement= {<div style={{ height: `100%` }} />}
          />

          {/* Menu Icon */}
          <MenuBoxStyled onClick={handleFilterMenuOpen}>
            <MenuIcon
              sx={{
                color: "#ffffff",
              }}
            />
          </MenuBoxStyled>

          {/* map filter left drawer */}
          <StyledDrawer  sx={{ px: 3, py: 2, gap: 2 }} open={open}>
            <Grid container
             style={{ display: open ? 'flex' : 'none', gap : "10px" }}
            >
            <Grid item xs={12} sx={{ position: "realtive" }}>
              <Typography variant="h3" color="text.alwayswhite">
                Protected Areas
              </Typography>
              <MapfilterBackIcon
              open={open}
              onClick={handleFilterMenuClose}>
                <KeyboardDoubleArrowLeftIcon />
              </MapfilterBackIcon>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" color="text.alwayswhite">
                Date Range
              </Typography>
              <MyDateRange
                onChange={(item) => setDateRange([item.selection])}
                dateRange={dateRange}
                homePage={true}
              />
            </Grid>

            <Grid item xs={12} container sx={{ gap: "5px" }}>
              <Typography variant="body1" color="text.alwayswhite">
                State/Union Territory
              </Typography>
              <FormControl fullWidth>
              <MySelectMultiple
                value={statesData}
                onChange={handleChangeStatesData}
                menuArray={allStatesList}
                />
            
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" color="text.alwayswhite">
                Protected Area Type
              </Typography>
              <FormControl fullWidth>
                <MySelectMultiple
                  value={paTypesData}
                  onChange={handleChangePaTypesdata}
                  menuArray={paTypeList}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" color="text.alwayswhite">
                Special Recognition
              </Typography>
              <FormControl fullWidth>
              <Select
                  value={specialRecognition}
                  onChange={(e) => setSpecialRecognition(e.target.value)}
                  renderValue={
                    specialRecognition !== ""
                      ? undefined
                      : (t) => <span style={{ color: "#555555" }}>Select</span>
                  }
                  IconComponent={() => (
                    <ExpandMore
                      sx={{ color: (t) => t.palette.secondary.main }}
                    />
                  )}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "44px",
                    backgroundColor: (t) => t.palette.grey[300],
                  }}
                  MenuProps={MenuProps}
                >
                  {specialRecognitionList?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      <Typography variant="body2">{item?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
               
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" color="text.alwayswhite">
                Ecosensitive Zone Status
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={eszStatus}
                  onChange={(e) => setEszStatus(e.target.value)}
                  renderValue={
                    eszStatus !== ""
                      ? undefined
                      : (t) => <span style={{ color: "#555555" }}>Select</span>
                  }
                  IconComponent={() => (
                    <ExpandMore
                      sx={{ color: (t) => t.palette.secondary.main }}
                    />
                  )}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "44px",
                    backgroundColor: (t) => t.palette.grey[300],
                  }}
                >
                  {eszZoneList?.map((item) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      <Typography variant="body2">{item?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

      
            <Grid item xs={12}>
            <Typography variant="body1" color="text.alwayswhite">
              Area of PA
            </Typography>
            <MySliderForESZArea
                value={ESZAreaMinMax}
                onChange={handleChange1}
                maxValue={maxValue}
                />
            
          </Grid>

            

            <Grid item xs={12}>
              <Grid container sx={{ gap: "10px", mt : "10px" }}>
                <ButtonOutlined
                  onClick={handleFilterReset}
                  sx={{ textTransform: "none", flex: 1 }}
                >
                  Reset
                </ButtonOutlined>
                <ButtonPrimary sx={{ flex: 1 }} onClick={handleFilterApply}>
                  Apply
                </ButtonPrimary>
              </Grid>
            </Grid>

            {/* <Grid item>
            <Typography variant="body1" color="text.alwayswhite">
              Area of PA
            </Typography>
            <Slider
              getAriaLabel={() => "Minimum distance"}
              value={value1}
              onChange={handleChange1}
              getAriaValueText={valuetext}
              valueLabelFormat={(value) => `${value}sq km`}
              disableSwap
              aria-label="myslider"
              valueLabelDisplay="on"
              //   max={1000}
              sx={{
                "& .MuiSlider-rail": {
                  backgroundColor: "#ffffff",
                  opacity: 1,
                },
                "& .MuiSlider-track": {
                  backgroundColor: theme.palette.vidhiRed.main,
                  border: "none",
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme.palette.vidhiRed.main,
                },
                "& .MuiSlider-valueLabelOpen": {
                  top: "52px",
                  padding: "2px",
                  backgroundColor: "transparent",
                  "&:before": {
                    top: "-6px",
                  },
                },
                "& .MuiSlider-valueLabelCircle": {
                  // width : "max-content",
                  // backgroundColor :'red'
                },
              }}
            />
          </Grid> */}
            {/* {paType?.id}{paType?.name}{state}{eszStatus}{specialRecognition} */}
            </Grid>
          </StyledDrawer>

          {/* map button at bottom */}
          <Box
            sx={{
              // display: "flex",
              // justifyContent: "flex-end",
              // gap: "10px",
              position: "absolute",
              right: 60,
              bottom: 20,
            }}
          >
            <ButtonPrimary
              sx={{ mr: 1 }}
              onClick={() => {
                setItem("pa", "All");
                navigate("/protectedareas");
              }}
             >
              View information as list
            </ButtonPrimary>
            {/* <ButtonPrimary 
        >
          View information on map
        </ButtonPrimary> */}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Map;
