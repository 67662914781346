import React from 'react'
import {  ThemeProvider, createTheme,  responsiveFontSizes, } from '@mui/material/styles';
import { getTheme } from '../themes/theme';
import { CssBaseline } from '@mui/material';


export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });//Creating Context

const ColorModeWrapper = ({children}) => {
  const [mode, setMode] = React.useState('light');

  const [fontSize, setFontSize] = React.useState(0)
  
  const IncreaseFontSize = () => {
    setFontSize((prev)=> prev + 4)
  }

  const defaultFontSize = () => {
    setFontSize(0)
  }

  const DecreaseFontSize = () => {
    setFontSize((prev)=> prev - 4)
  }
  
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
         setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

 
  const theme = createTheme(getTheme(mode, fontSize))
  // const baseTheme = createTheme(getTheme(mode, fontSize))
 // const theme = responsiveFontSizes(baseTheme, {
  //   // breakpoints: {
  //   //   values: {
  //   //     xs: 0,
  //   //     sm: 600,
  //   //     md: 960,
  //   //     lg: 1280,
  //   //     xl: 1920,
  //   //   },
  //   // },
  //   // Set your desired font sizes for each breakpoint
  //   // The font size will change automatically based on the screen size
  //   factor: 2, // The factor by which to increase or decrease the base font size
  // });
  
  return (
   <ColorModeContext.Provider value={ {colorMode, IncreaseFontSize, DecreaseFontSize, defaultFontSize, fontSize, mode}}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default ColorModeWrapper;