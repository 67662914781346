import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  Grid,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./mapstyle.css";

// import ReactDatamaps from "react-india-states-map";
import MapChart from "./MapChart";
import MySelect from "../../components/Select/MySelect";
import { useDispatch, useSelector } from "react-redux";
import MyDateRange from "../../components/DateRange/MyDateRange";
import Loader from "../../components/Loader/Loader";
import {
  ButtonOutlined,
  ButtonPrimary,
  FilterStyled
  
} from "../../components/StyledComponents/StyledComponents";


import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterListIcon from "@mui/icons-material/FilterList";
import { handleDateFormatforFilter } from "../../utils/StatusStyle";


const ViewDataOnMap = ({ open, closeMapDialog }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const loader = useSelector((state) => state.commonReducer.showLoader2);

  let isXs = false;
  let isSm = false;
  let isMd = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));
  isSm = useMediaQuery(theme.breakpoints.down('md'));
  isMd = useMediaQuery(theme.breakpoints.up('md'));

  const [statesData, setStatesData] = useState([]);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  // const [statesData, setStatesData] = useState([])

  //states for holding filter's item's data Listing
  const [houseList, setHouseList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [statesList, setStatesList] = useState([]);

  //states for holding filter's item's value
  const [house, setHouse] = useState("");
  const [sessionNumber, setSessionNumber] = useState("");
  const [states, setStates] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    getMapFilterListData();
    getMapStatesData();
  }, []);

  const getMapFilterListData = () => {
    dispatch({
      type: "QUESTION_MAP_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
    });
  };

  const getMapStatesData = () => {
    dispatch({
      type: "GET_QUESTION_MAP_STATES",
      successCallBack: successCallBack,
      path: `/contents/questions_data`,
    });
  };

  const getMapStatesFilteredData = (path) => {
    dispatch({
      type: "GET_QUESTION_MAP_STATES",
      successCallBack: successCallBack,
      path: path,
    });
  };

  let statesDataModified = {};

  statesData?.forEach((item) => {
    statesDataModified = {
      ...statesDataModified,
      [item.state]: { value: item.questions },
    };
  });

  console.log("states modified", statesDataModified);

  const successCallBack = (res) => {
    setStatesData(res?.data?.data);
    setOpenFilter(false)
  };

  const successCallBackFilterListData = (data) => {
    setHouseList(data?.house);
    setSessionList(data?.sessions);
    setStatesList(data?.states_data);
    setOpenFilter(false)
  };

  const handleFilterApply = () => {
    let queryParams = [];
  
    if (house) {
      queryParams.push(`house_id[]=${house}`);
    }
  
    if (states) {
      queryParams.push(`states_id[]=${states}`);
    }
  
    if (sessionNumber) {
      queryParams.push(`session_number_id[]=${sessionNumber}`);
    }
    // if(dateRange[0].startDate && dateRange[0].endDate){
    //   isSearching = true;
    //   queryParams.push(`&date_of_question_start=${handleDateFormatforFilter(dateRange[0].startDate)}&date_of_question_end=${handleDateFormatforFilter(dateRange[0].endDate)}`);
    // }
    // Check if there are other query parameters before adding 'search=true'
    if (queryParams.length > 0) {
      queryParams.push('search=true');
    }
  
    // Construct the path
    const path = `/contents/questions_data${queryParams.length > 0 ? '?' + queryParams.join('&') : ''}`;
  
    getMapStatesFilteredData(path);
  };
  

  const handleFilterReset = () => {
    setHouse("");
    setSessionNumber("");
    setDateRange((date) => {
      return [{ ...date[0], startDate: new Date(), endDate: null }];
    });
    getMapStatesData()
  };

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  if(isSm){
    return (
      <Dialog
      open={open}
      onClose={closeMapDialog}
      fullScreen
      sx={{
        "& .MuiPaper-root": {
          // maxHeight:"90vh",
          margin: 0,
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ 
            px: isXs ? "16px": "32px", 
            py: "20px", position: "relative" }}
        >
          <Typography variant="h2">Questions Data</Typography>
          <CloseIcon
            onClick={closeMapDialog}
            sx={{ position: "absolute", right: 20, top: 20 }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems={"flex-start"}
        justifyContent="flex-start"
        // sx={{height : '100%'}}
      >
      
      {/* Responsive Filter */}
      <Grid item xs ={12} container  sx={{ 
            px: isXs ? "16px": "32px", 
             }}
         >
             {
              isXs ? (
                isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark, mb : '10px'}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark, mb : '10px'}}/>)
              )  : (
                <FilterStyled sx={{mb : '10px'}} isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
              )
            }

       <Dialog
        open={openFilter}
        onClose={handleFilterClose}
        fullScreen={isXs}
        sx={{
          "& .MuiPaper-root": {
            // minWidth: "90vw",
            
          },
        }}
      >
       {/* Dialog filed container */}
       <Grid item xs={12} sm={12} md={3} sx={{ px: "20px", }}>
            <Grid
              container
              sx={{ gap: "20px", py: "20px" }}
              alignContent="flex-start"
            >
              <Grid
              item
               sx={{ width: "100%",position: "relative" }}
              >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>
              {/* Session */}
              <FormControl fullWidth>
                <Typography>Session</Typography>
                <MySelect
                  value={sessionNumber}
                  onChange={(e) => setSessionNumber(e.target.value)}
                  menuArray={sessionList}
                />
              </FormControl>
  
              {/* House */}
              <FormControl fullWidth>
                <Typography>House</Typography>
                <MySelect
                  value={house}
                  onChange={(e) => setHouse(e.target.value)}
                  menuArray={houseList}
                />
              </FormControl>
  
              {/* Date Range Select */}
              <FormControl fullWidth>
                <Typography>Date Range</Typography>
                <MyDateRange
                  dateRange={dateRange}
                  onChange={(item) => setDateRange([item.selection])}
                />
              </FormControl>
  
              <Grid item xs={12}>
                <Grid container sx={{ gap: "10px" }}>
                  <ButtonOutlined
                    onClick={handleFilterReset}
                    sx={{ textTransform: "none", flex: 1 }}
                  >
                    Reset
                  </ButtonOutlined>
                  <ButtonPrimary sx={{ flex: 1 }} onClick={handleFilterApply}>
                    Apply
                  </ButtonPrimary>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(4,1fr)",
                  lg: "repeat(4,1fr)",
                  xl: "repeat(4,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              
            </Box>
          </Grid> */}
      </Dialog>   
        
      </Grid>
        

        {/* Indian States Map grid starts here */}
        {loader ? (
          <Grid item xs={12} sm={12} md={8} sx={{ height: "400px" }}>
            <Loader />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            // sm={12}
            // md={8}
            container
            className="mapSvgContainer"
            justifyContent={"center"}
          >
            <MapChart myRegionData={statesDataModified} />
          </Grid>
        )}
      </Grid>
    </Dialog>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={closeMapDialog}
      sx={{
        "& .MuiPaper-root": {
          minWidth: "70vw",
          maxHeight: "80vh",
          // minWidth: "70vw",
          // width: ["100vw", "100vw", "70vw", "70vw", "70vw"],
          // maxHeight: "80vh",
          margin: 0,
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ 
            px: isXs ? "16px": "32px", 
            py: "20px", position: "relative" }}
        >
          <Typography variant="h2">Questions Data</Typography>
          <CloseIcon
            onClick={closeMapDialog}
            sx={{ position: "absolute", right: 20, top: 20 }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems={"flex-start"}
        justifyContent="flex-start"
        // sx={{height : '100%'}}
      >
       
            <Grid item xs={12} sm={12} md={3} sx={{ pl: "30px", maxHeight: "400px" }}>
            <Grid
              container
              sx={{ gap: "20px", mt: "40px" }}
              alignContent="flex-start"
            >
              <Typography variant="h3">Filters</Typography>
              {/* Session */}
              <FormControl fullWidth>
                <Typography>Session</Typography>
                <MySelect
                  value={sessionNumber}
                  onChange={(e) => setSessionNumber(e.target.value)}
                  menuArray={sessionList}
                />
              </FormControl>
  
              {/* House */}
              <FormControl fullWidth>
                <Typography>House</Typography>
                <MySelect
                  value={house}
                  onChange={(e) => setHouse(e.target.value)}
                  menuArray={houseList}
                />
              </FormControl>
  
              {/* Date Range Select */}
              <FormControl fullWidth>
                <Typography>Date Range</Typography>
                <MyDateRange
                  dateRange={dateRange}
                  onChange={(item) => setDateRange([item.selection])}
                />
              </FormControl>
  
              <Grid item xs={12}>
                <Grid container sx={{ gap: "10px" }}>
                  <ButtonOutlined
                    onClick={handleFilterReset}
                    sx={{ textTransform: "none", flex: 1 }}
                  >
                    Reset
                  </ButtonOutlined>
                  <ButtonPrimary sx={{ flex: 1 }} onClick={handleFilterApply}>
                    Apply
                  </ButtonPrimary>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        
        
        

        {/* Indian States Map grid starts here */}
        {loader ? (
          <Grid item xs={12} sm={12} md={8} sx={{ height: "400px" }}>
            <Loader />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            container
            className="mapSvgContainer"
            justifyContent={"center"}
          >
            <MapChart myRegionData={statesDataModified} />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default ViewDataOnMap;
