
import { put, call } from "redux-saga/effects";
import { performPatchRequest, performPostRequest } from "../../services/apiServices/AxiosUtils";
import { showSnackbarAction } from "../actions/commonActions";


import { createUserAction, userLoginAction } from "../actions/userActions";
//-----------------------------------------------------------------------------------
export const userLogin = function* userLoginSaga(action) {
  yield put({type : "SHOW_LOADER"})
  console.log("user saga in vidhi",action.payload)
  let body = {
    user: {
      email: action.payload.email,
      password: action.payload.password,
    },
  };

  try {
    const path = "/authentication/login";
    const res = yield call(performPostRequest, path, body);
    console.log(
      "@@@ user login Response =======",res);
    if (res !== undefined && res.data.status === "success") {
     yield action.payload.navigation();
     yield put({type : "HIDE_LOADER"})
     yield localStorage.setItem("isUserLoggedIn",true)

    yield put(userLoginAction(res?.data?.user));
     } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      yield put(showSnackbarAction("Incorrect Email or password"))
     }
  } catch (error) {
    console.log("@@@ LOGIN API error ========", error);
    }
};

//-----------------------------------------------------------------------------------
export const userSignUp = function* userSignUpSaga(action) {
  console.log("saga function run on createaccount click", action);

  let body = {
    "user": {
    email: action.payload.email,
    password: action.payload.password,
    full_name: action.payload.name,
    login_type: 0,
    }
  };
  
  try {
    const path = "/authentication/signup";
    const res = yield call(performPostRequest, path, body);
    console.log(
      "@@@ user signup Response =======",res);
    if (res !== undefined && res.data.status === "success") {
     yield action.payload.successCallBack(res);
    //  yield put(createUserAction(res?.data?.user));
     }else if(res.data.status === "error"){
      yield put(showSnackbarAction(res?.data?.data[0]))
      yield put({type : "HIDE_LOADER"})
     
     }
  } catch (error) {
    console.log("@@@ SIGN UP API error ========", error);
    }

//   try {
//     const res = yield call(performPostRequest, path, body);
//     if (res !== undefined && res.status === 200) {
//       //  yield put({
//       //   type: CONST.USER_SIGNUP_ACTION,
//       //   payload: res.data.data,
//       // });
//       yield localStorage.setItem("userId", JSON.stringify(res?.data?.data?.id));
//       yield action.navigation();
//       yield put({
//         type: "SHOW_TOAST",
//         payload: { severity: "success", message: res?.data?.message },
//       });
//     }
//   } catch (error) {
//     console.log("@@@ SIGNUP API error ========", error);
//     yield put({
//       type: "SHOW_TOAST",
//       payload: { severity: "error", message: error?.response?.data?.message },
//     });
//   }
};

//-----------------------------------------------------------------------------------



export const getOTP = function* userGetOTP(action) {
  console.log("user saga in vidhi",action.payload)
  let body = {
    user: {
      email: action.payload.email,
      },
  };

 
  try {
    const path = "/authentication/get_confimation_otp";
    const res = yield call(performPostRequest, path, body);
    console.log(
      "@@@ getOTP Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.payload.successCallBack()
     }else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      
     }
  } catch (error) {
    console.log("@@@ getOTP API error ========", error);
    }
};

//-----------------------------------------------------------------------------------




export const verifyOTP = function* userVerifyOTP(action) {
  yield put({type : "SHOW_LOADER"})
  console.log("user saga in vidhi",action.payload)
  let body = {
    user: {
      email: action.payload.email,
      otp : action.payload.otp,
      }
  };

  
  try {
    const path = "/authentication/confimed_via_otp";
    const res = yield call(performPatchRequest, path, body);
    console.log(
      "@@@ verifyOTP Response =======",res);
    if (res !== undefined && res.data.status === "success") {
     yield action.payload.successCallBack(action.payload.email);
     yield localStorage.setItem("isUserLoggedIn",true)

     yield put({type : "HIDE_LOADER"})
     } 
     else if(res.data.status === "error"){
      console.log("else iff,,,,")
     yield put({type : "HIDE_LOADER"})
   // yield put({
      //   type : "SHOW_SNACKBAR",
      //   message : "Incorrect OTP"
      // })
      yield put(showSnackbarAction("Incorrect otp"))
      yield action.payload.setOtp("")
    }
  } catch (error) {
     yield action.payload.setOtp("")
     yield put({type : "HIDE_LOADER"})

    console.log("@@@ verifyOTP API error ========", error);
    }
};


//-----------------------------------------------------------------------------------

export const forgotSentEmail = function* forgotSentEmail(action) {
  yield put({type : "SHOW_LOADER"})
  console.log("user saga in vidhi",action.payload)
  let body = {
    user: {
      email: action.payload.email,
      }
  };

  
  try {
    const path = "/authentication/send_password_reset_link";
    const res = yield call(performPostRequest, path, body);
    console.log(
      "@@@ forgotSentEmaile =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield put({type : "HIDE_LOADER"})
      yield action.payload.successCallBack()
     }else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      yield put(showSnackbarAction(res.data.data))
     }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
    yield put(showSnackbarAction("User not found"))
     console.log("@@@ forgotSentEmail API error ========", error);
    }
};
//-----------------------------------------------------------------------------------

export const confirmEmailWithOtp = function* confirmEmailWithOtp(action) {
  yield put({type : "SHOW_LOADER"})
 console.log("user saga in vidhi",action.payload)
  let body = {
    user: {
      email: action.payload.email,
      otp : action.payload.otp
      },
  };

  
  try {
    const path = "/authentication/check_email_with_otp";
    const res = yield call(performPostRequest, path, body);
    console.log(
      "@@@ confirmEmailWithOtp Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.payload.successCallBack()
     yield put({type : "HIDE_LOADER"})
       yield put(showSnackbarAction("Otp has been verified"))
     }else if(res.data.status === "error"){
     yield put({type : "HIDE_LOADER"})
    yield put(showSnackbarAction("Incorrect otp"))
     }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
console.log("@@@ confirmEmailWithOtp API error ========", error);
    
    }
};


//-----------------------------------------------------------------------------------




export const updatePassword = function* userupdatePassword(action) {
  console.log("user saga in vidhi",action.payload)
  let body = {
    user: {
          email: action.payload.email,
          new_password: action.payload.password,
          new_password_confirmation: action.payload.confirmPassword,
          }
    
  };

  
  try {
    const path = "/authentication/reset_password";
    const res = yield call(performPatchRequest, path, body);
    console.log(
      "@@@ reset password Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield put(showSnackbarAction("Password has updated"))
      yield action.payload.setPasswordResetted(true)
      
     } 
     else if(res.data.status === "error"){
      console.log("else iff,,,,")
      // yield put(showSnackbarAction("Incorrect otp"))
      
    }
  } catch (error) {
     yield action.payload.setOtp("")
    console.log("@@@ verifyOTP API error ========", error);
    }
};


//-----------------------------------------------------------------------------------


export const socailLogin = function* socialLogin(action) {
  yield put({type : "SHOW_LOADER"})
  
  let body = {
    user: {
        email: action.payload.googleEmail,
        full_name : action.payload.googleName,
        login_type: action.payload.loginType,
        uuid: action.payload.googleId,
    },
  };

  
  try {
    const path = "/authentication/signup";
    const res = yield call(performPostRequest, path, body);
    console.log(
      "@@@ user social login Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data)
      yield put(userLoginAction(res?.data?.user));
      yield put({type : "HIDE_LOADER"})
     }else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      yield put(showSnackbarAction(res?.data?.data))
     }
  } catch (error) {
    console.log("@@@ LOGIN API error ========", error);
    }
};






