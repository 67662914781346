import React, {useState, useEffect}  from 'react'
import {
  Grid,
  Box,
  InputBase,
  Typography,
  Dialog,
  Divider,
 DialogActions,
  FormControl,
  useTheme,
  useMediaQuery
} from "@mui/material";import LinkBar from '../../components/LinkBar/LinkBar'
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SearchBarStyled, FilterStyled,ButtonOutlined, ButtonPrimary, MyBox } from '../../components/StyledComponents/StyledComponents'
import debatesImg from '../../assets/Images/debates.png'
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card/Card';
import CloseIcon from "@mui/icons-material/Close";
import InfiniteScroll from "react-infinite-scroll-component";
import * as CONST from '../../utils/Contants'
import { showSnackbar } from "../../utils/PdfUtils";
import axios from "axios";


import MySelect from "../../components/Select/MySelect";
import { useDispatch, useSelector} from 'react-redux';
import Loader from '../../components/Loader/Loader';
import NoDataFoundComponent from '../../components/NoDataFoundUI/NoDataFound'
import MyDateRange from '../../components/DateRange/MyDateRange';
import moment from 'moment';


const AllReports = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()
  const loader = useSelector((state) => state.commonReducer.showLoader);

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const [openFilter, setOpenFilter] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);

  const [hasMore, setHasMore] = useState(true);
  const [pageReset, setPageReset] = React.useState(false);
  const [scrollLoader, setScrollLoader] = useState(false)


  const [allReports, setAllReports] = useState([]);


   //states for holding filter's item's data Listing
   const [houseList, setHouseList] = useState([]);
   const [committeeTypeList, setCommitteeTypeList] = useState([]);
   const [topicList, setTopicList] = useState([]);
   const [ministryList, setMinistryList] = useState([]);
   const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
   
 
   //states for holding filter's item's value
   const [house, setHouse] = useState("");
   const [committeeType, setCommitteeType] = useState("");
   const [topic, setTopic ] = useState("")
   const [ministry, setMinistry] = useState("")
   const [startDate, setStartDate] = useState(new Date());
   
    
  useEffect(() => {
    // getReportsList()
    getFilterListData()
  }, []);

  
  //This effect will run when component mounts
  useEffect(() => {
    console.log("useEffect single",page)
    fetchMoreData(page)
  }, []);

  useEffect(() => {
    console.log("useEffect",page)
    if(isFilterApplied){
    console.log("useEffect isFilter APplied true and page is also changing useEffect and page",page)
       fetchMoreData(page)
    }
    if(pageReset){
      fetchMoreData(page)
     }
  }, [page, isFilterApplied, pageReset]);



  
  const successCallBackScroll = (res) => {
    setScrollLoader(false)
    if(res?.data?.data.length < 1){
      setHasMore(false)
      return
    }
    setPageReset(false)
    let data = res?.data?.data;
    setAllReports((report) => [...report, ...data]);
    setPage((p) => p + 1);
  }


  const fetchMoreData = async () => {
    setScrollLoader(true)

    let path = `${CONST.BASE_URL}/contents/reports?less_details=true&page=${page}&per_page=10`;
    if(isFilterApplied){
      let isSearching = false;
    if(house){
      isSearching = true
      path = path + `&house_id[]=${house}`
    }
    if(committeeType){
      isSearching = true
      path = path + `&committee_id[]=${committeeType}` //add path later
    }
    if(topic){
      isSearching = true
      path = path + `&topic[]=${topic}`
    }
    if(ministry){
      isSearching = true
      path = path + `&ministry_id[]=${ministry}` 
    }
    if(dateRange[0].startDate && dateRange[0].endDate ){
      isSearching = true
      path = path + `&date_of_table_of_report_start=${moment(dateRange[0].startDate).format('YYYY-MM-DD')}&date_of_table_of_report_end=${moment(dateRange[0].endDate).format('YYYY-MM-DD')}` //add path later
    }
    if(isSearching){
      path = path + "&search=true"
    }
    }
    
    try {
      await axios.get(path)
      .then(res => {
          successCallBackScroll(res)
        });
    } catch (error) {
    setScrollLoader(false)
    showSnackbar("Server Error!",dispatch)
    }
       
    }

 

  const getFilterListData = () => {
    dispatch({
      type: "REPORTS_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
      })
  }

  
  const successCallBackFilterListData = (data) => {
        setHouseList(data?.house)
        setTopicList(data?.topic_types)
        setCommitteeTypeList(data?.committed)
        setMinistryList(data?.ministry)
  };

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const handleFilterApply = () => {
    setAllReports([])
    setPage(1)
    setIsFilterApplied(true)
    setOpenFilter(false)
  };


  const handleFilterReset = () => {
    setHouse("")
    setCommitteeType("")
    setTopic("")
    setMinistry("")
    setDateRange((date) =>  {
      return [{...date[0], startDate : new Date(), endDate : null}]
      })
      setIsFilterApplied(false) 
      setOpenFilter(false)
      setAllReports([])
      setPage(1)
      setHasMore(true)
      setPageReset(true)
  };

  const renderNoDatafound = () => {
      return <NoDataFoundComponent title={"No Report Found"} data={allReports} searchValue={searchValue} loader={loader} />
  };

  return (
    <>
    <Grid
    item 
    container
    sx={{backgroundColor : (t)=> t.palette.mode === "dark" ? t.palette.background.default : t.palette.grey[100]
  }}
    >
     <LinkBar title="Reports"/>
     

     {/* first line */}
     <Grid item xs={12} container 
         sx={{
          px: isXs ? "16px" : '32px',
          my: "1.5rem",
          justifyContent: "space-between",
          alignItems: "center",
          rowGap : "16px"
        }}
        >
       <Typography
       sx={{width : isXs ? "100%" : "auto"}}
       variant='h2'>All Reports</Typography>
       <Box 
      >
       <SearchBarStyled>
         <SearchIcon />
         <InputBase 
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search here"
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={(e) => setSearchValue(e.target.value)}
          
          />
          </SearchBarStyled>
       </Box>
       {
              isXs ? (
                isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}}/>)
              )  : (
                <FilterStyled isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
              )
            }
    
     </Grid>

        {/* card section */}

       {
        loader ? <MyBox sx={{width : 1, height : "50vh"}}><Loader /></MyBox> : (
          <>
           <Grid item xs={12}>
           <InfiniteScroll
          dataLength={allReports?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4 style={{ textAlign: "center", display : scrollLoader ? "block" : "none" }}>{}Loading...</h4>}
          
        >
      <Box
       sx= {{
        display : 'grid',
        gridTemplateColumns : { xs : 'repeat(1,1fr)', sm : 'repeat(2,1fr)',  md : 'repeat(3,1fr)', lg : 'repeat(4,1fr)', xl : 'repeat(4,1fr)'},
        gap : '50px 50px',
        gridGap : '50px 50px',
        px: isXs ? "16px" : "32px",
        pb : '32px',
        boxSizing : 'border-box'
    }}
       >

        {
          allReports.filter((item) =>
          item?.title?.toLowerCase().includes(searchValue?.toLowerCase())
        ).map( item => (
            <Card 
            key={item?.id}
            item={item}
            title={item?.title}
            subtitle={item?.small_detail}
            cardImg={item?.thumbnail_detail?.link}
            date={item?.date}
            to="/report"
           
            />
           
          ))

        }
        

       </Box>
       </InfiniteScroll>

          {renderNoDatafound()}
      </Grid>
          </>
        )
       }
      

      {/* card section end    */}
    </Grid>

      {/* Filter Dialog start here */}
      <Dialog
        // fullWidth={fullWidth}
        //  maxWidth={maxWidth}
        // maxWidth={['xs','sm','md','xl','lg']}
        // maxWidth={"80vw"}
        fullScreen={isXs}
        open={openFilter}
        onClose={handleFilterClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "80vw",
            // backgroundColor : 'khaki'
          },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(3,1fr)",
                  lg: "repeat(3,1fr)",
                  xl: "repeat(3,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
            {/* House */}
              {/* <FormControl fullWidth>
                <Typography>House</Typography>
                <MySelect
                 value={house}
                 onChange={e=>setHouse(e.target.value)}
                 menuArray={houseList}
               />
               </FormControl> */}

              {/* Committee Type */}
              <FormControl fullWidth>
                <Typography>Type</Typography>
                <MySelect
               value={committeeType}
               onChange={e=>setCommitteeType(e.target.value)}
               menuArray={committeeTypeList}
                />
              </FormControl>

              {/* Member of Parliament */}
              <FormControl fullWidth>
                <Typography>Topic</Typography>
                <MySelect 
                value={topic}
                onChange={e=>setTopic(e.target.value)}
                menuArray={topicList}
                />

              </FormControl>

               {/* Theme/Subject*/}
              {/* <FormControl fullWidth>
                <Typography>Theme/Subject</Typography>
                <MySelectMultiple />
              </FormControl> */}

              {/* Ministry*/}
            <FormControl fullWidth>
                <Typography>Ministry</Typography>
                <MySelect
                value={ministry}
                onChange={e=>setMinistry(e.target.value)}
                menuArray={ministryList}
                />
              </FormControl>

              
              {/* State*/}
              {/* <FormControl fullWidth>
                <Typography>State</Typography>
                <MySelect />

              </FormControl> */}

              <FormControl fullWidth>
                <Typography>Date</Typography>
                <MyDateRange
                onChange={(item) => setDateRange([item.selection])}
                dateRange={dateRange}
                />
              </FormControl>
            </Box>

            

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined
            onClick={handleFilterReset}
            sx={{ textTransform: "none" }}
          >
            Reset
          </ButtonOutlined>
          <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>
  </>
  )
}

export default AllReports