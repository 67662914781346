import { combineReducers } from "redux";
import { commonReducer } from "./commonReducers";
import { userReducer } from "./userReducer";


const rootReducer = combineReducers({
 userReducer : userReducer,
 commonReducer : commonReducer
});

export default rootReducer;