import * as React from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Dialog,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

export const ButtonPrimary = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Lato",
  fontWeight: 600,
  backgroundColor: "#DC002D",
  borderRadius: "4px",
  height: "48px",
  color: "#ffff",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#FF8278",
  },
  "&.Mui-disabled": {
    backgroundColor: "#DCDCDC",
    color: "#AAAAAA",
  },
  // [theme.breakpoints.down('md')]:{
  //   fontSize : "14px",
  //   backgroundColor : "green"
  // },
  // [theme.breakpoints.down('sm')]:{
  //   fontSize : "12px",
  //   background  : 'pink'
  // },
}));

export const ButtonText = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  borderRadius: "4px",
  // padding : "0px 0px",
  minWidth: "40px",
  // backgroundColor : theme.palette.vidhiRed.light,
  color: theme.palette.mode === "dark" ? "#ffffff" : "#1C1B1F",
  "&:hover": {
    // backgroundColor : theme.palette.vidhiRed.light,

    boxShadow: "none",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    // backgroundColor: "green",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    // background: "pink",
  },
}));

export const ButtonOutlined = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  fontSize: "1rem",
  fontFamily: "Lato",
  fontWeight: 600,
  borderRadius: "4px",
  border: `2px solid ${theme.palette.primary.dark}`,
  padding: "8px 0px",
  color: theme.palette.primary.dark,
  "&:hover": {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.dark,
    color: "#fff",
  },
}));

export const ButtonOutlinedSearch = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  fontSize: "1rem",
  fontFamily: "Lato",
  fontWeight: 600,
  borderRadius: "4px",
  border: `2px solid ${theme.palette.primary.dark}`,
  padding: "8px 16px",
  color: theme.palette.primary.dark,
  "&:hover": {
    boxShadow: "none",
    // backgroundColor: theme.palette.primary.dark,
    // color :'#fff'
  },
}));

export const ButtonWhiteBg = styled(Button)(({ theme }) => ({
  textTransform: "none",
  boxShadow: "none",
  fontSize: "1rem",
  fontFamily: "Lato",
  fontWeight: 600,
  borderRadius: "4px",
  backgroundColor: "#ffffff",
  padding: "8px 8px",
  color: theme.palette.vidhiRed.main,
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#ffffff",
    color: theme.palette.vidhiRed.main,
  },
}));

export const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

// export const MenuLinkStyled = styled(Link)(({ theme }) => ({
//   textDecoration: "none",
//   color : "#fff",
//   "&:hover":{
//     color : "#FF8278"
//   }
// }));

// export const LinkNavbar = styled(Typography)(({ theme, open }) => ({
//   cursor :"pointer",
//   display : 'flex',
//   alignItems : 'center',
//   textDecoration: "none",
//   gap : "8px",
//   "&>p":{
//     fontSize : "0.875rem",
//   },
//   fontSize : "14px",
//   lineHeight : "1rem",
//   color : "#DC002D",
//   // ...(open && {
//   //      color :"#000",
//   //   "&>div":{
//   //     transform: "rotate(180deg)",
//   //     borderTop : "5px solid #000",
//   //   }
//   // }),
//     "&:hover":{
//       color :"#000",
//       "&>div":{
//         transform: "rotate(180deg)",
//         borderTop : "5px solid #000",
//       }
//     },
//   "&>div":{
//     width : 0,
//     height : 0,
//     borderLeft : "5px solid transparent",
//     borderRight : "5px solid transparent",
//     borderTop : "5px solid #DC002D",
//     transition : "transform 0.5s ease-in-out",

//   },

// }));

export const TextTruncate3 = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const UnderlinedTypography = styled(Typography)(({ red, theme }) => ({
  textDecoration: "underline",
  cursor: "pointer",
  ...(red && {
    textDecorationColor: theme.palette.primary.dark,
  }),
}));
export const TextTruncate2 = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const LabelStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme }) => ({
  marginBottom: "8px",
  //  fontWeight : 600,
  //  fontSize : "0.875rem"
}));

export const Dot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ black, theme }) => ({
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  backgroundColor: "#FF8278",
  ...(black && {
    width: "6px",
    height: "6px",
    backgroundColor: theme.palette.common.black,
  }),
}));

export const DrawerListStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ black, theme }) => ({
 padding : "16px 25px",
 display : "flex",
 alignItems : 'center',
 gap : '10px',
 "& > :nth-child(2)": {
  color : theme.palette.primary.dark
},
}));

export const FooterIconsBorderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme }) => ({
  borderLeft: "1px solid #454545",
  borderTop: "1px solid #454545",
  borderBottom: "1px solid #454545",
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&>svg": {
   fill:
      theme.palette.mode === "dark" ? "#ffffff" : theme.palette.vidhiRed.main,
  },
  "&:hover": {
    backgroundColor: theme.palette.vidhiRed.lightest,
  },
  "&:last-child": {
    // borderRight : '1px solid #454545',
  },
  [theme.breakpoints.down('sm')]:{
    width: "30px",
    height: "35px",
  },
}));

export const SocaiIconsRedBorder = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme }) => ({
  borderLeft: "1px solid #DC002D",
  borderTop: "1px solid #DC002D",
  borderBottom: "1px solid #DC002D",
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&>svg": {
    color:
      theme.palette.mode === "light" ? theme.palette.vidhiRed.main : "#ffffff",
    fill:
      theme.palette.mode === "light" ? theme.palette.vidhiRed.main : "#ffffff",
  },
}));

export const FilterStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ isFilterApplied, theme }) => ({
  cursor: "pointer",
  borderRadius: "4px",
  border:
    theme.palette.mode === "light" ? "2px solid #DC002D" : "2px solid #ffffff",
  color: theme.palette.mode === "light" ? "#DC002D" : "#ffffff",
  textTransform: "uppercase",
  // padding: "0.5rem 1rem",
  padding: "8px 16px",
  gap: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...(isFilterApplied && {
    backgroundColor: "#DC002D",
    color: "#ffffff",
  }),
  ...(theme.palette.mode === "dark" && {
    "&:hover": {
      border: `2px solid ${theme.palette.vidhiRed.light}`,
      color: theme.palette.vidhiRed.light,
    },
  }),
}));

export const TabsStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme }) => ({
  backgroundColor: theme.palette.primary.light,
  border:
    theme.palette.mode === "dark"
      ? `1px solid ${theme.palette.background.default}`
      : `1px solid ${theme.palette.common.black}`,
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  height: "40px",
  gap: "8px",
  flex: 1,
  paddingLeft: "16px",
  "&>svg": {
    fill: theme.palette.text.primary,
  },
  "&:hover": {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.vidhiRed.light
        : theme.palette.vidhiRed.main,
    "&>p,&>svg": {
      ...(theme.palette.mode === "dark"
        ? {
            color: theme.palette.vidhiRed.light,
            fill: theme.palette.vidhiRed.light,
          }
        : {
            color: theme.palette.vidhiRed.main,
            fill: theme.palette.vidhiRed.main,
          }),
    },
  },
}));

export const TagsPAStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme }) => ({
  backgroundColor: "#F5DDDB",
  width: "max-content",
  borderRadius: "40px",
  padding: "10px",
  color: "#DC002D",
}));

export const BoxDateStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ homePage, theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  border: `1px solid #999999`,
  borderRadius: "4px",
  padding: "6px 10px",
  padding: "10px",
  color: "#555555",
  cursor: "pointer",
  backgroundColor: theme.palette.grey[300],
  ...(homePage && {
    height: "44px",
    backgroundColor: theme.palette.grey[300],
  }),
  // border : `1px solid ${theme.palette.primary.main}`,
}));

export const SearchBarStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme }) => ({
  [theme.breakpoints.up("sm")]:{
    width: "300px",
    maxWidth: "300px",
  },
  [theme.breakpoints.down('sm')]:{
    maxWidth : "250px",
    width : "auto"
  },
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.primary.light,
  // backgroundColor : theme.palette.secondary.searchbar,
  borderRadius: "4px",
  // border : `1px solid ${colors.LightGray}`,
  padding: "5px",
  color: theme.palette.secondary.light,
  "&>svg": {
    marginLeft: "4px",
  },
  ...(success && {
    backgroundColor: theme.palette.secondary.searchbar,
  }),
}));

export const TextUpperCase = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme }) => ({
  textTransform: "uppercase",
  letterSpacing: "1.3px",
}));

export const ButtonPrimaryWithProp = styled(Button, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme, round }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Lato",
  fontWeight: 600,
  backgroundColor: "#DC002D",
  borderRadius: "4px",
  height: "48px",
  color: "#ffff",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#FF8278",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    backgroundColor: "green",
  },
  ...(success && {
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "black",
    },
  }),

  ...(round && {
    borderRadius: "20px",
  }),
}));

export const StatusButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme, round }) => ({
  boxShadow: "none",
  // textTransform: "none",
  fontSize: "12px",
  fontFamily: "Lato",
  fontWeight: 400,
  backgroundColor: "#0E7B201F",
  borderRadius: "2px",
  height: "24px",
  color: "#0E7B20",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#0E7B201F",
  },
  ...(success && {
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "black",
    },
  }),

  ...(round && {
    borderRadius: "20px",
  }),
}));

export const StatusBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, theme, round }) => ({
  borderRadius: "2px",
  textTransform: "uppercase",
  padding: "2px 8px",
  fontSize: "12px",
  fontFamily: "Lato",
  width: "72px",
  height: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const FieldStyled = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "success",
})(({ success, empty, invalid, theme }) => ({
  "& .MuiOutlinedInput-root": {
    border:
      theme.palette.mode === "dark"
        ? `1px solid ${theme.palette.secondary.main}`
        : "1px solid #D0D5DD",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.secondary.main : "#ffffff",

    "& fieldset": {
      border: "none",
    },
    "& input": {
      padding: "12px",
      border: "none",
      boxShadow: "none",
      //  backgroundColor : theme.palette.background.default,
    },
    // "&.Mui-focused": {
    //   border : "none"
    // },
    // ...(empty && {
    //   border:  `1px solid ${theme.palette.vidhiRed.main}`,
    //    }),

    // ...(invalid && {
    //     border:  `1px solid ${theme.palette.vidhiRed.main}`,
    //      })
  },
}));

export const DialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "16px",
    backgroundColor: "khaki",
  },
  "& .MuiDialogContent-root": {
    padding: "1rem",
  },
  "& .MuiDialogActions-root": {
    padding: "1rem",
  },
}));

export const UpdatesHeadingGrid = styled(Grid)(({ theme }) => ({
  // margin : "16px 0px",
  paddingLeft: "10px",
  justifyContent: "space-between",
  alignItems: "flex-start",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    display: "block",
    top: "3px",
    left: "0px",
    width: "4px",
    height: "16px",
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const PaginationContainerStyled = styled(Grid)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#555555" : theme.palette.common.white,
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  width: "98%",
  margin: "auto",
  [theme.breakpoints.down("sm")]:{
    "& > :first-child":{
      paddingLeft : "16px" ,
      "@media (max-width: 448px)" : {
        marginTop : "10px"
      }
  }
  }
  
}));

export const StyledDrawer = styled(Box)(({ theme, open }) => ({
  width: open ? "300px" : "0",
  minWidth: open ? "300px" : "0",
  height: "100%",
  background:
    open
      ? "linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.10) 100%))"
      : "none", // Apply the background only when the drawer is open
  backdropFilter: open ? "blur(20px)" : "none", 
  position: "absolute",
  overflow: "hidden",
  top: 0,
  left: 0,
  transition: "0.4s ease",
  zIndex: 2,
  pointerEvents: open ? "auto" : "none",
}));

export const MenuBoxStyled = styled(Box)(({ theme, open }) => ({
  position: "absolute",
  top: 0,
  lett: "60px",
  padding: "8px 16px",
  borderRadius: "0px 0px 20px 0px",
  background:
    "linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.10) 100%)",
  backdropFilter: "blur(20px)",
  cursor: "pointer",
  zIndex: open ? 1 : 0,
}));

export const MapfilterBackIcon = styled(Box)(({ theme, open }) => ({
  backgroundColor: theme.palette.vidhiRed.main,
  width:  open ? "22px" : '0px',
  height: open ? "22px" : '0px',
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 16,
  right: 16,
  "&>svg": {
    color: "#ffffff",
    fontSize: "18px",
  },
}));

export const SocialIconsBox = styled(Box)(({ theme, open }) => ({
  display: "flex",
  justifyContent: "center",
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: "10px",
  width: "25%",
  padding: "10px 10px",
  cursor: "pointer",
  "&>svg": {
    color: theme.palette.primary.dark,
  },
}));

export const ChipStyled = styled(Box)(({ theme, selected }) => ({
  // backgroundColor: theme.palette.background.default,
  backgroundColor: "#ffffff",
  color : "#000000",
  padding: "1px 6px",
  // borderRadius : '4px',
  display: "flex",
  alignItems: "center",
  flexWrap : "nowrap",
  height: "37px",
  cursor: "pointer",
  borderRadius : '2px',
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
  "&>p": {
    fontSize: "14px",
  },
  ...(selected && {
    backgroundColor: theme.palette.vidhiRed.light,
    color: theme.palette.mode === "light" && "#ffff",
  }),
}));

export const MyBox = styled(Box)(({ theme, column, center }) => ({
  display: "flex",
  alignItems: "center",
  ...(center && {
    justifyContent: "center",
  }),
  ...(column && {
    flexDirection: "column",
    alignItems: "flex_start",
  }),
}));

export const PaDetailBoxStyled = styled(Box)(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  paddingTop: "20px",
  paddingLeft: "20px",
  paddingRight: "10px",
  paddingBottom: "20px",
  // cursor : 'pointer',
  // "&>svg":{
  //   color : theme.palette.primary.dark,

  // }
}));

export const PATypeLabelStyled = styled(Box)(({ theme, clickable }) => ({
  // border : theme.palette.mode === "dark" ? `1px solid ${theme.palette.background.default}` : `1px solid ${theme.palette.common.black}` ,
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: "4px",
  padding: "6px 10px",
  ...(clickable && {
    cursor: "pointer",
  }),
}));

export const DateBoxStyled = styled(Box)(({ theme, clickable }) => ({
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: "4px",
  padding: "2px 4px",
}));

export const BillDateBoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "flex-start",
  // border : `1px solid ${theme.palette.primary.dark}`,
  // borderRadius : '8px',
  padding: "16px 0px 16px 16px",
  // minWidth : '250px',
}));

export const PAStateLabelStyled = styled(Box)(({ theme, lightPink }) => ({
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: "4px",
  padding: "6px 10px",
  cursor: "pointer",
  // ...(!lightPink && {
  //   cursor : 'pointer'
  // }),
  ...(lightPink && {
    border: `1px solid ${theme.palette.vidhiRed.light}`,
  }),
}));
