
import { put, call } from "redux-saga/effects";
import { performGetRequest } from "../../services/apiServices/AxiosUtils";
import { showSnackbarAction } from "../actions/commonActions";


//-----------------------------------------------------------------------------------

export const getNbwlFilterListData = function* getNbwlFilterListData(action) {

    yield put({type : "SHOW_LOADER"})
    const path = '/list/nbwl_filters_list';  
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ getNbwlFilterListData Response =======",res);
      if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res?.data?.data)
        yield put({type : "HIDE_LOADER"})
      } else if(res.data.status === "error"){
        yield put({type : "HIDE_LOADER"})
        }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
      yield put(showSnackbarAction("server error!"))
        console.log("@@@ getNbwlFilterListData API error ========", error);
      }
  };
  
  
  //-----------------------------------------------------------------------------------


  export const getNbwlTableData = function* getNbwlTableData(action) {
    yield put({type : "SHOW_LOADER"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getNbwlTableData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
      } else if(res.data.status === "error"){
        yield put({type : "HIDE_LOADER"})
        }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
      yield put(showSnackbarAction("server error!"))
        console.log("@@@ getNbwlTableData API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------


  export const getHomePageData = function* getHomePageData(action) {
    yield put({type : "SHOW_LOADER"})
    const path = '/contents/home_page_data';    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getHomePageData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res?.data)
        yield put({type : "HIDE_LOADER"})
      } else if(res.data.status === "error"){
        yield put({type : "HIDE_LOADER"})
        }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getHomePageData API error ========", error);
      }
  };
 
  //-----------------------------------------------------------------------------------  

  export const getHomePageMapData = function* getHomePageMapData(action) {
    yield put({type : "SHOW_LOADER_MAP"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getHomePageMapData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER_MAP"})
        if(res?.data?.data?.length < 1){
          yield put(showSnackbarAction("No Kml file found"))
}
      } else if(res.data.status === "error"){
        yield put({type : "HIDE_LOADER_MAP"})
        }
    } catch (error) {
      yield put({type : "HIDE_LOADER_MAP"})
        console.log("@@@ getHomePageMapData API error ========", error);
      }
  };


    //-----------------------------------------------------------------------------------  

    export const getMeetingDateInfo = function* getMeetingDateInfo(action) {
      yield put({type : "SHOW_LOADER"})
      const path = action.path;    
       try {
        const res = yield call(performGetRequest, path);
        console.log(
          "@@@ user getHomePageMapData  Response =======",res);
          
       if (res !== undefined && res.data.status === "success") {
          yield action.successCallBack(res)
          yield put({type : "HIDE_LOADER"})
        } else if(res.data.status === "error"){
          yield put({type : "HIDE_LOADER"})
          }
      } catch (error) {
        yield put({type : "HIDE_LOADER"})
          console.log("@@@ getHomePageMapData API error ========", error);
        }
    };

//-----------------------------------------------------------------------------------  



export const getGlobalSearchInfo = function* getGlobalSearchInfo(action) {
  yield put({type : "SHOW_LOADER"})
  const path = action.path;    
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ user getGlobalSearchInfo  Response =======",res);
      
   if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getGlobalSearchInfo API error ========", error);
    }
};

//-----------------------------------------------------------------------------------  
