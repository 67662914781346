// pdfUtils.js
export const showPDF = (pdfUrl, dispatch, message) => {
    if (!pdfUrl) {
      showSnackbar(message, dispatch);
      return;
    }
    // for any type of link
    window.open(pdfUrl, "_blank");
  };
  
  export const showSnackbar = (message, dispatch) => {
    try {
      dispatch({
        type: "SHOW_SNACKBAR",
        message: message,
      });
    } catch (error) {
      console.error("Failed to dispatch Snackbar:", error);
      // Handle the error appropriately, e.g., log it or show a generic error message.
    }
  };
  