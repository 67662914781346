import { Grid, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LinkBar from "../../components/LinkBar/LinkBar";
import Loader from "../../components/Loader/Loader";

import {
  ButtonPrimary,
  Dot,
  MyBox,
  UnderlinedTypography,
} from "../../components/StyledComponents/StyledComponents";


function useQuery() {
  const { search } = useLocation();
  console.log("uselocation(0",useLocation())
  console.log("serach", search);

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SearchGlobal = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const loader = useSelector( (state) => state.commonReducer.showLoader)
  const query = useQuery()
  const searchString = query.get("s")
  console.log("query",query)
  console.log("string",searchString)
//   const { state } = useLocation();
//   const { id } = state;
//   console.log("state", id);
  const isDark = theme.palette.mode == "dark";
  
  const [emptyResult,setEmptyResult] =useState("")
  const [questions, setQuestions] = useState([])
  const [reports, setReports] = useState([])
  const [bills, setBills] = useState([])
  const [protectedArea, setProtectedArea] = useState([])
  const [esz, setEsz] = useState([])
  const [nbwl, setNbwl] = useState([])


  useEffect(() => {
    getGlobalSearchData()
  },[searchString]);

  
  const getGlobalSearchData = () => {
    let path = `/home/global_search?text=${searchString}`
    dispatch({
      type: "GLOBAL_SEARCH",
      path : path,
      successCallBack: successCallBack,
    });
  };


  const successCallBack = (data) => {
    console.log("daata......",data)
    setQuestions(data?.result?.questions)
    setReports(data?.result?.reports)
    setBills(data?.result?.bills)
    setProtectedArea(data?.result?.protected_area)
    setEsz(data?.result?.ecosensitve_zone)
    setNbwl(data?.result?.nbwl)
    let isEmpty = areAllArraysEmpty(data?.result)  //function to check is all data are empty
    setEmptyResult(isEmpty)
  }

  const areAllArraysEmpty = (result) => {
    for (const key in result) {
      if (Array.isArray(result[key]) && result[key].length > 0) {
        return false; // If any array has a length greater than 0, return false
      }
    }
    return true; // If all arrays have a length of 0, return true

    
    // return Object.values(result).every(array => Array.isArray(array) && array.length === 0);
  
  
   }  

 
  return (
    <Grid container sx={{backgroundColor : isDark ? theme.palette.common.white : theme.palette.grey[100], position :'relative', zIndex : 2 }}>
      <LinkBar title={`You Searched For ${searchString}`} />

      {
        loader ? <MyBox sx={{width : "100%", height : '50vh'}}><Loader /></MyBox> : (
          <Grid
          container
          sx={{
            p: ["20px", "20px", "32px", "0px", "0px"],
          
          }}
         
        >
        <Grid item xs={12} container sx={{mb : '20px', gap :'5px'}}>
        <Typography variant="h2" sx={{color : '#AAAAAA'}}>
                Results for
        </Typography>
        <Typography variant="h2">
        &ldquo;{searchString}&rdquo;
        </Typography>
        </Grid>
 

      {
        emptyResult && (
          <Typography variant="h6" sx={{my : "15vh"}}>We didn’t find any search matching your selection.</Typography>
        )
      }

        
        {/* Questions links */}
        {
          questions?.length > 0 && (
            <Grid container sx={{borderBottom : '1px solid #AAAAAA', py : '30px'}}>
            <Grid item xs={12} sm={3} md={3} xl={3} lg={3}>
            <Typography variant="h3">
                    Questions
            </Typography>
            </Grid>
            <Grid item xs={12} sm={9} md={9} xl={9} lg={9} sx={{mt : ["16px",0,0,0,0]}}>
      
            <Grid container direction="column" sx={{gap : "16px"}}>
              {
                questions?.map( item => (
                  <MyBox sx={{gap : '12px'}}>
                    <Dot black/>
                    <UnderlinedTypography variant="subheading2" color="primary.dark">{item?.title}</UnderlinedTypography>
                  </MyBox>
      
                ))
              }
            </Grid>
             </Grid>
         </Grid>
          )
        }
   
       {/* Reports links */}
       {
        reports?.length > 0 && ( <Grid container sx={{borderBottom : '1px solid #AAAAAA', py : '30px'}}>
        <Grid item xs={12} sm={3} md={3} xl={3} lg={3}>
        <Typography variant="h3">
                Reports
        </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} xl={9} lg={9} sx={{mt : ["16px",0,0,0,0]}}>
  
        <Grid container direction="column" sx={{gap : "16px"}}>
          {
            reports?.map( item => (
              <MyBox sx={{gap : '12px'}}>
                <Dot black/>
                <UnderlinedTypography 
                onClick={()=>navigate("/report", {state : {id : item?.id}})}
                variant="subheading2" color="primary.dark">{item?.title}</UnderlinedTypography>
              </MyBox>
  
            ))
          }
        </Grid>
         </Grid>
       </Grid>)
       }
       
              
          {/* Bills links */}
         {
          bills?.length > 0 && (
            <Grid container sx={{borderBottom : '1px solid #AAAAAA', py : '30px'}}>
        <Grid item xs={12} sm={3} md={3} xl={3} lg={3}>
        <Typography variant="h3">
                Bills
        </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} xl={9} lg={9} sx={{mt : ["16px",0,0,0,0]}}>
  
        <Grid container direction="column" sx={{gap : "16px"}}>
          {
            bills?.map( item => (
              <MyBox key={item?.id} sx={{gap : '12px'}}>
                <Dot black/>
                <UnderlinedTypography
                onClick={()=>navigate("/bill", {state : {id : item?.id}})}
                variant="subheading2" color="primary.dark">{item?.title}</UnderlinedTypography>
              </MyBox>
  
            ))
          }
        </Grid>
        </Grid>
       </Grid>
          )
         }

         {/* pa links */}
        {
          protectedArea?.length > 0 && (
            <Grid container sx={{borderBottom : '1px solid #AAAAAA', py : '30px'}}>
        <Grid item xs={12} sm={3} md={3} xl={3} lg={3}>
        <Typography variant="h3">
                Protected Areas
        </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} xl={9} lg={9} sx={{mt : ["16px",0,0,0,0]}}>
  
        <Grid container direction="column" sx={{gap : "16px"}}>
          {
            protectedArea?.map( item => (
              <MyBox key={item?.id} sx={{gap : '12px'}}>
                <Dot black/>
                <UnderlinedTypography 
                onClick={()=>navigate("/paInformation", {state : {  paID : item?.id}})} 
                variant="subheading2" color="primary.dark">{item?.name}</UnderlinedTypography>
              </MyBox>
  
            ))
          }
        </Grid>
        </Grid>
       </Grid>
          )
         }

          {/* Esz links */}
         {
          esz?.length > 0 && (
            <Grid container sx={{borderBottom : '1px solid #AAAAAA', py : '30px'}}>
        <Grid item xs={12} sm={3} md={3} xl={3} lg={3}>
        <Typography variant="h3">
                EcoSensitive Zone
        </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} xl={9} lg={9} sx={{mt : ["16px",0,0,0,0]}}>
  
        <Grid container direction="column" sx={{gap : "16px"}}>
          {
            esz?.map( item => (
              <MyBox key={item?.id} sx={{gap : '12px'}}>
                <Dot black/>
                <UnderlinedTypography
                 onClick={()=>navigate("/meeting", { state : { id : item?.id, type : "COMMITTE"} })}
                variant="subheading2" color="primary.dark">{item?.title}</UnderlinedTypography>
              </MyBox>
  
            ))
          }
        </Grid>
        </Grid>
       </Grid>
          )
         }
        
       
       {/* Nbwl links */}
        {
          nbwl?.length > 0 && (
            <Grid container sx={{borderBottom : '1px solid #AAAAAA', py : '30px'}}>
        <Grid item xs={12} sm={3} md={3} xl={3} lg={3}>
        <Typography variant="h3">
                NBWL
        </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} xl={9} lg={9} sx={{mt : ["16px",0,0,0,0]}}>
  
        <Grid container direction="column" sx={{gap : "16px"}}>
          {
            nbwl?.map( item => (
              <MyBox key={item?.id} sx={{gap : '12px'}}>
                <Dot black/>
                <UnderlinedTypography
                onClick={()=>navigate("/meeting", { state : { id : item?.id, type : "NBWL"} })}
                variant="subheading2" color="primary.dark">{item?.title}</UnderlinedTypography>
              </MyBox>
  
            ))
          }
        </Grid>
        </Grid>
       </Grid>
          )
         }
        
     
        </Grid>
        )
      }

      

  
    </Grid>
  );
};

export default SearchGlobal;
