import React, { useEffect } from "react";
import { GoogleMap, KmlLayer, useJsApiLoader } from "@react-google-maps/api";
import "./mapStyle.css"

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 22.3511,
  lng: 78.6677,
};


const HomeMapComponent = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCwv6RWio5eJBZ5T9mEJfAa1ysutFbDpz0",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    map.setOptions({
      zoom : 1,
      mapTypeControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER, // Set map type control position
      },
      mapTypeIds: ["roadmap","satellite",  "terrain", "hybrid"] 
     });

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function generateRandom() {
    return Math.random() * 10000000000000000;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }


  return  (
    <GoogleMap
      mapContainerStyle={containerStyle}
     center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      >
     
    {props.data &&
        props.data.map((urlLink, index) => {
          return <KmlLayer url={urlLink} key={`kmlLayer-${index}`} />;
        })}
    </GoogleMap>
 ) 
};

export default React.memo(HomeMapComponent);
