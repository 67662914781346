import { Grid, Typography, Box, useTheme } from '@mui/material'
import React from 'react'
import recentPb from "../../assets/Images/recentpb.jpg"
import recentPub from "../../assets/Images/recentPub.png"
import Card from '../../components/Card/Card'

// import Swiper core and required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import './custom.css'
import Loader from '../../components/Loader/Loader'

const RecentPublications = ({data, loader}) => {

  const theme = useTheme()

  // const handleResponsive = () => {
  //    if([theme.breakpoints.down('md')]){
  //     return 2
  //    }
  //    if([theme.breakpoints.down('sm')]){
  //     return 1
  //    }
  //    if([theme.breakpoints.up("md")]){
  //     return 3
  //    }
  //    if([theme.breakpoints.up("xl")]){
  //     return 4
  //    }
    
  // }
  return (
    <Grid
    item
    xs={12}
    container
    alignContent="flex-start"
    sx={{ 
       padding : '32px',
      //  minHeight : 550,
       backgroundColor : (t) => t.palette.mode === 'dark' ? t.palette.common.white : t.palette.grey[50]
    }}
    >
      
      <Grid item xs={12}>
      <Typography 
      color="text.primary"
      align='center'
      variant='h4'
      sx={{letterSpacing : theme.letterSpacing.three, mb : 4}}
      >
          Recent Publications
          </Typography>
    
      </Grid>

      {
        loader ? (<Loader /> ) : (
          <Grid item xs={12}>
          <Swiper
             // install Swiper modules
             modules={[Autoplay, Pagination ]}
             spaceBetween={50}
             slidesPerView={3}
             autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              }}
            breakpoints={{
              250:{
                slidesPerView : 1,
              },
              600: {
                slidesPerView: 2,
              },
              960: {
                slidesPerView: 3,
              },
              1920 :{
                slidesPerView : 4
              },
              
              
            }}
            // pagination={{clickable : true}}
            className="mySwiper"
           >
             {
              data?.map( item => (
                <SwiperSlide key={item}>
                 <Card
                item={item}
                title={item?.title}
                subtitle={item?.small_detail}
                // cardImg={item?.thumbnail_detail?.base_url + item?.thumbnail_detail?.link}
                cardImg={recentPub}
                // summary={item?.small_detail}
                date={item?.date}
                to="/publication"
                />
          </SwiperSlide>
               
              ))
    
            }
          
        
          
        </Swiper>
         
          </Grid>
        )
      }

        

    </Grid>
  )
}

export default RecentPublications