import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// export const Data = [
//     {
//       id: 1,
//       year: 2016,
//       userGain: 80000,
//       userLost: 823
//     },
//     {
//       id: 2,
//       year: 2017,
//       userGain: 45677,
//       userLost: 345
//     },
//     {
//       id: 3,
//       year: 2018,
//       userGain: 78888,
//       userLost: 555
//     },
//     {
//       id: 4,
//       year: 2019,
//       userGain: 90000,
//       userLost: 4555
//     },
//     {
//       id: 5,
//       year: 2020,
//       userGain: 43000,
//       userLost: 234
//     },
//     {
//       id: 6,
//       year: 2021,
//       userGain: 80000,
//       userLost: 134
//     }
//   ];
  

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display : false,
      position: 'left',
    },
    title: {
      display: false,
      text: 'Questions Bar Chart',
    },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data1 = {
  //   labels,
  //   datasets: [
    //     {
      //       label: 'Dataset 1',
      //       data: data.map((data) => data.questions),
      //       borderColor: 'transparent',
      //       backgroundColor: '#DC002D',
      //     },
      //     // {
        //     //   label: 'Dataset 2',
        //     //   data: Data.map((data) => data.userGain),
        //     //   borderColor: 'rgb(53, 162, 235)',
        //     //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //     // },
        //   ],
        // };
        
// const labels = ['0', '200', '400', '600', '800', '1000', '1200','1400','1600','1800'];
// const labels = ['0', '10', '20', '30', '40', '50', '60','70','80','90'];
const range = ['0', '1', '2', '3', '4', '5', '6','7','8','9','10'];

export default function Chart({data}) {
  // const questions = data.map((data) => data.questions)
  // const labels =  ['1', '1', '2', '3', '4', '1', '6','1','1','9','10',"2",'1'];
  const labels =  data.map(item => item.state);
  const range = data.map( item => item.questions)
  const data2 ={
   labels,
   datasets: [
    {
      data : range.map( label => label),
      // data: data.map((data) => data.questions),
      borderColor: 'transparent',
      backgroundColor: '#DC002D',
    },
    // {
    //   label: 'Dataset 2',
    //   data: questions.map((data) => data),
    //   borderColor: 'rgb(53, 162, 235)',
    //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
    // },
  ],

  }
  return <Bar style={{width : "20%"}} options={options} data={data2} />;
}



