import React, {useState, useEffect}  from 'react'
import {
  Grid,
  Box,
  InputBase,
  Typography,
  Dialog,
  Divider,
 DialogActions,
  FormControl,
  useTheme,
  useMediaQuery
} from "@mui/material";import LinkBar from '../../components/LinkBar/LinkBar'
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';


import { SearchBarStyled, FilterStyled,ButtonOutlined, ButtonPrimary, MyBox } from '../../components/StyledComponents/StyledComponents'

import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card/Card';
import CloseIcon from "@mui/icons-material/Close";
import InfiniteScroll from "react-infinite-scroll-component";
import * as CONST from '../../utils/Contants'
import { showSnackbar } from "../../utils/PdfUtils";
import axios from "axios";




import { useDispatch, useSelector} from 'react-redux';
import Loader from '../../components/Loader/Loader';
import NoDataFoundComponent from '../../components/NoDataFoundUI/NoDataFound'
import MySelectMultiple from '../../components/Select/MySelectMultiple';
import { handleArrayQueryParam } from '../../utils/StatusStyle';


const AllPublications = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const loader = useSelector((state) => state.commonReducer.showLoader);

  let isXs = false;
  let isSm = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));
  isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [openFilter, setOpenFilter] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);

  const [hasMore, setHasMore] = useState(true);
  const [pageReset, setPageReset] = React.useState(false);
  const [scrollLoader, setScrollLoader] = useState(false)


  const [allPublications, setAllPublications] = useState([]);

  //states for holding filter's item's data Listing
  const [publicationThemeList, setPublicationThemeList] = useState([]);
  const [publicationSubjectList, setPublicationSubjectList] = useState([]);
 

  //states for holding filter's item's value
  const [themes, setThemes] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    getFilterListData()
   
  }, []); 

  //This effect will run when component mounts
  useEffect(() => {
    console.log("useEffect single",page)
    fetchMoreData(page)
  }, []);

  useEffect(() => {
    console.log("useEffect",page)
    if(isFilterApplied){
    console.log("useEffect isFilter APplied true and page is also changing useEffect and page",page)
       fetchMoreData(page)
    }
    if(pageReset){
      fetchMoreData(page)
     }
  }, [page, isFilterApplied, pageReset]);



  
  const successCallBackScroll = (res) => {
    setScrollLoader(false)
    if(res?.data?.data.length < 1){
      setHasMore(false)
      return
    }
    setPageReset(false)
    let data = res?.data?.data;
    setAllPublications((pub) => [...pub, ...data]);
    setPage((p) => p + 1);
  }

  const fetchMoreData = async () => {
    setScrollLoader(true)
    let path = `${CONST.BASE_URL}/contents/publications?less_details=true&page=${page}&per_page=10`;
    if(isFilterApplied){
     let isSearching = false;
    if(subjects.length > 0){
      isSearching = true
      path = path + handleArrayQueryParam(subjects, "sub_themes_ids[]")
    }
    if(themes.length > 0){
      isSearching = true
      path = path + handleArrayQueryParam(themes,"themes_ids[]" )
    }
   
    if(isSearching){
      path = path + "&search=true"
    }
    }
    
    try {
      await axios.get(path)
      .then(res => {
          successCallBackScroll(res)
        });
    } catch (error) {
      setScrollLoader(false)
      showSnackbar("Server Error!",dispatch)
    }
       
    }

 

  

  const getFilterListData = () => {
    dispatch({
      type: "PUBLICATIONS_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
    });
  };

 




   const successCallBackFilterListData = (data) => {
    setPublicationThemeList(data?.theme)
    setPublicationSubjectList(data?.subject)
    };

  
 


  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const handleFilterApply = () => {
    setAllPublications([])
    setPage(1)
    setIsFilterApplied(true)
    setOpenFilter(false)
  };

  const handleFilterReset = () => {
    setSubjects([])
    setThemes([])
    setIsFilterApplied(false) 
    setOpenFilter(false)
    setAllPublications([])
    setPage(1)
    setHasMore(true)
    setPageReset(true)
    
  };

  const handleChangePublicationThemes = (event) => {
    const {
      target: { value },
    } = event;
    setThemes(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangePublicationSubjects = (event) => {
    const {
      target: { value },
    } = event;
    setSubjects(typeof value === "string" ? value.split(",") : value);
  };





  const renderNoDatafound = () => {
      return <NoDataFoundComponent title={"No Publications Found"} data={allPublications} searchValue={searchValue} loader={loader} />
  };

  return (
    <>
    <Grid
    item 
    container
    sx={{backgroundColor : (t)=> t.palette.mode === "dark" ? t.palette.background.default : t.palette.grey[100]
  }}
    >
     <LinkBar title="All Publications"/>
     

     {/* first line */}
     <Grid item xs={12} container 
         sx={{
          px: isXs ? "16px" : '32px',
          my: "1.5rem",
          justifyContent: "space-between",
          alignItems: "center",
          rowGap : "16px"
        }}
        >
       <Typography
       sx={{width : isXs ? "100%" : "auto"}}
       variant='h2'>All Publications</Typography>
       <Box 
     
       >
       <SearchBarStyled>
         <SearchIcon />
         <InputBase 
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search here"
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={(e) => setSearchValue(e.target.value)}
          
          />
          </SearchBarStyled>
       </Box>
       {
              isXs ? (
                isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}}/>)
              )  : (
                <FilterStyled isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
              )
            }

      </Grid>

        {/* card section */}

       {
        loader ? <MyBox sx={{width : 1, height : "50vh"}}><Loader /></MyBox> : (
          <>
           <Grid item xs={12}>
           <InfiniteScroll
          dataLength={allPublications?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4 style={{ textAlign: "center", display : scrollLoader ? "block" : "none" }}>{}Loading...</h4>}
          
        >
      <Box
       sx= {{
        display : 'grid',
        gridTemplateColumns : { xs : 'repeat(1,1fr)', sm : 'repeat(2,1fr)',  md : 'repeat(3,1fr)', lg : 'repeat(4,1fr)', xl : 'repeat(4,1fr)'},
        gap : '50px 50px',
        gridGap : '50px 50px',
        px : '32px',
        pb : '32px',
        boxSizing : 'border-box'
    }}
       >

        {
          allPublications.filter((item) =>
          item?.title?.toLowerCase().includes(searchValue?.toLowerCase())
        ).map( item => (
            <Card 
            key={item?.id}
            item={item}
            title={item?.title}
            subtitle={item?.small_detail}
            cardImg={item?.thumbnail_detail?.link}
            date={item?.date}
            to="/publication"
            />
           
          ))

        }
        

       </Box>
       </InfiniteScroll>

          {renderNoDatafound()}
      </Grid>

          </>
        )
       }
      

      {/* card section end    */}
    </Grid>

      {/* Filter Dialog start here */}
      <Dialog
        fullScreen={isXs}
        open={openFilter}
        onClose={handleFilterClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: isSm ? "80vw" : "50vw",
           },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(2,1fr)",
                  lg: "repeat(3,1fr)",
                  xl: "repeat(3,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              {/* Theme/Subject*/}
              <FormControl fullWidth>
                <Typography>Themes</Typography>
                <MySelectMultiple
                  value={themes}
                  onChange={handleChangePublicationThemes}
                  menuArray={publicationThemeList}
                />
              </FormControl>

               {/* Theme/Subject*/}
               <FormControl fullWidth>
                <Typography>Subjects</Typography>
                <MySelectMultiple
                  value={subjects}
                  onChange={handleChangePublicationSubjects}
                  menuArray={publicationSubjectList}
                />
              </FormControl>

             
            
            </Box>

            

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined
            onClick={handleFilterReset}
            sx={{ textTransform: "none" }}
          >
            Reset
          </ButtonOutlined>
          <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>
  </>
  )
}

export default AllPublications
