// export const TabsArray = [
//     {value : "one", name: "All" },
//     {value : "two", name: "National Park" },
//     {value : "three", name: "Wildlife Sanctuary" },
//     {value : "four", name: "Conservation Reserve" },
//     {value : "five", name: "Community Reserve" }
//   ]

// export const TabsArray = [
//   {value : "All", name: "All" },
//   {value : "National Park", name: "National Park" },
//   {value : "Wildlife Sanctuary", name: "Wildlife Sanctuary" },
//   {value : "Conservation Reserve", name: "Conservation Reserve" },
//   {value : "Community Reserve", name: "Community Reserve" }
// ]

// export const TabsArray = [
//   {value : "all", name: "All" },
//   {value : "nationalpark", name: "National Park" },
//   {value : "wildlife", name: "Wildlife Sanctuary" },
//   {value : "conservation", name: "Conservation Reserve" },
//   {value : "community", name: "Community Reserve" }
// ]

export const TabsArray = [
  {value : "All", name: "All" },
  {value : "National Park", name: "National Park" },
  {value : "Wildlife Sanctuary", name: "Wildlife Sanctuary" },
  {value : "Conservation Reserve", name: "Conservation Reserve" },
  {value : "Community Reserve", name: "Community Reserve" }
]