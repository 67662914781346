import { Grid, Typography, useTheme } from '@mui/material'
import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonPrimary, FieldStyled } from '../StyledComponents/StyledComponents'
import * as VALIDATOR from '../../utils/validators'

const NewsLetter = () => {
  const theme = useTheme()
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);
  
  const [email, setEmail] = useState("")
  const [emptyEmailErr, setEmptyEmailErr] = useState(false)
  const [invalidEmailErr, setInvalidEmailErr] = useState(false)

  const handleSubscribe = () => {
     let isValid = true
     
    if (email.trim().length === 0) {
      setEmptyEmailErr(true);
      isValid = false;
    } else if (!VALIDATOR.isEmail(email)) {
      setEmptyEmailErr(false);
      setInvalidEmailErr(true);
      isValid = false;
    } 

    if(isValid){
      dispatch({
        type : "POST_NEWS_LETTER",
       payload : {
        email : email,
        successCallBack : successCallBack 
       }

      })
    }
  }

  const successCallBack = () => {
    setEmail("")
  }
  
  return (
    <Grid
    item
    container
    justifyContent="center"
    alignItems='center'
    sx={{width : "100%", minHeight : "156px", 
    backgroundColor : (theme) => theme.palette.primary.light, 
    borderBottom : `2px solid ${theme.palette.background.default}`,
    py : ["20px", "20px", 0 ,0,0]
  
  }}
    >

    <Grid item xs={12} sm={6} md={10}> 
     <Grid container  alignItems="center" sx={{pl : [2,0,0,0,0]}} justifyContent="center"> 
        <Grid item sx={{flexGrow : 1}}>
        <Typography variant='h3' color="text.primary" sx={{
          textAlign : ["center", "center", "left","left", "left"]
        }}>Join our newsletter</Typography>
        <Typography color="text.secondary" sx={{mt:1, textAlign : ["center", "center", "left","left", "left"]}}>We’ll send you a nice letter once per week. No spam.</Typography>
        </Grid>


        <Grid item>
         <Grid container justifyContent={"center"} >
          <FieldStyled
          variant='outlined' 
          placeholder='Enter your email'
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
          onFocus={()=>{
            setEmptyEmailErr(false)
            setInvalidEmailErr(false)
           }}
           sx={{mt : ['15px','15px', 0,0,0]}}
          />
           <ButtonPrimary sx={{mt : ['15px','15px', 0,0,0], ml :1}} onClick={handleSubscribe}>Subscribe</ButtonPrimary>

          </Grid>
           <Grid item>
           {
            emptyEmailErr && 
            <Typography variant='body2' color="vidhiRed.main">Email Required</Typography>
          }
          {
            invalidEmailErr && 
            <Typography variant='body2' color="vidhiRed.main">Invalid Email</Typography>
          }
           </Grid>
        </Grid>
        
       

         
        


        </Grid>
        </Grid>
    </Grid>
  )
}

export default NewsLetter