import { Select,  MenuItem, Typography } from '@mui/material'
import React from 'react'
import ExpandMore from "@mui/icons-material/ExpandMore";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MySelect = ({value,onChange, menuArray, mydisabled, handleDisable}) => {

  if(mydisabled){
    return (
      <Select
      value={value}
      onChange={onChange}
      renderValue={
          value !== ""
          ? undefined
          : (t) => <span style={{ color: "#555555" }}>Select</span>
      }
      IconComponent={() => (
        <ExpandMore
          sx={{ 
            color: (t) => t.palette.secondary.main,
            fontSize :'16px'
          }}
        />
      )}
      displayEmpty
      disabled={handleDisable}
      inputProps={{ "aria-label": "Without label" }}
      sx={{
        height: "44px",
        backgroundColor: (t) => t.palette.grey[300],
        "& .Mui-disabled": {
          cursor: 'not-allowed'
      }
      }}
      MenuProps={MenuProps}
      >
      <MenuItem value="clear">
      <Typography variant="body2">Clear selection</Typography>
      </MenuItem>
      {menuArray?.map((item) => (
        <MenuItem value={item?.id} key={item?.id}>
          <Typography variant="body2">{item?.name}</Typography>
        </MenuItem>
      ))}
    </Select>
    )
  }else{
    return (
      <Select
      value={value}
      onChange={onChange}
      renderValue={
          value !== ""
          ? undefined
          : (t) => <span style={{ color: "#555555" }}>Select</span>
      }
      IconComponent={() => (
        <ExpandMore
          sx={{ color: (t) => t.palette.secondary.main }}
        />
      )}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      sx={{
        height: "44px",
        backgroundColor: (t) => t.palette.grey[300],
      }}
      MenuProps={MenuProps}
    >
       {/* <MenuItem value="clear" sx={{fontStyle : 'italic'}}>
      <Typography variant="body2">Clear selection</Typography>
      </MenuItem> */}
      {
      menuArray?.map((item) => (
        <MenuItem value={item?.id} key={item?.id}>
          <Typography variant="body2">{item?.name}</Typography>
        </MenuItem>
      ))}
    </Select>
    )
  }
}

export default MySelect