import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  InputBase,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Dialog,
  Divider,
  DialogActions,
  FormControl,
  Skeleton,
  useMediaQuery,
} from "@mui/material";

import LinkBar from "../../components/LinkBar/LinkBar";
import CloseIcon from "@mui/icons-material/Close";
import { OverViewItem } from "./Styled";
import {
  ButtonOutlined,
  FilterStyled,
  SearchBarStyled,
  DialogStyled,
  ButtonPrimary,
  PaginationContainerStyled,
  MyBox,
} from "../../components/StyledComponents/StyledComponents";
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
} from "../../components/StyledComponents/StyledTable/styledTable";

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";

import ViewDataOnMap from "./ViewDataOnMap";
import ViewDataOnGraph from "./ViewDataonGraph";

import MySelect from "../../components/Select/MySelect";
import MySelectMultiple from "../../components/Select/MySelectMultiple";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import NoDataFoundComponent from "../../components/NoDataFoundUI/NoDataFound";
import MyDateRange from "../../components/DateRange/MyDateRange";
import {
  addCommasToNumber,
  handleArrayQueryParam,
  handleDateFormatforFilter,
} from "../../utils/StatusStyle";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const Questions = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const [openFilter, setOpenFilter] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [openMapDialog, setOpenMapDialog] = React.useState(false);
  const [openGraphDialog, setOpenGraphDialog] = React.useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //state for question table and overview
  const [overViewData, setOverViewData] = useState({
    noOfQuestionLastSession: "",
    noOfQuestionResponded: "",
    noOfBillIntroduced: "",
  });
  const [questionData, setQuestionData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  //states for holding filter's item's data Listing
  const [houseList, setHouseList] = useState("");
  const [sessionNumberList, setSessionNumberList] = useState("");
  const [questionTypeList, setQuestionTypeList] = useState("");
  const [questionNumberList, setQuestionNumberList] = useState("");
  const [memberOfPaList, setMemberOfPaList] = useState("");
  const [ministryList, setMinistryList] = useState("");
  const [questionThemeList, setQuestionThemeList] = useState([]);
  const [statesList, setStatesList] = useState("");

  //states for holding filter's item's value
  const [house, setHouse] = useState("");
  const [sessionNumber, setSessionNumber] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionNumber, setQuestionNumber] = useState("");
  const [memberOfPa, setMemberOfPa] = useState("");
  const [ministry, setMinistry] = useState("");
  const [questionTheme, setQuestionTheme] = useState([]);
  const [states, setStates] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  //useEffect for filter item data listing and overview data
  useEffect(() => {
    getFilterListData();
    getOverViewQuestionData();
  }, []);

  //Effect for question data
  useEffect(() => {
    if (isFilterApplied) {
      handleFilterApply();
    } else {
      getQuestionData();
    }
  }, [rowsPerPage, page]);

  const getOverViewQuestionData = () => {
    dispatch({
      type: "QUESTION_OVERVIEW_DATA",
      successCallBack: successCallBackOverViewData,
    });
  };
  const getFilterListData = () => {
    dispatch({
      type: "QUESTION_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
    });
  };

  const getQuestionData = () => {
    dispatch({
      type: "GET_QUESTION_DATA",
      successCallBack: successCallBack,
      path: `/contents/questions?page=${page + 1}&per_page=${rowsPerPage}`,
    });
  };

  const getQuestionFilteredData = (path) => {
    dispatch({
      type: "GET_QUESTION_DATA",
      successCallBack: successCallBackForFilteredData,
      path: path,
    });
  };

  const successCallBackFilterListData = (data) => {
    setHouseList(data?.house);
    setSessionNumberList(data?.sessions);
    let modifiedQuestionNumberArray = data?.question_number?.map((item) => {
      return { name: item.name, id: item.name };
    });
    setQuestionNumberList(modifiedQuestionNumberArray);
    setQuestionTypeList(data?.type);
    setMemberOfPaList(data?.member_of_parliament);
    setQuestionThemeList(data?.theme_subject);
    setMinistryList(data?.ministry);
    setStatesList(data?.states_data);
  };

  const successCallBack = (res) => {
    setQuestionData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
  };

  const successCallBackForFilteredData = (res) => {
    setQuestionData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    setIsFilterApplied(true);
    setOpenFilter(false);
  };

  const successCallBackOverViewData = (res) => {
    setOverViewData({
      noOfQuestionLastSession: res?.data?.data?.total_number_of_question,
      noOfQuestionResponded: res?.data?.data?.questions_responded_by_the_moefcc,
      noOfBillIntroduced: res?.data?.data?.bill_introduce_by_moefcc,
    });
  };

  const showPDF = (pdfUrl) => {
    if (!pdfUrl) {
      showSnackbar("No pdf found");
    }

    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank"; // Open in a new tab
      link.rel = "noopener noreferrer";
      link.download = pdfUrl;
      link.click();
    }
    //  window.open(pdfUrl, "_blank");
  };

  const showSnackbar = (message) => {
    try {
      dispatch({
        type: "SHOW_SNACKBAR",
        message: message,
      });
    } catch (error) {
      console.error("Failed to dispatch Snackbar:", error);
      // Handle the error appropriately, e.g., log it or show a generic error message.
    }
  };

  const handleChangePage = (event, newPage) => {
    console.log("new Page", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMapDialogClose = () => {
    setOpenMapDialog(false);
  };
  const handleGraphDialogClose = () => {
    setOpenGraphDialog(false);
  };

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const handleChangeQuestionThemes = (event) => {
    const {
      target: { value },
    } = event;
    setQuestionTheme(typeof value === "string" ? value.split(",") : value);
  };

  const handleFilterApply = () => {
    let path = `/contents/questions?page=${page + 1}&per_page=${rowsPerPage}`;
    let isSearching = false;
    if (house) {
      isSearching = true;
      path = path + `&house_id[]=${house}`;
    }
    if (sessionNumber) {
      isSearching = true;
      path = path + `&session_number_id[]=${sessionNumber}`;
    }
    if (questionNumber) {
      isSearching = true;
      path = path + `&question_no[]=${questionNumber}`;
    }

    if (questionType) {
      isSearching = true;
      path = path + `&type_of_question[]=${questionType}`;
    }
    if (questionTheme) {
      isSearching = true;
      path = path + handleArrayQueryParam(questionTheme, "theme_subjects_id[]");
    }
    if (memberOfPa) {
      isSearching = true;
      path = path + `&member_of_parliament_id[]=${memberOfPa}`;
    }
    if (states) {
      isSearching = true;
      path = path + `&states_id[]=${states}`;
    }
    if (ministry) {
      isSearching = true;
      path = path + `&ministry_id[]=${ministry}`;
    }
    if (dateRange[0].startDate && dateRange[0].endDate) {
      isSearching = true;
      path =
        path +
        `&question_date_start=${handleDateFormatforFilter(
          dateRange[0].startDate
        )}&question_date_end=${handleDateFormatforFilter(
          dateRange[0].endDate
        )}`;
    }
    if (isSearching) {
      path = path + "&search=true";
    }
    getQuestionFilteredData(path);
  };

  const handleFilterReset = () => {
    setHouse("");
    setSessionNumber("");
    setQuestionType("");
    setQuestionNumber("");
    setMemberOfPa("");
    setMinistry("");
    setQuestionTheme([]);
    setStates("");
    setDateRange((date) => {
      return [{ ...date[0], startDate: new Date(), endDate: null }];
    });
    setOpenFilter(false);
    setIsFilterApplied(false);
    getQuestionData(); // all questions api calling
  };

  const renderNoDatafound = () => {
    return (
      <NoDataFoundComponent
        data={questionData}
        title={"No Questions Found"}
        loader={loader}
        searchValue={searchValue}
      />
    );
    // if (questionData.length == 0 && !loader) {
    //   return (
    //     <Grid
    //       item
    //       xs={12}
    //       container
    //       justifyContent={"center"}
    //       alignContent={"center"}
    //       sx={{ height: "30vh" }}
    //     >
    //       <Typography variant="subheading1">No data found</Typography>
    //       <Typography
    //         variant="body1"
    //         align="center"
    //         sx={{ width: "100%", mt: "10px" }}
    //       >
    //         {CONST.NoDataFound}
    //       </Typography>
    //     </Grid>
    //   );
    // }
    // let filterData = questionData?.filter((item) =>
    //   item.title?.toLowerCase().includes(searchValue?.toLowerCase())
    // );
    // if (searchValue.length > 0 && filterData.length < 1) {
    //   return (
    //     <Grid
    //       item
    //       xs={12}
    //       container
    //       justifyContent={"center"}
    //       alignContent={"center"}
    //       sx={{ height: "30vh" }}
    //     >
    //       <Typography variant="subheading1">No data found</Typography>
    //       <Typography
    //         variant="body1"
    //         align="center"
    //         sx={{ width: "100%", mt: "10px" }}
    //       >
    //         Try adjusting your search or filter to find what you are looking
    //         for.
    //       </Typography>
    //     </Grid>
    //   );
    // }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRecords) : 0;

  return (
    <>
      <Grid
        item
        container
        sx={{ backgroundColor: (t) => t.palette.background.default }}
      >
        <LinkBar title="Questions" />

        <Grid container sx={{ py: "2rem", px: isXs ? "16px" : "32px" }}>
          <Typography variant="h1" color="text.primary" sx={{ mb: "1rem" }}>
            Overview of the Last Session
          </Typography>
          <Grid item container justifyContent="space-between">
            <OverViewItem item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Typography
                sx={{ fontSize: isXs ? "24px" : "48px", fontWeight: 700 }}
                color="primary.dark"
              >
                {loader ? (
                  <Skeleton />
                ) : (
                  addCommasToNumber(overViewData?.noOfQuestionLastSession) ??
                  "0"
                )}
              </Typography>
              <Typography variant="subheading2" color="text.primary">
                Total no of questions in the last session
              </Typography>
            </OverViewItem>
            <OverViewItem item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Typography
                sx={{ fontSize: isXs ? "24px" : "48px", fontWeight: 700 }}
                color="primary.dark"
              >
                {loader ? (
                  <Skeleton />
                ) : (
                  overViewData?.noOfQuestionResponded ?? "0"
                )}
              </Typography>
              <Typography variant="subheading2" color="text.primary">
                Questions responded by the MoEFCC in the last session
              </Typography>
            </OverViewItem>
            <OverViewItem item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Typography
                sx={{ fontSize: isXs ? "24px" : "48px", fontWeight: 700 }}
                color="primary.dark"
              >
                {loader ? (
                  <Skeleton />
                ) : (
                  overViewData?.noOfBillIntroduced ?? "0"
                )}
              </Typography>
              <Typography variant="subheading2" color="text.primary">
                Bill introduced by MoEFCC in the last session
              </Typography>
            </OverViewItem>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "dark"
                ? t.palette.background.default
                : theme.palette.grey[100],
          }}
        >
          <Grid
            container
            justifyContent="center"
            sx={{ px: "16px", my: "2rem" }}
          >
            <Grid item xs={12} sm={11} md={10} xl={10} lg={10}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(1,1fr)",
                    sm: "repeat(2,1fr)",
                    md: "repeat(2,1fr)",
                    lg: "repeat(2,1fr)",
                    xl: "repeat(2,1fr)",
                  },
                  gap: ["10", "10px", "20px", "30px", "30px"],
                  rowGap: "20px",
                  // boxSizing: "border-box",
                }}
              >
                <ButtonOutlined
                  onClick={() => {
                    setOpenMapDialog(true);
                  }}
                  sx={{ borderColor: theme.palette.primary.dark }}
                >
                  View questions data on map
                </ButtonOutlined>
                <ButtonOutlined
                  onClick={() => {
                    setOpenGraphDialog(true);
                  }}
                  sx={{ borderColor: theme.palette.primary.dark }}
                >
                  View questions data on graph
                </ButtonOutlined>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              px: isXs ? "16px" : "24px",
              my: "1.5rem",
              justifyContent: "space-between",
              alignItems: "center",
              rowGap: "16px",
            }}
          >
            <Typography
              sx={{ width: isXs ? "100%" : "auto" }}
              // sx={{width : ['100%', "auto", "auto", "auto", "auto"]}}
              variant="h2"
            >
              Questions
            </Typography>
            <Box
              sx={
                {
                  // display: ["none", "block", "block", "block", "block"],
                }
              }
            >
              <SearchBarStyled>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1 }}
                  placeholder="Search here"
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </SearchBarStyled>
            </Box>
            {isXs ? (
              isFilterApplied ? (
                <FilterAltIcon
                  onClick={handleFilterOpen}
                  sx={{ color: theme.palette.primary.dark }}
                />
              ) : (
                <FilterAltOutlinedIcon
                  onClick={handleFilterOpen}
                  sx={{ color: theme.palette.primary.dark }}
                />
              )
            ) : (
              <FilterStyled
                isFilterApplied={isFilterApplied}
                onClick={handleFilterOpen}
              >
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
            )}
          </Grid>
          {loader ? (
            <MyBox sx={{ height: "50vh", width: "100%" }}>
              {" "}
              <Loader />
            </MyBox>
          ) : (
            <Grid container sx={{ pl: isXs ? "16px" : "24px", pb: "1rem" }}>
              <Grid item xs={12}>
                <StyledTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>House</StyledTableCell>
                        <StyledTableCell>Session Number</StyledTableCell>
                        <StyledTableCell>Question Number</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Type</StyledTableCell>
                        <StyledTableCell>Member of Parliament</StyledTableCell>
                        <StyledTableCell>Theme/Subject</StyledTableCell>
                        <StyledTableCell>Ministry</StyledTableCell>
                        <StyledTableCell>Title</StyledTableCell>
                        {/* <StyledTableCell></StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {questionData
                        ?.filter(
                          (item) =>
                            item?.title
                              ?.toLowerCase()
                              .includes(searchValue?.toLowerCase()) ||
                            item?.house.name
                              ?.toLowerCase()
                              .includes(searchValue?.toLowerCase())
                        )
                        .map((row) => (
                          <StyledTableRow key={row?.id}>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.house?.name ?? "NA"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.session_number?.name ?? "NA"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.question_no ?? "NA"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.question_date ?? "NA"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.type_of_question ?? "NA"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "220px !important" }}
                            >
                              {row?.member_of_parliament_details?.length > 0
                                ? row?.member_of_parliament_details?.map(
                                    (item, index) => (
                                      <div key={index}>
                                        {item?.name}{" "}
                                        {row?.member_of_parliament_details
                                          ?.length -
                                          1 ===
                                        index
                                          ? ""
                                          : ", "}
                                      </div>
                                    )
                                  )
                                : "NA"}
                              </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "220px !important" }}
                            >
                              {
                                row?.theme_subjects?.length > 0 ? (row?.theme_subjects?.map( (th, index) => (
                                   <div key={index}>
                                    {th?.name}{row?.theme_subjects?.length -1 === index ? "" : ", "}
                                   </div>
                                ))) : "NA"
                            }
                              
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ minWidth: "150px !important" }}
                            >
                              {row?.ministry_details?.name ?? "NA"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{
                                minWidth: "280px !important",
                                color: "#DC002D !important",
                                cursor: "pointer",
                              }}
                              onClick={() => showPDF(row?.doc_detail?.link)}
                            >
                              {row?.title ?? "NA"}
                            </StyledTableCell>
                            {/* <StyledTableCell><MoreVertIcon /></StyledTableCell> */}
                          </StyledTableRow>
                        ))}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={10} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </Grid>

              {renderNoDatafound()}

              {/* Table Pagination starts here */}
              <Grid item xs={12}>
                <PaginationContainerStyled
                  container
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography sx={{ pl: 1 }} variant="body2">
                    {totalRecords} entries found
                  </Typography>
                  <TablePagination
                    component="div"
                    count={totalRecords}
                    page={page}
                    rowsPerPageOptions={[5, 10, 20]}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </PaginationContainerStyled>
              </Grid>
            </Grid>
          )}
        </Grid>

        {openMapDialog && (
          <ViewDataOnMap
            open={openMapDialog}
            closeMapDialog={handleMapDialogClose}
          />
        )}

        {openGraphDialog && (
          <ViewDataOnGraph
            open={openGraphDialog}
            closeGraphDialog={handleGraphDialogClose}
          />
        )}
      </Grid>

      {/* Filter Dialog start here */}
      <Dialog
        fullScreen={isXs}
        open={openFilter}
        onClose={handleFilterClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "90vw",
            // backgroundColor : 'khaki'
          },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(4,1fr)",
                  lg: "repeat(4,1fr)",
                  xl: "repeat(4,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              {/* House    */}
              <FormControl fullWidth>
                <Typography>House</Typography>
                <MySelect
                  value={house}
                  onChange={(e) => setHouse(e.target.value)}
                  menuArray={houseList}
                />
              </FormControl>

              {/* Session Number */}
              <FormControl fullWidth>
                <Typography>Session Number</Typography>
                <MySelect
                  value={sessionNumber}
                  onChange={(e) => setSessionNumber(e.target.value)}
                  menuArray={sessionNumberList}
                />
              </FormControl>

              {/* Question Number */}
              <FormControl fullWidth>
                <Typography>Question Number</Typography>
                <MySelect
                  value={questionNumber}
                  onChange={(e) => setQuestionNumber(e.target.value)}
                  menuArray={questionNumberList}
                />
              </FormControl>

              {/* Date */}
              <FormControl fullWidth>
                <Typography>Date</Typography>
                <MyDateRange
                  onChange={(item) => setDateRange([item.selection])}
                  dateRange={dateRange}
                />
                {/* <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      width: "100%",
                      height: "44px",
                      backgroundColor: (t) => t.palette.grey[300],
                    },
                  }}
                  placeholder="Select"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                /> */}
              </FormControl>

              {/* Type */}
              <FormControl fullWidth>
                <Typography>Type</Typography>
                <MySelect
                  value={questionType}
                  onChange={(e) => setQuestionType(e.target.value)}
                  menuArray={questionTypeList}
                />
              </FormControl>

              {/* Member of Parliament */}
              <FormControl fullWidth>
                <Typography>Member of Parliament</Typography>
                <MySelect
                  value={memberOfPa}
                  onChange={(e) => setMemberOfPa(e.target.value)}
                  menuArray={memberOfPaList}
                />
              </FormControl>

              {/* Theme/Subject*/}
              <FormControl fullWidth>
                <Typography>Theme/Subject</Typography>
                <MySelectMultiple
                  value={questionTheme}
                  onChange={handleChangeQuestionThemes}
                  menuArray={questionThemeList}
                />
              </FormControl>

              {/* Ministry*/}
              <FormControl fullWidth>
                <Typography>Ministry</Typography>
                <MySelect
                  value={ministry}
                  onChange={(e) => setMinistry(e.target.value)}
                  menuArray={ministryList}
                />
              </FormControl>

              {/* Title*/}
              {/* <FormControl fullWidth>
                <Typography>Title</Typography>
                <MySelect />
              </FormControl> */}

              {/* State*/}
              <FormControl fullWidth>
                <Typography>State</Typography>
                <MySelect
                  value={states}
                  onChange={(e) => setStates(e.target.value)}
                  menuArray={statesList}
                />
              </FormControl>
            </Box>

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined
            onClick={handleFilterReset}
            sx={{ textTransform: "none" }}
          >
            Reset
          </ButtonOutlined>
          <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Questions;
