import React, {useState, useRef} from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Divider } from '@mui/material';
import LoginSignUpBackground from '../../components/LoginSignUpBackground/LoginSignUpBackground';
import { ButtonPrimary, FieldStyled, LabelStyled, LinkStyled, SocialIconsBox } from '../../components/StyledComponents/StyledComponents';



import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import TwitterLogin from 'react-twitter-login';
import './loginStyle.css'

import { useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';


import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import * as VALIDATOR from '../../utils/validators'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

export default function Login() {
 const dispatch = useDispatch()
 const navigate = useNavigate();
 const loader = useSelector( (state) => state.commonReducer.showLoader)

 const [showPassword, setShowPassword] = React.useState(false);
 
 const [email, setEmail] = useState("")
const [password, setPassword] = useState("")

const [emailEmptyErr, setEmailEmptyErr] = useState(false)
const [passwordEmptyErr, setPasswordEmptyErr] = useState(false)
const [invalidEmailErr, setInvalidEmailErr] = useState(false)
// const [invalidPassErr, setInvalidPassErr] = useState(false)
 

 const [ user, setUser ] = useState([]);
 const [ profile, setProfile ] = useState({});

 const twitterLoginButtonRef = useRef(null);


React.useEffect(
  () => {
      if (user.access_token) {
          axios
              .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                  headers: {
                      Authorization: `Bearer ${user.access_token}`,
                      Accept: 'application/json'
                  }
              })
              .then((res) => {
                console.log('userProfile: ', res);
                  setProfile(res.data);
                  
                  
              })
              .catch((err) => console.log(err));
      }
  },
  [ user ]
);
     console.log("profile",profile)
     //Google login button
    const googleLogin = useGoogleLogin({
      onSuccess: (codeResponse) => setUser(codeResponse),
      onError: (error) => console.log('Login Failed:', error)
    });

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
      googleLogout();
      setProfile(null);
  };


  const { linkedInLogin } = useLinkedIn({
    clientId: '77xbyz1sl3kyqo',
    redirectUri: `${window.location.origin}`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

 // Add a separate useEffect for handleSocialLoginGoogle(1) with a dependency on profile
React.useEffect(() => {
  if (profile?.email) {
    handleSocialLoginGoogle(1);
  }
}, [profile]); 

const handleSocialLoginGoogle = (loginType) => {
   dispatch({
    type : "SOCIAL_LOGIN",
    payload : {
      loginType : loginType,
      googleEmail : profile.email,
      googleName : profile.name,
      googleId : profile.id,
    },
    successCallBack : successCallBackSocialLogin
   })
}

const successCallBackSocialLogin = (res) =>{
  VALIDATOR.setItem("isUserLoggedIn",true)
  navigateToHome()
}

const handleSocialIconBoxClick = () => {
  // Programmatically trigger the Twitter login when SocialIconsBox is clicked
  
  if (twitterLoginButtonRef.current) {
    twitterLoginButtonRef.current.handleLoginClick();
  }
  
};

const handleTwitterLogin = (authData) => {
  console.log("authData",authData);
  // You can handle the authentication data here (e.g., send it to your server).
};


const navigateToHome = () => {
  navigate("/");
};

const handleLogIn = () => {
   let isValid = true;
  if(email.trim().length === 0){
    setEmailEmptyErr(true)
    isValid = false;
  }else if(!VALIDATOR.isEmail(email)){
      setInvalidEmailErr(true)
     isValid = false;

  }

  if(password.trim().length == 0){
    setPasswordEmptyErr(true)
    isValid=false;
  }else{
     setPasswordEmptyErr(false)
  }
  
  if(isValid){
    console.log("userlogin kr rahan ian")
    dispatch({
      type : "USER_LOGIN_SAGA",
      payload : {
        email : email,
        password : password,
        navigation : navigateToHome,
      }
    })
  }


}



  return (
    
      <Grid 
      container 
      justifyContent={"center"}
      component="main" style={{width : "100vw", height : "100vh"}} >
       
            <LoginSignUpBackground />
       
            <Grid item container xs={12} sm={9} md={4} lg={4} xl={4} alignItems='center' 
            sx={{
              py : '40px',
              px : ['40px', '40px', '20px','20px','20px'],
              backgroundColor : (t) => t.palette.mode === "dark" && t.palette.primary.main
              }}>
               {
              loader ? <Loader /> : (
             <Grid container direction="column" gap={"10px"}>
             <Typography variant='h2'>Log in</Typography>
             <Typography variant='body1' sx={{mb : 1}} color="text.secondary">Welcome back</Typography>
            
             <Grid item>
              <LabelStyled variant='body2'>Email*</LabelStyled>
              <FieldStyled
              onChange={(e)=>setEmail(e.target.value)}
              placeholder='Enter your email'
              variant='outlined' 
              fullWidth
              value={email}
              onFocus={()=>{
                setEmailEmptyErr(false)
                setInvalidEmailErr(false)
              }}
              empty={emailEmptyErr}
              invalid={invalidEmailErr}
             />
             {
              emailEmptyErr && 
              <Typography variant='body2' color="vidhiRed.main">Enter Email</Typography>
             }
             {
              invalidEmailErr && 
              <Typography variant='body2' color="vidhiRed.main">Invalid Email</Typography>
             }
             </Grid>
    
             <Grid item>
              <LabelStyled variant='body2' >Password*</LabelStyled>
              <FieldStyled
              onChange={(e)=>setPassword(e.target.value)}
              placeholder='Enter your password'
              variant='outlined'
              fullWidth
              value={password}
              onFocus={()=>setPasswordEmptyErr(false)}
              empty={passwordEmptyErr}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ fontSize: "30px" }}
                    position="start"
                  >
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ?  <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
             
              />
              {
                passwordEmptyErr && 
                <Typography variant='body2' color="vidhiRed.main">Enter password</Typography>
              }
             </Grid>
    
             <Grid item container justifyContent='flex-end' alignItems='center'> 
              {/* <Box sx={{display : 'flex', alignItems:'center'}}>
                <Checkbox 
                disableRipple
                />
                <Typography variant='body2' color="text.secondary">Remember for 30 days</Typography>
              </Box> */}
               <LinkStyled to="/forgotpassword"><Typography variant='subtitle2' color="vidhiRed.light">Forgot password ?</Typography></LinkStyled>
             </Grid>
    
            
            <ButtonPrimary
            onClick={handleLogIn} 
            sx={{my : 1}}>Log in</ButtonPrimary>
    
            <Divider sx={{my : 1}}><Typography color="text.secondary">OR</Typography></Divider>
    
            <Grid container justifyContent='space-between' alignItems='center' sx={{my: 1}}>
                <SocialIconsBox onClick={()=>googleLogin()}><GoogleIcon/></SocialIconsBox>
                <SocialIconsBox  onClick={linkedInLogin}>
                  <LinkedInIcon />
                {/* <img
                onClick={linkedInLogin}
                 src={linkedin}
                 alt="Sign in with Linked In"
                 style={{ maxWidth: '180px', cursor: 'pointer' }}
                    /> */}
                </SocialIconsBox>
                <SocialIconsBox
                onClick={handleSocialIconBoxClick}
                ><TwitterIcon /></SocialIconsBox>
                <TwitterLogin
                id="twitter-btn"
                ref={twitterLoginButtonRef}
                authCallback={handleTwitterLogin}
                consumerKey={"PcN4sSQc0jYnYGRFSMa9UFKoI"}
               consumerSecret={"npBT0mDjqSJyY0DBAmbj21Q4Y5N7of6eWQtHSvQa3h5URpXh2s"}
                buttonTheme="light_short"
                className="twitter-login-button"
               />

              </Grid>
           
            <Grid container justifyContent='center' alignItems='center'>
                <Typography variant='body2' color="text.secondary">Don't have an account?</Typography>
                <LinkStyled to="/signup"><Typography variant='subtitle2' color="vidhiRed.light" ml={1}>Sign up</Typography></LinkStyled>
            </Grid>
    
    
            </Grid>
    
               )}
    
            
            </Grid>
         
        

      </Grid>
     
  );
}