
import { put, call } from "redux-saga/effects";
import { showSnackbarAction } from "../actions/commonActions";
import { performGetRequest, performPostRequest } from "../../services/apiServices/AxiosUtils";


//-----------------------------------------------------------------------------------

export const getQuestionFilterListData = function* getQuestionFilterListData(action) {

    yield put({type : "SHOW_LOADER"})
    const path = '/list/question_filters_list';   
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ getQuestionFilterListData Response =======",res);
      if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res?.data?.data)
        yield put({type : "HIDE_LOADER"})
      } 
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getQuestionFilterListData API error ========", error);
      }
  };
  
  
  //-----------------------------------------------------------------------------------


  export const getQuestionTableData = function* getNbwlTableData(action) {
    yield put({type : "SHOW_LOADER"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getQuestionTableData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
      }
      else{
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
         
      }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getQuestionTableData API error ========", error);
      }
  };

 
   //-----------------------------------------------------------------------------------

   export const getOverViewData = function* getOverViewData(action) {
    yield put({type : "SHOW_LOADER"})
    const path = "/contents/questions_static";    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getOverViewData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
      } 
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getOverViewData API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------

   export const getQuestionMapFilterListData = function* questionMapFilterListData(action) {

    yield put({type : "SHOW_LOADER_MAP"})
    const path = '/list/question_data_filters_list';   
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ getQuestionFilterListData Response =======",res);
      if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res?.data?.data)
        yield put({type : "HIDE_LOADER_MAP"})
      } 
    } catch (error) {
      yield put({type : "HIDE_LOADER_MAP"})
        console.log("@@@ getQuestionFilterListData API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------

   export const getQuestionMapData = function* questionMapData(action) {
    yield put({type : "SHOW_LOADER_MAP"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getQuestionMapData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER_MAP"})
      } 
    } catch (error) {
      yield put({type : "HIDE_LOADER_MAP"})
        console.log("@@@ getQuestionMapData API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------


   export const getAllBills = function* getAllBills(action) {
    yield put({type : "SHOW_LOADER"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getAllBills  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
      }else if(res?.data?.status === "error"){
        yield put({type : "HIDE_LOADER"})

      }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getAllBills API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------


export const getBillsFilterListData = function* getBillsFilterListData(action) {

  yield put({type : "SHOW_LOADER"})
  const path = '/list/bill_filters_list';   
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ getQuestionFilterListData Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data?.data)
      yield put({type : "HIDE_LOADER"})
    } 
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getQuestionFilterListData API error ========", error);
    }
};
   //-----------------------------------------------------------------------------------


   export const getAllReports = function* getAllReports(action) {
    yield put({type : "SHOW_LOADER"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getQuestionTableData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
      }else if(res?.data?.status === "error"){
        yield put({type : "HIDE_LOADER"})

      }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getQuestionTableData API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------

   export const getReportInfo = function* getReportInfo(action) {
    yield put({type : "SHOW_LOADER"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getQuestionTableData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
      } else if(res.data.status === "error"){
        yield put({type : "HIDE_LOADER"})
        }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getQuestionTableData API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------


export const getReportsFilterListData = function* getReportsFilterListData(action) {

  yield put({type : "SHOW_LOADER"})
  const path = '/list/report_filters_list';   
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ getQuestionFilterListData Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data?.data)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getQuestionFilterListData API error ========", error);
    }
};
   //-----------------------------------------------------------------------------------


   export const getDebatesTableData = function* getDebatesTableData(action) {
    yield put({type : "SHOW_LOADER"})
    const path = action.path;    
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ user getDebatesTableData  Response =======",res);
        
     if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res)
        yield put({type : "HIDE_LOADER"})
      } else if(res.data.status === "error"){
        yield put({type : "HIDE_LOADER"})
        }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getDebatesTableData API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------

  export const getDebatesFilterListData = function* getDebatesFilterListData(action) {

    yield put({type : "SHOW_LOADER"})
    const path = '/list/debate_filters_list';   
     try {
      const res = yield call(performGetRequest, path);
      console.log(
        "@@@ getDebatesFilterListData Response =======",res);
      if (res !== undefined && res.data.status === "success") {
        yield action.successCallBack(res?.data?.data)
        yield put({type : "HIDE_LOADER"})
      } else if(res.data.status === "error"){
        yield put({type : "HIDE_LOADER"})
        }
    } catch (error) {
      yield put({type : "HIDE_LOADER"})
        console.log("@@@ getDebatesFilterListData API error ========", error);
      }
  };

   //-----------------------------------------------------------------------------------



   export const postFeedBackForm = function* feedbackForm(action) {
    yield put({type : "SHOW_LOADER"})
    console.log("feedback form in vidhi",action.payload)
    let body = {
      "feedback": {
        "name": action.payload.name,
        "email": action.payload.email,
        "feedback_text": action.payload.feedBackText,
        "feedback_type": action.payload.feedBackTypeNumber,
        "protected_area_id": action.payload.paId  
        }
    };
  
    const path = "/feedbacks";
    try {
      const res = yield call(performPostRequest, path, body);
      console.log(
        "@@@ user login Response =======",res);
      if (res !== undefined && res.data.status === "success") {
        yield action.payload.successCallBack();
        yield put({type : "HIDE_LOADER"})
        yield put(showSnackbarAction("Your comments have been recorded. We appreciate your input."))
        
       } else if(res.data.status === "error"){
        yield put({type : "HIDE_LOADER"})
        // yield put(showSnackbarAction("Incorrect Email or password"))
       }
    } catch (error) {
      console.log("@@@ LOGIN API error ========", error);
      }
  };