import React, { useEffect, useState } from "react";
import {
  Grid,
  InputBase,
  Typography,
  Box,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  useTheme,
  Dialog,
  DialogActions,
  MenuItem,
  Select,
  Divider,
  FormControl,
  Checkbox,
  ListItemText,
  useMediaQuery
} from "@mui/material";
import LinkBar from "../../components/LinkBar/LinkBar";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { handleDateFormatforFilter, handleStatusColor, handleTextTransform } from "../../utils/StatusStyle";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import {
  FilterStyled,
  SearchBarStyled,
  StatusBox,
  PaginationContainerStyled,
  ButtonOutlined,
  ButtonPrimary,
  MyBox,
  UnderlinedTypography,
} from "../../components/StyledComponents/StyledComponents";
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
} from "../../components/StyledComponents/StyledTable/styledTable";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import MySelectMultiple from "../../components/Select/MySelectMultiple";
import MySelect from "../../components/Select/MySelect";
import * as CONST from "../../utils/Contants";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { showPDF } from "../../utils/PdfUtils";
import MyPopover from "../../components/Popover/MyPopover";
import MyDateRange from "../../components/DateRange/MyDateRange";
import MySliderforEsz from "../../components/Slider/MySliderForESZArea"


let  minValue = 0
let  maxValue = 100

const Nbwl = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);
  const navigate = useNavigate();

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));


  const [openFilter, setOpenFilter] = React.useState(false);
  const [tableSearch, setTableSearch] = useState("");

  //states for table data
  const [nbwlData, setNbwlData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [affectedAreaMinMax, setAffectedAreaMinMax] = React.useState([0, 100]);


  //State for listing filter items
  const [paTypeList, setPaTypeList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [meetingList, setMeetingList] = useState([]);
  const [projectCategoryList, setProjectCategoryList] = useState([]);
  const [projectSubCategoryList, setProjectSubCategoryList] = useState([]);
  const [diversionTypeList, setDiversionTypeList] = useState([]);
  const [decisionList, setDecisionList] = useState([]);

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  //state for holding filter item values

  const [paTypesData, setPaTypesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [meetingNumber, setMeetingNumber] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [projectCategory, setProjectCategory] = useState("");
  const [projectSubCategory, setProjectSubCategory] = useState("");
  const [diversionType, setDiversionType] = useState("");
  const [decision, setDecision] = useState("");

  //useEffect for filter item data listing
  useEffect(() => {
    getFilterListData();
  }, []);

  //Effect for nbwl data
  useEffect(() => {
    if (isFilterApplied) {
      handleFilterApply();
    } else {
      getNbwlData();
    }
  }, [rowsPerPage, page]);

  const getFilterListData = () => {
    dispatch({
      type: "NBWL_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
    });
  };

  const getNbwlData = () => {
    dispatch({
      type: "GET_NBWL_DATA",
      successCallBack: successCallBack,
      path: `/nbwls?page=${page + 1}&per_page=${rowsPerPage}`,
    });
  };

  const getNbwlFilteredData = (path) => {
    dispatch({
      type: "GET_NBWL_DATA",
      successCallBack: successCallBackforFilteredData,
      path: path,
    });
  };



  const successCallBackFilterListData = (data) => {
    minValue=data?.forest_area_affected_min;
    maxValue=data?.forest_area_affected_max;
    setStatesList(data?.states_data);
    setPaTypeList(data?.protectedarea_categories_data);
    setProjectCategoryList(data?.project_categories_data);
    setProjectSubCategoryList(data?.project_sub_categories_data);
    setDiversionTypeList(data?.nbwl_type_of_diversions_keys);
    setDecisionList(data?.nbwl_data);
    let modifiedMeetingNumberArray = data?.meeting_number.map( (item) => { return { name : item.name, id : item.name  }})
    setMeetingList(modifiedMeetingNumberArray);
    let area = [minValue, maxValue]
    setAffectedAreaMinMax(area)
  };

  const successCallBack = (res) => {
    setNbwlData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    setIsFilterApplied(false)
    console.log("table data nbwl", nbwlData, totalRecords);
  };

  const successCallBackforFilteredData = (res) => {
    setNbwlData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    setIsFilterApplied(true)
    setOpenFilter(false)
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    // if (!Array.isArray(newValue)) {
    //   return;
    // }

    console.log('setValue(newValue);', newValue);
    setAffectedAreaMinMax(newValue)
    
  };

  const handleChangePage = (event, newPage) => {
    console.log("new Page", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRecords) : 0;

  // No data found UI
  const renderNoDatafound = () => {

    if(nbwlData?.length == 0 && !loader ){
      return (
        <Grid item xs={12} container justifyContent={"center"} alignContent={"center"} sx={{height : "30vh"}}>
            <Typography variant='subheading1'>No data found</Typography>
            <Typography variant='body1' align='center' sx={{width : '100%', mt: '10px'}}>Try adjusting your search or filter to find what you are looking for.</Typography>
       </Grid>
      )
     }
    let filterData = nbwlData?.filter((item) =>
      item?.protected_area?.name
        .toLowerCase()
        .includes(tableSearch.toLowerCase())
    );
    if (tableSearch.length > 0 && filterData.length < 1) {
      return (
        <Grid
          item
          xs={12}
          container
          justifyContent={"center"}
          alignContent={"center"}
          sx={{ height: "30vh" }}
        >
          <Typography variant="subheading1">No data found</Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ width: "100%", mt: "10px" }}
          >
            {CONST.NoDataFound}
          </Typography>
        </Grid>
      );
    }
  };

  const handleChangePaTypesdata = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    setPaTypesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeStatesData = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);

    setStatesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getSelectedPA = () => {
    let str = "";
    paTypesData.forEach((item) => {
      str = str + "&pa_category_ids[]=" + item.id;
    });
    return str;
  };

  const getSelectedStates = () => {
    let str = "";
    statesData.forEach((item) => {
      str = str + "&state_ids[]=" + item.id;
    });
    return str;
  };

  const handleFilterApply = () => {
    let path = `/nbwls?page=${page + 1}&per_page=${rowsPerPage}`;
    let isSearching = false;
    if (paTypesData.length > 0) {
      isSearching = true;
      path = path + getSelectedPA();
    }
    if (statesData.length > 0) {
      isSearching = true;
      path = path + getSelectedStates();
    }
    if (meetingNumber) {
      isSearching = true;
      path = path + `&metting_number=${meetingNumber}`;
    }
    if(dateRange[0].startDate && dateRange[0].endDate ){
      isSearching = true
      path = path + `&meeting_date_start=${handleDateFormatforFilter(dateRange[0].startDate)}&meeting_date_end=${handleDateFormatforFilter(dateRange[0].endDate)}`
    }
    if (projectCategory) {
      isSearching = true;
      path = path + `&project_category_ids[]=${projectCategory}`;
    }
    if (projectSubCategory) {
      isSearching = true;
      path = path + `&project_sub_category_ids[]=${projectSubCategory}`;
    }
    if (diversionType) {
      isSearching = true;
      path = path + `&type_of_diversion=${diversionType}`;
    }
    if (decision) {
      isSearching = true;
      path = path + `&final_decision[]=${decision}`;
    }
    if(affectedAreaMinMax[0] !== minValue || affectedAreaMinMax[1] !== maxValue ){  //confirm this forest area affected
      isSearching = true;
      path = path + `&forest_area_affected_start=${affectedAreaMinMax[0]}&forest_area_affected_end=${affectedAreaMinMax[1]}`;
    }
    if (isSearching) {
      path = path + "&search=true";
    }
    getNbwlFilteredData(path);
  };

  //   "pa_category_ids": [
  //     1,
  //     2
  // ],
  // "state_ids": [
  //     1,
  //     2
  // ],
  // "metting_number": "12",
  // "meeting_date": "2023-01-01",
  // "project_category_ids": [
  //     1,
  //     2
  // ],
  // "project_sub_category_ids": [
  //     1,
  //     2
  // ],
  // "type_of_diversion": "",
  // "final_decision": [
  //     1,
  //     2
  // ],
  // "forest_area_affected_start": "",
  // "forest_area_affected_end": ""

  const handleFilterReset = () => {
    setPaTypesData([]);
    setStatesData([]);
    setMeetingNumber("");
    setProjectCategory("");
    setProjectSubCategory("");
    setDiversionType("");
    setDecision("");
    setAffectedAreaMinMax([minValue, maxValue])
    setDateRange((date) =>  {
      return [{...date[0], startDate : new Date(), endDate : null}]
      })
    setIsFilterApplied(false)
    setOpenFilter(false)
     getNbwlData() 
  };



  return (
    <>
      <Grid
        item
        container
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === "dark"
              ? t.palette.background.default
              : t.palette.grey[100],
        }}
      >
        <LinkBar title="NBWL" />

        {/* heading search and filter contaiener */}
        <Typography variant="h2" sx={{ pl: isXs ? "16px" : "32px", mt: "20px" }}>
          National Board of Wildlife
        </Typography>
        <Grid
          container
          sx={{
            px: isXs ? "16px" : "32px",
            my: "1.5rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            // sx={{
            //   display: ["none", "block", "block", "block", "block"],
            // }}
          >
            <SearchBarStyled>
              <SearchIcon />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here"
                inputProps={{ "aria-label": "search google maps" }}
                onChange={(e) => setTableSearch(e.target.value)}
              />
            </SearchBarStyled>
          </Box>
          {
              isXs ? (
                isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}}/>)
              )  : (
                <FilterStyled isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
              )
            }
          
        </Grid>

        {/* Nbwl Table */}
        {loader ? (
          <MyBox sx={{ width: 1, height: "30vh" }}>
            <Loader />
          </MyBox>
        ) : (
          <Grid item container sx={{ pl: isXs ? "16px" : "32px", pb: "1rem", width: "100%" }}>
            <StyledTableContainer>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>PA Name</StyledTableCell>
                    <StyledTableCell>PA Type</StyledTableCell>
                    <StyledTableCell>State/UT</StyledTableCell>
                    <StyledTableCell>Meeting Number</StyledTableCell>
                    <StyledTableCell>Meeting Date</StyledTableCell>
                    <StyledTableCell>Project Category</StyledTableCell>
                    <StyledTableCell>Project Sub-Category</StyledTableCell>
                    <StyledTableCell>Project Title</StyledTableCell>
                    <StyledTableCell>Type of Diversion</StyledTableCell>
                    <StyledTableCell>Forest Area Affected</StyledTableCell>
                    <StyledTableCell>Non-forest Area Affected</StyledTableCell>
                    <StyledTableCell>
                      Distance from PA (if located outside km)
                    </StyledTableCell>
                    <StyledTableCell>
                      Recognised Wildlife Corridors
                    </StyledTableCell>
                    <StyledTableCell>Final Decisons</StyledTableCell>
                    <StyledTableCell>Court Cases</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nbwlData
                    ?.filter((item) =>
                      item?.protected_area?.name
                        ?.toLowerCase()
                        .includes(tableSearch.toLowerCase())
                    )
                    .map((row) => (
                      <StyledTableRow key={row?.id}>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="primary.dark"
                          >
                            {row?.protected_area?.name ?? "NA"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.protected_area?.pa_categories[0]?.name ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                        {row?.state?.name ?? "NA"}
                          {/* {row?.protected_area?.states?.length > 0
                            ? row?.protected_area?.states[0]?.name
                            : "NA"} */}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.metting_number ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          <UnderlinedTypography
                            red
                            color="primary.dark"
                            onClick={()=>navigate(`/meeting/${row?.id}/nbwl`)}
                            sx={{ fontSize: "14px" }}
                          >
                            {row?.meeting_date ?? "NA"}
                          </UnderlinedTypography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.project_category?.name ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.project_sub_category?.name ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.project_tittle ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.type_of_diversion ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.forest_area_affected))}}>
                          {row?.forest_area_affected != null ? `${row?.forest_area_affected} sq km` : "NA"}
                   </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.non_forest_area_affected)) }}>
                         {row?.non_forest_area_affected != null ? `${row?.non_forest_area_affected} sq km` : "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.distance_of_pa)) }}>
                          {row?.distance_of_pa != null ? `${row?.distance_of_pa} km` : "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.wildlife_corridor?.name ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          <Box sx={{ display: "flex" }}>
                            <StatusBox
                              style={handleStatusColor(row?.final_decision)}
                            >
                              {row?.final_decision}
                            </StatusBox>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            showPDF(
                              row?.court_case_link,
                              dispatch,
                              "No link found"
                            )
                          }
                          sx={{ minWidth: "200px !important" }}
                        >
                          <MyPopover
                            caseTitle={row?.court_case_title}
                            content={row?.court_case}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>

            {renderNoDatafound()}

            {/* Table Pagination starts here */}
            <Grid item xs={12}>
              <PaginationContainerStyled
                container
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography sx={{ 
                  pl: 1,
                  
                  }} variant="body2">
                  {totalRecords} entries found
                </Typography>
                <TablePagination
                  component="div"
                  count={totalRecords}
                  page={page}
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </PaginationContainerStyled>
              {/* Table pagination end */}
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Filter Dialog start here */}
      <Dialog
        // fullWidth={fullWidth}
        //  maxWidth={maxWidth}
        // maxWidth={['xs','sm','md','xl','lg']}
        // maxWidth={"80vw"}
        fullScreen={isXs}
        open={openFilter}
        onClose={handleFilterClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "90vw",
            // backgroundColor : 'khaki'
          },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(4,1fr)",
                  lg: "repeat(4,1fr)",
                  xl: "repeat(4,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              {/* PA Type Select     */}
              <FormControl fullWidth>
                <Typography>PA Type</Typography>
                <MySelectMultiple
                  value={paTypesData}
                  onChange={handleChangePaTypesdata}
                  menuArray={paTypeList}
                />
              </FormControl>

              {/* State Select */}
              <FormControl fullWidth>
                <Typography>State</Typography>
                <MySelectMultiple
                  value={statesData}
                  onChange={handleChangeStatesData}
                  menuArray={statesList}
                />
              </FormControl>

                {/*Meeting Date Select */}
                <FormControl fullWidth>
                <Typography>Meeting Date</Typography>
                <MyDateRange
                  onChange={(item) => setDateRange([item.selection])}
                  dateRange={dateRange}
                />
              </FormControl>

              {/* Metting Number Select   */}
              <FormControl fullWidth>
                <Typography>Metting</Typography>
                <MySelect
                  value={meetingNumber}
                  onChange={(e) => setMeetingNumber(e.target.value)}
                  menuArray={meetingList}
                />
              </FormControl>

            
              {/* Project Category  */}
              <FormControl fullWidth>
                <Typography>Project Category</Typography>
                <MySelect
                  value={projectCategory}
                  onChange={(e) => setProjectCategory(e.target.value)}
                  menuArray={projectCategoryList}
                />
              </FormControl>

              {/* Project Title  */}
              <FormControl fullWidth>
                <Typography>Project Sub-category</Typography>
                <MySelect
                  value={projectSubCategory}
                  onChange={(e) => setProjectSubCategory(e.target.value)}
                  menuArray={projectSubCategoryList}
                />
              </FormControl>

              {/* Type of Diversion  */}
              <FormControl fullWidth>
                <Typography>Type of Diversion</Typography>
                <MySelect
                  value={diversionType}
                  onChange={(e) => setDiversionType(e.target.value)}
                  menuArray={diversionTypeList}
                />
              </FormControl>

              {/*Final Decisions */}
              <FormControl fullWidth>
                <Typography>Decision</Typography>
                <MySelect
                  value={decision}
                  onChange={(e) => setDecision(e.target.value)}
                  menuArray={decisionList}
                />
              </FormControl>

              {/* Forest Area Affected */}
              <FormControl fullWidth>
                <Typography>Forest Area Affected (sq km)</Typography>
                {/* <Select
                  value={forestAreaAffected}
                  onChange={(e) => setForestAreaAffected(e.target.value)}
                  renderValue={
                    forestAreaAffected !== ""
                      ? undefined
                      : (t) => <span style={{ color: "#555555" }}>Select</span>
                  }
                  IconComponent={() => (
                    <ExpandMore
                      sx={{
                        color: (t) => t.palette.secondary.main,
                      }}
                    />
                  )}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "44px",
                    backgroundColor: (t) => t.palette.grey[300],
                  }}
                >
                  {forestAreaAffectedList?.map((item) => (
                    <MenuItem value={item} key={item}>
                      <Typography variant="body2">{item}</Typography>
                    </MenuItem>
                  ))}
                </Select> */}
               <MySliderforEsz
                value={affectedAreaMinMax}
                onChange={handleChange1}
                maxValue={maxValue}
                />
               </FormControl>
            </Box>

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined sx={{ textTransform: "none" }}
          onClick={handleFilterReset}
          >Reset</ButtonOutlined>
          <ButtonPrimary
          onClick={handleFilterApply}
          >Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Nbwl;
