import React from "react";
import { Select, MenuItem, Typography, ListItemText, Checkbox } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";


const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
    },
  },
};

const handleArray = (selected) => {
    let arr = [];
    arr = selected?.map((item) => item.name);
    return arr;
  };

const MySelectMultiple = ({ value, onChange, menuArray }) => {
  return (
    <Select
      id="demo-multiple-checkbox"
      value={value}
      onChange={onChange}
      renderValue={(selected) =>
        selected?.length == 0 ? (
          <span style={{ color: "#555555" }}>Select</span>
        ) : (
          handleArray(selected)?.join(", ")
        )
      }
      IconComponent={() => (
        <ExpandMore sx={{ color: (t) => t.palette.secondary.main }} />
      )}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      sx={{
        height: "44px",
        width :'100%',
        backgroundColor: (t) => t.palette.grey[300],
      }}
      // labelId="demo-multiple-checkbox-label"
      multiple
      MenuProps={MenuProps}
    //   disabled={["National Park", "Wildlife Sanctuary"].some(
    //     (value) => value === getItem("pa")
    //   )}
    >
      {menuArray?.map((item) => (
        <MenuItem
          key={item.id}
          value={item}
          sx={{
            "&.MuiButtonBase-root": {
              // backgroundColor : 'khaki'
            },
          }}
        >
          <Checkbox
            disableRipple
            checked={handleArray(value).indexOf(item.name) > -1}
          />
          <ListItemText disableRipple primary={item.name} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MySelectMultiple;
