export const discussionOnIncreasingList = [
    {
      id : 1,
      name : "Yes"
    },
    {
      id : 2,
      name : "No"
    }
  
  ]

  export const discussionOnLandscapeList = [
    {
      id : 1,
      name : "Yes"
    },
    {
      id : 2,
      name : "No"
    }
  
  ]

  export const discussionOnInterStateBorderList = [
    {
      id : 1,
      name : "Yes"
    },
    {
      id : 2,
      name : "No"
    }
  
  ]

  export const discussionOnWetlandList = [
    {
      id : 1,
      name : "Yes"
    },
    {
      id : 2,
      name : "No"
    }
  
  ]

  export const discussionOnLivelihoodList = [
    {
      id : 1,
      name : "Yes"
    },
    {
      id : 2,
      name : "No"
    }
  
  ]


  export const handleTrueFalseValue = (number) => {
     if(number == 1){
      return true
     }else if(number == 2){
      return false
     }else
     return ;
  }