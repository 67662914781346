import React, { useState } from "react";
import {
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ButtonOutlined,
  ButtonOutlinedSearch,
  UnderlinedTypography,
} from "../StyledComponents/StyledComponents";
import { ReactComponent as AllSearchIcon } from "../../assets/SvgIcons/SearchAllIcon.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { setItem } from "../../utils/validators";
import { useRef } from "react";
import { useEffect } from "react";

export default function SearchAll({ openSearch, closeSearch }) {
  const navigate = useNavigate()
  const theme = useTheme();
  const inputRef = useRef(null)


  useEffect( ()=>{
    inputRef.current.focus()
    return () => {}
  }, [inputRef])

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [isSearchEmpty, setIsSearchEmpty] = useState(false)

  const handleSerchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = () => {
    if(searchValue.length < 1){
      setIsSearchEmpty(true)
      return ;
    }
    navigate(`/search?s=${searchValue}`)
    closeSearch()
  }

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          position: "absolute",
          // height: "60vh",
          width: "100%",
          top: "133px",
          zIndex: 3,
          px: isXs ? "16px" : "32px",
          py : ["32px", "32px", "64px", "64px", "64px"]
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Typography variant="h2">Search</Typography>
          <IconButton onClick={closeSearch} disableRipple>
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* search field and button container */}
        <Grid
          container
          justifyContent={"center"}
          alignItems={"flex-end"}
          sx={{ gap: "20px", mt: "20px" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            xl={4}
            lg={4}
            // sx={{ borderBottom: `2px solid ${theme.palette.divider}` }}
          >
            <InputBase
              inputRef={inputRef}
              autoComplete
              placeholder="Search here.."
              onChange={handleSerchValue}
              fullWidth
              id="sId"
              sx={{
                pb: "0px",
                "& input::placeholder": {
                  fontSize: ["20px","20px","24px","24px","24px"],
                },
                fontSize: ["20px","20px","24px","24px","24px"],
                borderBottom: `2px solid ${theme.palette.divider}`
              }}
              onFocus={()=>setIsSearchEmpty(false)}
            />
          
          </Grid>

          <ButtonOutlinedSearch
            startIcon={
              <AllSearchIcon
                style={{ fill: theme.palette.primary.dark, marginRight: "4px" }}
              />
            }
            sx={{ textTransform: "none" }}
            onClick={handleSearch}
          >
            Search
            
          </ButtonOutlinedSearch>

          {/* { isSearchEmpty && 
             <Typography align="center" variant="body1" color="vidhiRed.main">Please search to get results or try quick links.</Typography>

            } */}
        </Grid>

        <Grid container justifyContent={"center"} sx={{ my: "30px" }}>
          <Typography variant="h2">Related Searches</Typography>
        </Grid>

        <Grid container justifyContent={"center"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            container
            justifyContent={"space-between"}
            sx={{ rowGap: "20px" }}
          >
            <Grid item xs={4}>
              <UnderlinedTypography
                align="center"
                red
                color="primary.dark"
                subheading2
               onClick={()=>{
                navigate("/questions")
                closeSearch()
               }}
                
              >
                Questions
              </UnderlinedTypography>
            </Grid>
            <Grid item xs={4}>
              <UnderlinedTypography
                align="center"
                red
                color="primary.dark"
                subheading2
                onClick={()=>{
                  navigate("/allreports")
                  closeSearch()
                 }}
              >
                Reports
              </UnderlinedTypography>
            </Grid>

            <Grid item xs={4}>
              <UnderlinedTypography
                align="center"
                red
                color="primary.dark"
                subheading2
                onClick={()=>{
                  navigate("/allbills")
                  closeSearch()
                 }}
              >
                Bills
              </UnderlinedTypography>
            </Grid>


            <Grid item xs={4}>
            <UnderlinedTypography align="center" red color="primary.dark" subheading2  onClick={()=>{
                 setItem("pa", "All");
                 navigate("/protectedareas");
                closeSearch()
               }}>
              Protected Area
            </UnderlinedTypography>
            </Grid>


            <Grid item xs={4}>

            <UnderlinedTypography align="center" red color="primary.dark" subheading2  onClick={()=>{
                navigate("/esznotification")
                closeSearch()
               }}>
              Ecosensitve Zone
            </UnderlinedTypography>
            </Grid>


            <Grid item xs={4}>

            <UnderlinedTypography align="center" red color="primary.dark" subheading2  onClick={()=>{
                navigate("/nbwl")
                closeSearch()
               }}>
              Nbwl
            </UnderlinedTypography>
          </Grid>

          </Grid>
        </Grid>
      </Paper>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open max-width dialog
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        disablePortal
      >
        <DialogTitle>Optional sizes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor="max-width">maxWidth</InputLabel>
              <Select
                autoFocus
                value={maxWidth}
                onChange={handleMaxWidthChange}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value={false}>false</MenuItem>
                <MenuItem value="xs">xs</MenuItem>
                <MenuItem value="sm">sm</MenuItem>
                <MenuItem value="md">md</MenuItem>
                <MenuItem value="lg">lg</MenuItem>
                <MenuItem value="xl">xl</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={fullWidth} onChange={handleFullWidthChange} />
              }
              label="Full width"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog> */}
    </React.Fragment>
  );
}
