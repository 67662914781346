import * as React from 'react';
import { Typography, Grid} from '@mui/material';


import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';


import LoginSignUpBackground from '../../components/LoginSignUpBackground/LoginSignUpBackground';
import { ButtonPrimary, FieldStyled, LabelStyled, LinkStyled, } from '../../components/StyledComponents/StyledComponents';
import { useState } from 'react';
import * as VALIDATOR from '../../utils/validators'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setItem } from '../../utils/validators';
import { showSnackbar } from '../../utils/PdfUtils';

const SignUp = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  

   const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = React.useState(false);


  const [emptyNameErr, setEmptyNameErr] = useState(false)
  const [emptyEmailErr, setEmptyEmailErr] = useState(false)
  const [emptyPassErr, setEmptyPassErr] = useState(false)
  const [invalidEmailErr, setInvalidEmailErr] = useState(false)
  const [invalidPassErr, setInvalidPassErr] = useState(false)


  const successCallBack = (res) => {
    setItem("from","signup")  
    setItem("email",email) 
    if(res?.data?.data?.opt_verify){
       showSnackbar("Email has Already taken", dispatch)
       return
    }else{
      navigate('/otp')
    }
  } 


  const handleOnSignin = () => {
     if(name.length == 0){
      setEmptyNameErr(true)
     }
     if(email.length == 0){
      setEmptyEmailErr(true)
     }else if(!VALIDATOR.isEmail(email)){
      setInvalidEmailErr(true)
     }


     if(password.length == 0){
      setEmptyPassErr(true)
     }else if(!VALIDATOR.validPassword(password)){
      setInvalidPassErr(true)
     }else if(VALIDATOR.validPassword(password) && VALIDATOR.isEmail(email) && name.length !== 0){
      console.log("sign up saga action call")

      // TODO
      // need to remove this code
      // VALIDATOR.setItem("isUserLoggedIn",true)
      // navigateToHome()

      dispatch({
        type : "USER_CREATE_SAGA",
        payload : {
          name : name,
          email : email,
          password : password,
          successCallBack : successCallBack
        }
      })
     }

  }

  return (
   
    <Grid 
    justifyContent={"center"}
    container style={{width : "100vw", height : "100vh"}} >
   
   <LoginSignUpBackground />

   <Grid item container xs={12} sm={9} md={4} lg={4} xl={4} alignItems='center'  
   sx={{
    py : '40px',
    px : ['40px', '40px', '20px','20px','20px'],
    backgroundColor : (t) => t.palette.mode === "dark" && t.palette.primary.main
    }}
   >
        
         <Grid container direction="column" gap={"10px"}>
         <Typography variant='h2'>Sign up</Typography>
         <Typography color="text.secondary" sx={{mb : 1}}>Start your journey with us</Typography>


         <Grid item>
          <LabelStyled variant='body2'>Name*</LabelStyled>
          <FieldStyled
          variant='outlined' 
          fullWidth
          placeholder='Enter your name'
          value={name}
          onChange={(e)=>setName(e.target.value)}
           onFocus={()=>{
            setEmptyNameErr(false)
           }}
          />
          {
            emptyNameErr && 
            <Typography variant='body2' color="vidhiRed.main">Name Required</Typography>
          }
         </Grid>

         <Grid item>
          <LabelStyled variant='body2'>Email*</LabelStyled>
          <FieldStyled
          variant='outlined' 
          fullWidth
          placeholder='Enter your email'
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
          onFocus={()=>{
            setEmptyEmailErr(false)
            setInvalidEmailErr(false)
           }}
          />
          {
            emptyEmailErr && 
            <Typography variant='body2' color="vidhiRed.main">Email Required</Typography>
          }
          {
            invalidEmailErr && 
            <Typography variant='body2' color="vidhiRed.main">Invalid Email</Typography>
          }
         </Grid>

         <Grid item>
          <LabelStyled variant='body2'>Password*</LabelStyled>
         
          <FieldStyled
          variant='outlined'
          fullWidth
          placeholder='Create a password'
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
          onFocus={()=>{
            setEmptyPassErr(false)
            setInvalidPassErr(false)
           }}
           type={showPassword ? "text" : "password"}
          InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ fontSize: "30px" }}
                    position="start"
                  >
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ?  <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
          />
          {
            emptyPassErr && 
            <Typography variant='body2' color="vidhiRed.main">Password Required</Typography>
          }
          {
            invalidPassErr && 
            <Typography variant='body2' color="vidhiRed.main">Password must be at least 8 characters</Typography>
          }
          <Typography variant='body2' color="text.secondary">Must be at least 8 characters</Typography>
          
         </Grid>

         <ButtonPrimary
         onClick={handleOnSignin}
         sx={{mb : 1, mt : 2}}>Get Started</ButtonPrimary>
       
        
         <Grid container justifyContent='center' alignItems='center'>
            <Typography color="text.secondary" variant='body2'>Already have an account?</Typography>
            <LinkStyled to="/login"><Typography variant='subtitle2' color="vidhiRed.light" ml={1}>Log in</Typography></LinkStyled>
        </Grid>
        </Grid>


        
         


        
        </Grid>
   </Grid>
  )
}

export default SignUp