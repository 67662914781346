import React, {useState} from 'react'
import { Grid, Typography, Box, TextField, Checkbox, Button, useTheme } from '@mui/material';
import LoginSignUpBackground from '../../components/LoginSignUpBackground/LoginSignUpBackground';
import { ButtonPrimary, FieldStyled, LabelStyled, LinkStyled} from '../../components/StyledComponents/StyledComponents';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import OTPInput from 'react-otp-input';
import {  useNavigate } from 'react-router-dom';
import * as VALIDATOR from '../../utils/validators'
import { useDispatch, useSelector } from 'react-redux';
import { setItem } from '../../utils/validators';
import Loader from '../../components/Loader/Loader';

const ForgotPassword = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loader = useSelector( (state) => state.commonReducer.showLoader)

  

  const [email, setEmail] = useState("")
  const [emailEmptyErr, setEmailEmptyErr] = useState(false)
  const [invalidEmailErr, setInvalidEmailErr] = useState(false)

  const successCallBack = () => {
    setItem("email",email)
    setItem("from","forgot")
    navigate('/otp')
 } 

  const handleResetPassword = () => {
    if(email.length == 0){
      setEmailEmptyErr(true)
    }else if(!VALIDATOR.isEmail(email)){
      setInvalidEmailErr(true)
    }
    
    if(VALIDATOR.isEmail(email))
     {
      dispatch({
        type : "USER_SEND_RESET",
        payload : {
          email : email,
          successCallBack : successCallBack,
        }
      })
     }
  }
  
  return (
    <Grid 
    justifyContent={"center"}
    container component="main" style={{width : "100vw", height : "100vh"}}
    sx={{
      alignContent : ["flex-start", "flex-start","none", "none", "none"]
    }}
    >
        
        <LoginSignUpBackground />
        
        <Grid item container xs={12} sm={9} md={4} lg={4} xl={4} 
        sx={{
          py : '40px',
          px : ['40px', '40px', '20px','20px','20px'],
          backgroundColor : (t) => t.palette.mode === "dark" && t.palette.primary.main,
          alignItems : ['flex-start', 'flex-start', 'center','center','center']
          }}
        >
         
         {
          loader ? <Loader /> : (
            <Grid container direction="column" gap={"10px"}>
            <Typography variant='h2' color="text.primary">Forgot Password</Typography>
            <Typography variant='body1' sx={{mb : 1}} color="text.secondary">No worries, we’ll send you reset instructions .</Typography>
              
            <Grid item>
             <LabelStyled variant='body2'>Email*</LabelStyled>
             <FieldStyled
             placeholder='Enter your email'
             variant='outlined' 
             fullWidth
             value={email}
             onChange={(e)=>setEmail(e.target.value)}
             onFocus={()=>{
               setEmailEmptyErr(false)
               setInvalidEmailErr(false)
             }}
            />
           {
             emailEmptyErr && 
             <Typography variant='body2' color="vidhiRed.main">Email Required</Typography>
            }
            {
             invalidEmailErr && 
             <Typography variant='body2' color="vidhiRed.main">Invalid Email</Typography>
            }
            </Grid>
   
            <ButtonPrimary sx={{my : 1}} onClick={handleResetPassword}>Reset Password</ButtonPrimary>
          
           <Grid container justifyContent='center' alignItems='center'>
               <LinkStyled to="/login">
               <Grid item container alignItems="center">
                <KeyboardBackspaceIcon sx={{ color : (t) => t.palette.secondary.main }}/>
               <Typography color="text.secondary" sx={{fontSize : 14, ml : 1}}>Back to log in</Typography>
               </Grid>
               </LinkStyled>
              
           </Grid>
           </Grid>
          )
         }
         
   



        
        </Grid>
       
      </Grid>
  )
}

export default ForgotPassword