import React from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { LinkStyled } from '../StyledComponents/StyledComponents'
import { useLocation } from 'react-router-dom';


function useQuery() {
  const { search } = useLocation();
  console.log("serach",search)
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LinkBar = (props) => {
   const theme = useTheme()
  const query = useQuery()
  const paName = query.get("current");
  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));
  
  let route = ""
  
   if(props.title === "Debates"){
    route = "/alldebates"
   }else if(props.title === "Bills"){
    route = "/allbills"
   }else if(props.title === "Reports"){
    route = "/allreports"
   }else if(props.title === "Publications"){
    route = "/allpublications"
   }

  const handleVariant = () => {
     if(props.title && props.subtitle){
      return 'body3'
     }else{
      return 'subtitle3'
     }
   }
  return (
    <Grid 
    container 
    alignItems="center"
    sx={{
    backgroundColor : (theme) => theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.secondary.dark,
     py : '18px',
     pl : isXs ? "16px" : "32px"}}
    >   
   
    <LinkStyled to="/"><Typography color="text.primary" variant="body3">Home</Typography></LinkStyled>
    {
      props.title &&
     <LinkStyled to={route}> <Typography color="text.primary" variant={handleVariant()} sx={{ml :"2px"}}>// {props.title}   </Typography></LinkStyled>  
    }
    {
     props.subtitle &&
    <Typography variant='subtitle3' sx={{ml :"2px"}}>// {props.subtitle}</Typography>
    }
    {/* link for Parliamentry Updates// */}
    {
      props.paName && props.paName === "All" &&
      <Typography variant='subtitle3' sx={{ml :"2px"}}>// Protected Areas</Typography>

    }
    {
      props.paName && props.paName !== "All" &&
      (
        <>
      <LinkStyled to="/protectedareas"> <Typography color="text.primary" variant='body3' sx={{ml :"2px"}}>// PA</Typography></LinkStyled>
      <Typography variant='subtitle3' sx={{ml :"2px"}}>// {props.paName}</Typography>
      </>
      )

    }
    </Grid>
  )
}

export default LinkBar