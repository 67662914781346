import { Grid, Typography, useTheme, Menu } from "@mui/material";
import React, { useState, useEffect } from "react";
import {   useNavigate, useParams } from "react-router-dom";
import LinkBar from "../../components/LinkBar/LinkBar";

import {
  ButtonPrimary,
  MyBox,
  PATypeLabelStyled,
  TextUpperCase,
} from "../../components/StyledComponents/StyledComponents";
import ReCAPTCHA from "react-google-recaptcha";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { handleDateFormat } from "../../utils/StatusStyle";
import parse from 'html-react-parser'
import Loader from "../../components/Loader/Loader";
import { getItem } from "../../utils/validators";
import { showSnackbar } from "../../utils/PdfUtils";

const Meeting = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id, type} = useParams();
  
  const loader = useSelector((state) => state.commonReducer.showLoader);

  

  const [meetingDetails, setMeetingDetails] = useState({})
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const parsedHtml = meetingDetails?.full_detail && parse(meetingDetails?.full_detail)
  const isUserLoggedIn = getItem('isUserLoggedIn')

  useEffect(() => {
    getMeetingInformation()
  }, [])

  const getMeetingInformation = () => {
    let path = getPath();
    dispatch({
      type: "GET_MEETING_INFO",
      successCallBack: successCallBack,
      path : path
    });
  }

  const successCallBack = (res) => {
  setMeetingDetails(res?.data?.data)
  }

  
  const getPath = () => {
    switch(type){
      case "committee":
      return `/esz_committee_meetings/${id}`;
      case "nbwl" :
      return `/nbwls/${id}`
    }
  }

  const handleDownloadReport = (event) => {
    if (isUserLoggedIn) {   //check user is logged in
      const reportLink = meetingDetails?.doc_detail?.link;
      
      const isPdf = Boolean(reportLink?.includes(".pdf"));
      // const isPdf = reportLink?.includes(".pdf");

      
      if (reportLink != null && isPdf) {
        setAnchorEl(event.currentTarget);
      } else {
        showSnackbar("Report not available", dispatch);
      }
    } else {
      showSnackbar("You need to log in to download", dispatch);
    }
  };

  const downloadReport = async() => {
    let pdfUrl = meetingDetails?.doc_detail?.link 

      if (pdfUrl) {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.download = pdfUrl;
        link.click();
  
        URL.revokeObjectURL(url);
      }
    
   handleClose()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    downloadReport()
  }
  

  return (
    <Grid
    container
    sx={{
      backgroundColor: (t) =>
        t.palette.mode === "dark"
          ? t.palette.background.default
          : theme.palette.grey[100],
    }}
    >
      <LinkBar title={meetingDetails?.title}/>
      {
        loader ? <MyBox sx={{width : '100%', height : '40vh'}}><Loader /></MyBox> : (
          <>
          <Grid
        container
        sx={{
          px: ["20px", "20px", "0px", "0px", "0px"],
          backgroundColor: "black",
        }}
        justifyContent="center"
      >
      
        
        {/* card item */}
        <Grid item xs={12} sm={11} md={10} lg={10} xl={10}>
          <Grid
            container
            alignItems={"flex-start"}
            flexDirection={"column"}
            sx={{ py: "20px", gap: "20px" }}
          >
            {/* first line */}
            <Grid item container>
              <Grid item>
                <TextUpperCase
                  sx={{ borderRight: "1px solid white", pr: 1 }}
                  variant="subtile2"
                  color="vidhiRed.light"
                >
                  Meeting
                </TextUpperCase>
              </Grid>
              <Grid item>
                <TextUpperCase
                  sx={{ borderRight: "1px solid white", px: 1 }}
                  variant="subtile2"
                  color="text.alwayswhite"
                >
                  {handleDateFormat(meetingDetails?.meeting_date)}
                </TextUpperCase>
              </Grid>
              <Grid item>
                <TextUpperCase
                  sx={{ pl: 1 }}
                  variant="subtile2"
                  color="text.alwayswhite"
                >
                  {meetingDetails?.meeting_number ?? "NA"}
                </TextUpperCase>
              </Grid>
            </Grid>
            <Typography variant="h2" color="text.alwayswhite">
             {meetingDetails?.title}
            </Typography>

            <Grid
              item
              sx={{ width: 40, height: 4, backgroundColor: "#FF8278" }}
            ></Grid>

            <MyBox
              sx={{
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "4px",
                p: "10px",
                cursor : 'pointer'
              }}
              onClick={handleDownloadReport}
              >
              <SimCardDownloadIcon
              sx={{ color: (t) => t.palette.text.alwayswhite }}
              />
            </MyBox>

            <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
         <ReCAPTCHA
              sitekey="6LcMPDQoAAAAANjVknUxEF_-lM_o5QXt-7nXe4bR"
              onChange={onChange}
                />
               
      </Menu>
          </Grid>
        </Grid>
      </Grid>

  
      {/* paragraph section starts here */}
      <Grid container justifyContent="center" sx={{ py: "24px" }}>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          sx={{
            px: ["15px", "15px", 0, 0, 0],
          }}
        >
          <Grid container sx={{ overflowX :'auto'}}>
          {parsedHtml}
       </Grid>
          {/* <Box>
            <Typography variant="subheading2" sx={{ pb: 2 }}>
              The Ministry of Environment, Forest & Climate Change introduced
              the Forest (Conservation) Amendment Bill, 2023 (“Bill”) in Lok
              Sabha on 29 March 2023. proposing amendments to the Forest
              (Conservation) Act, 1980 (“FCA”). The Bill has been referred to
              the Joint Committee of the Parliament (“JC”) for examination,
              which is expected to give its report in July 2023. The JC vide
              press communication CBC 31201/11/0001/2324 has invited views/
              suggestions on the proposed amendment. This submission seeks to
              express concerns and make suggestions on the same. 
            </Typography>
            <Typography variant="subheading2" sx={{ pb: 2 }}>
              The Ministry of Environment, Forest & Climate Change introduced
              the Forest (Conservation) Amendment Bill, 2023 (“Bill”) in Lok
              Sabha on 29 March 2023. proposing amendments to the Forest
              (Conservation) Act, 1980 (“FCA”). The Bill has been referred to
              the Joint Committee of the Parliament (“JC”) for examination,
              which is expected to give its report in July 2023. The JC vide
              press communication CBC 31201/11/0001/2324 has invited views/
              suggestions on the proposed amendment. This submission seeks to
              express concerns and make suggestions on the same. 
            </Typography>
            <Typography variant="subheading2" sx={{ pb: 2 }}>
              The Ministry of Environment, Forest & Climate Change introduced
              the Forest (Conservation) Amendment Bill, 2023 (“Bill”) in Lok
              Sabha on 29 March 2023. proposing amendments to the Forest
              (Conservation) Act, 1980 (“FCA”). The Bill has been referred to
              the Joint Committee of the Parliament (“JC”) for examination,
              which is expected to give its report in July 2023. The JC vide
              press communication CBC 31201/11/0001/2324 has invited views/
              suggestions on the proposed amendment. This submission seeks to
              express concerns and make suggestions on the same. 
            </Typography>
          </Box> */}

         

          <ButtonPrimary
            sx={{ px: 2, my: 2 }}
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={handleDownloadReport}
          >
            View Report
          </ButtonPrimary>

          {/* Tageed PA's */}
          <Grid container flexDirection="column" sx={{ gap: "20px", my: 4 }}>
            <Typography variant="h2">Tagged PA's</Typography>

            <Grid item xs={12} container alignItems="center">
              <MyBox sx={{ gap: "10px", flexWrap : "wrap" }}>
                {
                  meetingDetails?.protected_area_detail?.length > 0 ? (
                    meetingDetails?.protected_area_detail?.map((pa) => (
                      <PATypeLabelStyled
                      key={pa?.id}
                       clickable
                       onClick={()=>navigate("/paInformation", {state : {  paID : pa?.id}})} 
                       >
                        <Typography color="primary.dark">{pa?.name}</Typography>
                      </PATypeLabelStyled>
                    ))
                  )  : (<Typography variant="body1">NA</Typography>)
                }
                
              </MyBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
          </>
        )      }

      
    </Grid>
  );
};

export default Meeting;
