import React from "react";
import {
  Divider,
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import NewsLetter from "./NewsLetter";
import { FooterIconsBorderStyled } from "../StyledComponents/StyledComponents";

import { ReactComponent as Twitter } from "../../assets/SvgIcons/twitter.svg";
import { ReactComponent as Feedback } from "../../assets/SvgIcons/feedback.svg";
import { ReactComponent as Facebook } from "../../assets/SvgIcons/facebook.svg";
import { ReactComponent as LinkedIn } from "../../assets/SvgIcons/linkedIN.svg";
import { ReactComponent as Youtube } from "../../assets/SvgIcons/youtube.svg";
import { ReactComponent as Spotify } from "../../assets/SvgIcons/spotify.svg";
import { ReactComponent as Podcast } from "../../assets/SvgIcons/podcast.svg";
import { ReactComponent as PodcastNew } from "../../assets/SvgIcons/podcastNew.svg";
import { ReactComponent as Instagram } from "../../assets/SvgIcons/instagram.svg";
// import TwitterIcon from '@mui/icons-material/Twitter';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import { ReactComponent as FooterLogo } from "../../assets/SvgIcons/vidhilogoRed.svg";
import { ReactComponent as FooterLogoDark } from "../../assets/SvgIconsDarkMode/vidhiLogoDark.svg";
import FooterLogoNew from "../../assets/Images/newLogo.png";

import { Link } from "@mui/material";
import moment from "moment";

const Footer = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      item
      container
      justifyContent="center"
      sx={{
        // backgroundColor :"#EEEEEE",
        backgroundColor: (t) =>
          t.palette.mode === "dark"
            ? t.palette.common.white
            : t.palette.secondary.dark,
      }}
    >
      <NewsLetter />
      <Grid
        item
        xs={12}
        sm={7}
        md={11}
        sx={{
          backgroundColor: (t) =>
            t.mode === "dark"
              ? t.palette.common.white
              : t.palette.secondary.dark,
        }}
      >
        <Grid container flexDirection="column" sx={{ py: "1rem", gap: "1rem" }}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              container
              justifyContent={[
                "center",
                "center",
                "flex-start",
                "flex-start",
                "flex-start",
              ]}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{ py: "1rem" }}
            >
              <Box
                component="img"
                src={FooterLogoNew}
                style={{ width: "250px", height: "80px" }}
              />
              {/* {theme.palette.mode === 'dark' ? (<FooterLogoDark style={handleDynamicWidth()} />) : (<FooterLogo style={handleDynamicWidth()} />)}  */}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 1,
                  width: "100%",
                  textAlign: ["center", "center", "left", "left", "left"],
                }}
              >
                Contents of this website can be freely accessed and utilised
                under
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                justifyContent: [
                  "center",
                  "center",
                  "flex-end",
                  "flex-end",
                  "flex-end",
                ],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  "&:last-child :nth-of-type(9)": {
                    borderRight: `1px solid #454545`,
                  },
                }}
              >
                <a
                  href="https://twitter.com/vidhi_india?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <Twitter />
                  </FooterIconsBorderStyled>
                </a>
                <a
                  href="https://soundcloud.com/vidhi-legal-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <Feedback />
                  </FooterIconsBorderStyled>
                </a>
                <a
                  href="https://www.facebook.com/vidhilegalpolicy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <Facebook />
                  </FooterIconsBorderStyled>
                </a>
                <a
                  href="https://www.linkedin.com/company/vidhi-centre-for-legal-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <LinkedIn />
                  </FooterIconsBorderStyled>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCKhXMC2UPLShJcZn1jKsFIQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <Youtube />
                  </FooterIconsBorderStyled>
                </a>
                <a
                  href="https://open.spotify.com/show/7B7BPtNCQ2Tg9MPddcgDcl?si=AnwPGeMcQY2-7Ei9PUHp1A&nd=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <Spotify />
                  </FooterIconsBorderStyled>
                </a>
                <a
                  href="https://podcasts.apple.com/in/podcast/vidhi-centre-for-legal-policys-podcast/id1530039305"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <Podcast />
                  </FooterIconsBorderStyled>
                </a>
                <a
                  href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zb3VuZGNsb3VkLmNvbS91c2Vycy9zb3VuZGNsb3VkOnVzZXJzOjE2NTE3ODI2OC9zb3VuZHMucnNz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <PodcastNew />
                  </FooterIconsBorderStyled>
                </a>
                <a
                  href="https://www.instagram.com/vidhi_india/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FooterIconsBorderStyled>
                    <Instagram />
                  </FooterIconsBorderStyled>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
          >{`© ${moment().year()} Website developed and managed by Vidhi Centre for Legal Policy`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
