import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box, TextField, Checkbox, Button } from '@mui/material';
import LoginSignUpBackground from '../../components/LoginSignUpBackground/LoginSignUpBackground';
import { ButtonPrimary, FieldStyled, LabelStyled, LinkStyled } from '../../components/StyledComponents/StyledComponents';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {  useNavigate } from 'react-router-dom';

import * as VALIDATOR from '../../utils/validators'
import { useDispatch } from 'react-redux';


import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';



export default function ResetPassword() {
   
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [passwordEmptyErr, setPasswordEmptyErr] = useState(false)
    const [confirmPassEmptyErr, setConfirmPassEmptyErr] = useState(false)
    
    const [invalidPassErr, setInvalidPassErr] = useState(false)
    const [invalidConfrimPassErr, setInvalidConfrimPassErr] = useState(false)

    
    const [passwordReseted, setPasswordResetted] = React.useState(false)

    const handleResetPassword = () => {
        if(password.length == 0){
            setPasswordEmptyErr(true)
        }else if(!VALIDATOR.validPassword(password)){
            setInvalidPassErr(true)
        }

        if(confirmPassword.length == 0){
            setConfirmPassEmptyErr(true)
        }else if(password != confirmPassword){
            setInvalidConfrimPassErr(true)
        }else if(VALIDATOR.validPassword(password) && password === confirmPassword){
            dispatch({
                type : "UPDATE_PASSWORD",
                payload : {
                  password :password,
                  confirmPassword : confirmPassword,
                  email : localStorage.getItem("email"),
                  otp : localStorage.getItem("otp"),
                  setPasswordResetted : setPasswordResetted
                }
              })
        }
    }
  
  return (
    
      <Grid 
      justifyContent={"center"}
      container component="main" style={{width : "100vw", height : "100vh"}}
      sx={{
        alignContent : ["flex-start", "flex-start","none", "none", "none"]
      }}
      >
     
        <LoginSignUpBackground />
        
        <Grid item container  xs={12} sm={9} md={4} lg={4} xl={4} 
        sx={{
          py : '40px',
          px : ['40px', '40px', '20px','20px','20px'],
            backgroundColor : (t) => t.palette.mode === "dark" && t.palette.primary.main,
            alignItems : ['flex-start', 'flex-start', 'center','center','center']

            }}>
         
         {
            passwordReseted ? (
        <Grid container direction="column" gap={"10px"}>
         <Typography variant='h2'>Password reset</Typography>
         <Typography sx={{mb : 1}} color="text.secondary">Your password has been successfully reset. Click below  continue to log in.</Typography>

         
         <ButtonPrimary sx={{my : 1}} onClick={()=>navigate("/login")}>Continue to Login</ButtonPrimary>

        {/* <Grid container justifyContent='center' alignItems='center'>
            <LinkStyled to="/login">
            <Grid item container alignItems="center">
             <KeyboardBackspaceIcon sx={{ color : (t) => t.palette.mode === "dark" && t.palette.secondary.main }}/>
            <Typography color="text.secondary" sx={{fontSize : 14, ml : 1}}>Back to log in</Typography>
            </Grid>
            </LinkStyled>
        </Grid> */}
       
      
        </Grid>
            ) : (
                <Grid container direction="column" gap={"10px"}>
         <Typography variant='h2'>Set new password</Typography>
         <Typography sx={{mb : 1}} color="text.secondary">Your new password must be different to previously used passwords.</Typography>

         <Grid item>
          <LabelStyled variant='body2'>Password*</LabelStyled>
          <FieldStyled
          placeholder='Enter password'
          variant='outlined' 
          fullWidth
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
          onFocus={()=>{
            setPasswordEmptyErr(false)
            setInvalidPassErr(false)
          }}
          type={showPassword ? "text" : "password"}
          InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ fontSize: "30px" }}
                    position="start"
                  >
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ?  <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
         />
         {
            passwordEmptyErr &&
            <Typography variant='body2' color="vidhiRed.main">Password Required</Typography>
         }
          {
            invalidPassErr &&
            <Typography variant='body2' color="vidhiRed.main">Password must be at least 8 characters</Typography>
         }
         </Grid>

         <Grid item>
          <LabelStyled variant='body2'>Confirm Password*</LabelStyled>
         
          <FieldStyled
          placeholder='Enter confirm password'
           variant='outlined'
          fullWidth
          value={confirmPassword}
          onChange={(e)=>setConfirmPassword(e.target.value)}
          onFocus={()=>{
            setConfirmPassEmptyErr(false)
            setInvalidConfrimPassErr(false)
          }}
          type={showConfirmPassword ? "text" : "password"}
          InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ fontSize: "30px" }}
                    position="start"
                  >
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ?  <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
          />
          {
            confirmPassEmptyErr &&
            <Typography variant='body2' color="vidhiRed.main">Confrim Password Required</Typography>
         }
          {
            invalidConfrimPassErr &&
            <Typography variant='body2' color="vidhiRed.main">Confrim Password must match password</Typography>
         }
         </Grid>
        
        <ButtonPrimary sx={{my : 1}} onClick={handleResetPassword}>Reset Password</ButtonPrimary>

        <Grid container justifyContent='center' alignItems='center'>
            <LinkStyled to="/login">
            <Grid item container alignItems="center">
             <KeyboardBackspaceIcon sx={{ color : (t) => t.palette.secondary.main }}/>
            <Typography variant='body2' color="text.secondary" sx={{ml : 1}}>Back to log in</Typography>
            </Grid>
            </LinkStyled>
           
        </Grid>
       
      
        </Grid>
            )
         }
         


        
        </Grid>
        
      </Grid>
     
  );
}