import { getAxiosInstance, getAxiosInstanceMultipart } from "./AxiosWrapper";

const BASE_URL = "https://api.ecosystems.vidhilegalpolicy.in/api/v1";

export const performPostRequest = async function performPostRequest(
  endPoint,
  jsonRequest
) {
  let wrapper = await getAxiosInstance();
  let API_URL = BASE_URL + endPoint;
  // console.log("@@@ Options ===========", wrapper);
  console.log("@@@ JSON BODY ===========", jsonRequest);
  console.log("@@@ URL ==============", endPoint);

  return wrapper
    .post(API_URL, jsonRequest)
    .then((res) => {
      console.log("@@@ RESPONSE ==============", res);
      return res;
    })
    .catch((ex) => {
      throw ex;
    });
};


export const performGetRequest = async function performGetRequest(
  endPoint
  ) {
  let wrapper = await getAxiosInstance();
  let API_URL = BASE_URL + endPoint;
  // console.log("@@@ Options ===========", wrapper);
  // console.log("@@@ JSON BODY ===========", jsonRequest);
  console.log("@@@ URL ==============", endPoint);

  return wrapper
    .get(API_URL)
    .then((res) => {
      console.log("@@@ RESPONSE ==============", res);
      return res;
    })
    .catch((ex) => {
      throw ex;
    });
};



export const performPatchRequest = async function performPatchRequest(
  endPoint,
  jsonRequest
) {
  let wrapper = await getAxiosInstance();
  let API_URL = BASE_URL + endPoint;
  // console.log("@@@ Options ===========", wrapper);
  console.log("@@@ JSON BODY ===========", jsonRequest);
  console.log("@@@ URL ==============", endPoint);

  return wrapper
    .patch(API_URL, jsonRequest)
    .then((res) => {
      console.log("@@@ RESPONSE ==============", res);
      return res;
    })
    .catch((ex) => {
      throw ex;
    });
};