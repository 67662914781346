export const showSnackbarAction = (message) => {
    console.log("action challala",message)
    return {
      type: "SHOW_SNACKBAR",
      message: message,
    };
  };


  export const loaderAction = () => {
     return {
      type: "SHOW_LOADER",
      };
  };  