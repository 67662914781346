import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  InputBase,
  Typography,
  TablePagination,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Dialog,
  Divider,
  DialogActions,
  FormControl,
  Autocomplete,
  TextField,
  useMediaQuery
} from "@mui/material";
import LinkBar from "../../components/LinkBar/LinkBar";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  handleDateFormatforFilter,
  handleStatusColor,
  handleTextTransform,
} from "../../utils/StatusStyle";
import {
  FilterStyled,
  MyBox,
  PaginationContainerStyled,
  SearchBarStyled,
  StatusBox,
  ButtonOutlined,
  ButtonPrimary,
  UnderlinedTypography
} from "../../components/StyledComponents/StyledComponents";
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
 } from "../../components/StyledComponents/StyledTable/styledTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import MySelect from "../../components/Select/MySelect";
import MySelectMultiple from "../../components/Select/MySelectMultiple";
import * as CONST from "../../utils/Contants";
import MyDateRange from "../../components/DateRange/MyDateRange";
import MyPopover from "../../components/Popover/MyPopover";
import MySliderForESZArea from '../../components/Slider/MySliderForESZArea'
import { showPDF } from "../../utils/PdfUtils";




let minValue = 0
let maxValue = 100
let minDis = 0
let maxDis = 100 



const EszNotificationTracker = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));


  const [openFilter, setOpenFilter] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [eszNotyData, setEszNotyData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);


  //State for listing filter items
  
  const [ESZAreaMinMax, setESZAreaMinMax] = React.useState([0, 100]);
  const [EszDistanceMinMax, setEszDistanceMinMax] = useState([0,100]);

  const [statesList, setStatesList] = useState([]);
  const [eszStatusList, setEszStatusList] = useState([]);
  const [paTypeList, setPaTypeList] = useState([]);
  const [wildlifeCorridorList, setWildlifeCorridorList] = useState([]);
  // const [justificationList, setJustificationList] = useState([]);
  // const [interStateBorderList, setInterStateBorderList] = useState([]);

  //state for holding filter item values
  const [paTypesData, setPaTypesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [eszStatus, setEszStatus] = useState("");
  const [justification, setJustification] = useState("");
  const [interStateBorder, setInterStateBorder] = useState("");
  const [wildlifeCorridor, setWildlifeCorridor] = useState("");
  const [wildlifeCorridorAutoComplete, setWildlifeCorridorAutoComplete] = useState("");
  
  const [dateRangeDraft, setDateRangeDraft] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [dateRangeFinal, setDateRangeFinal] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

 
  //state for page and row per page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //useEffect for filter item data listing
  useEffect(() => {
    getFilterListData();
  }, []);

  // Esz Notification tracker data api calling
  useEffect(() => {
    if (isFilterApplied || searchQuery.length > 2) {
      console.log("useEffect filter wala")
      handleFilterApply();
    } else if(searchQuery.length === 0) {
      console.log("useEffect all data no filter")

      getEszNotificationData();
    }
  }, [page, rowsPerPage, searchQuery]);

  // useEffect(() => {
  //   console.log("useEffect serch query useEffect")
  //   if(searchQuery.length > 2 ){
  //     console.log("useEffect calling 2")
  //           handleFilterApply()
  //   }else if(searchQuery.length === 0){
  //     console.log("useEffect calling 0")

  //     getEszNotificationData()
  //   }

  //  }, [searchQuery])
  

  const getFilterListData = () => {
    dispatch({
      type: "ESZ_NOTIF_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
    });
  };

  const getEszNotificationData = () => {
    dispatch({
      type: "ESZ_NOTIF_DATA",
      successCallBack: successCallBack,
      path: `/esz_notification_trackers/?page=${page + 1}&per_page=${rowsPerPage}`,
    });
  };

  const getEszNotificationFilteredData = (path) => {
    dispatch({
      type: "ESZ_NOTIF_DATA",
      successCallBack: successCallBackForFilteredData,
      path: path,
    });
  };

  

  const successCallBackFilterListData = (data) => {
    minValue = data?.esz_min_area;
    maxValue = data?.esz_max_area;
    minDis = data?.esz_min_distance;
    maxDis = data?.esz_max_distance;
    setStatesList(data?.states_data);
    setPaTypeList(data?.pa_categories_data);
    setEszStatusList(data?.statuses_keys);
    setWildlifeCorridorList(data?.wildlife_corridor_data)
    let area = [minValue, maxValue]
    let distance = [minDis, maxDis]
    setEszDistanceMinMax(distance)
    setESZAreaMinMax(area)
  };

  const successCallBack = (res) => {
    setEszNotyData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    console.log("table data esz", eszNotyData, totalRecords);
  };

  const successCallBackForFilteredData = (res) => {
    setEszNotyData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    console.log("table data esz", eszNotyData, totalRecords);
    setOpenFilter(false)
    if(searchQuery.length > 2){
      setIsFilterApplied(false)
    }else{
      setIsFilterApplied(true)
    }
  };

  console.log("table data esz", eszNotyData, totalRecords);

 
  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const handleChangePage = (event, newPage) => {
    console.log("new Page", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    // if (!Array.isArray(newValue)) {
    //   return;
    // }

    console.log('setValue(newValue)', newValue);
    setESZAreaMinMax(newValue)
   
  };

  const handleChange2 = (event, newValue, activeThumb) => {
    console.log("newValue", newValue);
    console.log("active thumb", activeThumb);
    setEszDistanceMinMax(newValue)
   
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRecords) : 0;

  // No data found UI
  const renderNoDatafound = () => {
    if(eszNotyData?.length == 0 && !loader ){
      return (
        <Grid item xs={12} container justifyContent={"center"} alignContent={"center"} sx={{height : "30vh"}}>
            <Typography variant='subheading1'>No data found</Typography>
            <Typography variant='body1' align='center' sx={{width : '100%', mt: '10px'}}>Try adjusting your search or filter to find what you are looking for.</Typography>
       </Grid>
      )
     }

    // let filterData = eszNotyData.filter((item) =>
    //   item?.protected_area_details?.name
    //     .toLowerCase()
    //     .includes(tableSearch.toLowerCase()) ||item?.justification?.toLowerCase().includes(tableSearch.toLowerCase())
    // );
    // if (tableSearch.length > 0 && filterData.length < 1) {
    //   return (
    //     <Grid
    //       item
    //       xs={12}
    //       container
    //       justifyContent={"center"}
    //       alignContent={"center"}
    //       sx={{ height: "30vh" }}
    //     >
    //       <Typography variant="subheading1">No data found</Typography>
    //       <Typography
    //         variant="body1"
    //         align="center"
    //         sx={{ width: "100%", mt: "10px" }}
    //       >
    //         {CONST.NoDataFound}
    //       </Typography>
    //     </Grid>
    //   );
    // }
  };

  const handleChangePaTypesdata = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    setPaTypesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeStatesData = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);

    setStatesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getSelectedPA = () => {
    let str = "";
    paTypesData.forEach((item) => {
      str = str + "&pa_category[]=" + item.id;
    });
    return str;
  };

  const getSelectedStates = () => {
    let str = "";
    statesData.forEach((item) => {
      str = str + "&state_ids[]=" + item.id;
    });
    return str;
  };

  const handleFilterApply = () => {
    let path = `/esz_notification_trackers?page=${page + 1}&per_page=${rowsPerPage}`;
    let isSearching = false;
    //for search
    if (searchQuery.length > 2) {
      isSearching = true;
      path = path + `&name=${searchQuery}`;
    }
    if (paTypesData.length > 0) {
      isSearching = true;
      path = path + getSelectedPA();
    }
    if (statesData.length > 0) {
      isSearching = true;
      path = path + getSelectedStates();
    }
    if (dateRangeDraft[0].startDate && dateRangeDraft[0].endDate) {
      isSearching = true;
      path = path + `&date_of_draft_notification_start=${handleDateFormatforFilter(dateRangeDraft[0].startDate)}&date_of_draft_notification_end=${handleDateFormatforFilter(dateRangeDraft[0].endDate)}`;
    }
    if (dateRangeFinal[0].startDate && dateRangeFinal[0].endDate) {
      isSearching = true;
      path =
        path + `&date_of_final_notification_start=${handleDateFormatforFilter(dateRangeFinal[0].startDate)}&date_of_final_notification_end=${handleDateFormatforFilter(dateRangeFinal[0].endDate)}`;
    }
    if (interStateBorder) {
      isSearching = true;
      path = path + `&border_state_ids[]=${interStateBorder}`;
    }
    if (wildlifeCorridor) {
      isSearching = true;
      path = path + `&wildlife_corridor_ids=${wildlifeCorridor}`; 
    }
    if (eszStatus) {
      isSearching = true;
      path = path + `&status[]=${eszStatus}`;
    }
    if(ESZAreaMinMax[0] !== minValue || ESZAreaMinMax[1] !== maxValue){
      isSearching = true;
      path = path + `&area_of_esz_start=${ESZAreaMinMax[0]}&area_of_esz_end=${ESZAreaMinMax[1]}`
    }
    if(EszDistanceMinMax[0] !== minDis || EszDistanceMinMax[1] !== maxDis){
      isSearching = true;
      path = path + `&distance_of_esz_start=${EszDistanceMinMax[0]}&distance_of_esz_end=${EszDistanceMinMax[1]}`
    }
    if (isSearching) {
      path = path + "&search=true";
    }
    getEszNotificationFilteredData(path);
  };

  const handleFilterReset = () => {
    setPaTypesData([]);
    setStatesData([]);
    setDateRangeDraft((date) => {
      return [{ ...date[0], startDate: new Date(), endDate: null }];
    });
    setDateRangeFinal((date) => {
      return [{ ...date[0], startDate: new Date(), endDate: null }];
    });
    
    setESZAreaMinMax([minValue, maxValue])
    setEszDistanceMinMax([minDis, maxDis])
    setEszStatus("");
    setInterStateBorder("")
    setWildlifeCorridor("")
    setIsFilterApplied(false);
    setOpenFilter(false);
    getEszNotificationData();
  };

  const wildlifeCorridorListAutoComplete = wildlifeCorridorList?.map( item =>{
    return {label : item.name, id : item?.id}
  })

  return (
    <>
      <Grid
        item
        container
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === "dark"
              ? t.palette.background.default
              : t.palette.grey[100],
        }}
      >
        <LinkBar title="ESZ Notification Tracker" />

        {/* heading search and filter contaiener */}
        <Typography variant="h2" sx={{ pl: isXs ? "16px" : '32px', mt: "20px" }}>
          ESZ Notification Tracker
        </Typography>
        <Grid
          container
          sx={{
            px: isXs ? "16px" : '32px',
            my: "1.5rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            // sx={{
            //   display: ["none", "block", "block", "block", "block"],
            // }}
          >
            <SearchBarStyled>
              <SearchIcon />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here"
                inputProps={{ "aria-label": "search google maps" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </SearchBarStyled>
          </Box>
          {
              isXs ? (
                isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}}/>)
              )  : (
                <FilterStyled isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
              )
            }
        </Grid>

        {/* ESz notification tracker Table */}
        <Grid item container sx={{ pl: isXs ? "16px" : '32px', pb: "20px", width: "100%" }}>
          {loader ? (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                alignItems: "center",
                width: 1,
              }}
            >
              <Loader />
            </Box>
          ) : (
            <StyledTableContainer>
              <Table sx={{ overflowX: "scroll" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      PA Name
                    </StyledTableCell>
                    <StyledTableCell>PA Type</StyledTableCell>
                    <StyledTableCell>State/UT</StyledTableCell>
                    <StyledTableCell>Biographic Zone</StyledTableCell>
                    <StyledTableCell>Biosphere Zone</StyledTableCell>
                    <StyledTableCell>Interstate Border</StyledTableCell>
                    <StyledTableCell>
                      Recognized Wildlife corridors
                    </StyledTableCell>
                    <StyledTableCell>ESZ Status</StyledTableCell>
                    <StyledTableCell>
                      Date of Draft Notification
                    </StyledTableCell>
                    <StyledTableCell>
                      Date of Final Notification
                    </StyledTableCell>
                    <StyledTableCell>
                      ESZ Area (as per latest notification)
                    </StyledTableCell>
                    <StyledTableCell>Min ESZ</StyledTableCell>
                    <StyledTableCell>Max ESZ</StyledTableCell>
                    <StyledTableCell>
                      Justification for zero boundary of ESZ
                    </StyledTableCell>
                    <StyledTableCell>
                      No. of ESZ committee meetings
                    </StyledTableCell>
                    <StyledTableCell>
                      Proceedings by ESZ committee
                    </StyledTableCell>
                    <StyledTableCell>Court Cases</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eszNotyData?.map((row) => (
                      <StyledTableRow key={row?.id}>
                        {/* <StyledTableCell><FileDownloadIcon sx={{color : (t) => t.palette.primary.dark}} /></StyledTableCell> */}
                        {/* <StyledTableCell sx={{minWidth : "270px !important"}}><Box sx={{display : 'flex', alignItems : 'center', gap : '10px', color : (t)=>t.palette.primary.dark}}><ArrowCircleDownIcon sx={{color : (t) => t.palette.primary.dark, cursor :'pointer'}} /><Typography sx={{fontSize : 14}} color="primary.dark">{row.paName}</Typography></Box></StyledTableCell> */}
                        <StyledTableCell
                          sx={{
                            minWidth: "270px !important",
                            }}
                        >
                          <Typography
                            sx={{ fontSize: 14, cursor :'pointer' }}
                            color="primary.dark"
                            onClick={() =>
                              navigate("/paInformation", { state: { paID: row?.protected_area_details?.id } })
                            }
                          >
                            {row?.protected_area_details?.name ?? "NA"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.protected_area_details?.pa_category.length !== 0 ? row?.protected_area_details?.pa_category[0]?.name : "NA"} 
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.protected_area_details?.states.length !== 0
                            ? row?.protected_area_details?.states[0]?.name
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.protected_area_details?.bio_geographic_zone ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.bio_sphere_zone ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.protected_area_details?.border_states.length !== 0
                            ? row.protected_area_details?.border_states[0]?.name
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.wildlife_corridor?.name ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          <Box sx={{ display: "flex" }}>
                            <StatusBox style={handleStatusColor(row?.status)}>
                              {row?.status ?? "NA"}
                            </StatusBox>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {/* {row?.date_of_draft_notification ?? "NA"} */}
                          {
                            row?.date_of_draft_notification ? (
                              <UnderlinedTypography
                              red
                              color="primary.dark"
                                sx={{
                                  fontSize :'14px'
                                }}
                                onClick={() =>
                                  showPDF(
                                    row?.detail_draft_notification_file?.link,
                                    dispatch,
                                    "No link found"
                                  )
                                }
                              >
                                {row?.date_of_draft_notification}
                              </UnderlinedTypography>
                             ) : "NA"
                          }
                         
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {/* {row?.date_of_final_notification ?? "NA"} */}
                          {
                            row?.date_of_final_notification ? (
                              <UnderlinedTypography
                            red
                            color="primary.dark"
                              sx={{
                                fontSize :'14px'
                              }}
                              onClick={() =>
                                showPDF(
                                  row?.detail_final_notification_file?.link,
                                  dispatch,
                                  "No link found"
                                )
                              }
                            >
                              {row?.date_of_final_notification}
                            </UnderlinedTypography>
                            ) : "NA"
                          }
                          
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.area)) }}>
                         {row?.area != null ? `${row?.area} sq km` : "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.min_area)) }}>
                          {row?.min_area != null ? `${row?.min_area} km` : "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.max_area)) }}>
                        {row?.max_area != null ? `${row?.max_area} km` : "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.justification ?? "NA"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          {row?.no_of_esz_commmitte_mettings?.length > 0 ? row?.no_of_esz_commmitte_mettings?.length : "NA" }
                        </StyledTableCell>
                        {/* <StyledTableCell sx={{minWidth : "200px !important"}}><MyBox column><Typography variant='body2'>{row?.esz_meetings?.meeting_date}</Typography> <Typography variant='body2'>{row.fullDecison}</Typography> <Typography variant='body2'>{row.fullDecison}</Typography></MyBox></StyledTableCell> */}
                        <StyledTableCell sx={{ minWidth: "200px !important" }}>
                          <MyBox column>
                            {row?.no_of_esz_commmitte_mettings?.length > 0 ? row?.no_of_esz_commmitte_mettings?.map((item) => (
                              <Typography
                              sx={{cursor : 'pointer'}}
                              onClick={()=>navigate("/meeting", {state : {id : item?.id, type : "COMMITTE" } })}
                              key={item?.id} variant="body2">
                                {item.meeting_date}
                              </Typography>
                            )) : "NA"}
                          </MyBox>
                        </StyledTableCell>
                        <StyledTableCell
                         onClick={() =>
                          showPDF(
                            row?.court_case_link,
                            dispatch,
                            "No link found"
                          )
                        }
                          sx={{
                            minWidth: "200px !important",
                            }}
                        >
                          <MyPopover caseTitle={row?.court_case_title} content={row?.court_case} />
                         </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 48 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}

          {renderNoDatafound()}

          {/* Table Pagination starts here */}
          <Grid item xs={12}>
            <PaginationContainerStyled
              container
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography sx={{ pl: 1 }} variant="body2">
                {totalRecords} entries found
              </Typography>
              <TablePagination
                component="div"
                count={totalRecords}
                page={page}
                rowsPerPageOptions={[5, 10, 20]}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </PaginationContainerStyled>
            {/* Table pagination end */}
          </Grid>
        </Grid>
      </Grid>

      {/* on hover summay menu */}
      {/* <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={4}
      >
        <Typography sx={{ p: 1 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi maxime consequatur vel odio, facere dicta sequi inventore repellendus accusantium doloremque expedita, aspernatur atque, odit illo. Ab omnis cupiditate sapiente beatae illum atque doloremque earum harum perspiciatis, perferendis quo id similique iure doloribus blanditiis consequatur dicta corporis cum suscipit eum quam?
         </Typography>
      </Popover> */}

      {/* Filter Dialog start here */}
      <Dialog
        // fullWidth={fullWidth}
        //  maxWidth={maxWidth}
        // maxWidth={['xs','sm','md','xl','lg']}
        // maxWidth={"80vw"}
        fullScreen={isXs}
        open={openFilter}
        onClose={handleFilterClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "90vw",
            // backgroundColor : 'khaki'
          },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(3,1fr)",
                  lg: "repeat(3,1fr)",
                  xl: "repeat(3,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              {/* PA Type Select     */}
              <FormControl fullWidth>
                <Typography>Protected Area Type</Typography>
                <MySelectMultiple
                  value={paTypesData}
                  onChange={handleChangePaTypesdata}
                  menuArray={paTypeList}
                />
              </FormControl>

              {/* Date of Draft Select     */}
              <FormControl fullWidth>
                <Typography>Date Draft ESZ</Typography>
                <MyDateRange
                  onChange={(item) => setDateRangeDraft([item.selection])}
                  dateRange={dateRangeDraft}
                />
              </FormControl>

              {/* Date of Final Select     */}
              <FormControl fullWidth>
                <Typography>Date Final ESZ</Typography>
                <MyDateRange
                  onChange={(item) => setDateRangeFinal([item.selection])}
                  dateRange={dateRangeFinal}
                />
              </FormControl>

              {/* State Select */}
              <FormControl fullWidth>
                <Typography>State</Typography>
                <MySelectMultiple
                  value={statesData}
                  onChange={handleChangeStatesData}
                  menuArray={statesList}
                />
              </FormControl>

              {/* ESZ Status */}
              <FormControl fullWidth>
                <Typography>ESZ Status</Typography>
                <MySelect
                  value={eszStatus}
                  onChange={(e) => setEszStatus(e.target.value)}
                  menuArray={eszStatusList}
                />
              </FormControl>

                 {/* InterState Border */}
                 <FormControl fullWidth>
                <Typography>Interstate Border</Typography>
                <MySelect
                  value={interStateBorder}
                  onChange={(e) => setInterStateBorder(e.target.value)}
                  menuArray={statesList}
                />
              </FormControl>

                 {/* Recognized wildlife corridor */}
                 <FormControl fullWidth>
                <Typography>Recognized wildlife corridor</Typography>
                <MySelect
                  value={wildlifeCorridor}
                  onChange={(e) => setWildlifeCorridor(e.target.value)}
                  menuArray={wildlifeCorridorList}
                />
                
              </FormControl>

               {/* Recognized wildlife corridor */}
               {/* <FormControl fullWidth>
                <Typography>Recognized wildlife corridor</Typography>
                <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={wildlifeCorridorListAutoComplete}
                       
                        onChange={(event , value, reason)=>{
                         setWildlifeCorridorAutoComplete(value?.id)
                        }}
                        renderInput={(params) => <TextField {...params}  />}
                      />
  
              </FormControl> */}

    

              {/* Justification for zero boundary of ESZ */}
              {/* <FormControl fullWidth>
                <Typography>Justification for zero boundary</Typography>
                <MySelect
                  value={justification}
                  onChange={(e) => setJustification(e.target.value)}
                  menuArray={States}
                />
              </FormControl> */}

              {/* ESZ Area(sqkm)  */}
              <FormControl fullWidth sx={{pl : '10px', pr :'20px'}}>
                <Typography>ESZ Area (sq km)</Typography>
                  <MySliderForESZArea 
                   value={ESZAreaMinMax}
                   onChange={handleChange1}
                   maxValue={maxValue}
                  />
               </FormControl>

              {/* Min ESZ(km)  */}
              <FormControl fullWidth>
                <Typography>ESZ distance (km)</Typography>
                <MySliderForESZArea 
                 onChange={handleChange2}
                 value={EszDistanceMinMax}
                 maxValue={maxDis}
                 />
              </FormControl>
            </Box>

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined
            onClick={handleFilterReset}
            sx={{ textTransform: "none" }}
          >
            Reset
          </ButtonOutlined>
          <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EszNotificationTracker;
