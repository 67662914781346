import * as React from 'react';
import {Box, Grid, Typography} from "@mui/material"
import { alpha, styled } from '@mui/material/styles';
import {colors} from '../../themes/colors'

export const OverViewItem = styled(Grid)(({  theme }) => ({
      display : "flex",
      flexDirection : "column",
      borderRadius : "4px",
      padding : "1rem",
      "&:hover":{
        backgroundColor : theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.vidhiRed.lightest
      }
}));
  
  
