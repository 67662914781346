
export const createUserAction = (data) => {
  return {
    type: "CREATE_USER",
    payload: data,
  };
};

export const userLoginAction = (data) => {
  return {
    type: "LOGIN_USER",
    paylaod : data
  };
};

// export const userForgotAction = (data) => {
//   return {
//     type: CONST.USER_FORGOT_ACTION,
//     paylaod: data,
//   };
// };

// export const verifiedOtpAction = (data) => {
//   return {
//     type: CONST.VERIFIED_OTP_ACTION,
//     payload: data,
//   };
// };

// export const userChangedPassword = (data) => {
//   return {
//     type: CONST.USER_CHANGED_PASSWORD,
//     payload: data,
//   };
// };



