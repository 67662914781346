import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TextTruncate2 } from '../StyledComponents/StyledComponents'
import moment from 'moment'



const Card = ({cardImg, title, subtitle, summary, date, item, to, billNo, id}) => {
    const navigate = useNavigate()
    return (
            <Box
             sx={{
              display : 'grid',
              gridTemplateRows : "max-content"
            }}
              onClick={()=>navigate(`${to}`, {state : {id : item?.id}})}
             >
            <Box sx={{height : "250px", overflow :'hidden'}}> 
            <Box
             component="img"
             src={cardImg} 
             alt="no-image"
             style={{width : "100%", maxHeight : "250px", objectFit :'fill'}}
             loading="lazy"
             />
             </Box>
          <Box
          sx={{ 
          padding : '20px', 
          background : (t)=>t.palette.primary.light,
          boxShadow: "rgba(123, 126, 138, 0.12) 0px 4px 4px 0px",
          cursor : 'pointer',
          marginTop : '-100px',
          marginLeft : '50px',
          display : 'grid',
          gridTemplateRows : 'auto',
          // justifyContent : 'flex-start',
          alignContent :'flex-start',
          // minHeight : '200px',
          gap : "10px",
          "&:hover":{
            backgroundColor : (t) => t.palette.mode === "light" && t.palette.vidhiRed.light,
            
            "&>p, &>h1, &>h3, &>h6" :{
              color : "#ffffff",
            },
            "&>:nth-child(3)":{
              backgroundColor : "#ffffff" 
              
            },
            "& > :nth-child(2) *":{
              color : "#ffffff" 
              
            }
            
          }
          
           }}>
           <Typography variant='subtitle2' color="primary.dark">{title}</Typography>
          
            <Box>

           <Typography variant='h7' sx={{mt : 1, 
            width : '90%'
          }} color="text.primary" >{subtitle}</Typography>
           {
             summary &&
             <TextTruncate2 variant='body1' color="text.primary">{summary}</TextTruncate2>
            }
            {
              billNo &&
              <Typography variant='body2'>Bill : {billNo}</Typography>
              
            }
            </Box>
            
          
             <Grid item sx={{width : 40, height : 4, backgroundColor : '#FF8278'}}></Grid>
            <Typography variant='body3' color="text.primary" >{moment(date).format("DD MMM YYYY")}</Typography>
        </Box>
            </Box>
  )
  }
  
  export default Card


    // const Card = ({cardImg, title, subtitle, summary, date, item, to, billNo, id}) => {
    //     const navigate = useNavigate()
    //     return (
    //             <Box
    //              sx={{display : 'flex', flexDirection :'column'}}
    //               onClick={()=>navigate(`${to}`, {state : {id : item?.id}})}
    //              >
    //             <Box sx={{height : "250px", overflow :'hidden'}}> 
    //             <Box
    //              component="img"
    //              src={cardImg} 
    //              style={{width : "100%", maxHeight : "250px", objectFit :'fill'}}
    //              loading="lazy"
    //              />
    //              </Box>
    //           <Box
    //           sx={{ 
    //           padding : '20px', 
    //           background : (t)=>t.palette.primary.light,
    //           boxShadow: "rgba(123, 126, 138, 0.12) 0px 4px 4px 0px",
    //           cursor : 'pointer',
    //           marginTop : '-100px',
    //           // ...( item?.thumbnail_detail?.link && {
    //           //   marginTop : '-100px'
    //           // }),
    //           marginLeft : '50px',
    //           display : 'flex',
    //           flexDirection : 'column',
    //           minHeight : '200px',
    //           gap : "10px",
    //           "&:hover":{
    //             backgroundColor : (t) => t.palette.mode === "light" && t.palette.vidhiRed.light,
    //             "&>p, &>h1, &>h3, &>h6" :{
    //               color : "#ffffff",
    //             },
    //             "&>div":{
    //               // backgroundColor : "#ffffff" 
                  
    //             }
                
    //           }
              
    //            }}>
    //            <Typography variant='subtitle2' color="primary.dark">{title}</Typography>
    //            <Typography variant='h7' sx={{mt : 1, 
    //             width : '80%'
    //           }} color="text.primary" >{subtitle}</Typography>
    //            {
    //              summary &&
    //              <TextTruncate2 variant='body1' color="text.primary">{summary}</TextTruncate2>
    //             }
    //             {
    //               billNo &&
    //               <Typography variant='body2'>Bill : {billNo}</Typography>
    
    //             }
              
    //            <Grid item sx={{width : 40, height : 4, backgroundColor : '#FF8278'}}></Grid>
    //             <Typography variant='body3' color="text.primary" >{moment(date).format("DD MMM YYYY")}</Typography>
    //         </Box>
    //             </Box>
    //   )
    // }
    
    // export default Card