import React from "react";
import { GoogleMap, KmlLayer, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 20.5937,
  lng: 78.9629,
};

const MyComponent = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCwv6RWio5eJBZ5T9mEJfAa1ysutFbDpz0",
  });

  const [map, setMap] = React.useState(null);
  const [showDraft, setShowDraft] = React.useState(true);

 

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.setOptions({
      zoom : 1,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ["roadmap", "satellite", "terrain","hybrid"],
      },
      fullscreenControl: true,
      fullscreenControlOptions : {
        position: window.google.maps.ControlPosition.RIGHT_CENTER,
      }
     });
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function generateRandom() {
    return Math.random() * 10000000000000000;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={5}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <KmlLayer
        url={props.paKML}
        key={generateRandom()}
        // options={{ preserveViewport: true }}
      />
      {props.draftChip && (
        <KmlLayer url={props.draftKML} key={generateRandom()} />
      )}
      {props.finalChip && (
        <KmlLayer url={props.finalKML} key={generateRandom()} />
      )}
      {props.nearByChip &&
        props.nearByPaList.map((pa) => {
          let kmlURL = pa?.detail_attachment_notified?.link;
          return <KmlLayer url={kmlURL} key={generateRandom()} />;
        })}

      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(MyComponent);
