import { useRouteError } from "react-router-dom";


import { Grid, Typography  } from '@mui/material'
import React from 'react'
import TopBar from "../components/Navbars/TopBar";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/Footer";



export default function ErrorPage() {

  const error = useRouteError();
  console.error(error);

  return (
    <>   
    <Grid
     container
     flexDirection="column"
     alignContent={'flex-start'}
     justifyContent="flex-start"
     style={{width : "100%", minHeight : '100dvh'}}
    //  style={{backgroundColor : "#eeee"}} 
     > 
    <TopBar />
    <Navbar />
    <Grid container  direction={"column"} alignItems="center" justifyContent={"center"} 
    sx={{
      height : "60vh",
      gap : "10px",
      backgroundColor: (t) =>
        t.palette.mode === "dark"
          ? t.palette.background.default
          : t.palette.grey[100],
    }}>
      <Typography variant="regular1">Oops!</Typography>
      <Typography variant="body1">Sorry, an unexpected error has occurred due to.</Typography>
      <Typography variant="body1">
        {error.statusText || error.message}
      </Typography>
    </Grid>
   
    <Footer />
    </Grid>
    </>

  )
}


// import { useRouteError } from "react-router-dom";

// export default function ErrorPage() {
//   const error = useRouteError();
//   console.error(error);

//   return (
//     <div id="error-page">
//       <h1>Oops!</h1>
//       <p>Sorry, an unexpected error has occurred due to.</p>
//       <p>
//         <i>{error.statusText || error.message}</i>
//       </p>
//     </div>
//   );
// }
