import * as React from 'react';
import { Button, Typography, Grid, useTheme} from '@mui/material';
import OTPInput from 'react-otp-input';

import LoginSignUpBackground from '../../components/LoginSignUpBackground/LoginSignUpBackground';
import { ButtonPrimary, } from '../../components/StyledComponents/StyledComponents';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getItem } from '../../utils/validators';
import Loader from '../../components/Loader/Loader';

const OtpPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme();
  const loader = useSelector( (state) => state.commonReducer.showLoader)
  
  const [otp, setOtp] = useState('');

  const initialMin = 2;
  const initialSec = 0;
  const [min, setMin] = useState(initialMin);
  const [sec, setSec] = useState(initialSec);
  const [isTimerOn, setIsTimerOn] = useState(true);


  useEffect(() => {
    let intervalId;
    // console.log("timer...");
    intervalId = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      }
      if (sec === 0) {
        if (min === 0) {
          clearInterval(intervalId);
          setIsTimerOn(false)
          // dispatch({ type: "ENABLE_RESEND" });
       } else {
          setMin(min - 1);
          setSec(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });
  // const {state} = useLocation()
  // const {email, from} = state;

   let from = getItem("from");
   let email = getItem("email")

  const successCallBack = (email) => {
    if(from === 'signup')
    navigate("/");
    else if(from === 'forgot'){
    navigate("/resetpassword")
    localStorage.setItem("otp",otp)
    }
  } 
  
  

 const handleVerifyOTP = () => {
    
    if(from && from=== "signup")
        {
          dispatch({
            type : "USER_VERIFY_OTP",
            payload : {
              email : email,
              otp : otp,
              successCallBack : successCallBack,
              setOtp : setOtp
            }
          })
        }
      else if(from && from === 'forgot'){
        dispatch({
          type : "CONFIRM_EMAIL_OTP",
          payload : {
            email : email,
            otp : otp,
            successCallBack : successCallBack,
            setOtp : setOtp
          }
        })
      }
  }

  const handleResendOtp = () => {
    dispatch({
      type : "USER_SEND_RESET",
      payload : {
        email : email,
        successCallBack : successCallBackResendOTP,
      }
    })
  }

  const successCallBackResendOTP = () => {
    setIsTimerOn(true)
    setMin(0)
    setSec(10)
  }
  

  return (
   
    <Grid 
    justifyContent={"center"}
    container style={{width : "100vw", height : "100vh"}}
    sx={{
      alignContent : ["flex-start", "flex-start","none", "none", "none"]
    }}
    >
   
   <LoginSignUpBackground />

   <Grid item container xs={12} sm={9} md={4} lg={4} xl={4} 
   sx={{
    py : '40px',
    px : ['40px', '40px', '20px','20px','20px'],
    backgroundColor : (t) => t.palette.mode === "dark" && t.palette.primary.main,
    alignItems : ['flex-start', 'flex-start', 'center','center','center']
    }}
   >
      {
        loader ? <Loader /> : (
          <Grid container direction="column" gap={"10px"}>
          <Typography variant='h2' color="text.primary">Check your email</Typography>
          <Typography color="text.secondary" sx={{mb : 1}}>We sent an otp to your email address<br />
          <b style={{fontSize : 14}}>{email}</b>
          </Typography>
 
          <Grid item container>
 
           <OTPInput 
             value={otp}
             onChange={setOtp}
             numInputs={4}
             renderSeparator={<span></span>}
             renderInput={(props) => <input {...props} />}
             shouldAutoFocus
             inputType='tel'
             containerStyle={{gap : "25px", margin : 'auto', display: 'flex', alignItems:'center'}}
             inputStyle={
               {
               border : `2px solid ${theme.palette.vidhiRed.main}`,
               borderRadius : '4px',
               width : '50px',
               height :'50px',
               fontSize : '25px',
               color : theme.palette.vidhiRed.main
                }
             }
           />
         </Grid>
 
          
 
          <ButtonPrimary 
          sx={{my : 1}} 
          disabled={otp.length < 4} 
         //  onClick={()=>{navigate("/resetpassword")}}
         onClick={handleVerifyOTP}
        >Verify OTP
          </ButtonPrimary>

          <Grid container>
        {min === 0 && sec === 0 ? null : (
          
          <Typography align="center">
            Time Remaining : 0{min} : {sec < 10 ? `0${sec}` : sec}
          </Typography>
        )}
       </Grid>
 
         <Grid container justifyContent='center' alignItems='center'>
             <Typography variant='body2' color="text.secondary">Didn’t receive the email?</Typography>
              {/* <Typography variant='subtitle2' color="vidhiRed.light" sx={{ml : 1, fontSize : 14}}>Click to resend</Typography> */}
             <Button onClick={handleResendOtp} disabled={isTimerOn}>Click to resend</Button>
         </Grid>
        
         {/* <Grid container justifyContent='center' alignItems='center'>
             <LinkStyled to="/login">
             <Grid item container alignItems="center">
             <KeyboardBackspaceIcon sx={{ color : (t) => t.palette.secondary.main }}/>
             <Typography variant='body2' color="text.secondary" sx={{ ml : 1}}>Back to log in</Typography>
             </Grid>
             </LinkStyled>
            
         </Grid> */}
 
         {/* {otp} */}
         </Grid>
        )
      }
    
    
         


        
        </Grid>
   </Grid>
  )
}

export default OtpPage