import React, { useState }from 'react'
import { Grid, Table, TableBody, TableCell,  TableHead, TableRow, TablePagination , useTheme, Typography, Box} from '@mui/material'
import { StyledTableCell, StyledTableContainer, StyledTableRow } from '../../components/StyledComponents/StyledTable/styledTable'
import { useNavigate } from 'react-router-dom';
import { MyBox, PaginationContainerStyled } from '../../components/StyledComponents/StyledComponents';
import { useSelector } from 'react-redux';
import  Loader from '../../components/Loader/Loader'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { handleTextTransform } from '../../utils/StatusStyle';


 const PaTable = ({data, searchValue, totalRecords, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, isAscending,handleAscendDescendAreaPA, isAscendDN, handleAscendDescendDateofNotify}) => {

  
  const loader = useSelector( (state) => state.commonReducer.showLoader)
  const theme = useTheme();
  const navigate = useNavigate();


  const renderNoDatafound = () => {
     if(data.length == 0 && !loader ){
      return (
        <Grid item xs={12} container justifyContent={"center"} alignContent={"center"} sx={{height : "30vh"}}>
            <Typography variant='subheading1'>No data found</Typography>
            <Typography variant='body1' align='center' sx={{width : '100%', mt: '10px'}}>Try adjusting your search or filter to find what you are looking for.</Typography>

       </Grid>
      )
     }
    // let filterData = data.filter( 
    //     (item) => item.name?.toLowerCase().includes(searchValue?.toLowerCase())
    //     )
    //   if(searchValue.length > 0 && filterData.length < 1){
    //   return (
    //     <Grid item xs={12} container justifyContent={"center"} alignContent={"center"} sx={{height : "30vh"}}>
    //         <Typography variant='subheading1'>No data found</Typography>
    //         <Typography variant='body1' align='center' sx={{width : '100%', mt: '10px'}}>Try adjusting your search or filter to find what you are looking for.</Typography>
    //    </Grid>
    //      ) 
    // }
    
  }

 

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRecords) : 0; 
  return (
    <>
    {
      loader ? <Box sx={{height : '50vh', display : 'flex', alignItems :'center', width : 1}}><Loader /></Box> : (
        <StyledTableContainer>
        <Table >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{minWidth :' 100px'}}>S.No</StyledTableCell>
              <StyledTableCell sx={{minWidth :' 200px'}}>Protected Area Name</StyledTableCell>
              <StyledTableCell sx={{minWidth :' 200px'}}>State/Union Territory</StyledTableCell>
              <StyledTableCell sx={{minWidth :' 200px'}}>Special Recognition</StyledTableCell>
              <StyledTableCell sx={{minWidth :' 200px'}}>Ecosensitive Zone</StyledTableCell>
              <StyledTableCell sx={{minWidth :' 200px'}}><MyBox sx={{gap : '5px'}}>Area of PA{ isAscending === "default" || isAscending === "asc" ? <ArrowUpwardIcon onClick={handleAscendDescendAreaPA} sx={{fontSize: 16, cursor : 'pointer', color : isAscending === "default" && 'gray' }}/> : <ArrowDownwardIcon onClick={handleAscendDescendAreaPA} sx={{fontSize: 16, cursor : 'pointer' }} />}</MyBox></StyledTableCell>
              <StyledTableCell sx={{minWidth :' 200px'}}><MyBox sx={{gap : '5px'}}>Date of Notification{ isAscendDN === 'default' || isAscendDN === "asc" ? <ArrowUpwardIcon onClick={handleAscendDescendDateofNotify} sx={{fontSize: 16, cursor : 'pointer', color : isAscendDN === "default" && 'gray' }}/> : <ArrowDownwardIcon onClick={handleAscendDescendDateofNotify} sx={{fontSize: 16, cursor : 'pointer' }} />}</MyBox></StyledTableCell>
              <StyledTableCell sx={{minWidth :' 100px'}}>View</StyledTableCell>
              {/* <StyledTableCell></StyledTableCell> */}
           </TableRow>
          </TableHead>
          <TableBody>
          {
              data?.map((row, index) => (
                <StyledTableRow key={index}>
                <StyledTableCell>{page * rowsPerPage + (index+1)}</StyledTableCell>
                <StyledTableCell>{row?.name ?? "NA"}</StyledTableCell>
                <StyledTableCell>{row?.states[0]?.name ?? "NA"}</StyledTableCell>
                <StyledTableCell>{row?.special_recognitions[0]?.name ?? "NA"}</StyledTableCell>
                <StyledTableCell>{row?.esz_notification_tracker?.status  ?? "NA"}</StyledTableCell>
                <StyledTableCell sx={{...(handleTextTransform(row?.area_of_pa))}}>{row?.area_of_pa != null ? `${row.area_of_pa} sq km` : "NA"}</StyledTableCell>
                <StyledTableCell>{row?.date_of_notify ?? "NA"}</StyledTableCell>
                <StyledTableCell><Typography onClick={()=>navigate("/paInformation", {state : { paData : row, paID : row.id}})} color="vidhiRed.light" variant='subtitle2' sx={{cursor :'pointer'}}>View</Typography></StyledTableCell>
               </StyledTableRow>
              ))
            }
            {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 48 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={10} />
                  </TableRow>
                )}
          </TableBody>
        </Table>
       </StyledTableContainer>
      ) 
    }
   

     {
      renderNoDatafound()
     }
     
     <Grid item xs={12}>
      <PaginationContainerStyled container justifyContent="space-between" alignItems={"center"}>
      <Typography sx={{pl : 1}} variant='body2'>{totalRecords} entries found</Typography>
      <TablePagination
      component="div"
      count={totalRecords}
      page={page}
      rowsPerPageOptions={[5,10,20]}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      
     />
      </PaginationContainerStyled>
    </Grid>
     </>
  )
}

export default PaTable