import {TableRow, TableCell, TableContainer, Grid} from "@mui/material"
import { tableCellClasses } from '@mui/material';

import {  styled } from '@mui/material/styles';


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.mode === "dark" ? "#555555"  : theme.palette.text.alwaysblack,
      // backgroundColor: theme.palette.mode === "dark" ? "#555555"  : theme.palette.secondary.light,
      fontSize : 14,   
      color: theme.palette.text.alwayswhite,
      fontWeight: 600,
      lineHeight : '16px',
       [theme.breakpoints.up('md')]:{
        '&:first-child': {
          position: "sticky",
          left: 0,
          zIndex: 1,
          },
       
      },
     

    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.common.black,
      lineHeight : '16px',
      borderColor :  "#AAAAAA",
      textTransform : "capitalize"
      
      
      },

  }));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.mode === "dark" ? "#303030" : theme.palette.grey[200],
      [theme.breakpoints.up('md')]:{
        '& > :first-child': {
          backgroundColor: theme.palette.mode === "dark" ? "#303030" : theme.palette.grey[200],
        },

      }
     
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.mode === "dark" ? "#1E1E1E" : "#ffffff",
      [theme.breakpoints.up('md')]:{

        '& > :first-child': {
          backgroundColor: theme.palette.mode === "dark" ? "#1E1E1E" : "#ffffff",
        },
      }
      },
    
    [theme.breakpoints.up('md')]: {
        '& > :first-child': {
          position: "sticky",
          left: 0,
          zIndex: 1,
        },
      },
    // hide last border
    '&:last-child td, &:last-child th': {
    //   border: 0,
    },
  }));

 
 export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  position :'relative', 
  maxHeight : "640px", 
   zIndex : 0,
  "&::-webkit-scrollbar": {
  width: 0,
  height : 8
  },
  "&::-webkit-scrollbar-track": {
  backgroundColor: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
  backgroundColor: "#aaaaaa",
  borderRadius : 16
  }
}));




