
import { put, call } from "redux-saga/effects";
import { performGetRequest, performPostRequest } from "../../services/apiServices/AxiosUtils";
import { showSnackbarAction } from "../actions/commonActions";


//-----------------------------------------------------------------------------------
export const getProtectedAreas = function* getPA(action) {
  yield put({type : "SHOW_LOADER"})
   try {
    const path = action.path    
    // const path = "/protected_areas";
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ user getProtectedAreas Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getProtectedAreas API error ========", error);
    }
};

//-----------------------------------------------------------------------------------

export const getFilterUIDataSaga = function* getFilterUI(action) {
  yield put({type : "SHOW_LOADER"})
   try {
    const path = action.path    
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ user getFilterUIDataSaga Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getFilterUIDataSaga API error ========", error);
    }
};

//-----------------------------------------------------------------------------------

export const getFilterListData = function* getFilterListData(action) {
  console.log("getFilterListData")
  yield put({type : "SHOW_LOADER"})
  const path = '/list/pa_filters_list';   
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ getFilterFieldsList Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data?.data)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getFilterFieldsList API error ========", error);
    }
};


//-----------------------------------------------------------------------------------

export const getNearByPAs = function* getNearByPAs(action) {
 
  yield put({type : "SHOW_LOADER"})

  const paID = action.payload.id;
  // const paID = 100;
  const startKM = action.payload.startKM;
  const endKM = action.payload.endKM;

  const path = `/protected_areas/${paID}/find_nearby_pa?start_km=${startKM}&end_km=${endKM}`; 
   
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ getNearByPAs Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data?.data)
      yield put({type : "HIDE_LOADER"})
    }else if( res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})

    }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getNearByPAs API error ========", error);
    }
};


//-----------------------------------------------------------------------------------


export const getEszNotificationTracker = function* getEszNotificationTracker(action) {
  yield put({type : "SHOW_LOADER"})
  const path = action.path;    
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ user EszNotificationTracker  Response =======",res);
      
   if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ EszNotificationTracker API error ========", error);
    }
};

//-----------------------------------------------------------------------------------

export const getEszCommitteeTracker = function* getEszCommitteeTracker(action) {
  yield put({type : "SHOW_LOADER"})
  const path = action.path;    
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ user getEszCommitteeTracker  Response =======",res);
      
   if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
    yield put(showSnackbarAction("server error!"))
      console.log("@@@ getEszCommitteeTracker API error ========", error);
    }
};


//-----------------------------------------------------------------------------------

export const getEszNotificationFilterListData = function* getEszNotificationFilterListData(action) {

  yield put({type : "SHOW_LOADER"})
  const path = '/list/esz_notification_filters_list';    
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ getEszNotificationFilterListData Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data?.data)
      yield put({type : "HIDE_LOADER"})
    } else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getEszNotificationFilterListData API error ========", error);
    }
};


//-----------------------------------------------------------------------------------

export const getEszCommitteeFilterListData = function* getEszCommitteeFilterListData(action) {
  
  yield put({type : "SHOW_LOADER"})
  const path = '/list/esz_commitee_filters_list';   
   try {
    const res = yield call(performGetRequest, path);
    console.log(
      "@@@ getEszCommitteeFilterListData Response =======",res);
    if (res !== undefined && res.data.status === "success") {
      yield action.successCallBack(res?.data?.data)
      yield put({type : "HIDE_LOADER"})
    }else if(res.data.status === "error"){
      yield put({type : "HIDE_LOADER"})
      }
  } catch (error) {
    yield put({type : "HIDE_LOADER"})
      console.log("@@@ getEszCommitteeFilterListData API error ========", error);
    }
};


//-----------------------------------------------------------------------------------



