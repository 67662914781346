import {
  Grid,
  Box,
  Typography,
  Avatar,
  Menu,
 useTheme,
 useMediaQuery
} from "@mui/material";
import React, {useState, useEffect} from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LinkBar from "../../components/LinkBar/LinkBar";
import { handleDateFormat } from "../../utils/StatusStyle";


import ReCAPTCHA from "react-google-recaptcha";


import {
  ButtonPrimary,
  PaginationContainerStyled,
  MyBox,
  SocaiIconsRedBorder,
  TextUpperCase,
  BillDateBoxStyled,
} from "../../components/StyledComponents/StyledComponents";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReactComponent as FacebookIcon } from "../../assets/SvgIcons/facebook.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/SvgIcons/linkedIN.svg";



import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../utils/validators";
import Loader from "../../components/Loader/Loader";

const Report = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const loader = useSelector((state) => state.commonReducer.showLoader);
  const { state } = useLocation();
  const id = state ? state.id : null
  console.log("state", id);


  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const isDark = theme.palette.mode == "dark";
  const isUserLoggedIn = getItem('isUserLoggedIn')

  const [reportDetails, setReportDetails] = useState({})
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
 
  const getReportDetailedInfo = () => {
    dispatch({
      type: "GET_ALL_REPORTS",
      successCallBack: successCallBack,
      path: `/contents/reports?search=true&id=${id}`,
    })
  }

  useEffect(() => {
    getReportDetailedInfo()
  }, [])


  const successCallBack = (res) => {
    setReportDetails(res?.data?.data[0])
   } ;

  
  const handleDownloadReport = (event) => {
    if (isUserLoggedIn) {   //check user is logged in
      const reportLink = reportDetails?.doc_detail?.link;
      
      const isPdf = Boolean(reportLink?.includes(".pdf"));
      // const isPdf = reportLink?.includes(".pdf");

      
      if (reportLink != null && isPdf) {
        setAnchorEl(event.currentTarget);
      } else {
        showSnackbar("Report not available");
      }
    } else {
      showSnackbar("You need to log in to download");
    }
  };

  const showSnackbar = (message) => {
    try {
      dispatch({
        type: "SHOW_SNACKBAR",
        message: message,
      });
    } catch (error) {
      console.error("Failed to dispatch Snackbar:", error);
      // Handle the error appropriately, e.g., log it or show a generic error message.
    }
  };

  
  
  const downloadReport = async() => {
    let pdfUrl = reportDetails?.doc_detail?.link 
       try{
        if (pdfUrl) {
          const response = await fetch(pdfUrl);
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
    
          const link = document.createElement('a');
          link.href = url;
          link.download = pdfUrl;
          link.click();
    
          URL.revokeObjectURL(url);
        }
       }catch(error){
      handleClose()
         
       }
      handleClose()
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    downloadReport()
  }

 



  return (
    <Grid container sx={{
      backgroundColor: (t) =>
      t.palette.mode === "dark"
        ? t.palette.background.default
        : theme.palette.grey[100],
    }}>
      <LinkBar title="Reports" subtitle={reportDetails?.title}  />

      {
        loader ? <MyBox sx={{height: '50vh', width : 1}}><Loader /></MyBox> : (
          <>
          <Grid
        container
        sx={{
          // flexDirection: [
          //   "column-reverse",
          //   "column-reverse",
          //   "row",
          //   "row",
          //   "row",
          // ],
          // alignItems: [
          //   "flex-start",
          //   "flex-start",
          //   "center",
          //   "center",
          //   "center",
          // ],
          // flexDirection:"row-reverse",
          alignItems : 'center',
          px: ["0px", "0px", "20px", "32px", "32px"],
         
        }}
      >
        {/* card item */}
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ order : [2,2,1,1,1] }}>
          <Grid container sx={{ backgroundColor: "black" }}>
            <Grid item xs={false} sm={false} md={1} lg={1} xl={2}/>
            <Grid item xs={12} sm={10} md={8} lg={8} xl={8} sx={{  
              px : isXs ? "16px" : "30px",
              py: "30px"
               }}>
              <Grid
                container
                alignItems={"flex-start"}
                flexDirection={"column"}
                sx={{ gap: "20px" }}
              >
                {/* first line */}
                <Grid item container>
                  <Grid item>
                    <TextUpperCase
                      sx={{ borderRight: "1px solid white", pr: 1 }}
                      variant="subtile2"
                      color="vidhiRed.light"
                    >
                      Reports
                    </TextUpperCase>
                  </Grid>
                  <Grid item>
                    <TextUpperCase
                      sx={{ borderRight: "1px solid white", px: 1 }}
                      variant="subtile2"
                      color="text.alwayswhite"
                    >
                      {/* 19 May 2023 */}
                      {handleDateFormat(reportDetails?.date_of_table_of_report)}
                    </TextUpperCase>
                  </Grid>
                  <Grid item>
                    <TextUpperCase
                      sx={{ pl: 1 }}
                      variant="subtile2"
                      color="text.alwayswhite"

                    >
                   9 mins read
                    </TextUpperCase>
                  </Grid>
                </Grid>
                <Typography variant="h2"    color="text.alwayswhite"
>
                  {/* The INDIAN FOREST (AMENDMENT) BILL, 2012 */}
                  {reportDetails?.title}
                </Typography>
                <Typography variant="subheading2" color="text.alwayswhite">
                  {reportDetails?.small_detail}
                  {/* Submission to The Joint Parliamentary Committee on The Forest
                  (Conservation) Amendment Bill 2023 */}
                </Typography>

                <Grid
                  item
                  sx={{ width: 40, height: 4, backgroundColor: "#FF8278" }}
                ></Grid>

                
                <Grid item xs container alignItems="center">
                  <Avatar 
                  sx={{width : "24px",height :"24px"}}
                  src={reportDetails?.committee_details?.profile_picture?.link}
                  // src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                   />
                   
                    <Typography
                      sx={{ ml: 1, cursor :'pointer' }}
                      variant="subtitle2"
                      color="vidhiRed.light"
                      onClick={()=>navigate("/author",{state : {id : reportDetails?.committee_details?.id, type : "committee" }})}
                      
                    >
                      {reportDetails?.committee_details?.name}
                      
                    </Typography>
                 
                </Grid>

                
                <MyBox
               sx={{
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "4px",
                p: "10px",
                cursor : 'pointer'
              }}
              onClick={handleDownloadReport}
            >
              <SimCardDownloadIcon
                sx={{ color: (t) => t.palette.text.alwayswhite }}
              />
            </MyBox>

            <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
         <ReCAPTCHA
              sitekey="6LcMPDQoAAAAANjVknUxEF_-lM_o5QXt-7nXe4bR"
              onChange={onChange}
                />
               
      </Menu>

            
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* image item */}
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ order :[1,1,,2,2,2] }}>
          <Grid container alignItems={"center"}>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                //  ml : ["0px", "0px", "0px", "40px", "50px"],
                //  zIndex : 1,
                position: "relative",
                left: ["0px","0px","-20px", "-30px", "-30px"],
                
                // backgroundColor: "lightGreen",
              }}
              component="img"
              src={reportDetails?.thumbnail_detail?.link}
            >
             
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Image and card container */}

      
      {/* paragraph and image section starts here */}
      <Grid container justifyContent="center" sx={{ py: "24px" }}>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          sx={{
            px: ["15px", "15px", 0, 0, 0],
          }}
           
        >


          
          <MyBox 
          sx={{overflow : 'auto'}}
          >
            <RenderHTML htmlContent={reportDetails?.full_detail}/>
          </MyBox>





          {/* social icon and download button */}
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                position: "relative",
                my: 2,
                "&:last-child>:last-child": {
                  borderRight: "1px solid #DC002D",
                },
              }}
            >
              
              <a href="https://twitter.com/vidhi_india?lang=en" target="_blank" rel="noopener noreferrer">
                    <SocaiIconsRedBorder>
                      <TwitterIcon />
                    </SocaiIconsRedBorder>
                  </a>
                  <a href="https://www.facebook.com/vidhilegalpolicy/" target="_blank" rel="noopener noreferrer">
                    <SocaiIconsRedBorder>
                      <FacebookIcon />
                    </SocaiIconsRedBorder>
                  </a>
                  <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <SocaiIconsRedBorder>
                      <WhatsAppIcon />
                    </SocaiIconsRedBorder>
                  </a>
                  <a href="https://www.linkedin.com/company/vidhi-centre-for-legal-policy/" target="_blank" rel="noopener noreferrer">
                    <SocaiIconsRedBorder>
                      <LinkedInIcon />
                    </SocaiIconsRedBorder>
                  </a>
            </Box>
          </Box>

          <ButtonPrimary
            sx={{ px: 2, my: 2 }}
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={handleDownloadReport}
          >
            Download the Report here
          </ButtonPrimary>

          {/* About the author */}
          <Grid container flexDirection="column" sx={{ gap: "20px", my: 3 }}>
            <Typography variant="subtitle2">ABOUT THE COMMITTEE</Typography>
            {/* <Grid item sx={{backgroundColor : 'khaki'}}>
            <Grid container alignItems="center">
              <Avatar 
              src='https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
              />
              <Typography sx={{ml : 1}} variant="subtitle2" color="vidhiRed.main">HIMANSHU AHLAWAT</Typography>
            </Grid>
          </Grid> */}

            <Grid item xs={12} sm={12} md={8} xl={8} lg={8} container alignItems="center" sx={{gap : "16px"}}>
              <Avatar 
                sx={{width : "36px",height :"36px"}}
                src={reportDetails?.committee_details?.profile_picture?.link}
              // src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
               />
              <Typography
                variant="subtitle2"
                color="vidhiRed.main"
              >
                {reportDetails?.committee_details?.name}
              </Typography>
            </Grid>
            <Typography variant="body1">
            {reportDetails?.committee_details?.bio ?? "No Bio Available"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      </>
        ) 
      }
    </Grid>
  );
};

export default Report;


const RenderHTML = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
