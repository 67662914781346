import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  InputBase,
  Typography,
  TablePagination,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Menu,
  Dialog,
  Divider,
  DialogActions,
  FormControl,
  useMediaQuery
} from "@mui/material";
import LinkBar from "../../components/LinkBar/LinkBar";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { handleDateFormatforFilter, handleStatusColor, handleTextTransform } from "../../utils/StatusStyle";
import {
  FilterStyled,
  MyBox,
  PaginationContainerStyled,
  SearchBarStyled,
  StatusBox,
  ButtonOutlined,
  ButtonPrimary,
  UnderlinedTypography,
} from "../../components/StyledComponents/StyledComponents";
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableRow,
} from "../../components/StyledComponents/StyledTable/styledTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import MySelectMultiple from "../../components/Select/MySelectMultiple";
import MySelect from "../../components/Select/MySelect";
import MySlider from "../../components/Slider/MySlider";
import * as CONST from '../../utils/Contants'
import { useNavigate } from "react-router-dom";
import MyDateRange from "../../components/DateRange/MyDateRange";
import { discussionOnIncreasingList, discussionOnLandscapeList,discussionOnInterStateBorderList, discussionOnLivelihoodList,discussionOnWetlandList, handleTrueFalseValue } from "./discussions";
import  MySliderForESZArea from "../../components/Slider/MySliderForESZArea"

const minDistance = 10;



let minValue = 0
let maxValue = 100
let minDis = 0
let maxDis = 100 



const EszCommittee = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.commonReducer.showLoader);

  let isXs = false;
  isXs = useMediaQuery(theme.breakpoints.down('sm'));


  const [openFilter, setOpenFilter] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [tableSearch, setTableSearch] = useState("");
  const [eszCommitteeData, setCommitteeData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //states for filter
  const [ESZAreaMinMax, setESZAreaMinMax] = React.useState([0, 100]);
  const [EszDistanceMinMax, setEszDistanceMinMax] = useState([0,100]);

  const [value2, setValue2] = React.useState([10, 40]);

  //State for listing filter items
  const [statesList, setStatesList] = useState([]);
  const [eszStatusList, setEszStatusList] = useState([]);
  const [paTypeList, setPaTypeList] = useState([]);
  const [proposalTypeList, setProposalTypeList] = useState([]);
  const [finalDecisionList, setFinalDecisionList] = useState([]);
  const [meetingNumberList, setMeetingNumberList] = useState([]);

  
  //state for holding filter item values
  const [eszStatus, setEszStatus] = useState("");
  const [statesData, setStatesData] = useState([]);
  const [paTypesData, setPaTypesData] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [proposalType, setProposalType] = useState("");
  const [finalDecision, setFinalDecision] = useState("");
  const [meetingNumber, setMeetingNumber] = useState("");
  const [discussionOnIncreasing, setDiscussionOnIncreasing] = useState("")
  const [discussionOnInterStateBorder, setDiscussionOnInterStateBorder] = useState("")
  const [discussionOnLandscape, setDiscussionOnLandscape] = useState("")
  const [discussionOnLivelihood, setDiscussionOnLivelihood,] = useState("")
  const [discussionOnWetland, setDiscussionOnWetland] = useState("")
  
 
  
  
  

  //  const paTypesDataWithID = paTypesData.map( item => item.id)
  //  const statesDataWithID = statesData.map( item => item.id)

    //useEffect for filter item data listing
    useEffect(() => {
      getFilterListData();
    }, []);
  
    //useEffect Esz Committee tracker data api calling
    useEffect(() => {
      if (isFilterApplied) {
        handleFilterApply();
      } else {
        getEszCommitteeData();
      }
    }, [page, rowsPerPage]);

  const getFilterListData = () => {
    dispatch({
      type: "ESZ_COMMITTEE_FILTER_DATA",
      successCallBack: successCallBackFilterListData,
    });
  };

  const getEszCommitteeData = () => {
    dispatch({
      type: "ESZ_COMMITTEE_DATA",
      successCallBack: successCallBack,
      path: `/esz_committee_meetings?page=${page + 1}&per_page=${rowsPerPage}`,
    });
  };

  const getEszCommitteeFilteredData = (path) => {
    dispatch({
      type: "ESZ_COMMITTEE_DATA",
      successCallBack: successCallBackForFilteredData,
      path: path,
    });
  };



  const successCallBackFilterListData = (data) => {
    minValue = data?.esz_min_area
    maxValue = data?.esz_max_area
    minDis = data?.esz_min_distance;
    maxDis = data?.esz_max_distance;
    setPaTypeList(data?.pa_categories_data);
    setStatesList(data?.states_data);
    setEszStatusList(data?.statuses_keys);
    setProposalTypeList(data?.proposal_types_keys)
    setFinalDecisionList(data?.final_decisions_keys)
    let modifiedMeetingNumberArray = data?.meeting_number.map( (item) => { return { name : item.name, id : item.name  }})
    setMeetingNumberList(modifiedMeetingNumberArray)
    let area = [minValue, maxValue]
    let distance = [minDis, maxDis]
    setEszDistanceMinMax(distance)
    setESZAreaMinMax(area)
    };

  const successCallBack = (res) => {
    setCommitteeData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    console.log("table data esz", eszCommitteeData, totalRecords);
  };

  const successCallBackForFilteredData = (res) => {
    setCommitteeData(res?.data?.data);
    setTotalRecords(res?.data?.metadata?.total_count);
    setOpenFilter(false)
    setIsFilterApplied(true)
  };

  const handleChangePage = (event, newPage) => {
    console.log("new Page", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };


  const handleChange1 = (event, newValue, activeThumb) => {
    // if (!Array.isArray(newValue)) {
    //   return;
    // }
      setESZAreaMinMax(newValue)
    
    };

  const handleChange2 = (event, newValue, activeThumb) => {
     setEszDistanceMinMax(newValue)
   };

 
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRecords) : 0;

  // No data found UI
  
  const renderNoDatafound = () => {
    //when no data ui return
    if (eszCommitteeData?.length == 0 && !loader) {
      return (
        <Grid
          item
          xs={12}
          container
          justifyContent={"center"}
          alignContent={"center"}
          sx={{ height: "30vh" }}
        >
          <Typography variant="subheading1">No Data Found</Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ width: "100%", mt: "10px" }}
          >
            {CONST.NoDataFound}
          </Typography>
        </Grid>
      );
    }
    //for searching 
    let filterData = eszCommitteeData?.filter((item) =>
      item?.protected_areas[0]?.name
        ?.toLowerCase()
        .includes(tableSearch.toLowerCase())
    );
    if (tableSearch.length > 0 && filterData.length < 1) {
      return (
        <Grid
          item
          xs={12}
          container
          justifyContent={"center"}
          alignContent={"center"}
          sx={{ height: "30vh" }}
        >
          <Typography variant="subheading1">No data found</Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ width: "100%", mt: "10px" }}
          >
              {CONST.NoDataFound}
          </Typography>
        </Grid>
      );
    }
  };


  const handleChangePaTypesdata = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value",value)
    setPaTypesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };


  const handleChangeStatesData = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);

    setStatesData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  
  const getSelectedPA = () => {
    let str = "";
    paTypesData.forEach( item =>  {
      str = str + "&pa_category_ids[]=" + item.id
   })
   return str
  }

  const getSelectedStates = () => {
    let str = "";
    statesData.forEach( item =>  {
      str = str + "&state_ids[]=" + item.id;
   })
   return str
  }
  

  const handleFilterApply = () => {  
      let path=`/esz_committee_meetings?page=${page + 1}&per_page=${rowsPerPage}`;
      let isSearching = false
      if(paTypesData.length > 0){
        isSearching = true;
        path = path + getSelectedPA()
      }
      if(statesData.length  > 0){
        isSearching = true;
        path = path + getSelectedStates()
      }
      if(dateRange[0].startDate && dateRange[0].endDate ){
        isSearching = true // correct date path later
        path = path + `&meeting_date_start=${handleDateFormatforFilter(dateRange[0].startDate)}&meeting_date_end=${handleDateFormatforFilter(dateRange[0].endDate)}`
      }
      if(meetingNumber){         //discuss and meeting number value or id
        isSearching = true;
        path = path + `&metting_number=${meetingNumber}`;
      }
      if (proposalType) {
        isSearching = true;
        path = path + `&proposal_type[]=${proposalType}`;
      }
      if(discussionOnIncreasing){
        isSearching = true;
        path = path + `&discussion_on_increasing_esz=${handleTrueFalseValue(discussionOnIncreasing)}`;
      }
      if(discussionOnLandscape){
        isSearching = true;
        path = path + `&discussion_on_landscape_connectivity=${handleTrueFalseValue(discussionOnLandscape)}`;
      }
      if(discussionOnInterStateBorder){
        isSearching = true;
        path = path + `&discussion_on_inter_state_border=${handleTrueFalseValue(discussionOnInterStateBorder)}`;
      }
      if(discussionOnWetland){
        isSearching = true;
        path = path + `&discussion_on_wetland_or_marine_water_soruces=${handleTrueFalseValue(discussionOnWetland)}`;
      }
      if(discussionOnLivelihood){
        isSearching = true;
        path = path + `&discussion_on_livelihoods=${handleTrueFalseValue(discussionOnLivelihood)}`;
      }
      if(finalDecision) {
        isSearching = true;
        path = path + `&final_decision[]=${finalDecision}`;
      }
      if (eszStatus) {
        isSearching = true;
        path = path + `&status[]=${eszStatus}`;
      }
      if(ESZAreaMinMax[0] !== minValue || ESZAreaMinMax[1] !== maxValue){
        isSearching = true;
         path = path + `&area_of_esz_start=${ESZAreaMinMax[0]}&area_of_esz_end=${ESZAreaMinMax[1]}`
      }
      if(EszDistanceMinMax[0] !== minDis || EszDistanceMinMax[1] !== maxDis){
        isSearching = true;
        path = path + `&distance_of_esz_start=${EszDistanceMinMax[0]}&distance_of_esz_end=${EszDistanceMinMax[1]}`
      }
      if(isSearching){
        path = path + "&search=true"
      }
      getEszCommitteeFilteredData(path)
  }

//   "params": {
//     "page": 1,
//     "per_page": 2,
//     "search": true,
//     "id": [
//         1,
//         2
//     ],
//     "pa_category_ids": [
//         1,
//         2
//     ],
//     "state_ids": [
//         1,
//         2
//     ],
//     "metting_number": "12",
//     "proposal_type": [],
//     "status": [],
//     "final_decision": [],
//     "area_of_esz_start": "",
//     "area_of_esz_end": "",
//     "discussion_on_increasing_esz": "true",
//     "discussion_on_landscape_connectivity": "false",
//     "discussion_on_inter_state_border": "true",
//     "discussion_on_wetland_or_marine_water_soruces": "true",
//     "discussion_on_livelihoods": "true"
// }

  const handleFilterReset = () => {
    setPaTypesData([])
    setStatesData([])
    setEszStatus("")
    setProposalType("")
    setMeetingNumber("")
    setFinalDecision("")
    setDiscussionOnIncreasing("")
    setDiscussionOnInterStateBorder("")
    setDiscussionOnLandscape("")
    setDiscussionOnLivelihood("")
    setDiscussionOnWetland("")
    setESZAreaMinMax([minValue, maxValue])
    setEszDistanceMinMax([minDis, maxDis])
    setOpenFilter(false)
    setIsFilterApplied(false)
    getEszCommitteeData();
    setDateRange((date) =>  {
      return [{...date[0], startDate : new Date(), endDate : null}]
      })
    }

  return (
    <>
      <Grid
        item
        container
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === "dark"
              ? t.palette.background.default
              : t.palette.grey[100],
        }}
      >
        <LinkBar title="ESZ Committee" />

        {/* heading search and filter contaiener */}
        <Typography variant="h2" sx={{ pl: isXs ? "16px" : '32px', mt: "20px" }}>
          ESZ Committee
        </Typography>
        <Grid
          container
          sx={{
            px: isXs ? "16px" : '32px',
            my: "1.5rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            // sx={{
            //   display: ["none", "block", "block", "block", "block"],
            // }}
          >
            <SearchBarStyled>
              <SearchIcon />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search here"
                inputProps={{ "aria-label": "search google maps" }}
                onChange={(e) => setTableSearch(e.target.value)}
              />
            </SearchBarStyled>
          </Box>
          {
              isXs ? (
                isFilterApplied ? (<FilterAltIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}} /> ) : (<FilterAltOutlinedIcon onClick={handleFilterOpen} sx={{ color : theme.palette.primary.dark}}/>)
              )  : (
                <FilterStyled isFilterApplied={isFilterApplied} onClick={handleFilterOpen}>
                <FilterListIcon />
                <Typography>Filter</Typography>
              </FilterStyled>
              )
            }
        
        </Grid>

        {/* Esz Committee */}
        <Grid item container sx={{ pl: isXs ? "16px" : '32px', pb: "20px", width: "100%" }}>
          {loader ? (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                alignItems: "center",
                width: 1,
              }}
            >
              <Loader />
            </Box>
          ) : (
            <StyledTableContainer>
                <Table
                sx={{ overflowX: "scroll" }}
                aria-label="sticky table"   
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>PA Name</StyledTableCell>
                      <StyledTableCell>PA Type</StyledTableCell>
                      <StyledTableCell>State/UT</StyledTableCell>
                      <StyledTableCell>Meeting Number</StyledTableCell>
                      <StyledTableCell>Meeting Date</StyledTableCell>
                      <StyledTableCell>Proposal Type</StyledTableCell>
                      <StyledTableCell>Min ESZ</StyledTableCell>
                      <StyledTableCell>Max ESZ</StyledTableCell>
                      <StyledTableCell>ESZ Area</StyledTableCell>
                      <StyledTableCell>
                        Discu ssion on increasing ESZ
                      </StyledTableCell>
                      <StyledTableCell>
                        Discussion on landscape connectivity
                      </StyledTableCell>
                      <StyledTableCell>
                        Discussion on Inter-state border
                      </StyledTableCell>
                      <StyledTableCell>
                        Discussion on wetland or marine water soruces
                      </StyledTableCell>
                      <StyledTableCell>
                        Discussion on livelihoods
                      </StyledTableCell>
                      <StyledTableCell>Final Decision</StyledTableCell>
                      <StyledTableCell>ESZ Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eszCommitteeData
                      ?.filter((item) =>
                        item?.protected_areas[0]?.name
                          .toLowerCase()
                          .includes(tableSearch.toLowerCase())
                      )
                      .map((row) => (
                        <StyledTableRow key={row?.id}>
                          {/* <StyledTableCell sx={{minWidth : "200px !important"}}>{row.paName}</StyledTableCell> */}
                          {/* <StyledTableCell sx={{minWidth : "270px !important"}}><Box sx={{display : 'flex', alignItems : 'center', gap : '10px', color : (t)=>t.palette.primary.dark}}><ArrowCircleDownIcon sx={{color : (t) => t.palette.primary.dark, cursor :'pointer'}} /><Typography sx={{fontSize : 14}} color="primary.dark">{row.paName}</Typography></Box></StyledTableCell> */}
                          <StyledTableCell
                            sx={{ minWidth: "270px !important",
                          
                          }}
                          >
                            <Typography
                              sx={{ fontSize: 14,
                              cursor : 'pointer'
                              }}
                              color="primary.dark"
                              onClick={()=>navigate("/paInformation", {state : {  paID : row?.protected_areas[0]?.id}})} 
                            >
                              {row?.protected_areas[0]?.name}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            { row?.protected_areas[0]?.pa_categories?.length !== 0 ? row?.protected_areas[0]?.pa_categories[0]?.name : "NA"}

                            {/* { row?.protected_area_details?.pa_category.length !== 0 ? row?.protected_areas[0]?.pa_category[0]?.name : "NA"} */}

                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            {/* {row?.protected_areas[0]?.states[0]?.name} */}
                            {row?.states[0]?.name ?? "NA"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            {row?.meeting_number}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            <UnderlinedTypography
                            red
                            color="primary.dark"
                              sx={{
                                fontSize :'14px'
                              }}
                              // onClick={()=>navigate("/meeting", { state : { id : row?.id, type : "COMMITTE"} })}
                              onClick={()=>navigate(`/meeting/${row?.id}/committee`)}
                            >
                              {row?.meeting_date}
                            </UnderlinedTypography>
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            {row?.proposal_type ? row.proposal_type : "NA"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.min_area)) }}
                          >
                            {row?.protected_areas[0]?.esz_notification_tracker?.min_area ? `${row?.protected_areas[0]?.esz_notification_tracker?.min_area} km` : "NA"}
                            {/* {row?.min_area != null ? `${row?.min_area} km` : "NA"} */}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.CONSTmax_area)) }}
                          >
                            {row?.protected_areas[0]?.esz_notification_tracker?.max_area ? `${row?.protected_areas[0]?.esz_notification_tracker?.max_area} km` : "NA"}
                         {/* {row?.max_area != null ? `${row?.max_area} km` : "NA"} */}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important", ...(handleTextTransform(row?.area)) }}
                          >
                            {row?.protected_areas[0]?.esz_notification_tracker?.area ? `${row?.protected_areas[0]?.esz_notification_tracker?.area} sq km` : "NA"}
                            {/* {row?.area != null ? `${row?.area} sq km` : "NA"} */}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            {row?.discussion_on_increasing_esz ? "Yes" : "No"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            {row?.discussion_on_landscape_connectivity
                              ? "Yes"
                              : "No"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            {row?.discussion_on_inter_state_border
                              ? "Yes"
                              : "No"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            {row?.discussion_on_wetland_or_marine_water_soruces
                              ? "Yes"
                              : "No"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            {row?.discussion_on_livelihoods ? "Yes" : "No"}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            <MyBox>
                              <StatusBox
                                style={handleStatusColor(row?.final_decision)}
                              >
                                {row?.final_decision ?? "NA"}
                              </StatusBox>
                            </MyBox>
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "200px !important" }}
                          >
                            <MyBox>
                              <StatusBox style={handleStatusColor(row?.protected_areas[0]?.esz_notification_tracker?.status )}>
                                {/* {row?.status ? row.status : "NA"} */}
                                {row?.protected_areas[0]?.esz_notification_tracker?.status ?? "NA"}
                                
                              </StatusBox>
                            </MyBox>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 48 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </StyledTableContainer>
          )}

          {renderNoDatafound()}

          {/* Table Pagination starts here */}
          <Grid item xs={12}>
              {/* Table Pagination starts here */}
              <Grid item xs={12}>
                <PaginationContainerStyled
                  container
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography sx={{ pl: 1 }} variant="body2">
                    {totalRecords} entries found
                  </Typography>
                  <TablePagination
                    component="div"
                    count={totalRecords}
                    page={page}
                    rowsPerPageOptions={[5, 10, 20]}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </PaginationContainerStyled>
                {/* Table pagination end */}
              </Grid>
            </Grid>
        </Grid>

       
      </Grid>
      {/* Filter Dialog start here */}
      <Dialog
        // fullWidth={fullWidth}
        //  maxWidth={maxWidth}
        // maxWidth={['xs','sm','md','xl','lg']}
        // maxWidth={"80vw"}
        fullScreen={isXs}
        open={openFilter}
        onClose={handleFilterClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "90vw",
            // backgroundColor : 'khaki'
          },
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{ width: "100%", mx: "32px", my: "20px", position: "relative" }}
          >
            <Typography variant="h2">Filters</Typography>
            <CloseIcon
              onClick={handleFilterClose}
              sx={{ position: "absolute", right: 0, top: 0 }}
            />
          </Grid>

          {/* top divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Dialog filed container */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1,1fr)",
                  sm: "repeat(2,1fr)",
                  md: "repeat(4,1fr)",
                  lg: "repeat(4,1fr)",
                  xl: "repeat(4,1fr)",
                },
                gap: "20px 50px",
                // gridGap : '50px 50px',
                px: "32px",
                py: "25px",
                boxSizing: "border-box",
              }}
            >
              {/* PA Type Select     */}
              <FormControl fullWidth>
                <Typography>PA Type</Typography>
                <MySelectMultiple
                value={paTypesData}
                onChange={handleChangePaTypesdata}
                menuArray={paTypeList}
                />
                
              </FormControl>

              {/* State Select */}
              <FormControl fullWidth>
                <Typography>State</Typography>
                <MySelectMultiple
                value={statesData}
                onChange={handleChangeStatesData}
                menuArray={statesList}
                />
               
              </FormControl>


                {/*Meeting Date Select */}
                <FormControl fullWidth>
                <Typography>Meeting Date</Typography>
                <MyDateRange
                 onChange={(item) => setDateRange([item.selection])}
                 dateRange={dateRange}
                />
              </FormControl>

              {/* Metting Number Select   */}
              <FormControl fullWidth>
                <Typography>Metting Number</Typography>
                <MySelect 
                value={meetingNumber}
                onChange={(e)=>setMeetingNumber(e.target.value)}
                menuArray={meetingNumberList}
                />
                
              </FormControl>

            

              {/* Proposal Type */}
              <FormControl fullWidth>
                <Typography>Proposal Type</Typography>
                <MySelect
                value={proposalType}
                onChange={(e)=>setProposalType(e.target.value)}
                menuArray={proposalTypeList}
                />
                
              </FormControl>

              {/* Discussion on increasing ESZ */}
              <FormControl fullWidth>
                <Typography>Discussion on increasing ESZ</Typography>
                <MySelect 
                value={discussionOnIncreasing}
                onChange={(e)=>setDiscussionOnIncreasing(e.target.value)}
                menuArray={discussionOnIncreasingList}
                />
               
              </FormControl>

              {/* Discussion on landscape connectivity */}
              <FormControl fullWidth>
                <Typography>Discussion on landscape connectivity</Typography>
                <MySelect
                value={discussionOnLandscape}
                onChange={(e)=>setDiscussionOnLandscape(e.target.value)}
                menuArray={discussionOnLandscapeList}
                />
                
              </FormControl>

              {/* Discussion on inter-state border*/}
              <FormControl fullWidth>
                <Typography>Discussion on inter-state border</Typography>
                <MySelect 
                value={discussionOnInterStateBorder}
                onChange={(e)=>setDiscussionOnInterStateBorder(e.target.value)}
                menuArray={discussionOnInterStateBorderList}
                />
               
              </FormControl>

              {/* Discussion on Wetland marine */}
              <FormControl fullWidth>
                <Typography>Discussion on Wetland marine</Typography>
                <MySelect 
                value={discussionOnWetland}
                onChange={(e)=>setDiscussionOnWetland(e.target.value)}
                menuArray={discussionOnWetlandList}
                />
                
              </FormControl>

              {/* Discussion on livelihoods */}
              <FormControl fullWidth>
                <Typography>Discussion on livelihoods</Typography>
                <MySelect  
                value={discussionOnLivelihood}
                onChange={(e)=>setDiscussionOnLivelihood(e.target.value)}
                menuArray={discussionOnLivelihoodList}
                />
              
              </FormControl>

              {/* Final Decision */}
              <FormControl fullWidth>
                <Typography>Final Decision</Typography>
                <MySelect
                 value={finalDecision}
                 onChange={(e)=>setFinalDecision(e.target.value)}
                 menuArray={finalDecisionList}
                />
                
              </FormControl>

              {/* ESZ Status */}
              <FormControl fullWidth>
                <Typography>ESZ Status</Typography>
                <MySelect
                value={eszStatus}
                onChange={(e) =>setEszStatus(e.target.value)}
                menuArray={eszStatusList}
                />
               
              </FormControl>

              {/* Min ESZ(km)  */}
              <FormControl fullWidth>
                <Typography>ESZ Area (sq km)</Typography>
                <MySliderForESZArea 
                   value={ESZAreaMinMax}
                   onChange={handleChange1}
                   maxValue={maxValue}
                  />
              </FormControl>

              {/* ESZ Area(sqkm)  */}
              <FormControl fullWidth>
                <Typography>Min & Max ESZ (km)</Typography>
                <MySliderForESZArea
                value={EszDistanceMinMax}
                onChange={handleChange2} 
                maxValue={maxDis}
                />
                </FormControl>
            </Box>

            {/* Dialog field container end */}
          </Grid>

          {/* bottom Divider */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <DialogActions sx={{ my: "10px" }}>
          <ButtonOutlined onClick={handleFilterReset} sx={{ textTransform: "none" }}>Reset</ButtonOutlined>
          <ButtonPrimary onClick={handleFilterApply}>Apply</ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EszCommittee;
