import React, {useState, useEffect} from 'react'
import { Grid, Typography, Box, Divider, useTheme, CssBaseline } from '@mui/material'
import { DateBoxStyled, LinkStyled, StatusBox, TabsStyled, TextTruncate3, UnderlinedTypography, UpdatesHeadingGrid} from '../../components/StyledComponents/StyledComponents'
import {  handleStatusColor } from '../../utils/StatusStyle';

import { ReactComponent as QuestionsIcon } from "../../assets/SvgIcons/icon=question.svg";
import { ReactComponent as ReportsIcon } from "../../assets/SvgIcons/icon=reports.svg";
import { ReactComponent as DebatesIcon } from "../../assets/SvgIcons/icon=debates.svg";
import { ReactComponent as BillsIcon } from "../../assets/SvgIcons/icon=bills.svg";

import RecentPublications from './RecentPublications';

// import Swiper core and required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import './custom.css'
import Map from './Map';
import { Link, useNavigate, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import MySwiper from './MySwiper';

const LandingScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.commonReducer.showLoader);
  const loaderMap = useSelector((state) => state.commonReducer.showLoader2);
  const theme = useTheme();

  const [publicationData, setPublicationData] = useState([]);
  const [parliamentaryData, setParliamentaryData] = useState([]);
  const [eszData, setEszData] = useState([]);
  const [nbwlData, setNbwlData] = useState([]);
 
  //get home data api calling
  useEffect(() => {
    getHomePageData()
  }, []);

  const getHomePageData = () => {
    dispatch({
      type: "GET_HOMEPAGE_DATA",
      successCallBack: successCallBack,
    });
  }

 
  const successCallBack = (res) => {
       setPublicationData(res?.data?.publications_details)
       setParliamentaryData(res?.data?.report_bills_details)
       setEszData(res?.data?.esz_notification_trackers_details)
       setNbwlData(res?.data?.nbwls_details)
  }

 
  const handleReadMore = (type, id) => {
     if(type === "Bill"){
      navigate(`/bill`, {state : {id : id}})
     }else if(type === "Report"){
      navigate(`/report`, {state : {id : id}})
    }
  }

  return (
    <>
    <Grid
    item
    container
    alignItems="flex-start"
    sx={{backgroundColor : theme.palette.common.white, flex: 1,}}
    >
    
    
    <Grid item xs={12} sm={12} md={4} xl={4} lg={4} sx={{
      order : [2,2,1,1,1]
      }}>
     
    <Box sx={{ 
      pt: 4, 
      pb : 2,
      pl : ["20px", "20px",4,4,4],
      pr : 2 }}>
    <Typography variant='h3' color="text.primary" sx={{mb : 2,}} gutterBottom>Parliamentary Updates</Typography>

    {/* <Typography>Hello</Typography> */}
    
    <Grid container sx={{gap : 2, margin : "0px 0px", backgroundColor : 'transparent'}}>
      <Grid item xs={12} container sx={{gap : [2,5,2,2,2]}}>
       <LinkStyled to="/questions" sx={{flex : 1}}> <TabsStyled><QuestionsIcon /><Typography  color="text.primary">QUESTIONS</Typography></TabsStyled></LinkStyled>
       <LinkStyled to="/allreports" sx={{flex : 1}}> <TabsStyled><ReportsIcon /><Typography  color="text.primary">REPORTS</Typography></TabsStyled></LinkStyled>
      </Grid>

      <Grid item xs={12} container sx={{gap : [2,5,2,2,2]}}>
     <LinkStyled to="/alldebates" sx={{flex : 1}}><TabsStyled><DebatesIcon /><Typography  color="text.primary">DEBATES</Typography></TabsStyled></LinkStyled> 
     <LinkStyled to="/allbills" sx={{flex : 1}}><TabsStyled><BillsIcon /><Typography  color="text.primary">BILLS/ACT</Typography></TabsStyled></LinkStyled> 
        
      </Grid>
    
    </Grid>
    </Box>

     <Divider />
     
     {/* All updates Container start */}
    <Box sx={{
      height : 500,  
      pl:[0,2,4,4,4], 
      pr: [1,4,2,2,2], 
      mt:1, 
      overflow : 'hidden'}}>

     {
      loader ? (<Loader />) : (
        <Box sx={{height : "100%",  pl : 2, overflowY : "scroll", 
        "&::-webkit-scrollbar":{
               display: "none"
             },
     }}>
        
       {/* All updates single card */}
       <Grid container alignItems={"center"} justifyContent="space-between">
         <Typography sx={{mt : '10px'}} variant='regular3' color="primary.dark">Recent Updates</Typography>
         {/* <Link to="/protectedareas" onClick={()=>{
           setItem("pa",'All')
         }}>
          <Typography variant="body2" color="text.primary" sx={{textDecoration :'underline'}}>View all</Typography>
          </Link> */}
       </Grid>
       <Swiper
            // install Swiper modules
            modules={[Autoplay, Pagination ]}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
             delay: 2500,
             disableOnInteraction: false,
             }}
           className="mySwiper"
           pagination={{clickable : true}}
          >
   
           {
              parliamentaryData?.map( item => (
               <SwiperSlide key={item?.id}>
                <Box sx={{py : 2}}>
       {/* <Grid container alignItems={"center"} justifyContent="space-between">
         <Typography variant='regular3' color="primary.dark">Parliament Updates</Typography>
         <Link to="/protectedareas" onClick={()=>{
           setItem("pa",'All')
         }}>
          <Typography variant="body2" color="text.primary" sx={{textDecoration :'underline'}}>View all</Typography>
          </Link>
       </Grid> */}
   
       <UpdatesHeadingGrid container>
         <Typography variant="h4" sx={{width : "70%"}} color="text.primary">{item?.title}</Typography>
         <DateBoxStyled><Typography variant="body3" color="primary.dark">{item?.date}</Typography></DateBoxStyled>

       </UpdatesHeadingGrid>
   
       <Box sx={{
         my: 2,
         
       }}>
       <TextTruncate3 variant='body1' color="text.primary"
       >
         {
           item?.small_detail
         }
         {/* The Constitution Bench held that the Supreme Court had the power to grant divorce to parties who directly approached it, by using the SC's discretionary Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt sed quos architecto, voluptatum laborum omnis aliquam ipsum excepturi magnam quae non nam cumque neque necessitatibus similique, doloribus repudiandae in itaque reiciendis accusamus! Aut nesciunt unde ex vitae mollitia blanditiis ut! */}
       </TextTruncate3>
       {/* <LinkStyled><Typography variant='body1' color="primary.dark">Read More</Typography></LinkStyled> */}
       <Typography
       onClick={()=>handleReadMore(item?.type, item?.id)}
       sx={{cursor : 'pointer'}}
       variant='body1' color="primary.dark">Read More</Typography>
       </Box>
   
       </Box>
         </SwiperSlide>
              
             ))
   
           }
         
       
         
       </Swiper>
      
   
       <Divider />
       
       <Box sx={{py : 2}}>
       <Grid container alignItems={"center"} justifyContent="space-between"  sx={{mb : 'px'}}>
         <Typography variant='regular3' color="primary.dark">ESZ Updates</Typography>
          <UnderlinedTypography variant="body2" onClick={()=>navigate("/esznotification")} >View all</UnderlinedTypography>
       </Grid>

       
        <MySwiper>
        {eszData?.map((item) => (
    <SwiperSlide key={item?.id} >
      <Box sx={{ py: 2, minHeight : "90px" }}>
        <UpdatesHeadingGrid container>
        <Typography variant="h4" color="text.primary" sx={{width : "70%"}}>{item?.protected_area}</Typography>
         <StatusBox style={handleStatusColor(item?.status)}>{item?.status}</StatusBox>
         </UpdatesHeadingGrid>
         <Grid container sx={{py : 1}} >
         <Grid item sx={{flex : 1, pl : 2}}><Grid container flexDirection="column"><Typography variant='body3' color="primary.dark">Protected Area</Typography><Typography variant='subtitle1' color="text.primary">{item?.protectedarea_categories[0]?.name}</Typography></Grid></Grid>
         <Grid item sx={{flex : 1, borderLeft : "1px solid #DCDCDC", pl : 2}}><Grid container flexDirection="column"><Typography variant='body3'  color="primary.dark">State</Typography><Typography variant='subtitle1' color="text.primary">{item?.state}</Typography></Grid></Grid>
         <Grid item sx={{flex : 1, borderLeft : "1px solid #DCDCDC", pl : 2}}><Grid container flexDirection="column"><Typography variant='body3'  color="primary.dark">ESZ Area (in sq km)</Typography><Typography variant='subtitle1' color="text.primary">{item?.area}</Typography></Grid></Grid>
       </Grid>
      </Box>
    </SwiperSlide>
  ))}
        </MySwiper>
       
      </Box>
   
       <Divider />
       <Box sx={{py : 2}}>
       <Grid container alignItems={"center"} justifyContent="space-between"  sx={{mb : 'px'}}>
       <Typography variant='regular3' color="primary.dark">NBWL Updates</Typography>
          <UnderlinedTypography onClick={()=>navigate("/nbwl")} variant="body2">View all</UnderlinedTypography>
       </Grid>

       
        <MySwiper>
        {nbwlData?.map((item) => (
       <SwiperSlide key={item?.id} >
      <Box sx={{ my: 2, minHeight : "90px" }}>
        <UpdatesHeadingGrid container>
        <Typography variant="h4" color="text.primary" sx={{width : "70%"}}>{item?.protected_area}</Typography>
         <StatusBox style={handleStatusColor(item?.final_decision)}>{item?.final_decision}</StatusBox>
         </UpdatesHeadingGrid>
         
         <Grid container alignItems="baseline" columnGap={2} sx={{mt : '10px'}}>
       <Typography color="text.primary">{item?.state}</Typography>
       <Typography sx={{ml: 1, mt : 1}} variant='subtitle1' color="text.primary">Meeting Date - <Typography component="span" color="primary.dark">{item?.meeting_date}</Typography> </Typography>
       </Grid>
      </Box>
    </SwiperSlide>
  ))}
        </MySwiper>
       
      </Box>

        </Box>
      )
     } 
   
    
   </Box>

    </Grid>

    {/* Map item Grid starts here */}
    <Grid item xs={12} sm={12} md={8} xl={8} lg={8}  sx={{ pt: 4, order : [1,1,2,2,2] }}>
    <Typography variant='h3' sx={{mb : 2, pl : ['20px', "20px", 0,0,0]}} color="text.primary">Protected Areas</Typography>
     <Map  loader={loaderMap}/>
   </Grid> 



    </Grid>
     <RecentPublications data={publicationData} loader={loader}/>
     </>
  )
}

export default LandingScreen