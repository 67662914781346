export const pa = [{
    id : 1,
    title : "National Park",
    path : "/protectedareas"
  },
  {
    id : 2,
    title : "Wildlife Sanctuary",
    path : "/protectedareas"
  },
  {
    id : 3,
    title : "Conservation Reserve",
    path : "/protectedareas"
  },
  {
    id : 4,
    title : "Community Reserve",
    path : "/protectedareas"
  },
]


export const esz = [{
    id : 1,
    title : "ESZ Notification Tracker",
    path : "/esznotification"
  },
  {
    id : 2,
    title : "ESZ Committee",
    path : "/eszcommittee"
  },
]

export const parliametryUpdates = [{
    id : 1,
    title : "Questions",
    path : "/questions"
  },
  {
    id : 2,
    title : "Debates",
    path : "/alldebates"
  },
  {
    id : 3,
    title : "Reports",
    path : "/allreports"
  },
  {
    id : 4,
    title : "Bills/Act",
    path : "/allbills"
  },
]

export const researchUpdates = [{
    id : 1,
    title : "Publications",
    path : "/allpublications"
  },
  // {
  //   id : 2,
  //   title : "Internal Reports",
  //   path : "/"
  // },
]